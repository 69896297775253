<ng-template #customLoadingTemplate>
    <div class="ctmloader">
        <img src="./../../../assets/images/loader.svg">
    </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>

<div class="back">
    <div class="container-fluid pt-3">
        <div class="ctm-body">
            <div class="d-flex mb-1 align-items-center">
                <h4>Evaluation Report for </h4>
                <div class="ml-3 pb-3 d-flex  align-items-center">
                  <button style="font-size: 25px;padding: 2px 10px 2px 10px;" class="btn2 btn arrowhover"  [disabled]="previousMonthClickDisable" (click)="onPrevClick()">
                  < 
                  </button>
                  <span style="font-size: 20px;font-weight: 600;"> &nbsp;{{ currentDate | date: 'MMM yyyy'}}  &nbsp;</span>
                  <button style="font-size: 25px;padding: 2px 10px 2px 10px;" class="btn2 btn arrowhover"
                  (click)="onNextClick()" [disabled]="futureMonthClickDisable"> > 
                  </button>
               </div>
                <!-- <div class="ml-auto">
                   <b style="font-size: 1rem;    padding: 75px;">My Projects Resource Count : <span
                      class="fc">{{resourceCount}}</span></b>
                </div> -->
                <div class="ml-auto">
                  <button type="button" (click)="exportExcel()" [value]="" selectionMode="multiple"
                  class="btn cstbtn btn2"><i class="fa fa-download" aria-hidden="true"></i> Download this report as Excel</button>
                </div>
             </div>

             <div class="ctm-card">
              <p-table class="bordertop" dataKey="resource_estimate_master_id"
             [value]="evaluationData" #dt [paginator]="true" [rows]="20"
             [rowsPerPageOptions]="[30,40,50]" pageLinks="3" [lazy]="false"
             [totalRecords]="evaluationData.length" [globalFilterFields]="['resource_name','project_name','client_name','overall_score','delivery_performance','demonstrated_solutioning','project_escalations',
             'showcased_dependability','demonstrated_initiative','remarks']">
                <ng-template pTemplate="caption">
                  <div class="d-flex ctmfilter align-items-end">
                    <div>
                      <label class="d-block text-left" for="exampleFormControlSelect1">Clients</label>
                      <select [ngModel]="client" [formControl]="clientControl" style="width: 200px;" class="form-control"
                        id="exampleFormControlSelect1" (change)="onClientChange(clientControl.value,$event)">
                        <option value='All' selected>All</option>
                        <option *ngFor="let client of clients" value="{{client.client_name}}">
                          {{client.client_name }}
                        </option>
                      </select>
                    </div>
                    <div class="ml-4">
                      <label class="d-block text-left" for="exampleFormControlSelect1">Projects</label>
                      <select style="width: 200px;" [ngModel]="project" [formControl]="projectControl"  class="form-control"
                        id="exampleFormControlSelect1">
                        <option value='All' selected>All</option>
                        <option *ngFor="let project of projects" value="{{project.project_name}}">
                          {{project.project_name }}
                        </option>
                    </select>
                    </div>

                    <div class="ml-3" hidden>
                     <label for="dtPicker" id="lbldtpicker"
                        class="d-block text-left control-label mr-2">OverAll Score </label>
                     <select style="max-width: 100px;" [ngModel]="selectedOverallscore" [formControl]="overallscoreControl" class="form-control">
                     <!-- //(change)="onClientChange(selectedClient)">-->
                     <option value="0">All</option>
                     <option *ngFor="let os of overallscores" [value]="os">
                        <span *ngIf="os == 1"> &nbsp;Poor&nbsp;</span>
                                  <span *ngIf="os == 2"> &nbsp;Average&nbsp;</span>
                                    <span *ngIf="os == 3"> &nbsp;Good&nbsp;</span>
                                       <span *ngIf="os == 4"> &nbsp;Exceptional&nbsp;</span>
                     </option>
                     </select>
                  </div>
                  <div class="ml-3" hidden>
                     <label for="dtPicker" id="lbldtpicker"
                        class="d-block text-left control-label mr-2">Delivery Performance </label>
                     <select style="max-width: 100px;" [ngModel]="selectedDeliveryPerformance" [formControl]="deliveryPerformanceControl" class="form-control">
                     <option value="0">All</option>
                     <option *ngFor="let dp of deliveryPerformances" [value]="dp">
                        <span *ngIf="dp == 1"> &nbsp;Poor&nbsp;</span>
                                  <span *ngIf="dp == 2"> &nbsp;Average&nbsp;</span>
                                    <span *ngIf="dp == 3"> &nbsp;Good&nbsp;</span>
                                       <span *ngIf="dp == 4"> &nbsp;Exceptional&nbsp;</span>
                     </option>
                     </select>
                  </div>
                   <!-- <div class="ml-3">
                      <label class="d-block text-left" for="exampleFormControlSelect1">Overall Score</label>
                      <select class="form-control">
                          <option>All</option>
                      </select>
                      </div> -->
                   <div class="ml-3" hidden>
                      <label class="d-block text-left" for="exampleFormControlSelect1">Escalations</label>
                      <select style="max-width: 100px;" class="form-control" [formControl]="escalationControl"
                      [ngModel]="selectedEscalation">
                      <option value="0">All</option>
                      <option value="Yes">YES</option>
                      <option value="No">NO</option>
                      </select>
                   </div>
                   <button type="button" class="btn cstbtn btn1 ml-3 mb-1" (click)="applyFilters()"><i
                      class="fa fa-filter" aria-hidden="true"></i> Apply filters</button>
                   <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;"
                      class="fa fa-times red" aria-hidden="true"></i> Reset Filters</div>
                   <!-- <div class="ml-auto  bl-1">
                      <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
                      <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                      <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                         (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                      
                   </div> -->
                   <div class="ml-auto  bl-1">
                     <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
                     <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                   <input type="text" pInputText size="40" [(ngModel)]="freeText" style="width:auto" placeholder="What are you looking for ?" (ngModelChange)="onFreeTextChange(freeText)" >
                  </div>
                </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="resource_name">
                      Name,<br> <span style="color: #8e908a;">RWT
                      (Level)</span><br>Role
                      <p-sortIcon field="resource_name"></p-sortIcon>
                   </th>
                    <th pSortableColumn="client_name">Client<p-sortIcon field="client_name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="project_name">
                      Project<br> <span style="color: #8e908a;">(Sub
                      Project)</span>
                      <p-sortIcon field="client"></p-sortIcon>
                   </th>
                    <th pSortableColumn="">Overall Score
                    </th>
                    <th pSortableColumn="">Delivery Performance 
                    </th>
                    <th pSortableColumn="">Demonstrated Solutioning
                    </th>
                    <th pSortableColumn="">Project Escalation
                    </th>
                    <th pSortableColumn="">Showcased Dependability
                    </th>
                    <th pSortableColumn="">Demonstrated Initiative
                    </th>
                    <th pSortableColumn="">Detailed Remarks
                    </th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-project let-editing="editing" let-ri="rowIndex">
                  <tr >
                     <td class="namerwt">
                        <div>{{project.resource_name}},</div>
                        <p>{{project.rwt_name}} ({{project.level_name}})</p>
                        <div>{{project.role}}</div>
                     </td>
                     <td class="namerwt">
                      <div>{{project.client_name}}</div>
                      
                   </td>
                     <td class="namerwt">
                        <div>{{project.project_name}}</div>
                        <p *ngIf="project.sub_project_id === ''">(NA)</p>
                      <p *ngIf="project.sub_project_id !== ''">({{project.sub_project_id}})</p>
                     </td>
                     <td style="font-weight: bold;">
                        <p-cellEditor>
                           <ng-template pTemplate="output">
                              <div class="text-center">
                                 <span [ngClass]="{
                                    'text-danger':project.overall_score == 1,
                                    'text-warning':project.overall_score == 2,
                                    'text-success':project.overall_score == 3,
                                    'text-green':project.overall_score == 4
                                    }">
                                    <span *ngIf="project.overall_score == 1"> &nbsp;Poor&nbsp;</span>
                                    <span *ngIf="project.overall_score == 2"> &nbsp;Average&nbsp;</span>
                                      <span *ngIf="project.overall_score == 3"> &nbsp;Good&nbsp;</span>
                                         <span *ngIf="project.overall_score == 4"> &nbsp;Exceptional&nbsp;</span>
                                   </span>
                              </div>
                           </ng-template>
                        </p-cellEditor>
                     </td>
                     <td style="font-weight: bold;">
                        <ng-container *ngIf="project.demonstrated_solutioning == '-2'">
                           NA
                        </ng-container>
                        <ng-container *ngIf="project.demonstrated_solutioning != '-2'">
                           <p-cellEditor>
                              <ng-template pTemplate="output">
                                 <div class="text-center">
                                    <span [ngClass]="{
                                       'hide':project.delivery_performance == -2,
                                       'text-danger':project.delivery_performance == 1,
                                       'text-warning':project.delivery_performance == 2,
                                       'text-success':project.delivery_performance == 3,
                                       'text-green':project.delivery_performance == 4
                                       }">
                                       <span *ngIf="project.delivery_performance == 1"> &nbsp;Poor&nbsp;</span>
                                       <span *ngIf="project.delivery_performance == 2"> &nbsp;Average&nbsp;</span>
                                         <span *ngIf="project.delivery_performance == 3"> &nbsp;Good&nbsp;</span>
                                            <span *ngIf="project.delivery_performance == 4"> &nbsp;Exceptional&nbsp;</span>
                                      </span>
                                 </div>
                              </ng-template>
                           </p-cellEditor>
                        </ng-container>
                     </td>
                     <td class="switchalign">
                        <ng-container *ngIf="project.demonstrated_solutioning == '-2'">
                           <b>NA</b>
                        </ng-container>
                        <ng-container *ngIf="project.demonstrated_solutioning !== '-2'">
                           <p-cellEditor>
                              <ng-template pTemplate="output">
                                 <b *ngIf="project.demonstrated_solutioning ==='0'"
                                    class="text-danger">NO</b>
                                 <b *ngIf="project.demonstrated_solutioning==='1'"
                                    class="text-success">&nbsp;YES&nbsp;</b>
                                 <b *ngIf="project.demonstrated_solutioning==='-1'"
                                    class="">&nbsp;NA&nbsp;</b>
                                 <b *ngIf="project.demonstrated_solutioning==='-2'"
                                    class="">&nbsp;NA&nbsp;</b>
                              </ng-template>
                           </p-cellEditor>
                        </ng-container>
                     </td>
                     <td class="switchalign">
                        <ng-container *ngIf="project.project_escalations == '-2'">
                           <b> NA</b>
                        </ng-container>
                        <ng-container *ngIf="project.project_escalations !== '-2'">
                           <p-cellEditor>
                              <ng-template pTemplate="output">
                                 <b *ngIf="project.project_escalations ==='0'" class="text-success">NO</b>
                                 <b *ngIf="project.project_escalations==='1'"
                                    class="text-danger">&nbsp;YES&nbsp;</b>
                                 <b *ngIf="project.project_escalations==='-1'" class="">&nbsp;NA&nbsp;</b>
                                 <b *ngIf="project.project_escalations==='-2'" class="">&nbsp;NA&nbsp;</b>
                              </ng-template>
                           </p-cellEditor>
                        </ng-container>
                     </td>
                     <td>
                        <p-cellEditor>
                           <ng-template pTemplate="output">
                              <b *ngIf="!project.showcased_dependability" class="text-danger">NO</b>
                              <b *ngIf="project.showcased_dependability" class="text-success">YES</b>
                           </ng-template>
                        </p-cellEditor>
                     </td>
                     <td>
                        <p-cellEditor>
                           
                           <ng-template pTemplate="output">
                              <b *ngIf="!project.demonstrated_initiative" class="text-danger">NO</b>
                              <b *ngIf="project.demonstrated_initiative" class="text-success">YES</b>
                           </ng-template>
                        </p-cellEditor>
                     </td>
                     <td>
                       
                      
                        <!-- <ng-template>
                           <textarea style="width: 150px;" pInputTextarea class="form-control" (change)="changeFn(project)"
                           [(ngModel)]="project.remarks"></textarea>
                        </ng-template> -->
                        <p-cellEditor>
                          <ng-template pTemplate="output">
                            <span [innerHTML]="project.remarks" class="preserve-newlines"></span>
                         </ng-template>
                      </p-cellEditor>
                       
                     </td>
                    
                  </tr>
               </ng-template>
              </p-table>
              </div>
        </div>
    </div>
</div>