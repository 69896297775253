import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as ConfigJson from '../../assets/config.json';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { RecommendationRelatedCloudWatchMetricsSourceDetails } from 'aws-sdk/clients/devopsguru';

// export interface Resource{
//   resource_id?:number;
//   employee_id?:number;
//   resource_name?:string;
//   resource_email?:string;
//   resource_start_date?:string;
//   resource_last_date?:string;
//   location_id?:number;
//   sub_location_id?:number;
//   billable_percent?:Number;
//   rwtid?:Number;
//   line_group_id?:number;
//   resource_rm_id?:Number;
//   rm_name?:ResourceWithCognitoGroupModel;
//   rm_email?:string;
//   department_id?:Number;
//   is_active: boolean;
//   sebatical_id?:number;
//   start_date?:string;
//   end_date?:string;
//   rwt_name?:string;
//   line_group_name?:string;
//   department_name?:string;
//   location_name?:string;
//   level_name?:string;
//   sub_location_name?:string;
//   cognito_user_name?:string;
//   Cognito_Group?:string;
// }

export interface Resource {
  resource_id?: number;
  employee_id?: number;
  resource_name?: string;
  resource_email?: string;
  resource_start_date?: string;
  resource_last_date?: string;
  location_id?: number;
  sub_location_id?: number;
  billable_percent?: number;
  rwtid?: Number;
  line_group_id?: number;
  resource_rm_id?: number;
  rm_name?: ResourceWithCognitoGroupModel;
  rm_email?: string;
  department_id?: number;
  is_active: boolean;
  sebatical_id?: number;
  start_date?: string;
  end_date?: string;
  rwt_name?: string;
  line_group_name?: string;
  department_name?: string;
  location_name?: string;
  level_name?: string;
  sub_location_name?: string;
  cognito_user_name?: string;
  Cognito_Group?: string;
  bu?: string;
  sub_bu?: string;
  rwt_name_history?: string,
  level?: number,
  rwt_start_date?: string,
  rwt_end_date?: string,
}

export interface RwtModel {
  rwtid?: number;
  rwt_level_id?: number;
  rwt_name?: string;
  level_id?: number;
  level_name?: string;
  location_id?: number;
  location_name?: number;
  baseline_rate?: Number;
  r_status?: string;
  end_date?:string;
  start_date?:string;
  cost_per_hr?:string;
  RWT_Cost_id?:string;
}

export interface ResourceCognitoGroupModel {
  resource_id?: number;
  resource_name?: string;
  resource_email?: string;
  location_id?: number;
  sub_location_id?: number;
  department_id?: Number;
  department_name?: string;
  location_name?: string;
  sub_location_name?: string;
  cognito_user_name?: string;
  cognito_group_id?: number;
  role_name?: string;
  cognito_group_name?: string;
}
export interface ResourceWithCognitoGroupModel {
  resource_id?: number;
  resource_name?: string;
  resource_email?: string;
  location_id?: number;
  location_name?: string;
  sub_location_id?: number;
  sub_location_name?: string;
  resource_rm_id?: number;
  department_id?: Number;
  cognito_user_name?: string;
  cognito_group_id?: number;
  role_name?: string;
  cognito_group_name?: string;

}

export interface ResourceWithDetails extends Resource {
  rwt_details: {
    rwt_name_history: string;
    level: number;
    rwt_start_date: string;
    rwt_end_date: string;
    location_name: string;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  base_url = ConfigJson.API_URL
  resource: any;
  constructor(private http: HttpClient, private storage: StorageService) {
    this.resource = JSON.parse(this.storage.getItem('user'))
  }

  getManageResource() {
    return this.http.get<any>(this.base_url + 'getManageResource')
      .toPromise()
      .then(res => <Resource[]>res).then(data => { return data; });
  }

  getResourceWithCognitoGroup() {
    return this.http.get<any>(this.base_url + 'getResourceWithCognitoGroup')
      .toPromise()
      .then(res => <ResourceWithCognitoGroupModel[]>res).then(data => {
        return data;
      });
  }

  getAllMasterData() {
    return this.http.get<any>(this.base_url + "getAllMasterData")
      .toPromise().then(data => {
        return data;
      });
  }
  postManageResourceData(manageResourceData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'addUpdateResourceData', manageResourceData)
  }
  postCognitoGroupData(cognitoResourceData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'addUpdateResourceCognitoGroup', cognitoResourceData)
  }

  getManageRWT() {
    return this.http.get<any>(this.base_url + 'getManageRWT')
      .toPromise()
      .then(res => <RwtModel[]>res).then(data => { return data; });
  }
  postManageRWTData(manageRWTData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'addUpdateRWTData', manageRWTData)
  }
  getManageCognitoGroup(getType, cognitoID) {
    return this.http.get<any>(this.base_url + "getManageCognitoGroup?getType=" + getType + "&cognitoID=\'" + cognitoID + "\'")
      .toPromise()
      .then(res => { return res; });
  }
  postManageCognitoGroupData(manageCognitoGroupData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'addUpdateCognitoGroupDB', manageCognitoGroupData)
  }
  getAddDeleteSabbaticalByResourceID(resourceID, sabaticalID, startDate, endDate, opType) {
    return this.http.get<any>(this.base_url + "getAddDeleteSabbaticalByResourceID?resourceID=" + resourceID + "&sabaticalID=" + sabaticalID + "&startDate=" + startDate + "&endDate=" + endDate + "&opType=" + opType + "")
      .toPromise()
      .then(res => { return res; });
  }
  getProjectEvaluationReportByAdmin(year, month_number, month_name, userRole) {
    // let apiEndPoint =  'getProjectEvaluationByAdmin';
    let apiEndPoint = (userRole === 'HR Admin' ? 'getProjectEvaluationByAdmin' : userRole === 'Delivery Partner' ? 'getProjectEvaluationReportByDP' : 'getProjectEvaluationReportByEP');
    return this.http.get<any>(this.base_url + apiEndPoint + '?evaluation_year=\'' + year + '\'&month_number=\'' + month_number + ''
      + '\'&month_name=\'' + month_name + '' + '\'')
      .toPromise().then(data => { return data; });
  }
  getAllProjectList(userRole, userName) {
    return this.http.get<any>(this.base_url + "GetALLProjects?userRole=\'" + userRole + "\'")
      .toPromise().then(data => {
        return data;
      });
  }
  postResourceEmpHistory(empHistoryData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'insertEmploymentHistory', empHistoryData)
  }
  getResourceEmpHistory(resourceID) {
    return this.http.get<any>(this.base_url + 'getEmploymentHistory?resourceID=\'' + resourceID + "\'")
      .toPromise().then(data => { return data; });
  }
  getSkillList() {
    return this.http.get<any>(this.base_url + "getskilldata")
      .toPromise().then(data => {
        return data;
      });
  }
  postResourceSkillData(empSkillData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'insertResourceSkillSet', empSkillData)
  }

  getResourceSkillList(CognitoUserName) {
    return this.http.get<any>(this.base_url + 'getresourceskilldata?CognitoUserName=\'' + CognitoUserName + "\'")
      .toPromise().then(data => { return data; });
  }

  postMasterSkillData(masterSkillData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'insertMasterSkill', masterSkillData)
  }

  // getPresignedUrl(fileName) {
  //   return this.http.get(this.base_url + 'presignedURL?FileName='+fileName).toPromise()
  // }

  getPresignedUrl(fileName) {
    return this.http.get(this.base_url + 'presignedURL?FileName=' + fileName + '&Type=Resume').toPromise()
  }


  getDownloadURl(fileName) {
    return this.http.get(this.base_url + 'presignedDownloadURL?FileName=' + fileName + '&Type=Resume').toPromise()
  }
}
