import { Component, OnInit, ViewChild  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ProjectService } from 'src/app/_services/project.service';
import { StorageService } from 'src/app/_services/storage.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-projects-status',
  templateUrl: './add-projects-status.component.html',
  styleUrls: ['./add-projects-status.component.scss'],
  providers: [MessageService],
})
export class AddProjectsStatusComponent implements OnInit {
  @ViewChild('dateSelector', undefined) private dateSelector: any;
  toFile;
  values;
  loading: boolean;
  projectId: any;
  projectDetail: any;
  projectStatusObj={
    project_id:'',
    Remarks:'',
    file_name:'',
    week_no:0,
    year:'',
    entry_start_date:null,
    entry_end_date:null,
    status:'On Track',
    createdby:''
  };
  options:any;
  selectedColor: any;
  visibleStatus: boolean=false;
  maximunDate:Date;
  projectStatusDetail: any;
  filteredProjectStatusDetail: any;
  downloadProjectStatusUrl:any ='';
  isFileDownload: boolean;
  constructor(
    private http: HttpClient, 
    private location: Location,
    private projectService: ProjectService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private storage: StorageService
  ) {
    
  }

  changeStatus(){
    this.visibleStatus=!this.visibleStatus
  }
  ngOnInit() {
    this.options = [
      {
        name: 'On Track',
        color: '#679c29'
      },
      {
        name: 'Issues',
        color: ' #f9b538'
      },
      {
        name: 'Attention',
        color: '#ed4d4d'
      }
    ];
    this.loading = true;
   
    this.selectedColor='#679c29';
    let resource = JSON.parse(this.storage.getItem('user'));
    
    this.projectId = this.activatedRoute.snapshot.params['id'];
    
    let startDate=moment().weekday(1).format('MM/DD/YYYY')
    let lastDate=moment().weekday(5).format('MM/DD/YYYY')
    this.maximunDate=new Date(lastDate);
    this.projectStatusObj.createdby=resource.userID;
    this.projectStatusObj.project_id=this.projectId;
    this.projectStatusObj.entry_start_date=startDate;
    this.projectStatusObj.entry_end_date=lastDate;
    
    let today = new Date(lastDate);
    let firstDate = new Date(startDate);
    this.values = [ firstDate, today];
    this.projectStatusObj.week_no=moment(lastDate).weeks();
    this.projectStatusObj.year=moment(lastDate).format('YYYY')
    // this.values[0]=moment().clone().weekday(1);
    // this.values[1]=moment().clone().weekday(7);
    this.projectService.getProjectDetails(this.projectId).then(project=>{
      this.projectDetail = project[0];
    }).catch(()=>{
      this.loading = false;
    })
    this.projectService.geProjectStatusDetails(this.projectId).then(projectData =>{
      this.projectStatusDetail = projectData;
      this.filterProjectStatusByWeek(this.projectStatusObj.week_no,this.projectStatusObj.year)
      this.loading = false;
    }).catch(()=>{
      this.loading = false;
    })
  }

  onDropdownChange(event){
    this.selectedColor = this.options[event.target.options.selectedIndex].color;
  }
 
 
  Reset(){
    this.location.back();
  }
  submit() {
    let projectArrayObj=[];
    projectArrayObj.push(this.projectStatusObj)
    if(this.projectStatusObj.entry_start_date !==null){
      if(this.toFile){
        if(this.projectStatusObj.status!=='On Track' && this.projectStatusObj.Remarks===''){
          Swal.fire("Remarks are mandatory when project statsus is not 'On Track'",'','warning')
        }else{
            this.loading = true;
            const file = this.toFile.item(0);
            let timestamp= moment().format("DDMMYYYYHHMMSS")
            this.projectStatusObj.file_name=timestamp+"_"+file.name;
            this.projectService.getPresignedUrl(this.projectStatusObj.file_name,file,projectArrayObj).then(dataObj => {
              const presigned_url = dataObj['url'];
              const headers = new HttpHeaders({ 'Content-Type':'multipart/form-data'});
              const requestOptions = {                                                                                                                                                                                 
                headers: headers 
              };
              
              this.http.put(presigned_url,file,requestOptions).toPromise().then(uploaded=>{
                this.projectService.insertProjectStatusDetails(projectArrayObj).then(submitData => {
                  if (submitData[0]) {
                    this.loading = false;
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Project status updated Successfully ',
                      life: 3000,
                    });
                    setTimeout(()=>{
                      this.router.navigate(['/projects']);
                    },1500)
        
                  }
                }).catch(err => {
                  this.loading = false;
                 // console.log('EROOR: ', JSON.stringify(err));
                  return false;
                })
              }).catch(err => {
                this.loading = false;
               // console.log('EROOR: ', JSON.stringify(err));
                return false;
              })
            }).catch(err => {
              this.loading = false;
              //console.log('EROOR: ', JSON.stringify(err));
              return false;
            })
        }
        
      }else{
        
        if(this.projectStatusObj.status!=='On Track' && this.projectStatusObj.Remarks===''){
          this.loading = false;
          Swal.fire("Remarks are mandatory when project status is not 'On Track'",'','warning')
        }else{
          this.projectService.insertProjectStatusDetails(projectArrayObj).then(submitData => {
            if (submitData[0]) {
              this.loading = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Project status updated Successfully ',
                life: 3000,
              });
              setTimeout(()=>{
                this.router.navigate(['/projects']);
              },1500)
            }
          }).catch(err => {
            this.loading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Project status not updated',
              life: 3000,
            });
          })
        }
        
      }
    }else{
      Swal.fire('Please select the week for project status','','warning')
    }

  }

  onChange(event) {
    this.toFile = event.target.files;

  }

  select(evt) {
    let start = new Date(evt);
    start.setDate(start.getDate() - (start.getDay()-1));
    this.values[0] = start;

    let end = new Date(start);
    end.setDate(start.getDate() + 4); 
    this.values[1] = end;
    this.projectStatusObj.entry_start_date=moment(start).format('YYYY-MM-DD');
    this.projectStatusObj.entry_end_date=moment(end).format('YYYY-MM-DD');
    this.projectStatusObj.week_no=moment(end).weeks();
    // this.projectStatusObj.week_no=moment(end).week();
    // this.projectStatusObj.week_no=moment(end).weekYear();
    this.projectStatusObj.year=moment(end).format('YYYY');
    this.projectStatusObj.status = 'On Track';
    this.projectStatusObj.Remarks = '';
    this.projectStatusObj.file_name = '';
    // this.projectStatusObj={
    //   project_id:'',
    //   Remarks:'',
    //   file_name:'',
    //   week_no:0,
    //   year:'',
    //   entry_start_date:null,
    //   entry_end_date:null,
    //   status:'On Track',
    //   createdby:''
    // };
    this.isFileDownload=false
    this.filterProjectStatusByWeek(this.projectStatusObj.week_no,this.projectStatusObj.year)
    this.dateSelector.hideOverlay()

  }

  filterProjectStatusByWeek(week_no,filter_year){
    this.filteredProjectStatusDetail=this.projectStatusDetail.filter((element) => element.week_no == week_no && element.year == filter_year )
    if(this.filteredProjectStatusDetail.length>0){
      if(this.filteredProjectStatusDetail[0]['file_name']){
        this.isFileDownload=true
        this.projectService.getDownloadURl(this.filteredProjectStatusDetail[0]['file_name']).then(data=>{
          this.downloadProjectStatusUrl= data['url']
        })
        
      }

      let filteredColour= this.options.filter((element)=>
        element.name === this.filteredProjectStatusDetail[0]['status']
      )
      this.selectedColor = filteredColour.length?filteredColour[0].color:'#679c29';
      // this.selectedColor = this.options[event.target.options.selectedIndex].color;
      this.projectStatusObj={
        project_id: this.filteredProjectStatusDetail[0].project_id,
        Remarks: this.filteredProjectStatusDetail[0].remarks,
        file_name:this.filteredProjectStatusDetail[0].file_name,
        week_no:this.filteredProjectStatusDetail[0].week_no,
        year:this.filteredProjectStatusDetail[0].year,
        entry_start_date:this.filteredProjectStatusDetail[0].entry_start_date,
        entry_end_date:this.filteredProjectStatusDetail[0].entry_end_date,
        status:this.filteredProjectStatusDetail[0].status,
        createdby:this.filteredProjectStatusDetail[0].createdby
      };
    
    }
   
  }

  onDownloadClick(){
    window.open(this.downloadProjectStatusUrl, '_blank');
  }


}