import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/_services/storage.service';
@Component({
  selector: 'app-secondary-navbar',
  templateUrl: './secondary-navbar.component.html',
  styleUrls: ['./secondary-navbar.component.scss']
})
export class SecondaryNavbarComponent implements OnInit {
  locationPath: string;
  userRole: any;
  linkText: string;

  constructor(private router: Router,private storage: StorageService,) { }

  ngOnInit(): void {
    this.userRole = this.storage.getItem("selectedRole");
    this.linkText = 'Resource Evaluation On Projects';
    if(this.userRole === 'Delivery Partner') {
      this.linkText = 'SPDM Evaluation On Projects';
    }

    this.locationPath= window.location.pathname.split('/')[1]
    if(this.userRole === 'Delivery Partner' || this.userRole === 'Engagement Partner' || this.userRole ===  'Delivery Ops Lead') {
      this.locationPath = 'projects';
    }
    if(this.userRole === 'PMO Admin' || this.userRole === 'HR Admin' || this.userRole === 'Talent Acquisition Manager' ){
      this.locationPath = 'admin';
    }
  }

}
