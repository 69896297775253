import { Component, OnInit, ɵConsole } from '@angular/core';
import { DashboardService, Projects } from '../../_services/dashboard.service';
import { of } from 'rxjs';
import { distinct } from 'rxjs/operators';
import { SortEvent } from 'primeng/api';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
} from '@angular/router';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_services/storage.service';

const moment = _moment;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  styles: [`
        :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
        }

        @media screen and (max-width: 64em) {
            :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
                top: 99px;
            }
        }
  `]
})
export class DashboardComponent implements OnInit {
  projects: Projects[];
  projetsDataTable = [];
  subproject = [];
  userRole: string;
  loading = false;
  startDate = new FormControl();
  endDate = new FormControl();
  projetStatus = new FormControl(); 
  statusFilter = 'Active';
  AllprojectsData: any;

  public filterStartDate;
  public filterEndDate;
  public filterMonths = {};
  activeCount: number=0;
  draftCount: number=0;
  closedCount: number=0;
  cancelledCount: number=0;

  constructor(
    private dashboardService: DashboardService,
    private router: Router,private storage: StorageService
  ) { }
  ngOnInit() {
    this.loading = true;
    let resource = JSON.parse(this.storage.getItem('user'));
    this.userRole = this.storage.getItem('selectedRole');
    this.dashboardService
      .getProjects(this.userRole, resource.userID)
      .then((projects) => {
        this.projects = projects;
        of(...this.projects)
          .pipe(distinct((p) => p.project_id))
          .subscribe((x) => {
            this.projetsDataTable.push(x);

            const obj = this.getHarvey();
            Object.entries(obj).map(([key, value]) => {
              if (key === x.project_id.toString()) {
                x['count'] = value;
              }
            });
          });
        this.AllprojectsData = this.projetsDataTable.sort((a, b) => a.project_name.localeCompare(b.project_name));
        this.projetsDataTable = this.projetsDataTable.filter((m) => {
          return (
            m.project_status === this.statusFilter
          );
        });
        let closedProjects=this.AllprojectsData.filter((m) => {
          return (
            m.project_status === 'Closed'
          );
        });
        let draftProjects=this.AllprojectsData.filter((m) => {
          return (
            m.project_status === 'Draft'
          );
        });
        let cancelledProjects=this.AllprojectsData.filter((m) => {
          return (
            m.project_status === 'Cancelled'
          );
        });
        
        this.activeCount=this.projetsDataTable.length
        this.closedCount=closedProjects.length
        this.draftCount=draftProjects.length
        this.cancelledCount=cancelledProjects.length
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  }
  clearFilters(){
    this.startDate.setValue(null);
    this.endDate.setValue(null);
    this.projetStatus.setValue('All');
    // this.ngOnInit();
    this.projetsDataTable=this.AllprojectsData;
  }

  filterStatus(event) {
    this.statusFilter=event.target.value
    // this.projetsDataTable = this.AllprojectsData;
    // if (event.target.value === 'All') {
    //   this.projetsDataTable = this.AllprojectsData;
    // } else {
    //   this.projetsDataTable = this.AllprojectsData.filter(
    //     (element) => element.project_status === event.target.value
    //   );
    // }
  }
  getHarvey() {
    const groupByProjects = this.projects.reduce((acc, it) => {
      acc[it.project_id] = acc[it.project_id] + 1 || 1;
      return acc;
    }, {});

    return groupByProjects;
  }


  filterChange() {
    let fromdate = moment(this.startDate.value).format('YYYY-MM-DD');
    let todate = moment(this.endDate.value).format('YYYY-MM-DD');
    let selectedData=this.AllprojectsData;
    if(this.startDate.value===null && this.endDate.value !==null){
    //  Swal.fire('select proper date filter','','warning');
      // alert("select proper date filter");
      selectedData=selectedData.filter((m) => {
        return (
        //  moment(m.start_date).isBetween(fromdate,todate, undefined, '[]') ||  moment(m.end_date).isBetween(fromdate,todate, undefined, '[]') ||
        //  moment(fromdate).isBetween(m.start_date,m.end_date, undefined, '[]') 
         // ||  moment(todate).isBetween(m.start_date,m.end_date, undefined, '[]')
          //moment(fromdate).isSameOrBefore(m.start_date) 
           moment(todate).isSameOrAfter(m.end_date)
        //  moment(fromdate).isEqual(m.start_date) || moment(fromdate).isBefore(m.start_date) && moment(todate).isAfter(m.end_date)
        );
      });
    }else if(this.startDate.value!==null && this.endDate.value ===null) {
      todate = moment().format('YYYY-MM-DD');
      selectedData=selectedData.filter((m) => {
        return (
        //  moment(m.start_date).isBetween(fromdate,todate, undefined, '[]') ||  moment(m.end_date).isBetween(fromdate,todate, undefined, '[]') ||
        //  moment(fromdate).isBetween(m.start_date,m.end_date, undefined, '[]') 
         // ||  moment(todate).isBetween(m.start_date,m.end_date, undefined, '[]')
          moment(fromdate).isSameOrBefore(m.start_date) 
          //&& moment(todate).isSameOrAfter(m.end_date)
        //  moment(fromdate).isEqual(m.start_date) || moment(fromdate).isBefore(m.start_date) && moment(todate).isAfter(m.end_date)
        );
      });
    }else if(this.startDate.value!=null && this.endDate.value !=null ){
      selectedData=selectedData.filter((m) => {
        return (
         //moment(fromdate).isBetween(m.start_date,m.end_date, undefined, '[]') ||  moment(todate).isBetween(m.start_date,m.end_date, undefined, '[]')
          // moment(fromdate).isBefore(m.start_date) && moment(todate).isAfter(m.end_date)
          moment(fromdate).isSameOrBefore(m.start_date) && moment(todate).isSameOrAfter(m.end_date)


        );
      });
    }

    if (this.statusFilter != 'All') {
      selectedData=selectedData.filter((m) => {
        return (
          m.project_status === this.statusFilter
        );
      });
    }
    this.projetsDataTable = selectedData;
    
  }
  getSubProjectsByProjectId(projectId) {
    this.subproject = this.projects.filter(
      (element) => element.project_id === projectId
    );
  }

  routeToProjectDetails(projectId) {
    this.getSubProjectsByProjectId(projectId);
    this.router.navigateByUrl('/projects/project-detail/' + projectId +'/' + this.subproject[0].sub_project_id);
  }
  routeToProjectStatusDetails(projectId) {
    this.router.navigateByUrl('/projects/project-status/' + projectId);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }
}
