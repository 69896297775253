<ng-template #customLoadingTemplate>
  <div class="ctmloader">
    <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
  <div class="container-fluid pt-3">
    <div class="row">
      <div class="col-6">
        <h4>Manage Skill Matrix</h4>
      </div>
      <div class="col-6 text-right">
        <ng-container>
          <button (click)="AddSkillPopup()" class="btn crvdbtn cstbtn btn2">
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Skill
          </button>
          <button type="button"  selectionMode="multiple"  (click)="exportExcel()"  
            class="btn cstbtn btn2 ml-3"><i class="fa fa-download" aria-hidden="true"></i> Download this report as
            Excel</button>
        </ng-container>
      </div>
    </div>

    <div class="full-height">
      <p-sidebar [(visible)]="visibleSidebar2" position="right" [style]="{width:'40em'}" [baseZIndex]="10000">
        <form name="myForm" [formGroup]="form">
          <h5 style="font-weight:normal">Add Skill Matrix</h5>
          <p><span class="red">*</span> Please Fill Required Fields</p>
          <p-toast></p-toast>
          <div class="row">
            <!-- <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> Delivery Function </label>
                <select [(ngModel)]="masterSkill.delivery_function" name="delivery_function"
                  formControlName="delivery_function" class="form-control" id="exampleFormControlSelect1">
                  <option value='' selected>--Select--</option>
                  <option>All</option>
                  <option>Digital Engineering</option>
                  <option>Decision Science</option>
                  <option>Data Analytics</option>
                  <option>QA Engineering</option>
                </select>
                
              </div>
              <div *ngIf="isFieldValid('delivery_function')" >
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    Please select the delivery function
                  </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span>Skill Category</label>
                <select [(ngModel)]="masterSkill.skill_category" name="skill_category" formControlName="skill_category"
                  class="form-control" id="exampleFormControlSelect1">
                  <option value='' selected>--Select--</option>
                  <option>Technology</option>
                  <option>Functional </option>
                </select>
                
              </div>
              <div *ngIf="isFieldValid('skill_category')" >
                <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                <span class="sr-only">(error)</span>
                <div class="error-msg">
                  Please select the skill category
                </div>
              </div>
            </div> -->

            <!-- <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> Skill Sub Category</label>
                <select [(ngModel)]="masterSkill.skill_sub_category" name="skill_sub_category"
                  formControlName="skill_sub_category" class="form-control" id="exampleFormControlSelect1">
                  <option value='' selected>--Select--</option>
                  <option>Application Engineering</option>
                  <option>Design Engineering</option>
                  <option>Operations Engineering</option>
                  <option>Analyst</option>
                  <option>Manegerial</option>
                  <option>Data Engineering</option>
                  <option>Cloud Computing</option>
                </select>
                
              </div>
              <div *ngIf="isFieldValid('skill_sub_category')" >
                <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                <span class="sr-only">(error)</span>
                <div class="error-msg">
                  Please select the skill sub category
                </div>
              </div>
            </div> -->

            <!-- <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> Skill Area()COEs</label>
                <select [(ngModel)]="masterSkill.skill_area_CoEs" name="skill_area_CoEs"
                  formControlName="skill_area_CoEs" class="form-control" id="exampleFormControlSelect1">
                  <option value='' selected>--Select--</option>
                  <option>Web App</option>
                  <option>DWH/Reporting</option>
                  <option>SharePoint</option>
                  <option>Mobile App</option>
                  <option>Cloud App</option>
                  <option>UI/UX</option>
                  <option>DevOps</option>
                  <option>Functional Analyst</option>
                  <option>Managers</option>
                  <option>Data Management</option>
                  <option>Business Intelligence</option>
                  <option>Big Data</option>
                  <option>DataOps</option>
                  <option>Managers</option>
                  <option>Machine Learning</option>
                  <option>Cloud-PaaS-AWS</option>
                  <option>Cloud-PaaS-Azure</option>
                  <option>Cloud-PaaS-GCP</option>
                  <option>Cloud-PaaS</option>
                  <option>Business Analyst</option>
                  <option>Technology  Analyst</option>
                  <option>Other</option>
                </select>
                
                
              </div>
              <div *ngIf="isFieldValid('skill_area_CoEs')" >
                <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                <span class="sr-only">(error)</span>
                <div class="error-msg">
                  Please select the skill area COE
                </div>
              </div>
            </div> -->
            <div class="col-9">
              <div class="form-group">
                <label><span class="red">*</span> Skill Name</label>
                <input pInputText type="text" [(ngModel)]="masterSkill.skill_name" name="skill_name"
                  formControlName="skill_name" class="form-control" id="">
                 
              </div>
              <div *ngIf="isFieldValid('skill_name')" >
                <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                <span class="sr-only">(error)</span>
                <div class="error-msg red" >
                  Please enter the skill name
                </div>
              </div>
            </div>

          </div>
          <div class="row sidebtngroup text-center">
            <div class="col">
              <p-button type="button" (click)="SaveSkillData()" label="Save" icon="fa fa-floppy-o"
                styleClass="p-button-success btn4"> </p-button>
              <p-button type="button" (click)="visibleSidebar2 = false" label="Cancel" icon="fa fa-times"
                styleClass="p-button-secondary ml-3"></p-button>
            </div>
          </div>
        </form>
      </p-sidebar>
    </div>

    <div class="ctm-card">
      <p-table class="bordertop" [value]="skillList" #dt [paginator]="true" [autoLayout]="true" [rows]="10"
        [rowsPerPageOptions]="[5,10,15,20]" [totalRecords]="skillList.length" pageLinks="3"
        [globalFilterFields]="['skill_area_CoEs','skill_category','skill_name','skill_sub_category','delivery_function']">
        <ng-template pTemplate="caption">
          <div class="d-flex ctmfilter align-items-end">
            <!-- <div>
              <label class="d-block text-left" for="exampleFormControlSelect1">Category</label>
          
              <select [(ngModel)]="category" [formControl]="categoryControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let cat of categorys" value="{{cat.skill_sub_category}}">
                  {{ cat.skill_sub_category }}
                </option>
              </select>
            </div>

            <div class=" ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">COE</label>
         
              <select [ngModel]="coe" [formControl]="coeControl" style="width: 120px;" class="form-control"
                                       id="exampleFormControlSelect1" >
                                       <option value='All' selected>All</option>
                                       <option *ngFor="let coe of coes" value="{{coe.skill_area_CoEs}}">
                                        {{ coe.skill_area_CoEs }}
                                       </option>
                                     </select>
            </div>

            <div class=" ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Delivery Function</label>
              <select [ngModel]="deliveryfunction" [formControl]="deliveryfunctionControl" style="width: 120px;" class="form-control"
                                       id="exampleFormControlSelect1" >
                                       <option value='All' selected>All</option>
                                       <option *ngFor="let deliveryfunction of deliveryfunctions" value="{{deliveryfunction.delivery_function}}">
                                        {{ deliveryfunction.delivery_function }}
                                       </option>
                                     </select>
            
            </div>



            <button class="btn cstbtn btn1  ml-3 mb-1"  (click)="onSearch(coeControl.value,categoryControl.value,deliveryfunctionControl.value)">
              <i class="fa fa-filter" aria-hidden="true"></i> Apply filters
            </button>
            <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red" aria-hidden="true"></i> Reset
              Filters</div> -->

            <div class="ml-auto bl-1">
              <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="40" placeholder="What are you looking for ?" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <!-- <th pSortableColumn="delivery_function">Delivery Function<p-sortIcon field="delivery_function"></p-sortIcon>
            </th>
            <th pSortableColumn="skill_category">Skill Category<p-sortIcon field="skill_category"></p-sortIcon>
            </th>
            <th pSortableColumn="skill_sub_category">Skill Sub Category<p-sortIcon field="skill_sub_category">
              </p-sortIcon>
            </th>
            <th pSortableColumn="skill_area_CoEs">Skill Area(COEs)<p-sortIcon field="skill_area_CoEs"></p-sortIcon>
            </th> -->
            <th pSortableColumn="skill_master_id">Skill ID<p-sortIcon field="skill_master_id"></p-sortIcon>
            </th>
            <th pSortableColumn="skill_name">Skill Name<p-sortIcon field="skill_name"></p-sortIcon>
            </th>
            <!-- <th>Action</th> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-skillList>
          <tr>
            <!-- <td>{{skillList.delivery_function}}</td>
            <td>{{skillList.skill_category}}</td>
            <td>{{skillList.skill_sub_category}}</td>
            <td>{{skillList.skill_area_CoEs}}</td> -->
            <td>{{skillList.skill_master_id}}</td>
            <td>{{skillList.skill_name}}</td>
            <!-- <td><i tooltipPosition="left" pTooltip="Click here to edit" class="fa faedit fa-pencil-square-o" aria-hidden="true"></i></td> -->
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>