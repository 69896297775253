import { Component, OnInit } from '@angular/core';
import { DashboardService, Projects } from '../../_services/dashboard.service';
import { FormControl } from '@angular/forms';
import { StorageService } from 'src/app/_services/storage.service';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import { ProjectService } from 'src/app/_services/project.service';
import { TimesheetSubmissionService } from 'src/app/_services/timesheet-submission.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as XLSX from 'xlsx';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { TabView } from 'primeng/tabview';
// import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  // exportAsConfig: ExportAsConfig = {
  //   type: 'xlsx', // the type you want to download
  //   elementIdOrContent: 'myTableElementId', // the id of html/table element
  // }
  @ViewChild('table') table: ElementRef;
  selectedIndex = 0;
  //class variable
  @ViewChild(TabView) tabView: TabView;
  loading = false;
  projects: any;
  projectList: any;
  resourceList: any;
  selectedProject= '';
  selectedResource= '';
  resourceDropdownList = [];
  projectDropdownList = [];
  dropdownResource: any = 'All';
  dropdownProject: any = 'All';
  resourceControl = new FormControl();
  projectControl = new FormControl();
  isProject = false;
  isResource =  false;
  isRecodExists = true;
  isResourceRecodExists = true;
  isTableView = false;
  isResourceTableView = false
  selectField = true;
  selectResourceField = true;
  isProjDetail = false;
  isResDetail = false;
  isUtilizationDetail = false;
  isResProjDetail = false;
  selectYears= '';
  selectPYears= '';
  selectCurrentYear = '';
  projectDetail= [];
  resourceDetail= [];
  resourceProjectDetails = [];
  utilizationData = [];
  years: number[] = [];
  AllreportsData: any[];
  currentYear: number = new Date().getFullYear();
  currentMonth = new Date().getMonth()+1;
  selectedMonth;
  userRole: string;
  months=[{ Id: 1, month: "Jan" },
  { Id: 2, month: "Feb" },
  { Id: 3, month: "Mar" },
  { Id: 4, month: "Apr" }, 
  { Id: 5, month: "May" }, 
  { Id: 6, month: "Jun" }, 
  { Id: 7, month: "Jul" },
  { Id: 8, month: "Aug" }, 
  { Id: 9, month: "Sep" }, 
  { Id: 10, month: "Oct" },
  { Id: 11, month: "Nov" }, 
  { Id: 12, month: "Dec" }];
  totalTeamStrength_DS: number = 0;
  totalTeamStrength_DA: number = 0;
  totalTeamStrength_DE: number = 0;
  totalTeamStrength_QE: number = 0;
  avgUtilization_DS: number = 0;
  avgUtilization_DA: number = 0;
  avgUtilization_DE: number = 0;
  avgUtilization_QE: number = 0;
  utilizationCountZero_DS: number = 0;
  utilizationCountZero_DA: number = 0;
  utilizationCountZero_DE: number = 0;
  utilizationCountZero_QE: number = 0;

  utilizationCountFifty_DS: number = 0;
  utilizationCountFifty_DA: number = 0;
  utilizationCountFifty_DE: number = 0;
  utilizationCountFifty_QE: number = 0;

  utilizationCountSeventyFive_DS: number = 0;
  utilizationCountSeventyFive_DA: number = 0;
  utilizationCountSeventyFive_DE: number = 0;
  utilizationCountSeventyFive_QE: number = 0;

  utilizationCountHundred_DS: number = 0;
  utilizationCountHundred_DA: number = 0;
  utilizationCountHundred_DE: number = 0;
  utilizationCountHundred_QE: number = 0;

  sabbaticalCount_DS: number = 0;
  sabbaticalCount_DA: number = 0;
  sabbaticalCount_DE: number = 0;
  sabbaticalCount_QE: number = 0;
  public cols: any[];
  public isExpanded: boolean = false;
  public rows: number = 10;
  public expandedRows = {};
  public temDataLength: number = 0;
  
  constructor( private adminService: AdminService,
    private projectService: ProjectService,
    private timesheetServie: TimesheetSubmissionService,
    private router: Router,
    private storage: StorageService) {
  }

  ngOnInit(): void {
    // this.userRole = this.storage.getItem("selectedRole");
    
    this.loading = true;
    this.selectPYears = this.currentYear.toString();
    this.selectYears = this.currentYear.toString();
    this.selectCurrentYear = this.currentYear.toString();
    this.selectedMonth = this.currentMonth;
    for(let i = (this.currentYear - 2); i <= (this.currentYear + 1); i++) {
      this.years.push(i);
      
    }
    let monthList = [...this.months]
    monthList.forEach(x => {
      if(x.Id === this.selectedMonth) {
        this.selectedMonth = x.month;  
      } 
      
    });
    this.isProjDetail = true;
    this.isResDetail = false;
    this.isUtilizationDetail = false;
    this.isResProjDetail = false;

    
    
    let resource = JSON.parse(this.storage.getItem('user'));
    this.userRole = this.storage.getItem('selectedRole');
    this.timesheetServie.getResourceByRole(this.userRole, resource.userID).then((resource) => {
      this.resourceList = resource;
      // console.log(' this.resourceList', this.resourceList)
        this.loading = false;
    });    
        
    this.adminService.getAllProjectList(this.userRole, resource.userID).then(projectlist => {
      this.projectList =projectlist;
      this.loading = false;
    });    
    this.onResourceProject();
    this.onSubmitDepartment();
    this.cols = [{
      field: 'client_name',
      header: 'client Name'
    },
    {
      field: 'project_name',
      header: 'Project Name'
    },
    {
      field: 'estimated_start_date',
      header: 'From Date'
    },
    {
      field: 'estimated_end_date',
      header: 'To Date'
    },
    {
      field: 'year',
      header: 'Year'
    }
    ];
    // this.projectService.getUtilizationReport(this.selectCurrentYear,this.selectedMonth).then(utilization => {
    //   this.utilizationData =utilization;
    //   console.log('utli',this.utilizationData);
    //   this.loading = false;
    // });    

  }
  
  onSubmit(){
    this.selectField = true;
    // this.isRecodExists = false; 
    this.isTableView= false;
    this.projectDetail = [];
    // console.log('pt',this.selectPYears)
    if(this.selectPYears!='' && this.selectedProject !='')
    { 
      this.loading =true;
      this.projectService.getResourceProjectDetails(this.selectedProject,this.selectPYears).then(project=>{
        // this.projectDetail = project;
        // console.log('projectDetail',this.projectDetail); 
        // console.log('projectDetail', this.projectDetail)
        let tempData = project;
        let tempObj = {}
        tempData.map(data => {
          // data['skill_name'] = data['skill_name'].replaceAll(',', ', ')
          if (!tempObj[data['project_id']]) {
            const resourceData = {}
            tempObj[data['project_id']] = {}
            tempObj[data['project_id']] = data
            tempObj[data['project_id']]['requestedresource'] = []
            resourceData['department_name'] = data['department_name'] 
            resourceData['resource_name'] = data['resource_name']
            resourceData['resource_email'] = data['resource_email']
            resourceData['employee_id'] = data['employee_id']
            resourceData['line_group_name'] = data['line_group_name']
            resourceData['rm_resource_name'] = data['rm_resource_name']
            resourceData['rwt_level'] = data['rwt_level']
            resourceData['rwt_name'] = data['rwt_name']
            resourceData['doj'] = data['doj']
            resourceData['sub_project_id'] = data['sub_project_id']
            resourceData['Jan'] = data['Jan']
            resourceData['Feb'] = data['Feb']
            resourceData['Mar'] = data['Mar']
            resourceData['Apr'] = data['Apr']
            resourceData['May'] = data['May']
            resourceData['Jun'] = data['Jun']
            resourceData['Jul'] = data['Jul']
            resourceData['Aug'] = data['Aug']
            resourceData['Sep'] = data['Sep']
            resourceData['Oct'] = data['Oct']
            resourceData['Nov'] = data['Nov']
            resourceData['Dec'] = data['Dec']
            // if (data['project_id'])
              tempObj[data['project_id']]['requestedresource'].push(resourceData)

          } else {
            const resourceData = {}
            resourceData['department_name'] = data['department_name'] 
            resourceData['resource_name'] = data['resource_name']
            resourceData['resource_email'] = data['resource_email']
            resourceData['resource_name'] = data['resource_name']
            resourceData['resource_email'] = data['resource_email']
            resourceData['employee_id'] = data['employee_id']
            resourceData['line_group_name'] = data['line_group_name']
            resourceData['rm_resource_name'] = data['rm_resource_name']
            resourceData['rwt_level'] = data['rwt_level']
            resourceData['rwt_name'] = data['rwt_name']
            resourceData['doj'] = data['doj']
            resourceData['sub_project_id'] = data['sub_project_id']
            resourceData['Jan'] = data['Jan']
            resourceData['Feb'] = data['Feb']
            resourceData['Mar'] = data['Mar']
            resourceData['Apr'] = data['Apr']
            resourceData['May'] = data['May']
            resourceData['Jun'] = data['Jun']
            resourceData['Jul'] = data['Jul']
            resourceData['Aug'] = data['Aug']
            resourceData['Sep'] = data['Sep']
            resourceData['Oct'] = data['Oct']
            resourceData['Nov'] = data['Nov']
            resourceData['Dec'] = data['Dec']
            // if (data['project_id'])
              tempObj[data['project_id']]['requestedresource'].push(resourceData)
          }
          return data;
        })
        // console.log("resourceRequestTable", tempObj)
        this.projectDetail = Object.values(tempObj)



        this.loading =false;
        if(project.length<=0)
        {
          this.isRecodExists = false; 
        }else{
          // this.isProjDetail = true;
          this.isRecodExists = true;
          this.isTableView= true;
        }
        this.isProject =true;
      }).catch(()=>{

      }) 
      // console.log('projectDetail',this.projectDetail); 
    }
    else{
      this.selectField = false;
      this.isRecodExists = true;
      
    }
    
  }
  onSubmitResource(){
    this.isResourceTableView = false
    this.selectResourceField = true; 
    if(this.selectYears!='' && this.selectedResource !='')
    {
      this.loading =true;
      this.projectService.getProjectResourceDetails(this.selectedResource,this.selectYears).then(resource=>{
        this.resourceDetail = resource;
        // console.log('resourceDetail',this.resourceDetail)
        this.loading =false;
        if(resource.length<=0)
        {
          this.isResourceRecodExists = false; 
        }
        else{
          // this.isResDetail = true;
          this.isResourceRecodExists = true;
          this.isResourceTableView = true;
        }
        this.isResource = true;
      }).catch(()=>{
      
      })
    }
    else{
      this.selectResourceField = false;
      this.isResourceRecodExists = true;
    }
    // console.log('resourceDetail',this.resourceDetail)
  }
  clearFilters(){
    // this.isResDetail = false;
    this.isTableView= false;
    this.projectDetail=[];
    this.isProject = false;
    this.isRecodExists = true;
    this.selectField = true; 
    // this.isProjDetail = false;
    // this.isUtilizationDetail = false;
    this.selectedProject= '';
    this.selectPYears = this.currentYear.toString();
  }
  clearResourceFilters(){
    this.isResourceTableView = false;
    this.resourceDetail = [];
    this.isResource = false;
    this.isResourceRecodExists = true; 
    this.selectResourceField = true; 
    // this.isResDetail = false;
    // this.isProjDetail = false;
    // this.isUtilizationDetail = false
    this.selectedResource= '';
    this.selectYears = this.currentYear.toString();
  }
 
  exportExcel() {
    // JSON.parse(JSON.stringify(this.AllreportsData));
    if(this.isProjDetail == true) {
      const ResourceProjectDetail = [];
      const fileName ='Project_View';
      const exportDataArray = [];
      this.projectDetail.map(report => {
        const exportData = {};
        exportData['Client'] = report.client_name,
        exportData['Project'] = report.project_name,
        exportData['Employee Id'] = report.employee_id,
        exportData['Resource'] = report.resource_name,
        exportData['Resource Email'] = report.resource_email,
        exportData['DOJ'] = report.doj,
        exportData['RWT'] = report.rwt_name,
        exportData['Resource Manager'] = report.rm_resource_name,
        exportData['Department'] = report.department_name,
        exportData['Line Group'] = report.line_group_name,
        exportData['Year'] = report.year,
        exportData['Jan'] = report.Jan,
        exportData['Feb'] = report.Feb,
        exportData['Mar'] = report.Mar,
        exportData['Apr'] = report.Apr,
        exportData['May'] = report.May,
        exportData['Jun'] = report.Jun,
        exportData['Jul'] = report.Jul,
        exportData['Aug'] = report.Aug,
        exportData['Sep'] = report.Sep,
        exportData['Oct'] = report.Oct,
        exportData['Nov'] = report.Nov,
        exportData['Dec'] = report.Dec,    
  
        exportDataArray.push(exportData);
        
      });
      this.importExcelData(exportDataArray,fileName);
    } else if(this.isResDetail == true){
      const ResourceProjectDetail = [];
      const exportDataArray = [];
      const fileName ='Resource_View';
      this.resourceDetail.map(report => {
        const exportData = {};
        exportData['Resource'] = report.resource_name,
        exportData['Resource Email'] = report.resource_email,
        exportData['Employee Id'] = report.employee_id,
        exportData['DOJ'] = report.doj,
        exportData['RWT'] = report.rwt_name,
        exportData['Resource Manager'] = report.rm_resource_name,
        exportData['Department'] = report.department_name,
        exportData['Line Group'] = report.line_group_name,
        exportData['Client'] = report.client_name,
        exportData['Project'] = report.project_name,
        exportData['Year'] = report.year,
        exportData['Jan'] = report.Jan,
        exportData['Feb'] = report.Feb,
        exportData['Mar'] = report.Mar,
        exportData['Apr'] = report.Apr,
        exportData['May'] = report.May,
        exportData['Jun'] = report.Jun,
        exportData['Jul'] = report.Jul,
        exportData['Aug'] = report.Aug,
        exportData['Sep'] = report.Sep,
        exportData['Oct'] = report.Oct,
        exportData['Nov'] = report.Nov,
        exportData['Dec'] = report.Dec,    
  
        exportDataArray.push(exportData);
             
      });
      this.importExcelData(exportDataArray,fileName);
    }else if(this.isResProjDetail == true){
      const ResourceProjectListDetail = [];
      const exportDataArray = [];
      const fileName ='Project_Resource_View';
      this.resourceProjectDetails.map(report => {
        const exportData = {};
        exportData['Resource Name'] = report.resource_name,
        exportData['Resource Email'] = report.resource_email,
        exportData['Client'] = report.client_name,
        exportData['Project'] = report.project_name,
        exportData['Sub Project'] = report.sub_project_name,
        exportData['Start Date'] = report.start_date,
        exportData['End Date'] = report.end_date,
        exportData['Project Amount'] = report.project_amount,
        exportData['Month'] = report.month,
        exportData['Year'] = report.year,
        exportData['Allocated Hours'] = report.allocated_hours,
        exportData['RWT'] = report.rwt_name,
        exportData['Level'] = report.level_name,
        exportData['Billing Rate'] = report.billing_rate,
        exportData['Cost($)'] = report.cost, 
  
        exportDataArray.push(exportData);
             
      });
      this.importExcelData(exportDataArray,fileName);
    }
      else if(this.isUtilizationDetail == true){
      this.fireEvent();
    }
    // this.isProjDetail = false;
    // this.isResDetail = false; 
  }
  importExcelData(exportDataArray,fileName){
    import('xlsx').then(xlsx => {
      const worksheet1 = xlsx.utils.json_to_sheet(exportDataArray);
      const workbook = {
        Sheets: {
          'Report': worksheet1,
        },
        SheetNames: ['Report']
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      this.saveAsExcelFile(excelBuffer,fileName);
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    // const filteredStartDate = moment(this.filterStartDate).format('MMM-YYYY');
    // const filteredEndDate = moment(this.filterEndDate).format('MMM-YYYY');
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      
      FileSaver.saveAs(data, fileName + '_Report' + EXCEL_EXTENSION);
      
    });
    
  }
  onSubmitDepartment(){
    this.loading = true;
    this.projectService.getUtilizationReport(this.selectCurrentYear,this.selectedMonth).then(utilization => {
      this.utilizationData =utilization;
      // console.log('utli',this.utilizationData);
      this.calculateDS();
      this.calculateDA();
      this.calculateDE();
      this.calculateQE();
      this.loading = false;
      // this.isUtilizationDetail == true;
    }); 
    
  }
  calculateSabbatical(){
    var sabbatical = 0;
    for (let i = 0; i < this.utilizationData.length; i++) {
      if (this.utilizationData[i].is_active == 1){ 
        // sabbatical += this.utilizationData[i].allocation;
        sabbatical++;
      }
    }
  }
  clearDepartmentFilters(){
    this.selectCurrentYear = this.currentYear.toString();
    let monthList = [...this.months]
    this.selectedMonth = this.currentMonth;
    monthList.forEach(x => {
      if(x.Id === this.selectedMonth) {
        this.selectedMonth = x.month;  
      } 
      
    });
    this.onSubmitDepartment();
  }
  calculateDS(){
    let counter = 0;
    let countForZero = 0;
    var avgUtilization = 0;
    let countForFifty = 0;
    let countForSeventyFive = 0;
    let countForHundred = 0;
    let sabbatical = 0;
    for (let i = 0; i < this.utilizationData.length; i++) {
      if (this.utilizationData[i].department_id == 2)
      {
        avgUtilization += this.utilizationData[i].allocation;
        counter++;
        if(this.utilizationData[i].allocation < 50)
        {
          countForZero++;
        }
        if(this.utilizationData[i].allocation >= 50 && this.utilizationData[i].allocation < 75)
        {
          countForFifty++;
        }
        if(this.utilizationData[i].allocation >= 75 && this.utilizationData[i].allocation <= 100)
        {
          countForSeventyFive++;
        }
        if(this.utilizationData[i].allocation > 100)
        {
          countForHundred++;
        }
        if(this.utilizationData[i].is_active == 1)
        {
          sabbatical++;
        }
    
      }
    }
    this.totalTeamStrength_DS = counter;
    this.avgUtilization_DS = avgUtilization/this.totalTeamStrength_DS;
    this.utilizationCountZero_DS = countForZero;
    this.utilizationCountFifty_DS = countForFifty;
    this.utilizationCountSeventyFive_DS = countForSeventyFive;
    this.utilizationCountHundred_DS = countForHundred;
    this.sabbaticalCount_DS = sabbatical;
  }
  calculateDA(){
    let counter = 0;
    let countForZero = 0;
    let countForFifty = 0;
    let countForSeventyFive = 0;
    let countForHundred = 0;
    var avgUtilization = 0;
    let sabbatical = 0;
    for (let i = 0; i < this.utilizationData.length; i++) {
      if (this.utilizationData[i].department_id == 1) 
      {
        avgUtilization += this.utilizationData[i].allocation;
        counter++;
        if(this.utilizationData[i].allocation < 50)
        {
          countForZero++;
        }
        if(this.utilizationData[i].allocation >= 50 && this.utilizationData[i].allocation < 75)
        {
          countForFifty++;
        }
        if(this.utilizationData[i].allocation >= 75 && this.utilizationData[i].allocation <= 100)
        {
          countForSeventyFive++;
        }
        if(this.utilizationData[i].allocation > 100)
        {
          countForHundred++;
        }
        if(this.utilizationData[i].is_active == 1)
        {
          sabbatical++;
        }
      }
    }
    this.totalTeamStrength_DA = counter;
    this.avgUtilization_DA = avgUtilization/this.totalTeamStrength_DA;
    this.utilizationCountZero_DA = countForZero;
    this.utilizationCountFifty_DA = countForFifty;
    this.utilizationCountSeventyFive_DA = countForSeventyFive;
    this.utilizationCountHundred_DA = countForHundred;
    this.sabbaticalCount_DA = sabbatical;
  }
  calculateDE(){
    let counter = 0;
    let countForZero = 0;
    var avgUtilization = 0;
    let countForFifty = 0;
    let countForSeventyFive = 0;
    let countForHundred = 0;
    let sabbatical = 0;
    for (let i = 0; i < this.utilizationData.length; i++) {
      if (this.utilizationData[i].department_id == 16)
      { 
        avgUtilization+= this.utilizationData[i].allocation;
        counter++;
        if(this.utilizationData[i].allocation < 50)
        {
          countForZero++;
        }
        if(this.utilizationData[i].allocation >= 50 && this.utilizationData[i].allocation < 75)
        {
          countForFifty++;
        }
        if(this.utilizationData[i].allocation >= 75 && this.utilizationData[i].allocation <= 100)
        {
          countForSeventyFive++;
        }
        if(this.utilizationData[i].allocation > 100)
        {
          countForHundred++;
        }
        if(this.utilizationData[i].is_active == 1)
        {
          sabbatical++;
        }
      }
      
    }
    this.totalTeamStrength_DE = counter;
    this.avgUtilization_DE = avgUtilization/this.totalTeamStrength_DE;
    this.utilizationCountZero_DE = countForZero;
    this.utilizationCountFifty_DE = countForFifty;
    this.utilizationCountSeventyFive_DE = countForSeventyFive;
    this.utilizationCountHundred_DE = countForHundred;
    this.sabbaticalCount_DE = sabbatical;
  }  
  calculateQE(){
    let counter = 0;
    let countForZero = 0;
    var avgUtilization = 0;
    let countForFifty = 0;
    let countForSeventyFive = 0;
    let countForHundred = 0;
    let sabbatical = 0;
    for (let i = 0; i < this.utilizationData.length; i++) {
      if (this.utilizationData[i].department_id == 17){ 
        avgUtilization += this.utilizationData[i].allocation;
        counter++;
        if(this.utilizationData[i].allocation < 50)
        {
          countForZero++;
        }
        if(this.utilizationData[i].allocation >= 50 && this.utilizationData[i].allocation < 75)
        {
          countForFifty++;
        }
        if(this.utilizationData[i].allocation >= 75 && this.utilizationData[i].allocation <= 100)
        {
          countForSeventyFive++;
        }
        if(this.utilizationData[i].allocation > 100)
        {
          countForHundred++;
        }
        if(this.utilizationData[i].is_active == 1)
        {
          sabbatical++;
        }
      }
    }
    this.totalTeamStrength_QE = counter;
    this.avgUtilization_QE = avgUtilization/this.totalTeamStrength_QE;
    this.utilizationCountZero_QE = countForZero;
    this.utilizationCountFifty_QE = countForFifty;
    this.utilizationCountSeventyFive_QE = countForSeventyFive;
    this.utilizationCountHundred_QE = countForHundred;
    this.sabbaticalCount_QE = sabbatical;

  }
  
  // export() {
  //   // download the file using old school javascript method
  //   this.exportAsService.save(this.exportAsConfig, 'My File Name').subscribe(() => {
  //     // save started
  //   });
 
  // }
  fireEvent()
  {
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, 'Utilization_Report.xlsx');
    
  }
  tabChange(e){
    var index = e.index;
    
  }

  onChange($event) {
    this.selectedIndex = $event.index;
    if( this.selectedIndex == 0){
      this.isProjDetail = true;
      this.isResDetail = false;
      this.isUtilizationDetail = false;
      this.isResProjDetail = false;
     } else if (this.selectedIndex == 1){
     this.isProjDetail = false;
      this.isResDetail = true;
      this.isUtilizationDetail = false;
      this.isResProjDetail = false;
     } else if (this.selectedIndex == 2){
       this.isProjDetail = false;
       this.isResDetail = false;
       this.isResProjDetail = true;
      //  console.log('indi',this.selectedIndex)
       this.isUtilizationDetail = false;
     } else if (this.selectedIndex == 3){
      this.isProjDetail = false;
      this.isResDetail = false;
      this.isResProjDetail = false;
     //  console.log('indi',this.selectedIndex)
      this.isUtilizationDetail = true;
    }
  }
  onRowExpand() {
    if (Object.keys(this.expandedRows).length === this.temDataLength) {
      this.isExpanded = true;
    }
  }

  onRowCollapse() {
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

  onPage(event: any) {
    this.temDataLength = this.projectDetail.slice(event.first, event.first + 10).length;
    this.isExpanded = false;
    this.expandedRows = {};
  }
  
  onResourceProject(){
    this.loading = true;
    this.projectService.getResourceProjectReport().then(resource=>{
      
      this.resourceDropdownList = resource.filter(
        (thing, i, arr) => arr.findIndex(t => t.resource_name === thing.resource_name) === i
      ).sort((a, b) => a.resource_name.localeCompare(b.resource_name));
      this.projectDropdownList = resource.filter(
        (thing, i, arr) => arr.findIndex(t => t.project_name === thing.project_name) === i
      ).sort((a, b) => a.project_name.localeCompare(b.project_name));
      // console.log('resourceProjectDetail',resource)
      this.resourceProjectDetails = resource.filter((item: any) => {
        if(this.dropdownResource !== 'All' && this.dropdownProject !== 'All') {
          return (item.resource_name === this.dropdownResource) && (item.project_name === this.dropdownProject);
        }else if (this.dropdownResource !== 'All' && this.dropdownProject === 'All') {
          return (item.resource_name === this.dropdownResource);
        }else if (this.dropdownResource == 'All' && this.dropdownProject !== 'All') {
          return (item.project_name === this.dropdownProject);
        }else {
          return item;
        }
      });
      this.loading =false;
      // if(resource.length<=0)
      // {
      //   this.isResourceRecodExists = false; 
      // }
      // else{
      //   // this.isResDetail = true;
      //   this.isResourceRecodExists = true;
      //   this.isResourceTableView = true;
      // }
      // this.isResource = true;
    }).catch(()=>{
    
    })
  }

  onFilter(resource,project){
    this.dropdownResource = resource;
    this.dropdownProject = project;
    this.onResourceProject();

  }
  clearFilter(){
    this.dropdownProject = 'All';
    this.dropdownResource = 'All';
    this.onResourceProject();
  }
  expandAll(){
    if (!this.isExpanded) {
      this.projectDetail.forEach(data => {
          this.expandedRows[data.project_id] = true;
      });
    } else {
      this.expandedRows = {};
    }
    this.isExpanded = !this.isExpanded;
  }
}
