import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectsComponent } from './projects.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { UtilizationReportComponent } from './utilization-report/utilization-report.component';
import { AuthGuard } from '../_helpers/auth.guard';
import { AddProjectsStatusComponent } from './add-projects-status/add-projects-status.component';
import { HealthStatusComponent } from './health-status/health-status.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { EvaluationConfigurationComponent } from './evaluation-configuration/evaluation-configuration.component';
import { ReportsComponent } from './reports/reports.component';
import { ResourceRequestComponent } from './resource-request/resource-request.component';

const routes: Routes = [
  {path:'',
    component:ProjectsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Billing Manager',"Delivery Manager","Delivery Partner",'Sub Project Delivery Manager',"Engagement Partner","Engagement Manager","Invoice Manager","Practice Delivery Manager", "Delivery Ops Lead"] },
    children: [
      { path: '',
       component: DashboardComponent
      },
      { path: 'project-detail/:id/:subProjectId', component: ProjectDetailComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Billing Manager',"Delivery Manager","Delivery Partner",'Sub Project Delivery Manager',"Engagement Partner","Engagement Manager","Invoice Manager"]},
        runGuardsAndResolvers: 'always'
      },
      { path: 'allocation-report', component: UtilizationReportComponent,
        data: { roles: ['Billing Manager',"Delivery Manager","Delivery Partner","Engagement Partner","Engagement Manager","Invoice Manager","Practice Delivery Manager","Delivery Ops Lead"]} ,
        runGuardsAndResolvers: 'always'
      },
      { path: 'project-status/:id', component: AddProjectsStatusComponent,
        canActivate: [AuthGuard],
        data: { roles: ["Delivery Manager","Delivery Partner"]},
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'evaluation', component: EvaluationComponent,
        data: { roles: ["Delivery Manager","Delivery Partner","Engagement Manager","Engagement Partner"]},
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'status-report', component: HealthStatusComponent,
        data: { roles: ["Delivery Manager","Delivery Partner","Engagement Manager","Engagement Partner"]},
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'evaluation-config', component: EvaluationConfigurationComponent,
        data: { roles: ["Delivery Manager","Delivery Partner","Engagement Manager","Engagement Partner"]},
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'reports', component: ReportsComponent,
        data: { roles: ["Billing Manager","Delivery Manager","Delivery Partner","Engagement Manager","Engagement Partner"]},
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'resources-request', component: ResourceRequestComponent,
        data: { roles: ["Billing Manager","Delivery Manager","Delivery Partner","Engagement Manager","Engagement Partner","Practice Delivery Manager","Delivery Ops Lead"]},
        runGuardsAndResolvers: 'always'
      }
    ],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
