<ng-template #customLoadingTemplate>
   <div class="ctmloader">
      <img src="./../../../assets/images/loader.svg">
   </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
   <div class="container-fluid pt-3">
      <div class="ctm-body">
         <div class="d-flex  align-items-center mb-1">
            <h4>Project Health/Status History</h4>
            <div class="ml-auto d-flex align-items-center">
               <div *ngIf="current_Week">
                  <span style="font-size: 16px;">
                     In the week of<br> <span class="fc"> {{current_Week}} </span>
                  </span>
               </div>
               <div class="topdata ml-2">
                  <div class="topred topblock">
                     <span style="font-size: 25px;">
                        {{totalCritical}} </span>
                     <p>needs attention!</p>
                  </div>
               </div>
               <div class="topdata ml-2">
                  <div class="toporange topblock">
                     <span style="font-size: 25px;">
                        {{totalIssues}} </span><br>
                     has issues
                  </div>
               </div>
               <div class="topdata ml-2">
                  <div class="topgreen topblock">
                     <span style="font-size: 25px;">
                        {{totalOntrack}} </span><br>
                     on track
                  </div>
               </div>
               <div style="padding-left: 80px;" class="col">
                  <button type="button" (click)="exportExcel()" selectionMode="multiple" class="btn cstbtn btn2"><i
                        class="fa fa-download" aria-hidden="true"></i> Download this report as
                     Excel</button>
               </div>
            </div>
         </div>
         <div class="ctm-card">
            <ng-container *ngIf="projectsDataTable">
               <p-table class="bordertop hvrcomponent" [value]="projectsDataTable" #dt [columns]="cols" [paginator]="true"
                  [rows]="10" [totalRecords]="projectsDataTable.length" [customSort]="true"
                  (sortFunction)="customSort($event)"
                  [globalFilterFields]="['Client_Name','Project_Name','Delivery_Manager']">
                  <ng-template pTemplate="caption">
                     <div class="d-flex ctmfilter align-items-end">
                        <div>
                           <label for="dtPicker" id="lbldtpicker"
                              class="d-block text-left control-label mr-2">Clients</label>
                           <select [(ngModel)]="selectedClient" (ngModelChange)="onDDLClientChange($event)"  class="form-control">
                              <option value="0">All</option>
                              <option *ngFor="let item of clients" [value]="item">{{item}}</option>
                           </select>
                        </div>
                        <div class="ml-3">
                           <label class="d-block text-left" for="exampleFormControlSelect1">Projects</label>
                           <select [(ngModel)]="selectedProject" class="form-control">
                              <option value="0">All</option>
                              <option *ngFor="let item of projects" [value]="item">{{item}}</option>
                           </select>
                        </div>
                        <button type="button" class="btn cstbtn btn1 ml-3 mb-1" (click)="applyFilters()"><i
                              class="fa fa-filter" aria-hidden="true"></i> Apply filters</button>
                        <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;"
                              class="fa fa-times red" aria-hidden="true"></i> Reset Filters</div>
                        <div class="ml-auto  bl-1">
                           <p style="padding-bottom: 10px;">Search the table below using this free text search
                           </p>
                           <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                           <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                              (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        </div>
                     </div>
                  </ng-template>
                  <ng-template pTemplate="colgroup" let-columns>
                     <colgroup>
                        <col style="width:150px;">
                        <col style="width:150px;">
                        <col style="width:150px;">
                     </colgroup>
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                     <tr>
                        <th *ngFor="let col of columns; let i = index" [pSortableColumn]="col.header"
                           [ngClass]="{'d-none':i === 0, 'fcbg':i===11}">
                           <span [innerHTML]="replaceUnderscore(col.header)"></span>
                           <p-sortIcon *ngIf="i <= 3" [field]="col.header"></p-sortIcon>
                        </th>
                     </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-project let-ri="rowIndex" let-columns="columns">
                     <tr>
                        <td style="position: relative;"  *ngFor="let col of columns; let i = index"  [ngClass]="getClass(project[col.header], i)">
                           {{project[col.header]| string:":":0}} 
                           <i style="position: absolute; right: 8px; bottom: 8px;font-size: 15px;" tooltipPosition="left" [pTooltip]="remarksCallback(project[col.header])"  *ngIf="i > 3 && isFailed(project[col.header])" class="fa fa-info-circle"></i>
                           <ng-container *ngIf="isURLFailed(project[col.header])">
                              <span style="position: absolute; left: 8px; bottom: 8px; cursor: pointer;font-size: 15px;display: block;line-height: 13px;"><a  (click)="onDownloadClick(project[col.header])">
                                 <i class="fa fa-arrow-circle-down" tooltipPosition="left" pTooltip="Download Status File" aria-hidden="true"></i>
                              </a></span>
                          </ng-container>
                        </td>
                     </tr>
                  </ng-template>
               </p-table>
            </ng-container>
         </div>
      </div>
   </div>
</div>