

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as ConfigJson from '../../assets/config.json';
import { StorageService } from './storage.service';
export interface UtilizationReport 
{
    Line_group: any;
    sub_project_id: any;
    EP;
    DM;
    year;
    month;
    employee_id;
    resource_id;
    resource_name;
    resource_email;
    resource_manager_name;
    resource_manager_email;
    resource_start_date;
    resource_last_date;
    hours;
    sub_project_name;
    sabatical_start_date;
    sabatical_end_date;
    client_name;
    project_name;
    rwt_name;
    department_name;
    project_id;
    skill_name;
    isPrimary;
    skill_master_id;
    Expr;
    prev_exp;
    total_exp;
    level_name;
    bu;
    sub_bu;
  }
@Injectable({
  providedIn: 'root'
})
export class UtilizationReportService {
  base_url = ConfigJson.API_URL
  constructor(private http: HttpClient,private storage: StorageService) {}
   

    getReports(filteredStartDate,filteredEndDate,resourceID) {  
      const userRole=this.storage.getItem('selectedRole')
      if(userRole ==='Resource Manager'){
        return this.http.get<any>(this.base_url+'resourceallocationreport?StartDate=\''+filteredStartDate+'\'&EndDate=\''+filteredEndDate+'\'') 
             .toPromise()
             .then(res => <UtilizationReport[]>res).then(data => { return data; });
      }else{
        return this.http.get<any>(this.base_url+'allocationreport?StartDate=\''+filteredStartDate+'\'&EndDate=\''+filteredEndDate+'\'') 
        .toPromise()
        .then(res => <UtilizationReport[]>res).then(data => { return data; });
      }
        
    }

    getRMDashboard(filteredStartDate,filteredEndDate,resourceID) {  
      const userRole=this.storage.getItem('selectedRole')
      if(userRole ==='Resource Manager'){
        return this.http.get<any>(this.base_url+'resourcemanagerdashboard?StartDate=\''+filteredStartDate+'\'&EndDate=\''+filteredEndDate+'\'') 
             .toPromise()
             .then(res => <UtilizationReport[]>res).then(data => { return data; });
      }else{
        return this.http.get<any>(this.base_url+'allocationreport?StartDate=\''+filteredStartDate+'\'&EndDate=\''+filteredEndDate+'\'') 
        .toPromise()
        .then(res => <UtilizationReport[]>res).then(data => { return data; });
      }
    }
  
}
