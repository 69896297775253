import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
// import { SecondaryNavbarComponent } from './shared/secondary-navbar/secondary-navbar.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {SidebarModule} from 'primeng/sidebar';
import {MatListModule} from '@angular/material/list';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { ChartModule } from 'primeng/chart'
import { AuthorizationService } from './_services/authorization.service';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './_helpers/token.interceptor';
import { StorageService } from './_services/storage.service';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { SharedService } from './_services/shared.service';
import { AuthService } from './auth.service';
import { MatIconModule } from '@angular/material/icon';
import { ProjectsModule } from './projects/projects.module';
import { FooterComponent } from './shared/footer/footer.component';
import { AdminModule } from './admin/admin.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    BreadcrumbsComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TableModule,
    MatSelectModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
    ToolbarModule,
    TooltipModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    CheckboxModule,
    CalendarModule,
    SidebarModule,
    FormsModule,
    PaginatorModule,
    TabViewModule,
    ChartModule,
    ProjectsModule,
    NgxLoadingModule.forRoot({}),
    MatIconModule,
    MatListModule,
    AdminModule,
    AutoCompleteModule,
  ],
  providers: [
    MatDatepickerModule,
    MatCheckboxModule,
    AuthorizationService,
    StorageService,
    SharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(_auth: AuthService) {
  }
}
