<ng-template #customLoadingTemplate>
   <div class="ctmloader">
      <img src="./../../../assets/images/loader.svg">
   </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<p-toast></p-toast>
<div class="container-fluid  pt-2 pb-4">
   <div *ngIf="projectDetail" class="ctm-card">
      <div class="row">
         <div class="col-4">
            <div style="padding-left: 17px;">Project Accounting For</div>
            <h4>{{ projectDetail.project_name }}
               <span class="iconfolder"
                  *ngIf="userRole=='Delivery Manager' || userRole=='Delivery Partner' || userRole=='Engagement Manager' || userRole=='Invoice Manager' || userRole=='Billing Manager'">
                  <a (click)="projectdetaillink()">
                     <i class="fa fa-folder-open" aria-hidden="true"></i>
                  </a></span>
            </h4>
         </div>
         <div class="col">
            <p>Client</p>
            <label class="fc">{{ projectDetail.client_name }}</label>
         </div>
         <div class="col">
            <p>Project Code</p>
            <label class="fc">{{ projectDetail.project_code }}</label>
         </div>
         <div class="col">
            <p>Start Date</p>
            <label class="fc">{{ projectDetail.start_date|date:'mediumDate' }}</label>
         </div>
         <div class="col">
            <p>End Date</p>
            <label class="fc">{{ projectDetail.end_date|date:'mediumDate' }}</label>
         </div>
         <div class="col">
            <p>Project Type</p>
            <label class="fc">{{ projectDetail.project_type === '--Select--'?'':projectDetail.project_type }}</label>
         </div>
      </div>
      <div class="row topcard mt-4" *ngIf="subProjectDEliveryManagerRoleFlag== false">
         <!-- <div class="col-2">
               <div>Project Amount</div>
               <label *ngIf="projectDetail.project_amount" [ngClass]="{'red':totalCostClientEstimate !== projectDetail.project_amount}">
                {{ projectDetail.project_amount|currency }}
               </label>
               <label *ngIf="!projectDetail.project_amount">
                 $0.00
               </label>
            </div> -->
         <!-- <div class="col-3">
               <div>Project Balance</div>
               <label *ngIf="projectDetail.project_balance">
                {{ projectDetail.project_balance |currency}}
               </label>
               <label *ngIf="!projectDetail.project_balance">
                 $0.00
               </label>  
             
            </div> -->
         <div class="col">
            <div>Milestone Amount</div>
            <!-- <p>{{ projectDetail.total_milestone_amount|currency }}</p> -->
            <label *ngIf="projectDetail.non_expense_milestone_amount">
               {{ projectDetail.non_expense_milestone_amount | currency }}
            </label>
            <label *ngIf="!projectDetail.non_expense_milestone_amount">
               $0.00
            </label>


         </div>
         <div class="col">
            <div>Invoiced Amount</div>
            <!-- <p> -->
            <label *ngIf="projectDetail.total_invoice">
               {{ projectDetail.total_invoice |currency }}
            </label>
            <label *ngIf="!projectDetail.total_invoice">
               $0.00
            </label>


            <!-- {{ projectDetail.total_invoicce }} -->
            <!-- </p> -->
         </div>
         <div class="col">
            <div>Milestone Amount to be Invoiced</div>
            <!-- <p> -->
            <label *ngIf="projectDetail.non_expense_milestone_amount">
               {{ projectDetail.non_expense_milestone_amount - projectDetail.total_invoice |currency }}
            </label>
            <label *ngIf="!projectDetail.non_expense_milestone_amount">
               $0.00
            </label>


            <!-- {{ projectDetail.total_invoicce }} -->
            <!-- </p> -->
         </div>

         <div class="col">
            <!-- <div>Resource Estimate at Baseline</div> -->
            <div>Project Margin</div>
            <!-- <p> -->
            <label *ngIf="estimateObjectData['total_hours']">
               <!-- {{estimateObjectData['total_hours'] |currency }} -->
               {{totalProjectMargin|currency}}
            </label>
            <label *ngIf="!estimateObjectData['total_hours']">
               $0.00
            </label>


            <!-- {{ projectDetail.total_invoicce }} -->
            <!-- </p> -->
         </div>


         <!-- <div class="col-2">
               <div>Project Delta</div>
               <label  *ngIf="projectDetail.project_delta">
                {{ projectDetail.project_delta | currency }}
               </label>
               <label *ngIf="!projectDetail.project_delta">
                 $0.00
               </label>  
             
            </div> -->
         <div class="col">
            <div>Profitability %</div>
            <label *ngIf="resourceProfitability" [ngClass]="{
               'positive' : resourceProfitability > 0,
               'orangecase' : resourceProfitability > -10 && resourceProfitability <= 0,
               'negative'   : resourceProfitability <= -10
            }">
               {{resourceProfitability | number : '1.0-2'}}%
            </label>
            <label class="fg" *ngIf="!resourceProfitability">
               $0.00
            </label>

         </div>
      </div>
   </div>
   <div class="ctm-card scrolheight br-1  mt-3">
      <div class="row">
         <div class="col-4">
            <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplayForMileStones()">Milestones</h5>
            <div class="mt-2" *ngIf="!mileStoneShowm">
               <ng-container *ngIf="allProjectDetail['milestones'].length>0">
                  <p-table [value]="allProjectDetail['milestones']" [scrollable]="true" scrollHeight="300px" #dt>
                     <ng-template pTemplate="header">
                        <tr>
                           <th>Milestone Name</th>
                           <th>Milestone Date</th>
                           <th class="text-right">Milestone Amount</th>
                        </tr>
                     </ng-template>
                     <ng-template pTemplate="body" let-mileStone>
                        <tr>
                           <td>{{ mileStone.milestone_name }}</td>
                           <td>{{ mileStone.milestone_date|date: 'MMM dd yyyy' }}</td>
                           <td class="text-right">{{ mileStone.milestone_amount | currency }}</td>
                        </tr>
                     </ng-template>
                  </p-table>
               </ng-container>
               <ng-container *ngIf="allProjectDetail['milestones'].length===0">
                  <p style="color: #ed4d4d; font-size: 16px; margin-top: 20px;">Milestones data is not available for
                     this project</p>

               </ng-container>
            </div>
         </div>
         <div class="col-4">
            <div class="d-flex">
               <div class="mr-auto">
                  <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplayForMileStones()">Work Values</h5>

               </div>
               <!-- <div class="crsr-pointr fs-16 fb" (click)="toggleDisplayForMileStones()">
                     <div *ngIf="mileStoneShowm">Show <i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                     <div style="white-space: nowrap;" *ngIf="!mileStoneShowm">Hide <i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                  </div> -->
            </div>
            <div class="mt-2" *ngIf="!mileStoneShowm">
               <p-table [resizableColumns]="true" [value]="allProjectDetail['workvalues']" [scrollable]="true"
                  scrollHeight="300px" #dt>
                  <ng-template pTemplate="header">
                     <tr>
                        <th>Work Value Month</th>
                        <th class="text-right">Work Value Amount</th>
                     </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-workValue>
                     <tr>
                        <td>{{ workValue.MonthYear |date: 'MMM yyyy' }}</td>
                        <td class="text-right">{{ workValue.workvalue_amount | currency }}</td>
                     </tr>
                  </ng-template>
               </p-table>
            </div>
         </div>
         <div class="col-4">
            <div class="d-flex">
               <div class="mr-auto">
                  <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplayForMileStones()">Resource Estimates
                  </h5>

               </div>
               <div class="crsr-pointr fs-16 fb" (click)="toggleDisplayForMileStones()">
                  <div *ngIf="mileStoneShowm">Show <i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                  <div style="white-space: nowrap;" *ngIf="!mileStoneShowm">Hide <i class="fa fa-minus-circle"
                        aria-hidden="true"></i></div>
               </div>
            </div>
            <div class="mt-2" *ngIf="!mileStoneShowm">
               <p-table [resizableColumns]="true" [value]="monthlyResourceEstimate" [scrollable]="true"
                  scrollHeight="300px" #dt>
                  <ng-template pTemplate="header">
                     <tr>
                        <th>Resource Estimate Month</th>
                        <th class="text-right">Resource Estimate Amount</th>
                     </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-monthlyResourceEstimate>
                     <tr>
                        <td>{{ monthlyResourceEstimate.MonthYear}}</td>
                        <td class="text-right">{{ monthlyResourceEstimate.value | currency }}</td>
                     </tr>
                  </ng-template>
               </p-table>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="back pt-2">
   <div class="container-fluid">
      <div class="d-flex topbar" *ngIf="allProjectDetail">
         <div class="mr-auto">
            <div class="form-inline">
               <div class="form-group">
                  <label>Select Subproject</label>
                  <select class="form-control cstm-form ml-2" [(ngModel)]="selectedSubProjectControl"
                     (change)="selextboxChangeConfirmPopup($event)">
                     <option *ngFor="let selectProject of filteredSubPro" id="{{ selectProject.sub_project_id }}"
                        value="{{ selectProject.sub_project_id }}">
                        {{ selectProject.sub_project_id }}
                        <ng-container *ngIf="selectProject.sub_project_name">
                           {{ (selectProject.sub_project_name).length==0 ? '' :
                           ((selectProject.sub_project_name).length>30) ? ( '('+(selectProject.sub_project_name) |
                           slice:0:30)+'.. )' : '( ' +selectProject.sub_project_name+' )' }}
                        </ng-container>

                     </option>
                  </select>
               </div>

               <div class="form-group">
                  <label class="ml-4">Subproject Count : </label>
                  <label class="fc"> &nbsp;{{filteredSubPro.length}}</label>
               </div>
            </div>
         </div>
         <button class="btn cstbtn btn2 mr-1" (click)="exportExcel()" class="btn cstbtn btn2"><i class="fa fa-download"
               aria-hidden="true"></i> Download this report as
            Excel</button>

         <button class="btn cstbtn btn2 mr-1" [disabled]="isDisabled()" (click)="addNewButtonDialog()"
            *ngIf="subProjectDEliveryManagerRoleFlag== false"><i class="fa fa-plus" aria-hidden="true"></i>
            Add New Subproject</button>

      </div>
      <!-- <p-dialog [(visible)]="selextboxChangeConfirm" [contentStyle]="{'overflow':'visible'}" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000">
      <p-header>
         You may loose your data do you still want to proceed
      </p-header>
      <p-footer>
         <div class="d-flex">
            <div class="ml-auto">
               <button type="button" (click)="selextboxChangeConfirm=false" class="btn cstbtn btn1">Close</button>
               <button type="button" (click)=" this.okSelectBoxSubProject()" class="btn cstbtn btn4">Ok</button>
            </div>
         </div>
      </p-footer>
      </p-dialog> -->
      <!-- <p-dialog [(visible)]="addNewSubProjectPopup" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000">
      <p-header>
         You May loose your unsaved data! You still want to create new project
      </p-header>
      <p-footer>
         <div class="d-flex">
            <div class="ml-auto">
               <button type="button" (click)="addNewSubProjectPopup=false" class="btn cstbtn btn5">No</button>
               <button type="button" (click)="addNewSubProject()" class="btn cstbtn btn4">Yes</button>
            </div>
         </div>
      </p-footer>
      </p-dialog> -->
      <!-- <p-dialog [(visible)]="deleteSubProjectPopup" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000">
      <p-header>
         Are you sure you want to delete this subproject ?
      </p-header>
      <p-footer>
         <div class="d-flex">
            <div class="ml-auto">
               <button type="button" (click)="deleteSubProjectPopup=false" class="btn cstbtn btn1">No</button>
               <button type="button" (click)="deleteSubProject()" class="btn cstbtn btn5">Yes</button>
            </div>
         </div>
      </p-footer>
      </p-dialog> -->
      <div class="ctm-card mt-2">
         <div *ngIf="selectedSubProject" class="row d-flex align-items-center">
            <div class="col-3">
               <h4> {{selectedSubProject.sub_project_id}} </h4>
            </div>
            <div class="col">
               <label>Subproject Name</label>
               <input pInputText type="text" [disabled]="isDisabled()" (change)="updateFlagTrue()"
                  class="form-control cstm-form" [(ngModel)]="selectedSubProject.sub_project_name">
            </div>
            <div class="col">
               <label>Practice <span class="required">*</span></label>
               <select class="form-control cstm-form" [disabled]="isDisabled()" (change)="updateFlagTrue()"
                  [(ngModel)]="selectedSubProject.department_id" required>
                  <option value='' selected>Select</option>
                  <option *ngFor="let dept of allDepartments" value="{{dept.department_id}}">
                     {{ dept.department_name }}
                  </option>
               </select>
            </div>
            <div class="col">
               <label style="font-size: 12px;">Subproject Delivery Manager <span class="required">*</span></label>
               <select class="form-control cstm-form" [disabled]="isDisabled()" (change)="updateFlagTrue()"
                  [(ngModel)]="selectedSubProject.delivery_manager_id" required>
                  <option value='' selected>Select</option>
                  <option *ngFor="let manager of allDeliveryManager" value="{{manager.resource_id}}">
                     {{ manager.resource_name }}
                  </option>
               </select>
            </div>
            <div class="col text-right">
               <button class="btn cstbtn btn5" *ngIf="deleteThisSubProjectFlag == true" [disabled]="isDisabled()"
                  (click)="deleteSubProjectButtonDialog()"><i class="fa fa-trash-o" aria-hidden="true"></i> Delete This
                  Subproject</button>
            </div>
         </div>
      </div>
      <!-- work values -->
      <div>
         <div class="row mt-2">
            <div class="col">
               <div style="background-color:  #fff;border: 10px solid #DBFDD9;" class="ctm-card colfreeze">
                  <div class="d-flex">
                     <div class="mr-auto">
                        <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplayForWorkValue()">Work Values
                        </h5>
                     </div>
                     <div class="crsr-pointr fs-16 fb" (click)="toggleDisplayForWorkValue()">
                        <div *ngIf="workValueShown">Show <i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                        <div *ngIf="!workValueShown">Hide <i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                     </div>
                  </div>
                  <div class="mt-2" *ngIf="!workValueShown">
                     <p-table [scrollable]="true" scrollHeight="350px" #pTable [frozenColumns]="frozenColsClient"
                        [columns]="scrollableCols" [scrollable]="true" [resizableColumns]="true"
                        [columns]="clientEstimationColumnLabel" frozenWidth="600px" [value]="MonthsColsDataValue"
                        [(selection)]="selectedRowsClient" (onRowSelect)="onRowSelect($event)"
                        (onRowUnselect)="onRowUnselect($event)" [metaKeySelection]="false" dataKey="index"
                        editMode="row" #dt>
                        <ng-template pTemplate="frozencolgroup" let-columns>
                           <colgroup>
                              <col style="width:50px">
                              <col style="width:250px">
                              <!-- <col style="width:100px">
                           <col style="width:100px">
                           <col style="width:100px"> -->
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                           <colgroup>
                              <col *ngFor="let col of columns" style="width:130px">
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                           <tr>
                              <th *ngFor="let col of columns">
                                 <ng-container *ngIf="col === 'checkBox' else baseTemp">
                                    <p-tableHeaderCheckbox [disabled]="isDisabled()"
                                       (click)="tableheaderCheckboxClickClient()"></p-tableHeaderCheckbox>
                                 </ng-container>
                                 <ng-template #baseTemp>
                                    {{col}}
                                 </ng-template>
                              </th>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="frozenbody" let-clientEstimates let-ri="rowIndex" let-columns="columns">
                           <tr>
                              <td>
                                 <p-tableCheckbox [value]="clientEstimates" [disabled]="isDisabled()"></p-tableCheckbox>
                              </td>
                              <td>
                                 <!-- {{clientEstimates.index}} {{ri}} -->
                                 <select class="cstm-form" [disabled]="!clientEstimates.slectBoxDisabledFlag"
                                    (change)="rwtNameOnBlurClient($event, ri, clientEstimates)"
                                    [(ngModel)]="clientEstimates.rwt_id" required>
                                    <option value='' selected>Select</option>
                                    <option *ngFor="let rwt of filteredRWT" value="{{rwt.rwt_level_id}}">
                                       {{ rwt.rwt_name }}
                                    </option>
                                 </select>

                              </td>
                              <td class="text-center">
                                 <select style="padding: 0px 0px 0px 10px;" class="cstm-form"
                                    [disabled]="!clientEstimates.slectBoxDisabledFlag"
                                    (change)="rwtLevelNameOnBlurClient($event, ri, clientEstimates)"
                                    [(ngModel)]="clientEstimates.level_id" required>
                                    <option value='' selected>Select</option>
                                    <option *ngFor="let level of levelDropDownDataForWorkValue[ri]"
                                       value="{{level.level_id}}">
                                       {{ level.level_name }}
                                    </option>
                                 </select>
                              </td>
                              <td>
                                 <select #clientLocationSelectBox class="cstm-form"
                                    [disabled]="!clientEstimates.slectBoxDisabledFlag"
                                    (change)="onRwtLocationSelection(clientEstimates,ri, $event)"
                                    [(ngModel)]="clientEstimates.location_id" required>
                                    <!-- <option *ngIf='locationDropDownData[ri] == ""' value="">Select</option> -->
                                    <option value='' selected>Select</option>
                                    <option *ngFor="let location of locationDropdownDataForWorkValue[ri]"
                                       value="{{location.location_id}}">
                                       {{ location.location_name }}
                                    </option>
                                 </select>
                              </td>
                              <td class="text-right" pEditableColumn>
                                 <p-cellEditor>
                                    <ng-template pTemplate="input">
                                       <input pInputText type="number" [disabled]="isDisabled()"
                                          (focus)="selectText($event)"
                                          (change)="calculateTotalCostClientEstimate($event, ri)"
                                          (keydown.enter)="calculateTotalCostClientEstimate($event, ri)"
                                          [(ngModel)]="clientEstimates['baseline_rate']">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                       {{clientEstimates['baseline_rate'] | currency}}
                                    </ng-template>
                                 </p-cellEditor>
                              </td>
                           </tr>

                        </ng-template>

                        <ng-template #target pTemplate="body" let-editing="editing" let-ri="rowIndex"
                           let-clientEstimates let-columns="columns">
                           <tr [pSelectableRow]="clientEstimates">
                              <!-- <ng-template *ngFor="let col of columns"> -->
                              <td pEditableColumn>
                                 {{clientEstimates['total_hours']}}
                              </td>
                              <td style="text-align: right;" pEditableColumn>
                                 {{clientEstimates['total_cost'] | currency}}
                              </td>
                              <td *ngFor="let col of monthlyValue" pEditableColumn>
                                 <p-cellEditor>
                                    <ng-template pTemplate="input">
                                       <input class="w60" pInputText type="number" pattern="\d*" maxlength="4" min=0
                                          onKeyPress="if(this.value.length==4) return false;"
                                          (keydown.enter)="calculateTotalCostClientEstimate($event, ri)"
                                          (focus)="selectText($event)"
                                          (blur)="calculateTotalCostClientEstimate($event, ri)"
                                          (ngModelChange)="calculateTotalCostClientEstimate($event, ri)"
                                          (change)="calculateTotalCostClientEstimate($event, ri)"
                                          [disabled]="isDisabled()" [(ngModel)]="clientEstimates[col]">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                       {{clientEstimates[col]}}
                                    </ng-template>
                                 </p-cellEditor>
                                 <!-- {{ clientEstimates[col] }} -->
                              </td>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                           <tr *ngIf="!hideFooterFlagClient">
                              <td></td>
                              <td colspan="50">
                                 <!-- <h5> Total Cost = {{totalCostClientEstimate  | currency}}</h5> -->
                              </td>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="frozenfooter">
                           <tr *ngIf="!hideFooterFlagClient">
                              <td></td>
                              <td>
                                 <label style="font-size: 16px;"> Grand Total Hours : <span class="fc">
                                       {{totalHoursClientEstimate}} </span></label>
                              </td>
                              <td colspan="50">
                                 <label style="font-size: 16px;"> Grand Total Cost : <span class="fc">
                                       {{totalCostClientEstimate | currency}} </span></label>
                              </td>
                           </tr>
                        </ng-template>
                     </p-table>
                     <!-- <p-dialog [(visible)]="duplicateFlag" [modal]="true" [style]="{width: '70vw'}" [baseZIndex]="10000">
                      <p-header>
                         <i class="fas fa-exclamation-circle"></i>
                         <i class="fa fa-exclamation" aria-hidden="true"></i>
                         Selected combination of RWT, Level and Location already exists!, please choose other
                      </p-header>
                      <p-footer>
                         <div class="d-flex">
                            <div class="ml-auto">
                               <button type="button" (click)="duplicateFlag=false" class="btn cstbtn btn1">Ok</button>
                             
                            </div>
                         </div>
                      </p-footer>
                      </p-dialog> -->
                     <div class="row mt-3">
                        <div class="col">

                           <!-- <button *ngIf="copyToNextGridClientButtonFlag== true" type="button" tooltipPosition="left"
                              [disabled]="isDisabled()" (click)="copyToNextGrid()" class="btn cstbtn btn2 ml-2">
                              <i class="fa fa-files-o" aria-hidden="true"></i> Clone Work Values to Internal Costing
                           </button> -->
                           <button tooltipPosition="left" *ngIf="cloneSelectedClientFlag== true"
                              [disabled]="isDisabled()" type="button" (click)="copyValues()"
                              class="btn btn2 cstbtn ml-2">
                              <i class="fa fa-clone" aria-hidden="true"></i> Clone Selected Row
                           </button>
                           <button type="button" tooltipPosition="left" [disabled]="isDisabled()"
                              (click)="newClientRow()" class="btn cstbtn btn2 ml-2">
                              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add New RWT
                           </button>
                           <button *ngIf="deleteClientFlag== true" type="button" tooltipPosition="left"
                              [disabled]="isDisabled()" (click)="deleteClientEstimate()" class="btn cstbtn btn5 ml-2">
                              <i class="fa fa-trash" aria-hidden="true"></i> Delete Selected Rows
                           </button>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- <div>
         <div class="row mt-2">
            <div class="col">
               <div style="background-color: #fff; border: 10px solid #DFF7EA;" class="ctm-card colfreeze">
                  <div class="d-flex">
                     <div class="mr-auto">
                        <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplayForInternalCost()">Internal
                           Costings</h5>
                     </div>
                     <div class="crsr-pointr fs-16 fb" (click)="toggleDisplayForInternalCost()">
                        <div *ngIf="internalCostShown">Show <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!internalCostShown">Hide <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        </div>
                     </div>
                  </div>
                  <div class="mt-2" *ngIf="!internalCostShown">
                     <p-table [scrollable]="true" scrollHeight="350px" #pTable1 [frozenColumns]="frozenColsInternal"
                        [columns]="scrollableCols" [autoLayout]="true" [scrollable]="true" [resizableColumns]="true"
                        [columns]="internalEstimationColumnLabel" frozenWidth="600px"
                        [value]="internalMonthsColsDataValue" [(selection)]="selectedRowsInternal"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        [metaKeySelection]="false" dataKey="index" editMode="row" #dt>
                        <ng-template pTemplate="frozencolgroup" let-columns>
                           <colgroup>
                              <col style="width:50px">
                              <col style="width:250px">
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                           <colgroup>
                              <col *ngFor="let col of columns" style="width:130px">
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                           <tr>
                              <th *ngFor="let col of columns">
                                 <ng-container *ngIf="col === 'checkBox' else baseTemp">
                                    <p-tableHeaderCheckbox [disabled]="isDisabled()"
                                       (click)="tableheaderCheckboxClickInternal()"></p-tableHeaderCheckbox>
                                 </ng-container>
                                 <ng-template #baseTemp>
                                    {{col}}
                                 </ng-template>
                              </th>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="frozenbody" let-internalEstimates let-ri="rowIndex"
                           let-columns="columns">
                           <tr>
                              <td style="text-align: center">
                                 <p-tableCheckbox [value]="internalEstimates" [disabled]="isDisabled()">
                                 </p-tableCheckbox>
                              </td>
                              <td>
                                 <select class="cstm-form" [disabled]="!internalEstimates.slectBoxDisabledFlag"
                                    (onblur)="rwtNameOnBlurClient()"
                                    (change)="rwtNameOnBlurInternal($event, ri, internalEstimates)"
                                    [(ngModel)]="internalEstimates.rwt_id" required>
                                    <option value='' selected>Select</option>
                                    <option *ngFor="let rwt of filteredRWT" value="{{rwt.rwt_level_id}}">
                                       {{ rwt.rwt_name }}
                                    </option>
                                 </select>
                              </td>
                              <td>
                                 <select style="padding: 0px 0px 0px 10px;" class="cstm-form"
                                    [disabled]="!internalEstimates.slectBoxDisabledFlag"
                                    (change)="rwtLevelNameOnBlurInternal($event, ri, internalEstimates)"
                                    (ngModelChange)="rwtLevelNameOnBlurInternalTest($event, ri, internalEstimates)"
                                    [(ngModel)]="internalEstimates.level_id" required>
                                    <option value='' selected>Select</option>
                                    <option *ngFor="let level of levelDropDownDataForInternalEstimate[ri]"
                                       value="{{level.level_id}}">
                                       {{ level.level_name }}
                                    </option>
                                 </select>
                              </td>
                              <td>
                                 <select panelClass="testClass" class="cstm-form"
                                    [disabled]="!internalEstimates.slectBoxDisabledFlag"
                                    (change)="onRwtLocationSelectionInternal(internalEstimates,ri,$event)"
                                    [(ngModel)]="internalEstimates.location_id" required>
                                    <option value='' selected>Select</option>
                                    <option *ngFor="let location of locationDropdownDataForInternalEstimate[ri]"
                                       value="{{location.location_id}}">
                                       {{ location.location_name }}
                                    </option>
                                 </select>
                              </td>
                              <td class="text-right">
                                 {{internalEstimates['baseline_rate'] | currency}}
                              </td>
                           </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex" let-internalEstimates>
                           <tr [pSelectableRow]="internalEstimates">
                              <td>
                                 {{internalEstimates['total_hours']}}
                              </td>
                              <td class="text-right">
                                 {{internalEstimates['total_cost'] | currency}}
                              </td>
                              <td *ngFor="let col of monthlyValue" pEditableColumn>
                                 <p-cellEditor>
                                    <ng-template pTemplate="input">
                                       <input class="w60" pInputText type="number" pattern="\d*" maxlength="4"
                                          onKeyPress="if(this.value.length==4) return false;"
                                          (keydown.enter)="calculateTotalCostInternalEstimate($event, ri)"
                                          pattern="[0-9]+" (blur)="calculateTotalCostInternalEstimate($event, ri)"
                                          (ngModelChange)="calculateTotalCostInternalEstimate($event, ri)"
                                          (change)="calculateTotalCostInternalEstimate($event, ri)"
                                          [disabled]="isDisabled()" [(ngModel)]="internalEstimates[col]">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                       {{internalEstimates[col]}}
                                    </ng-template>
                                 </p-cellEditor>
                              </td>
                           </tr>
                        </ng-template>

                        <ng-template pTemplate="footer">
                           <tr *ngIf="!hideFooterFlagInternal">
                              <td></td>
                              <td colspan="50">
                              </td>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="frozenfooter">
                           <tr *ngIf="!hideFooterFlagInternal">
                              <td></td>
                              <td>
                                 <label style="font-size: 16px;"> Grand Total Hours : <span class="fc">
                                       {{totalHoursInternalEstimate}} </span></label>
                              </td>
                              <td colspan="50">
                                 <label style="font-size: 16px;"> Grand Total Cost : <span class="fc">
                                       {{totalCostInternalEstimate | currency}} </span></label>
                              </td>
                           </tr>
                        </ng-template>
                     </p-table>

                     <div class="row mt-3">
                        <div class="col">

                           <button *ngIf="copyToNextGridInternalButtonFlag== true" type="button"
                              [disabled]="isDisabled()" (click)="copyToNextGridInternal()" class="btn btn2 cstbtn ml-2">
                              <i class="fa fa-files-o" aria-hidden="true"></i> Clone Internal Costing to Resource
                              Estimates
                           </button>
                           <button [disabled]="isDisabled()" *ngIf="cloneSelectedInternalFlag== true" type="button"
                              (click)="copyValuesForInternal()" class="btn btn2 cstbtn ml-2">
                              <i class="fa fa-clone" aria-hidden="true"></i> Clone Selected Row
                           </button>
                           <button type="button" [disabled]="isDisabled()" (click)="newInternalRow()"
                              class="btn btn2 cstbtn ml-2">
                              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add New RWT
                           </button>
                           <button type="button" *ngIf="deleteInternalFlag== true;" [disabled]="isDisabled()"
                              (click)="deleteInternalEstimate()" class="btn btn5 cstbtn ml-2">
                              <i class="fa fa-trash" aria-hidden="true"></i> Delete Selected Rows
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div> -->
      <div>
         <div class="row mt-2">
            <div class="col">
               <div style="background-color: #fff; border: 10px solid #FFE6E6;" class="ctm-card colfreeze">
                  <div class="d-flex">
                     <div class="mr-auto">
                        <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplayForResourceEstimate()">
                           Project Margin</h5>
                     </div>
                     <div class="crsr-pointr fs-16 fb" (click)="toggleDisplayForResourceEstimate()">
                        <div *ngIf="resourceEstimateShown">Show <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!resourceEstimateShown">Hide <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        </div>
                     </div>
                  </div>
                  <div class="mt-2" *ngIf="!resourceEstimateShown">
                     <!-- <div class="form-check mb-2 form-check-inline">
                        <input class="form-check-input ml-1" [disabled]="isDisabled()"
                           [checked]="probalityColumnAddedCheck" (change)="changeProbabilityCheck($event)"
                           type="checkbox">
                        <label class="form-check-label" for="inlineCheckbox1">Show Probabilty Columns</label>

                     </div> -->
                     <p-table [scrollable]="true" scrollHeight="350px" #pTable2 [resizableColumns]="true"
                        [frozenColumns]="frozenColsResourceEstimate" [autoLayout]="true" [scrollable]="true"
                        [autoLayout]="true" [columns]="scrollableColsResources" [value]="resourceMonthsColsDataValue"
                        frozenWidth="300px" selectionMode="" [(selection)]="selectedRowsResources"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        [metaKeySelection]="false" dataKey="index" #dt>

                        <ng-template pTemplate="frozencolgroup" let-columns>
                           <colgroup>
                              <col style="width:50px">
                              <col style="width:120px">
                              <!-- <col style="width:200px"> -->
                              <!-- <col style="width:100px">
                         <col style="width:100px"> -->
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                           <colgroup>
                              <col style="width:150px">
                              <col *ngFor="let col of columns" style="width:150px">
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                           <tr>
                              <th *ngFor="let col of columns">
                                 <ng-container *ngIf="col.label === 'checkBox' else baseTemp">
                                    <p-tableHeaderCheckbox [disabled]="isDisabled()"
                                       (click)="tableheaderCheckboxClickResource()"></p-tableHeaderCheckbox>
                                 </ng-container>
                                 <ng-template #baseTemp>
                                    <div *ngIf="col.show == true"> <span [innerHTML]="col.label"></span></div>
                                 </ng-template>
                              </th>
                           </tr>
                        </ng-template>




                        <!-- <ng-template pTemplate="header" let-columns>
                        <tr>
                           <th style="width: 3rem">
                              <p-tableHeaderCheckbox [disabled]="isDisabled()" (click)="tableheaderCheckboxClickResource()"></p-tableHeaderCheckbox>
                           </th>
                           <th  *ngFor="let col of columns">
                              / {{col}} 
                              <div *ngIf= "col.show == true" class="break-word">  {{ col.label }}</div>
                           </th>
                        </tr>
                     </ng-template> -->
                        <ng-template pTemplate="frozenbody" let-resourceEstimates let-ri="rowIndex"
                           let-columns="columns">
                           <tr [pSelectableRow]="resourceEstimates">
                              <td>
                                 <p-tableCheckbox [value]="resourceEstimates" [disabled]="isDisabled()">
                                 </p-tableCheckbox>
                              </td>
                              <td>

                                 <!-- <p-dropdown #dd [options]="resourcesArray" [(ngModel)]="resourceEstimates.resource_id"
                                    selectedItem="resourceEstimates.resource_id"
                                    [disabled]="!resourceEstimates.slectBoxDisabledFlag" [style]="{'width':'100%'}"
                                    [title]="resourceEstimates.resource_id ? 'Department: ' + resourceObj[resourceEstimates.resource_id].department_name + ', RWT: ' + resourceObj[resourceEstimates.resource_id].rwt_name + ', Level: ' + resourceObj[resourceEstimates.resource_id].level_id + ', Cost Per Hour($) : ' + resourceObj[resourceEstimates.resource_id].billing_rate:''"
                                    tooltipPosition="right" scrollHeight="400px" [filter]="true"
                                    filterBy="resource_name" optionLabel="resource_name" optionValue="resource_id"
                                    resetFilterOnHide="true"
                                    (onChange)="resourceIdChange(resourceEstimates, $event, ri)" appendTo="body"> -->

                                 <p-dropdown #dd [options]="resourcesArray" [(ngModel)]="resourceEstimates.resource_id"
                                    selectedItem="resourceEstimates.resource_id"
                                    [disabled]="!resourceEstimates.slectBoxDisabledFlag" [style]="{'width':'100%'}"
                                    [title]="resourceEstimates.resource_id ? 'RWT: ' + resourceObj[resourceEstimates.resource_id].rwt_name + ',Location :'+resourceObj[resourceEstimates.resource_id].location_name + ', Level: ' + resourceObj[resourceEstimates.resource_id].level_id + ', Cost Per Hour($) : ' + resourceObj[resourceEstimates.resource_id].billing_rate:''"
                                    tooltipPosition="right" scrollHeight="400px" [filter]="true"
                                    filterBy="resource_name" optionLabel="resource_name" optionValue="resource_id"
                                    resetFilterOnHide="true"
                                    (onChange)="resourceIdChange(resourceEstimates, $event, ri)" appendTo="body">

                                    <ng-template pTemplate="selectedItem">
                                       <span class="item-value1"
                                          title="{{ resourceEstimates.resource_id ? 'Email: ' + resourceObj[resourceEstimates.resource_id].resource_email +',Location :'+resourceObj[resourceEstimates.resource_id].location_name + ', Cost Per Hour($) : ' + resourceObj[resourceEstimates.resource_id].billing_rate:'Select a resource' }}">
                                          {{ resourceEstimates.resource_id ?
                                          resourceObj[resourceEstimates.resource_id].resource_name : 'Select a resource'
                                          }}
                                       </span>

                                       <!-- <span class="item-value1"
                                          title=" {{  resourceEstimates.resource_id ? 'Email: '+resourceObj[resourceEstimates.resource_id].resource_email + ', Department: ' + resourceObj[resourceEstimates.resource_id].department_name: 'Select a resource'}}">
                                          {{ resourceEstimates.resource_id ?
                                          resourceObj[resourceEstimates.resource_id].resource_name : 'Select a
                                          resource'}}
                                       </span>-->
                                    </ng-template>
                                    <ng-template let-resource pTemplate="item">
                                       <div class="item-drop1"
                                          title="{{'Email: '+resource.resource_email + ', Cost Per Hour($) : ' + resource.billing_rate+ ',Level :  ' + resource.level_id + ', Location :  ' + resource.location_name }}">
                                          <mat-list-item>
                                             <span class="item-value1">{{resource.resource_name}}</span>
                                          </mat-list-item>
                                       </div>
                                    </ng-template>
                                 </p-dropdown>
                              </td>
                           </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-resourceEstimates let-ri="rowIndex">

                           <tr [pSelectableRow]="resourceEstimates">
                              <td>
                                 {{resourceEstimates['total_hours']}}
                              </td>
                              <td>
                                 {{resourceEstimates['total_cost'] | currency}}
                              </td>
                              <td *ngFor="let col of tdResourceReadOnlyColData; let i = index"
                                 style="color: rgb(141, 138, 138);">
                                 <div>
                                    <p-cellEditor>
                                       <ng-template pTemplate="output">
                                          <div *ngIf="col.show == true">
                                             {{resourceEstimates[col.label]}}
                                          </div>
                                       </ng-template>
                                    </p-cellEditor>
                                 </div>
                              </td>
                              <td *ngFor="let col of tdResourceColumns; let i = index" pEditableColumn>
                                 <div>
                                    <p-cellEditor>
                                       <ng-template pTemplate="input">
                                          <div *ngIf="col.show == true">
                                             <input class="w60" pInputText type="number" pattern="\d*" maxlength="4"
                                                onKeyPress="if(this.value.length==4) return false;"
                                                (keydown.enter)="calculateTotalCostResourceEstimate($event)"
                                                (keydown.tab)="onTabPress($event, i, ri)"
                                                (change)="calculateTotalCostResourceEstimate($event)"
                                                [disabled]="isDisabled()" [(ngModel)]="resourceEstimates[col.label]">
                                          </div>
                                       </ng-template>
                                       <ng-template pTemplate="output">
                                          <div *ngIf="col.show == true">
                                             {{resourceEstimates[col.label]}}
                                          </div>
                                       </ng-template>
                                    </p-cellEditor>
                                 </div>
                              </td>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                           <tr *ngIf="!hideFooterFlagResource">
                              <td></td>
                              <td></td>
                              <td class="text-center" style="color:#c881e9"
                                 *ngFor="let cols of resourceEstimatesColumnFooter; let i = index">
                                 <div *ngIf="probalityColumnAddedCheckFooter">
                                    {{monthlyHoursFooterData[cols]}}
                                 </div>
                                 <div *ngIf="!probalityColumnAddedCheckFooter">
                                    {{monthlyHoursFooterData[cols]}}
                                 </div>
                              </td>
                           </tr>
                           <tr class="fc" *ngIf="!hideFooterFlagResource">
                              <td></td>
                              <td></td>
                              <td class="text-center fc"
                                 *ngFor="let cols of resourceEstimatesColumnFooter; let i = index">
                                 <div *ngIf="probalityColumnAddedCheckFooter">
                                    {{estimateObjectData[cols] | currency}}
                                 </div>
                                 <div *ngIf="!probalityColumnAddedCheckFooter">
                                    {{estimateObjectData[cols] | currency}}
                                 </div>
                              </td>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="frozenfooter">
                           <tr *ngIf="!hideFooterFlagResource"
                              style="width:100%; height:100%; background-color: white; text-align:center">
                              <!-- <td ></td> -->
                              <td colspan="2" style="line-height: 26px;text-align:right;">
                                 <label style="font-size: 15px;"> Grand Total Hrs : <span style="color:#c881e9;text-indent: 7px;
                              display: inline-block;"> {{totalCostResourceEstimate}} </span></label>
                              </td>
                           </tr>
                           <tr *ngIf="!hideFooterFlagResource"
                              style="width:100%; height:100%; background-color: white; text-align:center">

                              <td colspan="2" style="    line-height: 27px;text-align:right;">
                                 <label style="font-size: 15px;"> Total Resource Estimate($): <span class="fc">
                                       {{estimateObjectData['total_hours'] | currency}} </span></label>
                              </td>
                           </tr>
                        </ng-template>

                     </p-table>
                     <div class="row mt-3">
                        <div class="col">
                           <button type="button" *ngIf="cloneSelectedResoureFlag== true" [disabled]="isDisabled()"
                              (click)="copyValuesForResource()" class="btn btn2 cstbtn ml-2">
                              <i class="fa fa-files-o" aria-hidden="true"></i> Clone Selected Row
                           </button>
                           <button type="button" [disabled]="isDisabled()" (click)="addResourceRow()"
                              class="btn btn2 cstbtn ml-2">
                              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add New Resource
                           </button>
                           <button type="button" *ngIf="deleteResoureFlag == true;" [disabled]="isDisabled()"
                              (click)="deleteResourceEstimate()" class="btn btn5 cstbtn ml-2">
                              <i class="fa fa-trash" aria-hidden="true"></i> Delete Selected Rows
                           </button>
                        </div>
                     </div>
                     <br>
                     <div class="row">
                        <div class="col">
                           <div class="form-check mb-2 form-check-inline">
                              <input class="form-check-input ml-1" type="checkbox" [(ngModel)]="isChecked"
                                 (change)="toggleTableVisibility($event)">
                              <label class="form-check-label">Show Additional Cost</label>
                           </div>
                        </div>
                     </div>
                     <div class="row" *ngIf="allProjectDetail.internalEstimates.length > 0">
                        <div class="col">
                           <p-table [value]="allProjectDetail.internalEstimates" [scrollable]="true"
                              scrollHeight="250px" *ngIf="showAdditionalCostTable">
                              <ng-template pTemplate="header">
                                 <tr>
                                    <th style="width: 5vw;"></th>

                                    <th>Expense Type</th>
                                    <th>Details</th>
                                    <th style="width: 13vw;">Amount</th>
                                    <th>Expense Date</th>
                                 </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-estimate let-indexvalue="rowIndex">
                                 <tr>
                                    <td style="width: 8vw;">
                                       <p-checkbox [value]="estimate"
                                          (onChange)="handleCheckboxSelection($event, estimate)"></p-checkbox>
                                    </td>

                                    <td>
                                       <select class="cstm-form form-control" [(ngModel)]="estimate.expense_type"
                                          [class.required-field]="estimate.expense_type_required" #selectDropdown
                                          (change)="highlightEmptySelect(selectDropdown)" required>
                                          <option value='' selected>Select</option>
                                          <option *ngFor="let type of addditionalExpenseType"
                                             value="{{type.expenseType}}">
                                             {{type.expenseType }}
                                          </option>
                                       </select>
                                    </td>
                                    <td>
                                       <textarea #detailsRef class="form-control" [(ngModel)]="estimate.details"
                                          (input)="highlightEmptyTextInput(detailsRef)"
                                          [class.highlight-empty]="!estimate.details">
                                    </textarea>

                                    </td>
                                    <td style="width: 13vw;" pEditableColumn>
                                       <!-- <input type="number" class=" form-control" name="" id=""
                                          [(ngModel)]="estimate.amount"
                                          [class.required-field]="estimate.amount_required"
                                          (input)="estimate.amount_required = false" (ngModelChange)="onAmountChange()"
                                          required> -->

                                       <p-cellEditor>
                                          <ng-template pTemplate="input">
                                             <input pInputText type="number" (focus)="selectText($event)"
                                                (input)="estimate.amount_required = false"
                                                (keydown.enter)="onAmountChange()" (change)="onAmountChange()"
                                                (ngModelChange)="onAmountChange()" [(ngModel)]="estimate.amount">
                                          </ng-template>
                                          <ng-template pTemplate="output">
                                             {{estimate.amount| currency}}
                                          </ng-template>
                                       </p-cellEditor>
                                    </td>

                                    <td>
                                       <input type="date" #expenseDateRef class="form-control"
                                          [(ngModel)]="estimate.expense_date"
                                          [class.highlight-empty]="!estimate.expense_date"
                                          (input)="highlightEmptyInput(expenseDateRef)" required />

                                    </td>
                                 </tr>
                              </ng-template>

                              <ng-template pTemplate="footer">
                                 <tr style="background-color: white; text-align: center;">
                                    <td colspan="2" style="line-height: 26px; text-align: left !important;">
                                       <label style="font-size: 15px;">Total Additional Cost:
                                          <span style="color:#c881e9; text-indent: 7px; display: inline-block;">
                                             {{ totalAmountAdditionalCost | currency }}
                                          </span>
                                       </label>
                                    </td>
                                 </tr>

                              </ng-template>
                           </p-table>
                        </div>
                     </div>
                     <div class="row mt-3" *ngIf="showAdditionalCostTable">
                        <div class="col">
                           <button type="button" (click)="addAdditionalCost()" class="btn btn2 cstbtn ml-2">
                              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Additional Cost
                           </button>
                           <button (click)="removeSelectedRows()" [hidden]="!this.deleteAdditionalCost"
                              class="btn btn5 cstbtn ml-2">
                              <i class="fa fa-trash" aria-hidden="true"></i> Delete Selected Rows
                           </button>

                        </div>
                     </div>
                     <br>
                     <div style="width: auto;background-color: rgb(247, 247, 247);height: 10vh; padding: 20px;">
                        <label style="font-size: 15px;" *ngIf="totalProjectMargin">Total Project
                           Margin($) :
                           <span class="fc">
                              {{ totalProjectMargin | currency }}
                           </span>
                        </label>
                        <label style="font-size: 15px;" *ngIf="!totalProjectMargin">Total Project
                           Margin($):
                           <span class="fc">
                              {{ '0' |currency }}
                           </span>
                        </label>
                     </div>


                  </div>
               </div>
            </div>
         </div>

         <!-- Project actuals -->
         <div class="row mt-2">
            <div class="col">
               <div style="background-color: #fff; border: 10px solid #FFE6E6;" class="ctm-card colfreeze">
                  <div class="d-flex">
                     <div class="mr-auto">
                        <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplayForProjectActuals()">Project
                           Actuals (%)</h5>
                     </div>
                     <div class="crsr-pointr fs-16 fb" (click)="toggleDisplayForProjectActuals()">
                        <div *ngIf="projectActualsShown">Show <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!projectActualsShown">Hide <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        </div>
                     </div>
                  </div>
                  <div class="text-center" *ngIf="!isRecodExists">
                     <p class="mt-2 red">No Records Found !</p>
                  </div>
                  <div class="mt-2" *ngIf="!projectActualsShown && isRecodExists">
                     <p-table [scrollable]="true" scrollHeight="350px" [frozenColumns]="frozenProjectActuals"
                        [columns]="scrollableColsProjectActuals" [scrollable]="true" [resizableColumns]="true"
                        [columns]="projectActualsColumnLabel" frozenWidth="350px"
                        [value]="projectActualsMonthsColsDataValue" [metaKeySelection]="false" dataKey="index">
                        <ng-template pTemplate="frozencolgroup" let-columns>
                           <colgroup>
                              <col style="width:50px">
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                           <colgroup>
                              <col *ngFor="let col of columns" style="width:130px">
                           </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                           <tr>
                              <th *ngFor="let col of columns">
                                 {{col}}
                              </th>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="frozenbody" let-projectActuals let-ri="rowIndex" let-columns="columns">
                           <tr>
                              <td>
                                 {{projectActuals.resource_name }}
                              </td>

                           </tr>

                        </ng-template>

                        <ng-template pTemplate="body" let-ri="rowIndex" let-projectActuals let-columns="columns">
                           <tr [pSelectableRow]="projectActuals">
                              <!-- <ng-template *ngFor="let col of columns"> -->

                              <td *ngFor="let col of projectActualsmonthlyValue">
                                 {{projectActuals[col]}}
                                 <!-- {{ clientEstimates[col] }} -->
                              </td>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                           <tr *ngIf="!hideFooterFlagClient">
                              <td></td>
                              <td colspan="50">
                                 <!-- <h5> Total Cost = {{totalCostClientEstimate  | currency}}</h5> -->
                              </td>
                           </tr>
                        </ng-template>

                     </p-table>

                  </div>
               </div>
            </div>
         </div>

      </div>

   </div>
</div>
<div class="container-fluid">
   <div>
      <div class="row py-2">
         <div class="col">
            <div class="br-1 p-2" style="margin-bottom: 50px;">
               <div class="row">
                  <div class="col">
                     <label style="font-size: 15px;">Overall Comments</label>
                     <textarea class="form-control" [disabled]="isDisabled()" (change)="updateFlagTrue()"
                        id="exampleFormControlTextarea1" [(ngModel)]="comments" rows="3"></textarea>
                  </div>
                  <div class="col d-flex align-items-center">
                     <!-- <label style="color: #5e94ff;cursor: pointer;" (click)="showDialog()" icon="pi pi-info-circle" label="Show"> Overall Comments/Remarks/Notes</label> -->
                     <div class="ml-auto">
                        <button style="font-size: 20px;" *ngIf="updateFlag==true"
                           [disabled]="areRequiredPropertiesTrue()" class="btn fxdbtn cstbtn btn4 ml-2 bounce"
                           (click)="saveButtonDialog()">
                           <i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp; Save Changes</button>
                     </div>
                     <!-- <label *ngIf="mandatoryFlag" class="mandatory">please Fill in all mandatory Field</label>
            <label *ngIf="duplicateFlag" class="mandatory">Please Fill  Remove Duplicate Data </label> -->
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>


<!-- <p-confirmDialog ></p-confirmDialog> -->
<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle">
   <p-footer>
      <div class="d-flex justify-content-center align-items-center">
         <button type="button" pButton icon="pi pi-check" label="Ok" (click)="cd.accept()"></button>
         <!-- <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="cd.reject()" ></button> -->
         <div class="clrfilter" style="height: 100%; padding-bottom: 0px; display: flex; align-items: center;"
            (click)="cd.reject()">
            <i style="color: #ed4d4d; margin-right: 10px;" class="fa fa-times red" aria-hidden="true"></i>
            Cancel
         </div>
      </div>
   </p-footer>
</p-confirmDialog>