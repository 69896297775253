<ng-template #customLoadingTemplate>
  <div class="ctmloader">
     <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
  <div class="container-fluid pt-3">
     <div class="ctm-body">
        <div class="d-flex mb-1">
              <div class="mr-auto">
                 <h4>Projects Lookup</h4>
              </div>
              <b style="font-size: 1rem;">My Projects Count : Active - <span class="fc">{{activeCount}}</span> , Closed - <span class="fc">{{closedCount}}</span> , Draft - <span class="fc">{{draftCount}}</span>, Cancelled - <span class="fc">{{cancelledCount}}</span></b>
        </div>
        <div class="ctm-card">
         <ng-container >
           <p-table  class="bordertop"  [value]="projetsDataTable" #dt 
           [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5,10,15,20]" pageLinks="3"  [totalRecords]="projetsDataTable.length"
           [globalFilterFields]="['client_name','project_name','start_date','end_date','po_number','project_amount','total_invoicce','total_milestone_amount','non_expense_milestone_amount']">
           <ng-template pTemplate="caption">
              <div class="d-flex ctmfilter align-items-end">
                 <div>
                    <label for="dtPicker" id="lbldtpicker" class="d-block text-left control-label mr-2">From Date</label>
                    <mat-form-field appearance="fill">
                       <input matInput  [matDatepicker]="dp" placeholder="MM/DD/YYYY" [formControl]="startDate">
                       <mat-datepicker-toggle matSuffix  [for]="dp"></mat-datepicker-toggle>
                       <mat-datepicker #dp  startView="year" 
                          panelClass="example-month-picker">
                       </mat-datepicker>
                    </mat-form-field>
                 </div>
                 <div class="ml-3">
                    <label for="dtPicker" id="lbldtpicker" class="d-block text-left control-label mr-2">To Date</label>
                    <mat-form-field appearance="fill">
                       <input matInput  [matDatepicker]="dp1" placeholder="MM/DD/YYYY" [formControl]="endDate">
                       <mat-datepicker-toggle matSuffix  [for]="dp1"></mat-datepicker-toggle>
                       <mat-datepicker #dp1  startView="year" panelClass="example-month-picker">
                       </mat-datepicker>
                    </mat-form-field>
                 </div>
                 <div class="ml-3 mb-1">
                    <label class="d-block text-left" for="exampleFormControlSelect1">Project Status</label>
                    <select class="form-control" [formControl]="projetStatus" id="exampleFormControlSelect1" (change)="filterStatus($event)" [ngModel]="statusFilter" >
                    <option value='All'>All</option>
                    <option value='Active'>Active</option>
                    <option value='Closed'>Closed</option>
                    <option value='Draft'>Draft</option>
                    <option value='Pending'>Pending</option>
                    <option value='Cancelled'>Cancelled</option>
                    </select>
                 </div>
                 <button type="button" (click)="filterChange()" class="btn cstbtn btn1 ml-3 mb-1"><i class="fa fa-filter" aria-hidden="true"></i> Apply filters</button>
                 <!-- <button type="button"  class="btn cstbtn btn1 ml-3 mb-1"><i class="fa fa-times" aria-hidden="true"></i> Reset Filters</button> -->
                 <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red" aria-hidden="true"></i> Reset Filters</div>
                 <div class="ml-auto bl-1">
                    <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                       (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                 </div>
              </div>
           </ng-template>
           <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                 <col style="width:180px;">
              </colgroup>
           </ng-template>
           <ng-template pTemplate="header">
              <tr>
                 <th pSortableColumn="project_name" >
                    Project
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th pSortableColumn="client_name">
                    Client
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th  pSortableColumn="sub_project_id" *ngIf="userRole=='Sub Project Delivery Manager'">
                    Sub Project Number
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th style="white-space: nowrap;" pSortableColumn="start_date">
                    Start Date
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th style="white-space: nowrap;" pSortableColumn="end_date">
                    End Date
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th pSortableColumn="po_number"
                    *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    PO#
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th style="text-align: right !important;" pSortableColumn="project_amount"
                    *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    Project Amount
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th style="text-align: right !important;" pSortableColumn="total_invoicce"
                    *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    Invoice Amount
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th style="text-align: right !important;" pSortableColumn="total_milestone_amount"
                    *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    MileStone Amount
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th style="text-align: right !important;" pSortableColumn="non_expense_milestone_amount"
                    *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    <span style="white-space: nowrap;">Non Expense</span> Milestone Amount
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th style="text-align: right !important;" pSortableColumn=""
                    *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    Work Value
                    <p-sortIcon field="client"></p-sortIcon>
                 </th>
                 <th>Accounting Entry Status</th>
                 <!-- <th>Action</th> -->
              </tr>
           </ng-template>
           <ng-template pTemplate="body" let-project>
              <tr>
                 <td>
                    <a>{{project.project_name}}</a>
                    <span class="d-block">
                       <a routerLink="projects/project-detail/project.project_id" (click)="routeToProjectDetails(project.project_id)">Manage</a> 
                       <ng-container *ngIf="userRole===('Delivery Manager' || 'Delivery Partner')  && project.project_status === 'Active' "> |
                          <a routerLink="" (click)="routeToProjectStatusDetails(project.project_id)">Update Status</a>   
                       </ng-container>
                    </span>
                 </td>
                 <td>{{project.client_name}}</td>
                 <td *ngIf="userRole=='Sub Project Delivery Manager'">{{project.sub_project_id}}</td>
                 <td>{{project.start_date|date:'mediumDate'}}</td>
                 <td>{{project.end_date|date:'mediumDate'}}</td>
                 <td *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    {{project.po_number}}
                 </td>
                 <td  style="text-align: right;" *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    {{project.project_amount|currency}}
                 </td>
                 <td  style="text-align: right;" *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    {{project.total_invoice|currency}}
                 </td>
                 <td  style="text-align: right;" *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    {{project.total_milestone_amount|currency}}
                 </td>
                 <td style="text-align: right;" *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    {{project.non_expense_milestone_amount|currency}}
                 </td>
                 <td style="text-align: right;" *ngIf="userRole!=='Project Team Member' && userRole!=='Sub Project Delivery Manager'">
                    {{project.total_workvalue_amt? (project.total_workvalue_amt | currency ) : (0 | currency) }}
                 </td>
                 <td style="width: 8rem">
                    <div class="fillcircle" *ngFor="let i of projects">
                       <div *ngIf="i.project_id == project.project_id">
                          <div *ngIf="i.client_estimate == 1 && i.internal_estimate ==0 && i.resource_estimate ==0">
                             <img style="display:inline;float:left;" src="assets/images/fill1.4.svg" height=20 width=20>
                          </div>
                          <div *ngIf="i.client_estimate == 0 && i.internal_estimate ==0 && i.resource_estimate ==1">
                             <img style="display:inline;float:left;" src="assets/images/fill1.4.svg" height=20 width=20>
                          </div>
                          <div *ngIf="i.client_estimate == 0 && i.internal_estimate ==1 && i.resource_estimate ==0">
                             <img style="display:inline;float:left;" src="assets/images/fill1.4.svg" height=20 width=20>
                          </div>
                          <div *ngIf="i.client_estimate == 1 && i.internal_estimate ==1 && i.resource_estimate ==0">
                             <img style="display:inline;float:left;" src="assets/images/fill3.4.svg" height=20 width=20>
                          </div>
                          <div *ngIf="i.client_estimate == 1 && i.internal_estimate ==0 && i.resource_estimate ==1">
                             <img style="display:inline;float:left;" src="assets/images/fill3.4.svg" height=20 width=20>
                          </div>
                          <div *ngIf="i.client_estimate == 0 && i.internal_estimate ==1 && i.resource_estimate ==1">
                             <img style="display:inline;float:left;" src="assets/images/fill3.4.svg" height=20 width=20>
                          </div>
                          <div *ngIf="i.client_estimate == 0 && i.internal_estimate ==0 && i.resource_estimate ==0">
                             <img style="display:inline;float:left;" src="assets/images/empty.svg" height=20 width=20>
                          </div>
                          <div *ngIf="i.client_estimate == 1 && i.internal_estimate ==1 && i.resource_estimate ==1">
                             <img style="display:inline;float:left;" src="assets/images/full.svg" height=20 width=20>
                          </div>
                       </div>
                    </div>
                 </td>
                 <!-- <td>0</td> -->
                 <!-- <td>
                    <a [routerLink]="" (click)="routeToProjectDetails(project.project_id)">Manage </a>
                    </td> -->
              </tr>
           </ng-template>
           </p-table>
         </ng-container>
           
        </div>
     </div>
  </div>
</div>