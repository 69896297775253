import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { StorageService } from 'src/app/_services/storage.service';
import * as ConfigJson from '../../../assets/config.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  anio: number; 
  bv = ConfigJson.build_version;
  constructor(private sharedServ: SharedService) { }

  ngOnInit(): void {
    this.anio  = new Date().getFullYear();
  }

}
