import { Component, OnInit } from '@angular/core';
import { UtilizationReportService, UtilizationReport } from '../../_services/utilization-report.service';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_services/storage.service';
import { MatCalendar } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AdminService } from 'src/app/_services/admin.service';
import { report } from 'process';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
@Component({
  selector: 'app-utilization-report',
  templateUrl: './utilization-report.component.html',
  styleUrls: ['./utilization-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  styles: [`
        :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
        }

        @media screen and (max-width: 64em) {
            :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
                top: 99px;
            }
        }
  `]
})
export class UtilizationReportComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  loading = false;

  public cars: any[];
  public cols: any[];
  public isExpanded: boolean = false;
  public rows: number = 10;
  public expandedRows = {};
  public temDataLength: number = 0;
  public noOfWorkingHrsPerDay = 8;
  public reportData = {};
  public reports = [];
  public reportsSorted = [];
  public filterMonth = moment().format('MMM');
  public filterYear = moment().format('yyyy');

  public filterStartDate;
  public filterEndDate;
  public filterMonths = {};

  public noOfSundays; noOfSaturdays; noOfWeekends; noOfWorkingDays; BillableHrsPerMonth;
  innerPage: any[];
  resource: any;
  departments: any;
  bu_name: any[];
  duration: any
  departmentFilter = '';
  businessUnitFilter = '';
  departmentDropDown: any;
  departmentObj: any = {};
  AllreportsData: any[];
  userRole: string;
  departmentControl = new FormControl('');
  businessUnitControl = new FormControl('');
  durationControl = new FormControl('');
  filterText: any;
  dateFieldFormat: string = 'MMM YYYY';
  filterClicked: boolean = false;
  resultdate: number = -1;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings={};
  settings;
  tableData = [];
  selectedIds = [];
  highlightedSkills = [];
  secondarySkill = '';
  primarySkill = '';
  availabilityData = '';
  showAdvancedFilter : boolean = false;

  constructor(private utilizationService: UtilizationReportService, private storage: StorageService,private adminService: AdminService,) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 5, 0, 1);
    this.maxDate = new Date(currentYear, 11, 31);
  }

  chosenstartYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.startDate.value;
    ctrlValue.year(normalizedYear.year());
    this.startDate.setValue(ctrlValue);
  }

  chosenstartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.startDate.value;
    ctrlValue.month(normalizedMonth.month());
    this.startDate.setValue(ctrlValue);
    datepicker.close();
    // this.filterChange()
  }

  chosenendYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.endDate.value;
    ctrlValue.year(normalizedYear.year());
    this.endDate.setValue(ctrlValue);
  }

  chosenendMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.endDate.value;
    ctrlValue.month(normalizedMonth.month());
    this.endDate.setValue(ctrlValue);
    datepicker.close();
    // this.filterChange();
  }


  ngOnInit() {
   this.getSkillMasterList();
   this.getMasterData();
    // this.dropdownSettings = {
    //   idField: 'skill_master_id',
    //   textField: 'skill_name',
    // };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'skill_master_id',
      textField: 'skill_name',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.settings = {
      singleSelection: false,
      idField: 'skill_master_id',
      textField: 'skill_name',
      enableCheckAll: true,
      selectAllText: "Chọn All",
      unSelectAllText: "Hủy chọn",
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: "Tìm kiếm",
      noDataAvailablePlaceholderText: "Không có dữ liệu",
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.userRole = this.storage.getItem('selectedRole');
    this.resource = JSON.parse(this.storage.getItem('user'));
    this.departments = JSON.parse(this.storage.getItem('departments'));
    this.duration = '0';
    this.departments.map(department => {
      // this.departmentDropDown.push({'label': department.department_name, 'value': department.department_id})
      this.departmentObj[department.department_id] = { 'name': department.department_name };
    });
    // this.bu_name = JSON.parse(this.storage.getItem('bu_name'));
    // console.log('bbb',this.bu_name)
    // this.bu_name.map(bu => {
    //   this.departmentObj[bu.department_id] = { 'name': bu.department_name };
    // });
    this.filterChange();


    this.cols = [{
      field: 'employee_id',
      header: 'Emp Id'
    },{
      field: 'resource_name',
      header: 'Resource'
    },
    // {
    //   field: 'department_name',
    //   header: 'Department'
    // },
    {
      field: 'bu',
      header: 'BU'
    },
    {
      field: 'rwt_name',
      header: 'RWT-(Level)'
    },
    {
      field: 'resource_manager_name',
      header: 'Resource Manager'
    }, {
      field: 'monthyear',
      header: 'Month Year'
    },
    // {
    //   field: 'year',
    //   header: 'Year'
    // },
    {
      field: 'WorkingHrs',
      header: 'Allocation (%)',
      type: Number

    },
    {
      field: 'availability',
      header: 'Availability Yes/No'
    },
    // {
    //   field: 'SabbaticalLeaveHrs',
    //   header: 'Sabbatical (%)'
    // },
    {
      field:  'prev_exp',
      header: 'Past Exp. (in Months)'
    },
    {
      field:  'Expr',
      header: 'MResult Exp. (in Months)'
    },
    {
      field:  'total_exp',
      header: 'Total Exp. (in Months)'
    },
    {
      field:  'skill_name',
      header: 'Skills'
    },
    ];

    this.reports.length < this.rows ? this.temDataLength = this.reports.length : this.temDataLength = this.rows;
  }
  getSkillMasterList(){
    // this.adminService.getSkillList().then(skillData=>{
    //   this.dropdownList = skillData;
    // }).catch(()=>{
    
    // })
    let formData = {};
    formData['type'] = 'get'
    formData['postBody'] = { 
      'opType': 5
    };

    this.adminService.postResourceSkillData(formData).subscribe(
      response => {
        this.dropdownList = response['body'];
        this.dropdownList.unshift({ 'skill_name': 'Secondary Skill', 'skill_master_id': '00' });

        this.dropdownList.unshift({ 'skill_name': 'Primary Skill', 'skill_master_id': '0' });
        // idField: 'skill_master_id',
        // textField: 'skill_name',
        //this.projectDropDown.push({ 'label': 'NA', 'value': '0' });
                // console.log('resourceSkillData',this.dropdownList)

      },
      err => {
        this.loading = false;
        console.log(err)
      });

  }

  expandAll() {
    if (!this.isExpanded) {
      this.reports.forEach(data => {
        this.expandedRows[data.row_id] = true;
      });
    } else {
      this.expandedRows = {};
    }
    this.isExpanded = !this.isExpanded;
  }

  onRowExpand() {
    if (Object.keys(this.expandedRows).length === this.temDataLength) {
      this.isExpanded = true;
    }
  }

  onRowCollapse() {
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

  onPage(event: any) {
    this.temDataLength = this.reports.slice(event.first, event.first + 10).length;
    this.isExpanded = false;
    this.expandedRows = {};
  }

  weekdaysBetween(d1, d2, isoWeekday) {
    // ensure we have valid moment instances
    d1 = moment(d1);
    d2 = moment(d2);
    // figure out how many days to advance to get to the next specified weekday (might be 0 if d1 is already the specified weekday).
    const daysToAdd = ((7 + isoWeekday) - d1.isoWeekday()) % 7;
    const nextTuesday = d1.clone().add(daysToAdd, 'days');
    // if we are already passed the end date, there must not  be any of that day in the given period.
    if (nextTuesday.isAfter(d2)) {
      return 0;
    }
    // otherwise, just return the whole number of weeks difference plus one for the day we already advanced to
    const weeksBetween = d2.diff(nextTuesday, 'weeks');
    return weeksBetween + 1;
  }

  getWorkingDaysByMonth(selectedDate) {

    let noOfWorkingDays, BillableHrsPerMonth, noOfWeekends, noOfSaturdays, noOfSundays; 

    let selectedDateMonth = selectedDate.split(' ')[0]
    let selectedDateYear = selectedDate.split(' ')[1]
    const lastday = moment().month(selectedDate).endOf('month').date();
    const d1 = moment(selectedDateYear + '-' + moment().month(selectedDateMonth).format('M') + '-'+moment().month(selectedDateMonth).startOf('month').format('DD')).format('YYYY-MM-DD');
    const d2 = moment(selectedDateYear + '-' + moment().month(selectedDateMonth).format('M') + '-'+moment().month(selectedDateMonth).endOf('month').format('DD')).format('YYYY-MM-DD');
  
    noOfSundays = this.weekdaysBetween(d1, d2, 0);
    noOfSaturdays = this.weekdaysBetween(d1, d2, 6);
    
    noOfWeekends = noOfSundays + noOfSaturdays;
    noOfWorkingDays = lastday - noOfWeekends;
    BillableHrsPerMonth = noOfWorkingDays * this.noOfWorkingHrsPerDay;

    return { 'noOfWorkingDays': noOfWorkingDays, 'BillableHrsPerMonth': BillableHrsPerMonth };
  }
  onItemSelect(item: any) {
    // this.selectedIds.push(item.skill_name);
    // console.log('this.selectedIds',this.selectedIds);
    // this.resetTable();
  }
  onSelectAll(item: any) {
    this.selectedIds = this.dropdownList.map(x => x.skill_name);
    // console.log('this.selectedIds',this.selectedIds);
    // this.resetTable();
  }
  onDeSelectAll(item: any) {
    this.selectedIds = [];
    // console.log('this.selectedIds',this.selectedIds);
    this.tableData = [...this.dropdownList];
  }
  onItemDeSelect(item: any) {
    // console.log('this.selectedIds',this.selectedIds);
    
  }

  filterChange() {
    // if(this.selectedIds.length && this.selectedIds.length !== 0)
    //   this.onSkillSelect();
    this.filterClicked = true;
    this.filterStartDate = moment(this.startDate.value).startOf('month').format('YYYY-MM-DD');
    this.filterEndDate = moment(this.filterStartDate).add('months', this.duration).endOf('month').format('YYYY-MM-DD');

    if (moment(this.filterEndDate).isBefore(this.filterStartDate)) {
      Swal.fire('Enter valid dates', '', 'warning')
    } else {
      this.reports = [];
      this.reportData = [];
      const dateStart = moment(this.startDate.value);
      const dateEnd = moment(this.filterEndDate);
      const interim = dateStart.clone();
      this.filterMonths = {};

      while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {

        this.filterMonths[interim.format('MMM')] = this.getWorkingDaysByMonth(interim.format('MMM YYYY'));
        interim.add(1, 'month');
      }
      this.getData(this.filterStartDate, this.filterEndDate);
    }
    if (moment(this.filterStartDate).format('MMM YYYY') === moment(this.filterEndDate).format('MMM YYYY')) {
      this.filterText = moment(this.filterStartDate).format('MMM YYYY')
    } else {
      this.filterText = moment(this.filterStartDate).format('MMM YYYY') + ' - ' + moment(this.filterEndDate).format('MMM YYYY')
    }


  }


  clearFilters() {
    this.selectedIds = [];
    this.filterClicked = false;
    this.startDate.setValue(moment());
    this.endDate.setValue(moment());
    this.duration = '0';
    this.departmentControl.setValue('');
    this.businessUnitControl.setValue('');
    this.departmentFilter = '';
    this.businessUnitFilter = '';
    this.showAdvancedFilter = false;
    this.filterChange();
  }

  getData(startDate, endDate) {
    this.loading = true;
    let filteredStartDate, filteredEndDate;
    filteredStartDate = moment(startDate).startOf('month').format('YYYY-MM-DD');
    filteredEndDate = moment(endDate).endOf('month').format('YYYY-MM-DD');
    let Utilization = 0, WorkingHrs = 0, SabbaticalLeaveHrs = 0;
    this.innerPage = [];
    this.utilizationService.getReports(filteredStartDate, filteredEndDate, this.resource.userID).then((reports) => {
      // if (this.departmentFilter !== '') {
      //   reports = reports.filter((data) =>
      //     data.department_name === this.departmentObj[this.departmentFilter].name
          
      //   );
      
      // }
      if(this.businessUnitFilter !== ''){
        reports = reports.filter((data) =>
          data.bu === this.businessUnitFilter );
      }
      if (this.selectedIds.length !== 0) {
        const temp = [];
        let primaryTemp = this.selectedIds.find(x => x.skill_name === 'Primary Skill')
        let secondaryTemp = this.selectedIds.find(x => x.skill_name === 'Secondary Skill')
        reports.forEach(x => {
          let returnFlag = false;
          if(x.skill_name != null) {
            this.selectedIds.forEach(y => {
              let skillTempData = x.skill_name.split(',');
              skillTempData.forEach(z => {
                //console.log()&& z.includes('Primary')
                let TempData = z.split(' |');
                if(this.selectedIds.length ===2 && primaryTemp !== undefined && primaryTemp.skill_name === 'Primary Skill' && secondaryTemp !== undefined && secondaryTemp.skill_name === 'Secondary Skill')
                {
                  returnFlag = true;
                }
                else if(this.selectedIds.length > 2 && primaryTemp !== undefined && primaryTemp.skill_name === 'Primary Skill' && secondaryTemp !== undefined && secondaryTemp.skill_name === 'Secondary Skill')
                {
                  if(TempData!== null && (TempData[0] === (y.skill_name))) {

                    returnFlag = true;
                  }
                }
                else if(primaryTemp !== undefined && primaryTemp.skill_name === 'Primary Skill')
                {
                    if(this.selectedIds.length === 1) {
                      if(z.includes('Primary')) {

                        returnFlag = true;
                      }
                    }else {
                      if(TempData!== null && (TempData[0] === (y.skill_name)) && z.includes('Primary')) {

                        returnFlag = true;
                      }
                    }

                    
                  } else if(secondaryTemp !== undefined && secondaryTemp.skill_name === 'Secondary Skill')
                  {
                    if(this.selectedIds.length === 1) {
                      if(!z.includes('Primary')) {

                        returnFlag = true;
                      }
                    }else {
                      if(TempData!== null && (TempData[0] === (y.skill_name)) && !z.includes('Primary')) {

                        returnFlag = true;
                      }
                    }

                    
                  }
                  else{
                    if(TempData!== null && (TempData[0] === (y.skill_name))) {

                      returnFlag = true;
                    }
                    // returnFlag = true;
                  }
                 
              });
            });
          }
          if(returnFlag){
            temp.push(x);
          }
        });
        reports = [];
        reports = [...temp];
      }
      this.innerPage = reports;
      reports.map((report, index) => {
        let project = {};
        const currentMonthStartDate = moment(report.year + '-' + moment().month(report.month).format('M') + '-' + moment().month(report.month).year(report.year).startOf('month').format('DD'), 'YYYY-MM-DD').format('YYYY-MM-DD');
        const currentMonthEndDate = moment(report.year + '-' + moment().month(report.month).format('M') + '-' + moment().month(report.month).year(report.year).endOf('month').format('DD'), 'YYYY-MM-DD').format('YYYY-MM-DD');
        WorkingHrs = this.calculate_utilization(report.resource_start_date, report.resource_last_date, currentMonthStartDate, currentMonthEndDate);
        SabbaticalLeaveHrs = this.calculate_sabbatical(report.sabatical_start_date, report.sabatical_end_date, currentMonthStartDate, currentMonthEndDate);
        
        let skills = [];
        let skillName = '';
        let skillNameList = '';
        // let MrExp = '';
        // let PrevExp = '';
        // if(report.sabatical_start_date !== null) {
        //   let rid=0;
        // }if
        
        if(report.skill_name!=null)
        {
          skillNameList=report.skill_name.split(',').join('\n');
        }
        // if(report.Expr!= null)
        // {
        //   MrExp = report.Expr.split(',').join('\n');
        // }

        // if(report.prev_exp!= null)
        // {
        //   PrevExp = report.prev_exp.split(',').join('\n');
         
        // }

        let skillTemp = [];
        let availability = 0;
        if (this.selectedIds.length) {
        let primaryTemp = this.selectedIds.find(x => x.skill_name === 'Primary Skill')
        let secondaryTemp = this.selectedIds.find(x => x.skill_name === 'Secondary Skill')
          let flagExist = false;
          skills = report.skill_name.split(',');
          skills.forEach( x => {
            this.selectedIds.forEach( y => {
              skillTemp = x.split(' |');
              // if (x.includes(y.skill_name)) {
                // skillTemp = x.split(' |');
                // console.log('daa',skillTemp[0]);
                if(this.selectedIds.length === 2 && primaryTemp !== undefined && primaryTemp.skill_name === 'Primary Skill' && secondaryTemp !== undefined && secondaryTemp.skill_name === 'Secondary Skill')
                {
                  flagExist = true
                  
                  // this.selectedIds.pop()
                  
                }
                else if(this.selectedIds.length > 2 && primaryTemp !== undefined && primaryTemp.skill_name === 'Primary Skill' && secondaryTemp !== undefined && secondaryTemp.skill_name === 'Secondary Skill')
                {
                  if(skillTemp[0] === y.skill_name) {

                    skillName += x + '\n';
                  }
                }
                else if(primaryTemp !== undefined && primaryTemp.skill_name === 'Primary Skill')
                {
                  if(this.selectedIds.length === 1) {
                    if(x.includes('Primary')) {

                      skillName += x + '\n';
                    }
                  }else {
                    if(skillTemp[0] === y.skill_name && x.includes('Primary')) {

                      skillName += x + '\n';
                    }
                  }
                }else if(secondaryTemp !== undefined && secondaryTemp.skill_name === 'Secondary Skill')
                {
                  if(this.selectedIds.length === 1) {
                    if(!x.includes('Primary')) {

                      skillName += x + '\n';
                    }
                  }else {
                    if(skillTemp[0] === y.skill_name && !x.includes('Primary')) {

                      skillName += x + '\n';
                    }
                  }
                }
                else{
                  if(skillTemp[0] === y.skill_name) {

                    skillName += x + '\n';
                  }
                  // returnFlag = true;
              }  
              // if(skillTemp[0] === y.skill_name) {
              //     skillName += x + '\n';
              // }
            });
            if(flagExist === true){
              skillName += x + '\n';
            }
          });
          // console.log('rp',this.selectedIds)
        } 
        
        if (!this.reportData[report.resource_id]) {
          this.reportData[report.resource_id] = {};
          this.reportData[report.resource_id][report.month] = {
            'row_id': index,
            'employee_id': report.employee_id,
            'resource_id': report.resource_id,
            'resource_name': report.resource_name,
            'resource_email': report.resource_email,
            'resource_manager_name': report.resource_manager_name,
            'resource_manager_email': report.resource_manager_email,
            'resource_start_date': report.resource_start_date,
            'resource_last_date': report.resource_last_date,
            'rwt_name': report.rwt_name +'-'+ '(' + report.level_name + ')',
            'rwt': report.rwt_name,
            'rwt_level':report.level_name,
            'department_name': report.department_name,
            'Line_group': report.Line_group,
            'sub_project_id': report.sub_project_id,
            'sub_project_name': report.sub_project_name,
            'sabatical_start_date': report.sabatical_start_date,
            'sabatical_end_date': report.sabatical_end_date,
            'SabbaticalLeaveHrs': SabbaticalLeaveHrs.toFixed(2),
            'totalhrs': report.hours,
            'month': report.month,
            'year': report.year,
            'monthyear': report.month + ' ' + report.year,
            'projects': [],
            'skill_name': this.selectedIds.length ? skillName : skillNameList,
            'Expr' :report.Expr,
            'prev_exp':report.prev_exp,
            'total_exp':report.total_exp,
            'bu':report.bu,
            'sub_bu':report.sub_bu,
            'availability': availability

         
           }; 
          if (report.project_name !== null) {
            project = {
              'resource_name': report.resource_name,
              'client_name': report.client_name,
              'sub_project_id': report.sub_project_id,
              'sub_project_name': report.sub_project_name,
              'project_name': report.project_name,
              'month': report.month,
              'year': report.year,
              'billing_hours': report.hours,
              'delivery_manager': report.DM,
              'engagement_partner': report.EP
            };
            this.reportData[report.resource_id][report.month]['projects'].push(project);
          }
        } else {
          //code added for Months
          if (!this.reportData[report.resource_id][report.month]) {

            this.reportData[report.resource_id][report.month] = {
              'row_id': index,
              'employee_id': report.employee_id,
              'resource_id': report.resource_id,
              'resource_name': report.resource_name,
              'resource_email': report.resource_email,
              'resource_manager_name': report.resource_manager_name,
              'resource_manager_email': report.resource_manager_email,
              'resource_start_date': report.resource_start_date,
              'resource_last_date': report.resource_last_date,
              'rwt_name': report.rwt_name +'-'+ '(' + report.level_name + ')',
              'rwt': report.rwt_name,
              'rwt_level':report.level_name,
              'department_name': report.department_name,
              'Line_group': report.Line_group,
              'sub_project_id': report.sub_project_id,
              'sub_project_name': report.sub_project_name,
              'sabatical_start_date': report.sabatical_start_date,
              'sabatical_end_date': report.sabatical_end_date,
              'SabbaticalLeaveHrs': SabbaticalLeaveHrs.toFixed(2),
              'month': report.month,
              'year': report.year,
              'monthyear': report.month + ' ' + report.year,
              'totalhrs': report.hours,
              'projects': [],
              'skill_name': this.selectedIds.length ? skillName : skillNameList,
              'Expr' :report.Expr,
              'prev_exp':report.prev_exp,
              'total_exp':report.total_exp,
              'bu':report.bu,
              'sub_bu':report.sub_bu,
              'availability': availability
            };
            if (report.project_name !== null) {
              project = {
                'resource_name': report.resource_name,
                'client_name': report.client_name,
                'project_name': report.project_name,
                'sub_project_id': report.sub_project_id,
                'sub_project_name': report.sub_project_name,
                'month': report.month,
                'year': report.year,
                'billing_hours': report.hours,
                'delivery_manager': report.DM,
                'engagement_partner': report.EP
              };
              this.reportData[report.resource_id][report.month]['projects'].push(project);
            }
            // this.reportData[report.resource_id][report.month]['totalhrs'] += report.hours;
          }
          else {
            // added by kiran if two sabatical leave
            if(SabbaticalLeaveHrs>0) {
             this.reportData[report.resource_id][report.month].SabbaticalLeaveHrs=SabbaticalLeaveHrs.toFixed(2);
            }
            //code added for projects
            if (report.project_name !== null) {
              project = {
                'resource_name': report.resource_name,
                'client_name': report.client_name,
                'project_name': report.project_name,
                'sub_project_id': report.sub_project_id,
                'sub_project_name': report.sub_project_name,
                'month': report.month,
                'year': report.year,
                'billing_hours': report.hours,
                'delivery_manager': report.DM,
                'engagement_partner': report.EP
              };
              this.reportData[report.resource_id][report.month]['projects'].push(project);
            } else {
              if (report.project_name !== null) {
                this.reportData[report.resource_id][report.month]['projects'].push({
                  'resource_name': report.resource_name,
                  'client_name': report.client_name,
                  'project_name': report.project_name,
                  'sub_project_id': report.sub_project_id,
                  'sub_project_name': report.sub_project_name,
                  'month': report.month,
                  'year': report.year,
                  'billing_hours': report.hours,
                  'delivery_manager': report.DM,
                  'engagement_partner': report.EP
                });
              }

            }
            this.reportData[report.resource_id][report.month]['totalhrs'] += report.hours;

          }
        }
        // Utilization = isNaN((this.reportData[report.resource_id][report.month]['totalhrs'] / WorkingHrs) * 100) ? 
        // 0 : ((this.reportData[report.resource_id][report.month]['totalhrs'] / WorkingHrs) * 100); // - SabbaticalLeaveHrs;
        Utilization = ((this.reportData[report.resource_id][report.month]['totalhrs'] / WorkingHrs) * 100); // - SabbaticalLeaveHrs;
        this.reportData[report.resource_id][report.month]['WorkingHrs'] = Utilization.toFixed(2);
         
        this.reportData[report.resource_id][report.month]['availability'] =   this.reportData[report.resource_id][report.month]['WorkingHrs']  <= 75 ? 'Yes': 'No';
        //this.reportData[report.resource_id][report.month]['availability'] =   this.reportData[report.resource_id][report.month]['WorkingHrs']  <= 75 ? 'Yes - ' + (100 - this.reportData[report.resource_id][report.month]['WorkingHrs']) + '%' : 'No';
        
        // console.log('uf',availabilityData)
      });
      Object.keys(this.reportData).map(item => {
        Object.values(this.reportData[item]).map(monthitem => {
          this.reports.push(monthitem);
        });
        
      });
      this.AllreportsData = this.reports.sort((a, b) => a.resource_name.localeCompare(b.resource_name));
      this.loading = false;
    }).catch(err => {
      this.reports = [];
      this.loading = false;
    });
  }

  durationChange(event) {
    if (event.target.value === '') {
      this.duration = '0';
    } else {
      this.duration = event.target.value;
    }
  }

  deptChange(event) {
    if (event.target.value === '') {
      this.departmentFilter = '';
    } else {
      this.departmentFilter = event.target.value; // this.departmentObj[event.target.value].name;
    }
  }



  calculate_utilization(startDate, endDate, currentMonthStartDate, currentMonthEndDate) {
    let resourceStartDay, resourceEndDay, noDaysFromStartDate, noDaysFromEndDate, resourceBillableHrs;
    const currentMonth = moment(currentMonthEndDate).format('MMM');
    if (moment(startDate).isBetween(currentMonthStartDate, currentMonthEndDate, undefined, '[]')) {
      resourceStartDay = parseInt(moment(startDate).format('D')) - 1;
      const lastDay = parseInt(moment(currentMonthEndDate).format('D'));
      const noOfSat = this.weekdaysBetween(moment(startDate), moment(currentMonthEndDate), 6);
      const noOfSun = this.weekdaysBetween(moment(startDate), moment(currentMonthEndDate), 0);
      noDaysFromStartDate = lastDay - (resourceStartDay + (noOfSat + noOfSun));
      resourceBillableHrs = noDaysFromStartDate * this.noOfWorkingHrsPerDay;
    }
    if (endDate != null) {
      if (moment(endDate).isBetween(currentMonthStartDate, currentMonthEndDate, undefined, '[]')) {
        resourceEndDay = parseInt(moment(endDate).format('D'));
        const noOfSat = this.weekdaysBetween(moment(currentMonthStartDate), moment(endDate), 6);
        const noOfSun = this.weekdaysBetween(moment(currentMonthStartDate), moment(endDate), 0);
        noDaysFromEndDate = resourceEndDay - (noOfSat + noOfSun);
        resourceBillableHrs = noDaysFromEndDate * this.noOfWorkingHrsPerDay;
      }
    }

    if (!moment(startDate).isBetween(currentMonthStartDate, currentMonthEndDate) && (endDate === null ||
      !moment(endDate).isBetween(currentMonthStartDate, currentMonthEndDate))) {
      resourceBillableHrs = this.filterMonths[currentMonth].BillableHrsPerMonth;
    }
    return resourceBillableHrs;
  }

  calculate_sabbatical(sab_startDate, sab_endDate, currentMonthStartDate, currentMonthEndDate) {
    
    let resourceStartDay, resourceEndDay, noDaysFromStartDate, noDaysFromEndDate, resourceBillableHrs, resourceSabbaticalStartDay, resourceSabbaticalEndDay, resourceLeaveHrs;
    const currentMonth = moment(currentMonthEndDate).format('MMM');
    if (moment(currentMonthStartDate).isBetween(sab_startDate, sab_endDate, undefined, '[]') && moment(currentMonthEndDate).isBetween(sab_startDate, sab_endDate, undefined, '[]')) {
      // isSabbatical=true;
      resourceLeaveHrs = 100; // (noOfResourceWorkingHrs/resourceBillableHrs)*100;
    } else if (moment(currentMonthStartDate).isBetween(sab_startDate, sab_endDate, undefined, '[]') && moment(currentMonthEndDate).isBetween(sab_startDate, sab_endDate, undefined, '[]')) {
      // isSabbatical=true;
      resourceLeaveHrs = 100; // (noOfResourceWorkingHrs/resourceBillableHrs)*100;
    } else {
      if (moment(sab_startDate).isBetween(currentMonthStartDate, currentMonthEndDate)) {
        resourceSabbaticalStartDay = parseInt(moment(sab_startDate).format('D')) - 1;
        const lastDay = parseInt(moment(currentMonthEndDate).subtract(1, 'day').format('D'));
        const noOfSat = this.weekdaysBetween(moment(sab_startDate), moment(currentMonthEndDate), 6);
        const noOfSun = this.weekdaysBetween(moment(sab_startDate), moment(currentMonthEndDate), 0);
        noDaysFromStartDate = lastDay - (resourceSabbaticalStartDay + (noOfSat + noOfSun));
        // resourceBillableHrs = (noDaysFromStartDate * this.filterMonths[currentMonth].noOfWorkingDays);
        resourceBillableHrs = (noDaysFromStartDate * 8);
        resourceLeaveHrs = (resourceBillableHrs / this.filterMonths[currentMonth].BillableHrsPerMonth) * 100;
      } else if (moment(sab_endDate).isBetween(currentMonthStartDate, currentMonthEndDate, undefined, '[]')) {

        resourceSabbaticalEndDay = parseInt(moment(sab_endDate).format('D'));
        // resourceSabbaticalEndDay=parseInt( moment(this.filterEndDate).format('D'))-parseInt( moment(sab_endDate).format('D'));
        const noOfSat = this.weekdaysBetween(moment(currentMonthStartDate), moment(sab_endDate), 6);
        const noOfSun = this.weekdaysBetween(moment(currentMonthStartDate), moment(sab_endDate), 0);

        // let noOfSat=weekdaysBetween(moment(sab_endDate),moment(this.filterEndDate), 6);
        // let noOfSun=weekdaysBetween(moment(sab_endDate),moment(this.filterEndDate), 0);
        noDaysFromEndDate = resourceSabbaticalEndDay - (noOfSat + noOfSun);
        // resourceBillableHrs = (noDaysFromEndDate * this.filterMonths[currentMonth].noOfWorkingDays);
        resourceBillableHrs = (noDaysFromEndDate * 8);
        resourceLeaveHrs = (resourceBillableHrs / this.filterMonths[currentMonth].BillableHrsPerMonth) * 100; // (noOfResourceWorkingHrs/resourceBillableHrs)*100;
      } else {
        resourceLeaveHrs = 0;
      }
    }
    return resourceLeaveHrs;
  }
  exportExcel() {
    const ProjectDetail = [];
    const exportDataArray = [];
    let reptsk = '';
    let primeExist = false;
    let tempPrime ='';
    // JSON.parse(JSON.stringify(this.AllreportsData));
    this.AllreportsData.map(report => {
      if(report.skill_name != null)
      {
        this.primarySkill= '';
        this.secondarySkill= '';
        reptsk = report.skill_name.split('yrs').join("yrs ,  ");
        let datajoin = report.skill_name.split('\n');
        datajoin.forEach( x => {
          if(x.includes('Primary')){
            this.primarySkill= x + '\n';
          } else{
            this.secondarySkill= this.secondarySkill + x + '\n';
          }
        });
      }
      // console.log('report.rwt_level,',report.rwt_level,)
      const exportData = {};
      exportData['Employee Id'] = report.employee_id,
      exportData['Resource'] = report.resource_name,
        exportData['Resource Email'] = report.resource_email,
        exportData['Resource Start Date'] = report.resource_start_date,
        exportData['RWT'] = report.rwt,
        exportData['Level']  = report.rwt_level,
        exportData['Resource Manager'] = report.resource_manager_name,
        exportData['Group Assigned'] = report.department_name,
        exportData['BU'] = report.bu,
        exportData['Sub BU']  = report.sub_bu,
        exportData['Line Group'] = report.Line_group,
        exportData['Month Year'] = report.month +' '+report.year,
        // exportData['Year'] = report.year,
        exportData['Sabbatical (%)'] = Number(report.SabbaticalLeaveHrs),
        exportData['Utilization (%)'] = Number( report.WorkingHrs),
        exportData['Availability Yes/No'] = (report.WorkingHrs <= 75 ? 'Yes': 'No'),
        exportData['Availability (%)'] = Number(report.WorkingHrs <=100 ? 100 - report.WorkingHrs : 0)
        exportData['Primary Skills'] = this.primarySkill;
        //reptsk;
        exportData['Secondary Skills'] = this.secondarySkill;
        exportData['Past Exp. (in Months)'] = report.prev_exp,
        exportData['MResult Exp. (in Months)'] = report.Expr,
        exportData['Total Exp. (in Months)'] = report.total_exp,

      exportDataArray.push(exportData);
      report['projects'].map(project => {
        const data = {};
        data['Resource'] = project.resource_name,
          data['Resource Email'] = report.resource_email,
          data['Client'] = project.client_name,
          data['Project'] = project.project_name,
          data['Resource Manager'] = report.resource_manager_name,
          data['Group Assigned'] = report.department_name,
          data['BU']  = report.bu,
          data['Sub BU']  = report.sub_bu,
          data['Line Group'] = report.Line_group,
          data['Month'] = project.month,
          data['Year'] = project.year,
          data['Allocated Hours'] = project.billing_hours,
          data['Delivery Manager'] = project.delivery_manager,
          data['Engagement Partner'] = project.engagement_partner,
          ProjectDetail.push(data);
      });

    });
    import('xlsx').then(xlsx => {
      const worksheet1 = xlsx.utils.json_to_sheet(exportDataArray);
      const worksheet2 = xlsx.utils.json_to_sheet(ProjectDetail);
      const workbook = {
        Sheets: {
          'Allocation Report': worksheet1,
          'Detailed Allocation Report': worksheet2
        },
        SheetNames: ['Allocation Report', 'Detailed Allocation Report']
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      this.saveAsExcelFile(excelBuffer, 'Resource_Allocations');
    });
  }
  sortColumn(eventPayload: any) {
    // console.log('Sorting Trigger ');
    // console.log(eventPayload);
    if (eventPayload.field === 'monthyear') {
      let varsortorder = this.resultdate;
      this.sortFn(eventPayload, varsortorder);
    } else if (eventPayload.field === 'WorkingHrs') {
      this.sortNormalColFnNumber(eventPayload);
    }
    else {
      this.sortNormalColFn(eventPayload);
    }
  }
  sortNormalColFnNumber(event: any): Array<any> {
    return this.reports.sort((item1, item2) => {
      const value1: number = Number(item1[event.field]);
      const value2: number = Number(item2[event.field]);
      if (value1 === null) {
        return 1;
      }
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
        // } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        //   result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  sortNormalColFn(event: any): Array<any> {
    return this.reports.sort((item1, item2) => {
      const value1: string = item1[event.field];
      const value2: string = item2[event.field];

      if (value1 === null) {
        return 1;
      }

      // //  if (this.isDateColumn(event.field)) {
      //     const date1 = moment(value1, this.dateFieldFormat);
      //     const date2 = moment(value2, this.dateFieldFormat);

      //     let result: number = -1;
      //     if (moment(date2).isBefore(date1, 'day')) { result = 1; }

      //     return result * event.order;
      // //  }

      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (event.order * result);
    });
  }
  sortFn(event: any, varsortorder: number): Array<any> {
    return this.reports.sort((item1, item2) => {
      const value1: string = item1[event.field];
      const value2: string = item2[event.field];

      if (value1 === null) {
        return 1;
      }

      //  if (this.isDateColumn(event.field)) {
      const date1 = moment(value1, this.dateFieldFormat);
      const date2 = moment(value2, this.dateFieldFormat);

      let result: number = -1;
      if (moment(date2).isBefore(date1, 'day')) { result = 1; }
      // else {this.resultdate = -1;}
      //  this.resultdate=this.resultdate*-1;
      let chkorder: number = 1;
      if (result == -1 && event.order == -1) {
        chkorder = 1
      }
      else if (result == 1 && event.order == -1) {
        chkorder = 1
      }
      else if (result == 1 && event.order == 1) {
        chkorder = 1
      }
      else {
        chkorder = -1
      }
      return chkorder * event.order;
      //  }

      // result = null;

      // if (value1 == null && value2 != null) {
      //   result = -1;
      // } else if (value1 != null && value2 == null) {
      //   result = 1;
      // } else if (value1 == null && value2 == null) {
      //   result = 0;
      // } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      //   result = value1.localeCompare(value2);
      // } else {
      //   result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      // }

      // return (event.order * result);
    });
  }
  isDateColumn(columnTitle: string) {
    for (const row of this.reports) {
      const value = row[columnTitle];
      if (!moment(value, this.dateFieldFormat).isValid() && value !== null) {
        return false;
      }
    }
    return true;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const filteredStartDate = moment(this.filterStartDate).format('MMM-YYYY');
    const filteredEndDate = moment(this.filterEndDate).format('MMM-YYYY');
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      if (filteredStartDate === filteredEndDate) {
        FileSaver.saveAs(data, fileName + '_Report_' + filteredStartDate + EXCEL_EXTENSION);
      } else {
        FileSaver.saveAs(data, fileName + '_Report_' + filteredStartDate + ' - ' + filteredEndDate + EXCEL_EXTENSION);
      }
    });
  }
  getMasterData() {
    this.adminService.getAllMasterData().then(masterData => {
      this.bu_name = masterData.BU;
      this.bu_name = this.bu_name.filter(
        (thing, i, arr) => arr.findIndex(t => t.bu === thing.bu) === i
      ).sort((a, b) => a.bu.localeCompare(b.bu));
    }).catch((err) => {
      this.loading = false;
    });
  }
  buChange(event) {
    if (event.target.value === '') {
      this.businessUnitFilter = '';
    } else {
      this.businessUnitFilter = event.target.value; // this.departmentObj[event.target.value].name;
    }
  }
  onAdvanced(){
    if ( this.showAdvancedFilter === true) {
      this.showAdvancedFilter = false;
    } else {
      this.showAdvancedFilter = true;
    }
  }
}
