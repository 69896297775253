<ng-template #customLoadingTemplate>
  <div class="ctmloader">
    <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
  <div class="container-fluid pt-3">
    <div class="row">
      <div class="col-6">
        <h4>Manage Resource Request</h4>
      </div>
      <div class="col-6 text-right">
        <!-- <ng-container>
          <button (click)="visibleSidebar2 = true" class="btn crvdbtn cstbtn btn2">
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Resource
          </button>
        </ng-container> -->
      </div>
    </div>

    <div class="full-height" *ngIf="resourceRequestForm">
      <p-sidebar class="sidescroll" (onHide)="close()" [(visible)]="visibleSidebar2" position="right"
        [style]="{width:'90em'}" [baseZIndex]="10000">
        <form [formGroup]="resourceRequestForm" (submit)="submit()">
          <h5 style="font-weight:normal"></h5>
          <p><span class="red">*</span> Please Fill Required Fields</p>
          <p-toast></p-toast>
          <div class="row">
            
            <div class="col-3">
              <div class="form-group">
                <label>Job Title</label>
                <textarea class="form-control" id="" formControlName="requirement_details"></textarea>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Job Description</label>
                <textarea class="form-control" id="" formControlName="requirement_description"></textarea>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Requirement Type</label>
                <select class="form-control" formControlName="requirement_type">
                  <!-- <option value=''>--Select Type--</option> -->
                  <option value='Back Fill'>Back Fill</option>
                  <option value="New Hire">New Hire</option>
                </select>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Primary skill set</label>
                <ng-multiselect-dropdown [placeholder]="'Select Skills'" [settings]="dropdownSettings"
                  formControlName="skillset" [data]="skillList" (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                  (onDeSelectAll)="onDeSelectAll()" [disabled]="true">
                </ng-multiselect-dropdown>
              </div>

            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Additional Skill</label>
                <input class="form-control" id="" disabled formControlName="additional_skill">
                <!-- </textarea> -->
              </div>
            </div>
            
            <div class="col-3">
              <div class="form-group">
                <label><span class="red">*</span>Department</label>
                <select class="form-control" formControlName="practice">
                  <!-- <option value=''>--Select Department--</option> -->
                  <option *ngFor="let practice of practices" value="{{practice.department_id}}">
                    {{ practice.department_name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="col-3">
              <div class="form-group">
                <label>Department</label>
                <select class="form-control" formControlName="practice">
                  <option value=''>--Select Department--</option>
                  <option value='16'>Digital Engineering</option>
                  <option value="1">Data Analytics</option>
                  <option value='2'>Decision Science</option>
                  <option value="17">Quality Analyst</option>
                </select>

              </div>
            </div> -->

            <div class="col-3">
              <div class="form-group">
                <label>Reporting Manager</label>
                <select class="form-control" formControlName="delivery_manager_id">
                  <option *ngFor="let dm of deliveryManagerDropDown" value="{{dm.value}}">
                    {{ dm.label }}
                  </option>
                </select>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Engagement Partner</label>
                <select class="form-control" formControlName="engagement_partner_id">
                  <option *ngFor="let ep of engagementPartnerDropDown" value="{{ep.value}}">
                    {{ ep.label }}
                  </option>
                </select>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>RWT</label>

                <select class="form-control" formControlName="rwt">
                  <option value=''>--Select RWT--</option>
                  <option *ngFor="let rwt of rwts" value="{{rwt.rwtid}}">
                    {{ rwt.rwt_name }}
                  </option>
                </select>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Level</label>
                <select class="form-control" formControlName="level">
                  <option value=''>--Select Level--</option>
                  <option *ngFor="let level of levels" value="{{level.level_id}}">
                    {{ level.level_name }}
                  </option>
                </select>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Allocation Required (%)</label>
                <input type="number" class="form-control" class="form-control" formControlName="allocation_required">
              </div>

            </div>
            <!-- <div class="col-3">
              <div class="form-group">
                <label>Number of Resources</label>
                <input type="number" class="form-control" formControlName="no_of_resource" id="">
              </div>

            </div> -->
            <div class="col-3">
              <div class="form-group">
                <label>Resource Availability</label>
                <select class="form-control" formControlName="resource_availability">
                  <option value=''>--Select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No"> No</option>
                </select>
              </div>
            </div>
            <!-- <div class="col-3">
              <div class="form-group">
                <label>CoE</label>
                <select class="form-control" formControlName="coe">
                  <option value=''>--Select--</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                </select>

              </div>
            </div> -->
            <div class="col-3">
              <div class="form-group">
                <label>Status</label>
                <select class="form-control" formControlName="status" (change)="onStatus()">
                  <option [disabled]="roleName === 'Practice Delivery Manager' || roleName === 'Delivery Ops Lead' || roleName === 'Talent Acquisition Manager'" value="Requested">Requested</option>
                  <option [disabled]="roleName !== 'Practice Delivery Manager'" value="Allocation Analysis">Allocation Analysis</option>
                  <option [disabled]="roleName !== 'Delivery Ops Lead'" value="Approval for Hiring">Approval for Hiring</option>
                  <option [disabled]="roleName !== 'Practice Delivery Manager'" value="Approved for Hiring">Approved for Hiring</option>
                  <option [disabled]="roleName !== 'Delivery Ops Lead'" value="Assigned Internal Resource">Assigned Internal Resource</option>
                  <option [disabled]="roleName === 'Practice Delivery Manager' || roleName === 'Delivery Ops Lead' || roleName === 'Talent Acquisition Manager'" value="On Hold">On Hold</option>
                  <option [disabled]="roleName !== 'Practice Delivery Manager'" value="Rejected by PDM">Rejected by PDM</option>
                  <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Hiring">Hiring</option>
                  <!-- <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Hired">Hired</option> -->
                  <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Offered">Offered</option>
                  <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Request Completed">Request Completed</option>
                  <!-- <option value=''>--Select Status--</option>
                  <option [disabled]="roleName==='Talent Acquisition Manager'" value="Requested">Requested</option>
                  <option [disabled]="roleName==='Talent Acquisition Manager'" value="Approved by VH">Approved by VH</option>
                  <option [disabled]="roleName==='Talent Acquisition Manager'" value="Assigned Internal Resource">Assigned Internal
                    Resource</option>
                  <option value="Hiring">Hiring</option>
                  <option value="Hired">Hired</option>
                  <option value="Offered">Offered</option>
                  <option [disabled]="roleName==='Talent Acquisition Manager'" value="On Hold">On Hold</option>
                  <option [disabled]="roleName==='Talent Acquisition Manager'" value="Rejected by VH">Rejected by VH</option> -->
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Remarks</label>
                <textarea class="form-control" id="" formControlName="remarks"></textarea>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Priority</label>
                <select class="form-control" formControlName="priority">
                  <option value=''>--Select Priority--</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">low</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label><span class="red">*</span>TA SPOC</label>
                <select class="form-control" formControlName="hr_spoc">
                  <option value=''>--Select HR--</option>
                  <option *ngFor="let resource of resourceArray" value="{{resource.resource_id}}">
                    {{ resource.resource_name }}
                  </option>
                </select>
                <div class="red d-flex" *ngIf="submitted && resourceForm.hr_spoc.errors?.required">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg ">
                    TA SPOC is required field
                  </div>
                </div>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label><span class="red">*</span>Recruiter</label>
                <select class="form-control" formControlName="recruiter">
                  <option value=''>--Select Recruiter--</option>
                  <option *ngFor="let resource of resourceArray" value="{{resource.resource_id}}">
                    {{ resource.resource_name }}
                  </option>
                </select>
                <div class="red d-flex" *ngIf="submitted && resourceForm.recruiter.errors?.required">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg ">
                    Recruiter is required field
                  </div>
                </div>

              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label><span class="red">*</span>Candidate Name</label>
                <input type="text" class="form-control" formControlName="canditateName">
                <div class="red d-flex" *ngIf="submitted && resourceForm.canditateName.errors?.required">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg ">
                    Candidate Name is required field
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Resume</label>
                <input #fileInput type="file" style="height: auto;" class="form-control"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="fileSource" (change)="onFileSelected($event)">
                <ng-container *ngIf="resume && resume!=='null'">
                  <span style="cursor: pointer;color: #5e94ff;margin-top: 5px;display: inline-block;"><a
                      (click)="onDownloadClick(resume)">
                      Download Resume
                    </a></span>
                </ng-container>
              </div>
            </div>
            <div class="col-4" *ngIf="!isAdd">
              <div class="form-group">
                <label>Candidate Status</label>
                <select class="form-control" id="exampleFormControlSelect1" formControlName="candidate_status"
                  name="candidate_status" [disabled]="roleName==='Delivery Manager'|| roleName==='Engagement Partner'">
                  <option value=''>--Select--</option>
                  <option value='To be Interviewed'>To be Interviewed</option>
                  <option value='Offered'>Offered</option>
                  <option value='Accepted'>Accepted</option>
                  <option value='Rejected'>Rejected</option>
                  <option value='On Hold'>On hold</option>
                  <option value='Declined'>Declined</option>
                  <option value='No Show'>No Show</option>
                </select>
              </div>
            </div>
            <div class="col-4" *ngIf="!isAdd">
              <div class="form-group">
                <label>Date of Joining</label>
                <input type="date" class="form-control" id="" formControlName="doj">
              </div>
            </div>
            <div class="col-4"  *ngIf="roleName==='Delivery Partner'|| roleName==='Talent Acquisition Manager';">
              <div class="form-group">
                <label>Excepted Salary: {{resourceRequestForm.value.salary ? resourceRequestForm.value.salary[0]: 2}} - {{resourceRequestForm.value.salary?resourceRequestForm.value.salary[1] : 75}}  (in Lakhs) </label>
                <p-slider formControlName="salary" min="2" max="75" [range]="true"  ></p-slider>
              </div>
            </div>


            <div class="col-12 mt-3 mb-3 ">
              <p-button type="button" label="Add Interview Panel" icon="fa fa-plus-circle"
                styleClass="p-button-success btn4" (click)="addPanelList()">
              </p-button>
            </div>
            <div class="col-12">
              <div *ngFor="let item of panelListGroups; let idx = index;">
                <div [formGroup]="item" class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label><span class="red">*</span>Interview Panel Type</label>
                      <select class="form-control" formControlName="dynamicPnlType">
                        <!--(change)="dynamicPanelChange($event,idx)" -->
                        <option value=''>--Select--</option>
                        <option value='Technical Round 1'>Technical Round 1</option>
                        <option value='Technical Round 2'>Technical Round 2</option>
                        <option value='Managerial'>Managerial</option>
                        <option value='HR'>HR</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label><span class="red">*</span>Interview Panel Resource</label>
                      <ng-container *ngIf="item.value.dynamicPnlType === 'Technical Round 1'">
                        <p-dropdown [options]="technicalResource_1_DropDown" selectedItem="item.value.dynamicPnlResource"
                          [style]="{'width':'100%'}" scrollHeight="400px" [filter]="true" filterBy="label"
                          optionLabel="label" optionValue="value" resetFilterOnHide="true"
                          formControlName="dynamicPnlResource">
                          <ng-template let-resource pTemplate="item">
                            <div class="item-drop1">
                              <mat-list-item>
                                <span class="item-value1">{{resource.label}}</span>
                              </mat-list-item>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </ng-container>
                      <ng-container *ngIf="item.value.dynamicPnlType === 'Technical Round 2'">
                        <p-dropdown [options]="technicalResource_2_DropDown" selectedItem="item.value.dynamicPnlResource"
                          [style]="{'width':'100%'}" scrollHeight="400px" [filter]="true" filterBy="label"
                          optionLabel="label" optionValue="value" resetFilterOnHide="true"
                          formControlName="dynamicPnlResource">
                          <ng-template let-resource pTemplate="item">
                            <div class="item-drop1">
                              <mat-list-item>
                                <span class="item-value1">{{resource.label}}</span>
                              </mat-list-item>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </ng-container>
                      <ng-container *ngIf="item.value.dynamicPnlType === 'HR'">
                        <p-dropdown [options]="hrResourceArray" selectedItem="item.value.dynamicPnlResource"
                          [style]="{'width':'100%'}" scrollHeight="400px" [filter]="true" filterBy="resource_name"
                          optionLabel="resource_name" optionValue="resource_id" resetFilterOnHide="true"
                          formControlName="dynamicPnlResource">
                          <ng-template let-resource pTemplate="item">
                            <div class="item-drop1">
                              <mat-list-item>
                                <span class="item-value1">{{resource.resource_name}}</span>
                              </mat-list-item>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </ng-container>
                      <ng-container *ngIf="item.value.dynamicPnlType !== 'Technical Round 2' && item.value.dynamicPnlType !== 'HR' && item.value.dynamicPnlType !== 'Technical Round 1'">
                        <p-dropdown [options]="resourceDropDown" selectedItem="item.value.dynamicPnlResource"
                          [style]="{'width':'100%'}" scrollHeight="400px" [filter]="true" filterBy="label"
                          optionLabel="label" optionValue="value" resetFilterOnHide="true"
                          formControlName="dynamicPnlResource">
                          <ng-template let-resource pTemplate="item">
                            <div class="item-drop1">
                              <mat-list-item>
                                <span class="item-value1">{{resource.label}}</span>
                              </mat-list-item>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </ng-container>

                    </div>
                  </div>
                  <div *ngIf="!isAdd" class="col-3">
                    <div class="form-group">
                      <label>Interview Panel Feedback</label>
                      <textarea type="text" class="form-control" class="form-control"
                        formControlName="dynamicPnlFeedback"> </textarea>
                    </div>
                  </div>
                  <div  [ngClass]="isAdd ? 'col-4':'col-1'" style="margin: auto;">
                    <div class="form-group" style="margin: auto;">
                      <div style="float: none;" aria-hidden="true" data-toggle="tooltip" title="Delete this row"
                        tooltipPosition="left" pTooltip="Delete this row" class="btn btn-danger btn-primary"
                        (click)="deleteRow(idx)">X</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div class="row sidebtngroup text-center">
            <div class="col">
              <p-button *ngIf= "!isCompleted" type="button" type="submit" label="Save" icon="fa fa-floppy-o"
                styleClass="p-button-success btn4"> </p-button>
              <p-button type="button" (click)="close()" label="Cancel" icon="fa fa-times"
                styleClass="p-button-secondary ml-3"></p-button>
            </div>
          </div>
        </form>
      </p-sidebar>
    </div>

    <div class="ctm-card">
      <p-tabView>
        <p-tabPanel  header="Resource Request">
          <p-table class="bordertop" [value]="resourceRequestTable" #dt dataKey="request_id" [autoLayout]="true"
          [paginator]="true" (onRowExpand)="onRowExpand()" [rows]="10" (onRowCollapse)="onRowCollapse()" [columns]="cols"
          [expandedRowKeys]="expandedRows" [rowsPerPageOptions]="[5,10,15,20]"
          [totalRecords]="resourceRequestTable?.length" pageLinkSize="3" (onPage)="onPage($event)"
          [globalFilterFields]="['request_id','client_name','project_name','start_date','end_date','rwts','requirement_details','no_of_resource','primary_skill_name','skill_name','requestor_name','status','requirement_type','practice_name']">
  
  
  
  
          <ng-template pTemplate="caption">
            <div class="d-flex filterwidth  align-items-end">
              <div class=" ml-4">
                <label class="d-block text-left" for="exampleFormControlSelect1">Status</label>
  
                <select [ngModel]="requestStatus" [formControl]="requestStatusControl" style="width: 200px;"
                  class="form-control">
                  <option value='All' selected>All</option>
                  <option *ngFor="let requestStatus of requestStatusList" value="{{requestStatus.status}}">
                    {{ requestStatus.status }}
                  </option>
                </select>
              </div>
  
              <div class=" ml-4">
                <label class="d-block text-left" for="exampleFormControlSelect1">Department</label>
                <select [ngModel]="department" [formControl]="departmentControl" style="width: 200px;"
                  class="form-control">
                  <option value='All' selected>All</option>
                  <option *ngFor="let department of deliveryfunctions" value="{{department.department_name}}">
                    {{ department.department_name }}
                  </option>
                </select>
  
              </div>
  
              <div class=" ml-4">
                <label class="d-block text-left" for="exampleFormControlSelect1">Engagement</label>
                <select [ngModel]="engagementFilter" [formControl]="engagementControl" style="width: 200px;"
                  class="form-control">
                  <option value='All' selected>All</option>
                  <option *ngFor="let engagementFilter of engagementList" value="{{engagementFilter.engagement}}">
                    {{ engagementFilter.engagement }}
                  </option>
                </select>
  
              </div>
  
              <button class="btn cstbtn btn1  ml-3 mb-1"
                (click)="onSearch(requestStatusControl.value,departmentControl.value,engagementControl.value)">
                <i class="fa fa-filter" aria-hidden="true"></i> Apply filters
              </button>
              <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                  aria-hidden="true"></i> Reset
                Filters</div>
  
              <div class="ml-auto bl-1">
                <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" size="40" placeholder="What are you looking for ?"
                  (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 5em">
                <a style="cursor: pointer;font-size: 16px; color:teal;" (click)="expandAll()">
                  <i *ngIf="!isExpanded" style="font-size: 20px;" class="fa fg fa-plus-circle" aria-hidden="true"></i>
                  <i *ngIf="isExpanded" style="font-size: 20px;" class="fa fg fa-minus-circle" aria-hidden="true"></i>
                </a>
              </th>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
            <tr [class]="expanded ? 'row-active-header' : ''">
              <td class="chevdownup">
                <a href="#" [pRowToggler]="rowData">
                  <i [class]="expanded ? 'pi fc pi-chevron-up' : 'pi fc pi-chevron-down'"></i>
                </a>
              </td>
              <td *ngFor="let col of columns">
                <!-- *ngIf="resourceRequestForm.value.status === 'Approved by VH'"  -->
                <ng-container *ngIf="col.field === 'action' && (rowData['status'] === 'Approved for Hiring' || rowData['status'] === 'Hiring')">
                  <a href="#" [pRowToggler]="rowData">
                    <button (click)="updateResourceRequest(rowData)" style="white-space: nowrap;"
                      class="btn crvdbtn cstbtn btn2">
                      <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Candidate
                    </button>
                  </a>
                </ng-container>
  
                <ng-container *ngIf="col.field !== 'action'">
                  {{rowData[col.field]}}
                </ng-container>
                <ng-container *ngIf="col.field === 'rwts'">
                  {{rowData[field]}}
                </ng-container>
  
              </td>
            </tr>
          </ng-template>
  
          <ng-template pTemplate="rowexpansion" let-rowData let-expanded="expanded" let-columns="columns">
            <tr class="row-active-body">
              <td [attr.colspan]="columns.length + 1" class="no-pad p-0">
                <div class="p-p-3">
                  <table class="innerTable"
                    *ngIf="rowData['requestedresource'] && rowData['requestedresource'].length > 0 && rowData['requestedresource']['name']!==null">
                    <thead class="innerHeader">
                      <tr>
                        <th>Name</th>
                        <th>Resume</th>
                        <th>TA SPOC</th>
                        <th>Recruiter</th>
                        <th>Technical Round 1 Panel</th>
                        <th>Technical Round 2 Panel</th>
                        <th>Manager Panel</th>
                        <th>HR Panel</th>
                        <th>Status</th>
                        <th>DOJ</th>
                        <th>Action</th>
                      <tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of rowData['requestedresource']" class="innerBody">
                        <td>{{row['name']}}</td>
                        <td>
                          <ng-container *ngIf="row['resume'] && row['resume']!=='null'">
                            <span style="cursor: pointer;color: #5e94ff;margin-top: 5px;display: inline-block;">
                              <a (click)="onDownloadClick(row['resume'])">
                                Download
                              </a></span>
                          </ng-container>
                        </td>
                        <td>{{row['hr']}}</td>
                        <td>{{row['recruiter_name']}}</td>
                        <td>{{row['Technical_Round_1']}}</td>
                        <td>{{row['Technical_Round_2']}}</td>
                        <td>{{row['Manager']}}</td>
                        <td>{{row['HR_panel']}}</td>
                        <td>{{row['candidate_status']}}</td>
                        <td>{{row['doj'] === '0000-00-00'? '' :row['doj']}}</td>
                        <td>
  
                          <span (click)="EditResourceRequestPopup(rowData,row['hr_mapping_id']);">
                            <i tooltipPosition="left" pTooltip="Click here to edit" class="fa faedit fa-pencil-square-o"
                              aria-hidden="true"></i>
                          </span>&nbsp;
                          <span (click)="DeleteResourceRequest(rowData,row['hr_mapping_id']);">
                            <i tooltipPosition="left" pTooltip="Click here to delete" class="fa fa-trash"
                              aria-hidden="true"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="color: #ed4d4d;padding: 1rem;" *ngIf="rowData['requestedresource'].length===0 ">
                  No Resources Allocated
                </div>
              </td>
  
            </tr>
          </ng-template>
        </p-table>
        </p-tabPanel>
        <!-- <p-tabPanel header="Dashboard">
          <table class="table ctmtable  table-bordered mt-3" #table>
            <thead>
              <tr>
                <th class="text-center" style="font-size:16px" colspan="4">Demand View</th>
                <th class="text-center" style="font-size:16px" colspan="5">Supply View</th>
              </tr>
              <tr>
                <th scope="col">Skill</th>
                <th class="text-center" scope="col">RWT Role</th>
                <th class="text-center" scope="col">Project Type(Development/Support)</th>
                <th class="text-center" scope="col"># Of position</th>
                <th class="text-center" scope="col">Customer Select (#)</th>
                <th class="text-center" scope="col">Engagement Select (#)</th>
                <th class="text-center" scope="col">Joined (#)</th>
                <th class="text-center" scope="col">Offered (#)</th>
                <th class="text-center" scope="col">Pending (#)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              
            </tbody>
          </table>
        </p-tabPanel> -->
      </p-tabView>
     
    </div>
  </div>
</div>