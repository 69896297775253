import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as ConfigJson from '../../assets/config.json';

export interface Department{
  department_id?:number;
  department_name?:string;
}
export interface timesheetConfigValues{
  config_key?:string;
  config_value?:number;
}
// export interface SubDepartment{
//   sub_department_id ?:number;
//   sub_department_name?:string;
//   department_id?:number;
// }
@Injectable()
export class SharedService {

  private loggedIn = new BehaviorSubject<any>(0);
  currentMessage = this.loggedIn.asObservable();
  base_url = ConfigJson.API_URL

  constructor(private http: HttpClient) { }
  
  // sendEvent(message) {
  //   this.loggedIn.next(message)
  // }

  userLoggedIn(data){
    this.loggedIn.next(data);
  }

  getloggedInData(){
    return this.loggedIn.asObservable();
  }

  getDepartmentData(){
      return this.http.get<any>(this.base_url+'departments') 
           .toPromise()
           .then(res => <Department[]>res).then(data => { return data; });
  }
  getConfigValues(config_type){
    return this.http.get<any>(this.base_url+'getConfigValues?config_type=' + config_type) 
         .toPromise()
         .then(res => <timesheetConfigValues[]>res).then(data => { return data; });
  }

  getSubDepartmentData(){
    return this.http.get<any>(this.base_url+'subdepartment') 
           .toPromise()
           .then(res => res).then(data => { return data; });
    
  }
}