import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/_services/admin.service';
import { StorageService } from 'src/app/_services/storage.service';
import { MessageService } from 'primeng/api';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-skill-matrix',
  templateUrl: './manage-skill-matrix.component.html',
  styleUrls: ['./manage-skill-matrix.component.scss']
})
export class ManageSkillMatrixComponent implements OnInit {
  form: FormGroup;
  loading = false;
  visibleSidebar2: boolean = false;
  skillCategory:[
    { Id: 1, category: "Technology" },
    { Id: 2, category: "Functional" }
  ] 
  coes= [
    {skill_area_CoEs: "Mobile App" },
    { skill_area_CoEs: "UI/UX" },
    { skill_area_CoEs: "Web App" },
    { skill_area_CoEs: "DWH/Reporting" },
    { skill_area_CoEs: "SharePoint" },
    { skill_area_CoEs: "Cloud App" },
    { skill_area_CoEs: "DevOps" },
    { skill_area_CoEs: "Functional Analyst" },
    { skill_area_CoEs: "Data Management" },
    { skill_area_CoEs: "Managers" },
    { skill_area_CoEs: "Business Intelligence" },
    { skill_area_CoEs: "DataOps" },
    { skill_area_CoEs: "Machine Learning" },
    { skill_area_CoEs: "Cloud-PaaS-AWS" },
    { skill_area_CoEs: "Cloud-PaaS-Azure" },
    { skill_area_CoEs: "Cloud-PaaS-GCP" },
    { skill_area_CoEs: "Cloud-PaaS" },
    { skill_area_CoEs: "Business Analyst" },
    { skill_area_CoEs: "Technology Analyst" },
];

  deliveryfunctions=[
  { delivery_function:"Digital Engineering"},
  { delivery_function:"Decision Science"},
  { delivery_function:"Data Analytics"},
  { delivery_function:"QA Engineering"},
];
  masterSkill={
    skill_name:'',
    skill_category:'',
    skill_sub_category:'',
    skill_area_CoEs:'',
    delivery_function:''
  }
  coe: any = 'All';
  category: any = 'All';
  deliveryfunction: any = 'All';
  // coes: any [];
  categorys: any[];
  coeControl = new FormControl();
  categoryControl = new FormControl();
  deliveryfunctionControl = new FormControl();
  skillList= [];
  skillLists = [];
  isShowError: boolean = true;
  isAdd: boolean = true;
  constructor(private adminService: AdminService,private messageService: MessageService,private storage: StorageService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      skill_name:[null, Validators.required],
      // skill_master_id:[null, Validators.required],
      skill_category:[null,Validators.required],
      skill_sub_category:[null,Validators.required],
      skill_area_CoEs:[null,Validators.required],
      delivery_function:[null,Validators.required],
    });
    this.getSkillMasterList();
  }
  AddSkillPopup(){
    this.visibleSidebar2 = true;
    this.isAdd = true;
    this.isShowError = false;
    this.masterSkill={
      skill_name:'',
      skill_category:'',
      skill_sub_category:'',
      skill_area_CoEs:'',
      delivery_function:''
    
    }
  }
  checkDuplicate(skill_name,isAdd) {
    if(isAdd) {
    let duplicateData = this.skillList.filter((item: any) => {
      return item.skill_name === skill_name ;
      
      //  return item.skill_name.toLowerCase() === skill_name.toLowerCase()  && item.level === level && item.years === Number(years) && item.certification === certification;
    });
    if (duplicateData.length>0) {
      return true;
    }
  } else {
    let duplicateData = this.skillList.filter((item: any) => {
      return item.skill_name === skill_name ;
   });
   if (duplicateData.length>0 && duplicateData[0].skill_name !== skill_name) {
     return true;
   }
  }
    return false;
  
  }
  SaveSkillData() {
    this.isShowError = true;
    if (this.form.get('skill_name').valid) {
      this.addmasterSkill(this.masterSkill) 
    }  else {
      
      Object.keys(this.form.controls).forEach(field => { // {1}
        const control = this.form.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    }
  }
  addmasterSkill(masterSkill){
    if(!this.checkDuplicate(masterSkill.skill_name,this.isAdd)) {
      this.loading = true;
      this.visibleSidebar2 = false;
      let formData = {};
      // console.log('formd',this.form);
          let addMessage = "Added";
          formData = {
            'skill_name':masterSkill.skill_name,
            'skill_category':masterSkill.skill_category,
            'skill_sub_category':masterSkill.skill_sub_category,
            'skill_area_CoEs':masterSkill.skill_area_CoEs,
            'delivery_function':masterSkill.delivery_function
          };
          // console.log('form',formData);
          this.adminService.postMasterSkillData(formData).subscribe(
            response => {
              this.loading = false;
              this.visibleSidebar2 = false;
              Swal.fire('Skill '+addMessage+' Successfully ','','success');
              this.getSkillMasterList();
            },
            err => {
              this.loading = false;
              console.log(err)
            });
    }
    else {
      // duplicate message
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Skill name already exists..', life: 3000 });
      
    }    
  }
  isFieldValid(field: string) {
    if (this.isShowError) {
    if ( field==='delivery_function' || field==='skill_category'|| field==='skill_sub_category' || field==='skill_area_CoEs') {
     if( this.form.get(field).value === '--Select--') {
      return this.form.get(field).valid && this.form.get(field).touched;
     }
    }
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  onSearch(coe,category,deliveryfunction) {
    this.category = category;
    this.coe = coe;
    this.deliveryfunction = deliveryfunction;
    this.getSkillMasterList();
  }
  clearFilters() {
    this.category = 'All';
    this.coe = 'All';
    this.deliveryfunction = 'All'
    this.getSkillMasterList();
  }
  getSkillMasterList(){
    let formData = {};
    formData['type'] = 'get'
    formData['postBody'] = { 
      'opType': 5
    };

    this.adminService.postResourceSkillData(formData).subscribe(
      response => {
        this.skillList = response['body'];
        // console.log('resourceSkillData',this.skillList)

      },
      err => {
        this.loading = false;
        console.log(err)
        this.skillList = [];
        this.skillLists = [];
      });
  }


  exportExcel() {
    const data = [];
    // products.map(product => product.id);

    this.skillList.map(element => {
      const obj = {};
      obj['Skill ID'] = element.skill_master_id;    
      obj['Skill Name'] = element.skill_name;   
    
      data.push(obj);
    });
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'PRM_MasterSkill');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_Report'+ EXCEL_EXTENSION);
    });
  }
  // getSkillMasterList(){

    
  //   this.adminService.getSkillList().then(skillData=>{
  //     // this.skillList = skillData;
  //     this.loading = true;
  //     // this.categorys = skillData.filter(
  //     //   (thing, i, arr) => arr.findIndex(t => t.skill_sub_category === thing.skill_sub_category) === i
  //     // ).sort((a, b) => a.skill_sub_category.localeCompare(b.skill_sub_category));
  //     // this.coes =this.coes.sort((a, b) => a.skill_area_CoEs.localeCompare(b.skill_area_CoEs));
  //     // this.deliveryfunctions = this.deliveryfunctions.sort((a,b)=> a.delivery_function.localeCompare(b.delivery_function));
     
  //     // this.coes = skillData.filter(
  //     //   (thing, i, arr) => arr.findIndex(t => t.skill_area_CoEs === thing.skill_area_CoEs) === i
  //     // ).sort((a, b) => a.skill_area_CoEs.localeCompare(b.skill_area_CoEs));

  //     // this.skillLists = skillData.filter((item: any) => {
  //     //   if (this.coe !== 'All' && this.category !== 'All' && this.deliveryfunction !== 'All') {
  //     //     return (item.skill_area_CoEs === this.coe) && (item.skill_sub_category === this.category) && (item.delivery_function === this.deliveryfunction);
  //     //   }
  //     //   // one field
  //     //   else if (this.coe !== 'All' && this.category === 'All' && this.deliveryfunction === 'All' ) {
  //     //     return (item.skill_area_CoEs === this.coe);
  //     //   }
  //     //   else if (this.coe === 'All' && this.category !== 'All' && this.deliveryfunction === 'All' ) {
  //     //     return (item.skill_sub_category === this.category);
  //     //   }
  //     //   else if (this.coe === 'All' && this.category === 'All' && this.deliveryfunction !== 'All' ) {
  //     //     return (item.delivery_function === this.deliveryfunction);
  //     //   }
       
  //     //   else if (this.coe !== 'All' && this.category !== 'All' && this.deliveryfunction === 'All' ) {
  //     //     return (item.skill_area_CoEs === this.coe) && (item.skill_sub_category === this.category);
  //     //   }
  //     //   else if (this.coe !== 'All' && this.category === 'All' && this.deliveryfunction !== 'All' ) {
  //     //     return (item.skill_area_CoEs === this.coe) && (item.delivery_function === this.deliveryfunction);
  //     //   }
  //     //   else if (this.coe === 'All' && this.category !== 'All' && this.deliveryfunction !== 'All' ) {
  //     //     return (item.skill_sub_category === this.category) && (item.delivery_function === this.deliveryfunction);
  //     //   }
        
  //     //   // right more combination filter 
  //     //   else if (this.coe === 'All' && this.category === 'All' && this.deliveryfunction === 'All' ) {
  //     //     return item;
  //     //   }
  //     // });
  //     this.skillList = skillData.sort((a, b) => a.skill_name.localeCompare(b.skill_name));
  //     //  console.log('ss',this.skillList)
  //         // return item;
      
  //     this.loading = false;
  //   }).catch(()=>{
  //     this.loading = false;
  //     this.skillList = [];
  //     this.skillLists = [];
    
  //   })
   
  // }
}
