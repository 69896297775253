import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as ConfigJson from '../../assets/config.json';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
export interface ResourceProjectData {
  resource_id;
  resource_name;
  RM;
  department_name;
  project_name;
  sub_project_name;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  base_url = ConfigJson.API_URL
  constructor(private http: HttpClient, private router: Router, private storage: StorageService) { }
  getResourceProjects() {
    const resource = JSON.parse(this.storage.getItem('user'))

    return this.http.get(this.base_url + 'get_projects_by_resource')
      .toPromise()
      .then(res => <ResourceProjectData[]>res)
      .then(data => { return data; });
  }

  getProjectDetails(projectId) {
    return this.http.get<any>(this.base_url + 'projectstatusdetail?projectID=' + projectId)
      .toPromise().then(data => { return data; });
  }

  geProjectStatusDetails(projectId) {
    return this.http.get<any>(this.base_url + 'projectstatusdetailbyID?projectID=' + projectId)
      .toPromise().then(data => { return data; });
  }


  insertProjectStatusDetails(submitObj) {
    return this.http.post(this.base_url + 'add_projectstatus', submitObj)
      .toPromise().then(data => { return data; });
  }

  insertActivityLog(logObj) {
    return this.http.post(this.base_url + 'add_activitylog', logObj)
      .toPromise().then(data => { return data; });
  }

  getProjectStatusReport(year) {
    const resource = JSON.parse(this.storage.getItem('user'));
    const roleName = this.storage.getItem('selectedRole');
    return this.http.get(this.base_url + 'getprojectstatusreport?year=\'' + year + '\'&roleName=\'' + roleName + '\'')
      .toPromise().then(data => { return data; });
  }

  getProjectEvaluationReport(year, month_number, month_name, userRole) {
    const resource = JSON.parse(this.storage.getItem('user'));
    // let apiEndPoint = userRole === 'Delivery Manager'?'getprojectevaluation':'getprojectevaluationForDM';
    let apiEndPoint =  (userRole === 'Sub Project Delivery Manager'?'getprojectevaluation' : userRole === 'Delivery Partner'? 'getprojectevaluationByDP' :userRole === 'Engagement Partner'? 'getProjectEvaluationByEP' : 'getprojectevaluationForEM');
    return this.http.get(this.base_url + apiEndPoint +'?evaluation_year=\'' + year + '\'&month_number=\'' + month_number + ''
      + '\'&month_name=\'' + month_name + '' + '\'')
      .toPromise().then(data => { return data; });
  }


  insertProjectEvaluationDetails(submitObj) {
    return this.http.post(this.base_url + 'add_projectevaluation', submitObj)
      .toPromise().then(data => { return data; });
  }

  // getPresignedUrl(fileName,file,submitObj) {
  //   return this.http.get(this.base_url + 'presignedURL?FileName='+fileName).toPromise()
  // }
  getPresignedUrl(fileName,file,submitObj) {
    return this.http.get(this.base_url + 'presignedURL?FileName='+fileName+'&Type=Status').toPromise()
  }


  getDownloadURl(fileName){
    return this.http.get(this.base_url + 'presignedDownloadURL?FileName='+fileName).toPromise()
  }
  getEvaluatorForDM() {  
    return this.http.get<any>(this.base_url+ "getEvaluatorForDM") 
        .toPromise().then(res => {  return res; });    
  }
 
  
  postEvaluatorForDMData(evaluatorForDMData): Observable<any> {  
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Cache-Control': 'no-cache'
    });    
    return this.http.post<any>(this.base_url+'addUpdateEvaluatorForDM',evaluatorForDMData)  
  }
  fileUpload(file, submitObj) { // not used
    let that = this;
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: ConfigJson.accessKeyId,
        secretAccessKey: ConfigJson.secretAccessKey,
        region: ConfigJson.region,

      });
    const params = {
      Bucket: 'ngpbucket01',
      Key: submitObj[0].file_name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        //console.log('EROOR: ', JSON.stringify(err));
        return false;
      }
      if (data) {
        that.insertProjectStatusDetails(submitObj).then(submitData => {
          if (submitData[0]) {
            Swal.fire('Successfully Updated', '', 'success')
            that.router.navigate(['/projects']);
            return true;

          }
        }).catch(err => {
         // console.log('EROOR: ', JSON.stringify(err));
          return false;
        })

      }

    });
  }

  getResourceProjectDetails(projectId,projectYear) {
    return this.http.get<any>(this.base_url + 'getresourceallocationbyprojectid?projectID=\'' + projectId + "\'&project_year=\'" + projectYear + "\'")
      .toPromise().then(data => { return data; });
  }

  getProjectResourceDetails(resourceID,projectYear) {
    return this.http.get<any>(this.base_url + 'getprojectallocationbyresourceid?resourceID=\'' + resourceID + "\'&project_year=\'" + projectYear + "\'")
      .toPromise().then(data => { return data; });
  }

  getUtilizationReport(inputYear,inputMonth) {
    return this.http.get<any>(this.base_url + 'utilizationreportdata?inputYear=\'' + inputYear + "\'&inputMonth=\'" + inputMonth + "\'")
      .toPromise().then(data => { return data; });
  }

  projectResourceRequest(submitObj) {
    return this.http.post(this.base_url + 'resourcerequest', submitObj)
      .toPromise().then(data => { return data; });
  }
  getResourceProjectReport() {  
    return this.http.get<any>(this.base_url+ "resourceprojectview") 
        .toPromise().then(res => {  return res; });    
  }

  
}
