import { Component, OnInit } from '@angular/core';
import { AdminService, Resource, RwtModel } from '../../_services/admin.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StorageService } from 'src/app/_services/storage.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manage-rwt',
  templateUrl: './manage-rwt.component.html',
  styleUrls: ['./manage-rwt.component.scss'],
  providers: [MessageService]
})
export class ManageRwtComponent implements OnInit {
  form: FormGroup;
  isShowError: boolean = true;
  loading = false;
  isAdd: boolean = true;
  popupHeader: string = 'Add RWT';
  visibleSidebar2: boolean = false;
  manageRWTData: RwtModel[];
  manageRWTDataTable = [];
  rwt: any = 'All';
  rwtItem: any[];
  level: any = 'All';
  location: any = 'All';
  status: any = 'All';
  rwts: any[];
  levels: any[];
  locations: any[];
  locationControl = new FormControl();
  levelControl = new FormControl();
  rwtControl = new FormControl();
  statusControl = new FormControl();
  filteredRWTs: any[];
  isErrorMsgBoolCheckDateRange: boolean = false;
  isErrorMsgBoolCheckDateValidity: boolean = false;
  rwtData = {
    rwtid: '',
    rwt_level_id: '',
    rwt_name: '',
    level_id: '--Select--',
    level_name: '--Select--',
    location_id: '--Select--',
    location_name: '',
    cost_per_hour: '',
    rwt_cost_id: '',
    old_end_date: '',
    start_date: '',
    end_date: ''
  };

  constructor(private adminService: AdminService, private storage: StorageService, private messageService: MessageService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      rwt_name: [null, Validators.required],
      level_name: [null, Validators.required],
      location_id: [null, Validators.required],
      cost_per_hour: [null, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null],
    });
    this.getManageRWTData();
  }
  isFieldValid(field: string) {
    if (this.isShowError) {
      if (field === 'level_name' || field === 'location_id') {
        if (this.form.get(field).value === '--Select--') {
          return this.form.get(field).valid && this.form.get(field).touched;
        }
      }
      return !this.form.get(field).valid && this.form.get(field).touched;
    }
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  onDateSelectedStart(selectedDate: string) {

    const rwtName = this.form.get('rwt_name')?.value?.rwt_name;
    const level_id = this.form.get('level_name')?.value;

    if (rwtName != null && level_id != null) {

      if (this.manageRWTDataTable) {

        var filteredData = this.manageRWTDataTable.filter((item) => {
          return item.rwt_name == rwtName && item.level_id == level_id;
        });
        if (filteredData[0].details.length > 0) {
          let res1 = this.checkDateRange(selectedDate, filteredData[0].details[0].start_date, filteredData[0].details[0].end_date)
          if (res1 == 'RWT cost per hour already exists for the selected date range') {
            this.isErrorMsgBoolCheckDateRange = true;

          } else {
            this.rwtData.rwt_cost_id = filteredData[0].RWT_Cost_id
            this.isErrorMsgBoolCheckDateRange = false;
          }

        } else {

        }

      } else {
        // console.log('Error: manageRWTData is not defined or is empty.');
      }
    }
  }

  onDateSelectedEnd(selectedDate: string) {
    const rwtName = this.form.get('rwt_name')?.value?.rwt_name;
    const level_id = this.form.get('level_name')?.value;
    if (rwtName != null && level_id != null) {
      let newstartdate = this.form.get('start_date')?.value;
      if (this.manageRWTDataTable) {

        var filteredData = this.manageRWTDataTable.filter((item) => {
          return item.rwt_name == rwtName && item.level_id == level_id;
        });
        if (filteredData[0].details.length > 0) {

          let res2 = this.checkDateValidity(newstartdate, selectedDate)
          if (res2 == 'End date must be greater than or equal to start date.') {
            this.isErrorMsgBoolCheckDateValidity = true
          } else { this.isErrorMsgBoolCheckDateValidity = false }
        } else {

        }

      } else {
        // console.log('Error: manageRWTData is not defined or is empty.');
      }
    }
  }

  isErrorMsgForDateValidity: string;

  checkDateValidity(startDate, endDate): string | null {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end <= start) {
      this.isErrorMsgForDateValidity = 'End date must be greater than or equal to start date'
      return "End date must be greater than or equal to start date.";
    }
    return null;
  }

  checkDateRange(selectedDate: string, startDate: any, endDate: any): string | null {
    const startDateValue = new Date(startDate);
    const endDateValue = new Date(endDate);

    // Convert string selected date to Date object
    const selectedDateObj = new Date(selectedDate);

    // Check if the selected date is within the date range
    if (endDate === '2050-12-31') {
      return null; // No error message for '2050-12-31'
    }
    else if (selectedDateObj >= startDateValue && selectedDateObj < endDateValue) {
      return 'RWT cost per hour already exists for the selected date range';
    } else {
      return 'Invalid date range';
    }
  }
  checkDateValidations(endDate: string): string {
    if (endDate == '2050-12-31') {
      return '';
    }
    return endDate;
  }


  getManageRWTData() {

    this.loading = true;
    this.adminService.getManageRWT().then(resourceData => {
      this.rwts = resourceData.filter(
        (thing, i, arr) => arr.findIndex(t => t.rwt_name === thing.rwt_name) === i
      ).sort((a, b) => a.rwt_name.localeCompare(b.rwt_name));
      this.locations = resourceData.filter(
        (thing, i, arr) => arr.findIndex(t => t.location_name === thing.location_name) === i
      );
      this.levels = resourceData.filter(
        (thing, i, arr) => arr.findIndex(t => t.level_name === thing.level_name) === i
      ).sort((a, b) => a.level_name.localeCompare(b.level_name));

      this.manageRWTData = resourceData.filter((item: any) => {
        if (this.rwt !== 'All' && this.level !== 'All' && this.location !== 'All' && this.status !== 'All') {
          return (item.rwt_name === this.rwt) && (item.level_name === this.level) && (item.location_name === this.location) && (item.r_status === this.status);
        }
        // one field
        else if (this.rwt !== 'All' && this.level === 'All' && this.location === 'All' && this.status === 'All') {
          return (item.rwt_name === this.rwt);
        }
        else if (this.rwt === 'All' && this.level !== 'All' && this.location === 'All' && this.status === 'All') {
          return (item.level_name === this.level);
        }
        else if (this.rwt === 'All' && this.level === 'All' && this.location !== 'All' && this.status === 'All') {
          return (item.location_name === this.location);
        }
        else if (this.rwt === 'All' && this.level === 'All' && this.location === 'All' && this.status !== 'All') {
          return (item.r_status === this.status);
        }
        // two field
        else if (this.rwt !== 'All' && this.level !== 'All' && this.location === 'All' && this.status === 'All') {
          return (item.rwt_name === this.rwt) && (item.level_name === this.level);;
        }
        else if (this.rwt !== 'All' && this.level === 'All' && this.location !== 'All' && this.status === 'All') {
          return (item.rwt_name === this.rwt) && (item.location_name === this.location);
        }
        else if (this.rwt !== 'All' && this.level === 'All' && this.location === 'All' && this.status !== 'All') {
          return (item.rwt_name === this.rwt) && (item.r_status === this.status);
        }
        else if (this.rwt === 'All' && this.level !== 'All' && this.location !== 'All' && this.status === 'All') {
          return (item.level_name === this.level) && (item.location_name === this.location);
        }
        else if (this.rwt === 'All' && this.level !== 'All' && this.location === 'All' && this.status !== 'All') {
          return (item.level_name === this.level) && (item.r_status === this.status);
        }
        else if (this.rwt === 'All' && this.level === 'All' && this.location !== 'All' && this.status !== 'All') {
          return (item.location_name === this.location) && (item.r_status === this.status);
        }
        // three field
        else if (this.rwt !== 'All' && this.level !== 'All' && this.location !== 'All' && this.status === 'All') {
          return (item.rwt_name === this.rwt) && (item.level_name === this.level) && (item.location_name === this.location);
        }
        else if (this.rwt !== 'All' && this.level !== 'All' && this.location === 'All' && this.status !== 'All') {
          return (item.rwt_name === this.rwt) && (item.level_name === this.level) && (item.r_status === this.status);
        }
        else if (this.rwt === 'All' && this.level !== 'All' && this.location !== 'All' && this.status !== 'All') {
          return (item.level_name === this.level) && (item.location_name === this.location) && (item.r_status === this.status);
        }
        else if (this.rwt !== 'All' && this.level === 'All' && this.location !== 'All' && this.status !== 'All') {
          return (item.rwt_name === this.rwt) && (item.location_name === this.location) && (item.r_status === this.status);
        }
        // right more combination filter 
        else if (this.rwt === 'All' && this.level === 'All' && this.location === 'All' && this.status === 'All') {
          return item;
        }
      });
      //console.log("Datttaaaa",this.manageRWTData.sort((a, b) => a.rwt_name.localeCompare(b.rwt_name)));
      // Sample data for transformation
      const data = this.manageRWTData.sort((a, b) => a.rwt_name.localeCompare(b.rwt_name));

      // Create a Map to store unique keys and their properties
      const uniqueKeysMap = new Map<string, any>();

      // Iterate through data to generate transformed data
      data.forEach((item) => {
        const { level_id, rwt_name, ...rest } = item;

        // Create a unique key based on level_id and rwt_name
        const uniqueKey = `${level_id}-${rwt_name}`;

        // Check if the key exists in the Map
        if (!uniqueKeysMap.has(uniqueKey)) {
          // If the key doesn't exist, create a new entry with initial properties and an empty 'details' array
          uniqueKeysMap.set(uniqueKey, {
            level_id,
            RWT_Cost_id: rest.RWT_Cost_id,
            level_name: rest.level_name,
            location_id: rest.location_id,
            location_name: rest.location_name,
            r_status: rest.r_status,
            rwt_level_id: rest.rwt_level_id,
            rwt_name,
            //rwtid: rest.rwtid,
            details: []
          });
        }

        // Add details to the corresponding key in the Map
        uniqueKeysMap.get(uniqueKey)?.details.push({
          level_name: rest.level_name,
          location_id: rest.location_id,
          location_name: rest.location_name,
          end_date: item.end_date,
          start_date: item.start_date,
          cost_per_hr: item.cost_per_hr,
          rwt_name,
          rwtid: item.rwtid
        });
      });

      // Convert Map values to the desired structure
      const transformedData = Array.from(uniqueKeysMap.values());
      transformedData.forEach((data) => {
        data.details.sort((a, b) => {
          const dateA = new Date(a.start_date).getTime();
          const dateB = new Date(b.start_date).getTime();
          return dateB - dateA; // Sort in descending order
        });
        // Find the latest cost_per_hr based on the end_date
        const latestCostPerHr = data.details.find(
          (detail) => new Date(detail.end_date).getTime() > new Date().getTime()
        )?.cost_per_hr;

        // Add latest_cost_per_hr to each object
        data.latest_cost_per_hr = latestCostPerHr || null;
      });

      console.log(transformedData);

      this.manageRWTDataTable = transformedData;
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.manageRWTData = [];
      this.manageRWTDataTable = [];
    });

  }

  expandedRow: any = null; // Variable to store expanded row data
  columnCount: number = 10; // Change this to your table's column count
  expandedRowIndex: number | null = null;

  toggleRow(data, index: number) {
    // this.getChevronIconClass(index)
    this.expandedRowIndex = this.expandedRowIndex === data ? null : data;

  }

  isExpanded(index: number): boolean {
    return this.expandedRowIndex === index;
  }

  getChevronIconClass(index: number): string {
    return this.isExpanded(index) ? 'pi fc pi-chevron-up' : 'pi fc pi-chevron-down';
  }

  // exportExcel() {
  //   const data = [];
  //   const data2 = [];
  //   // products.map(product => product.id);
  //   console.log("Data :", this.manageResourceDataTable
  //   );
  //   this.manageResourceDataTable.map(element => {
  //     const obj = {};
  //     obj['Resource Name'] = element.resource_name;
  //     obj['Resource Email'] = element.resource_email;
  //     obj['RWT'] = element.rwt_name;
  //     obj['Level'] = element.level_name;
  //     obj['Line Group'] = element.line_group_name;
  //     obj['Resource Manager'] = element.rm_name;
  //     obj['BU'] = element.bu;
  //     obj['Group Assigned'] = element.department_name;
  //     obj['Start Date'] = element.resource_start_date;
  //     obj['Sub Location'] = element.sub_location_name;
  //     data.push(obj);
  //     element.rwt_details.forEach(rwtDetail => {
  //       const combinedObj = {
  //         'Resource Email': element.resource_email,
  //         'Level': rwtDetail.level,
  //         'Location Name': rwtDetail.location_name,
  //         'RWT End Date': rwtDetail.rwt_end_date,
  //         'RWT Start Date': rwtDetail.rwt_start_date,
  //         // Add other fields from rwt_details as needed
  //       };
  //       data2.push(combinedObj);
  //     });

  //   });

  //   import('xlsx').then(xlsx => {
  //     const worksheet1 = xlsx.utils.json_to_sheet(data);
  //     const worksheet2 = xlsx.utils.json_to_sheet(data2);

  //     const workbook = {
  //       Sheets: {
  //         'ResourceEmailData': worksheet1,
  //         'CombinedRWTDetails': worksheet2
  //       },
  //       SheetNames: ['Resource', 'RWT']
  //     };

  //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //     this.saveAsExcelFile(excelBuffer, 'PRM_Resource');
  //   });
  // }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   import('file-saver').then(FileSaver => {
  //     const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //     const EXCEL_EXTENSION = '.xlsx';
  //     const data: Blob = new Blob([buffer], {
  //       type: EXCEL_TYPE
  //     });
  //     FileSaver.saveAs(data, fileName + '_Report' + EXCEL_EXTENSION);
  //   });
  // }

  isEndDateValid(endDate: string): string {
    if (!endDate) {
      return ''; // Return empty string if end date is not defined or falsy
    }

    const currentDate = new Date();
    const endDateValue = new Date(endDate);

    // Check if endDateValue is a valid date
    if (isNaN(endDateValue.getTime())) {
      return ''; // Return empty string if the parsed date is invalid
    }

    // Compare the end date with the current date
    return endDateValue.getTime() > currentDate.getTime() ? '' : endDate;
  }
  // exportExcel() {
  //   const data = [];
  //   // products.map(product => product.id);

  //   this.manageRWTDataTable.map(element => {
  //     const obj = {};
  //     obj['RWT'] = element.rwt_name;
  //     obj['Level'] = element.level_id;
  //     obj['Location'] = element.location_name;
  //     obj['Baseline Rate'] = element.cost_per_hour;
  //     data.push(obj);
  //   });
  //   import('xlsx').then(xlsx => {
  //     const worksheet = xlsx.utils.json_to_sheet(data);
  //     const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //     this.saveAsExcelFile(excelBuffer, 'PRM_RWT');
  //   });
  // }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   import('file-saver').then(FileSaver => {
  //     const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //     const EXCEL_EXTENSION = '.xlsx';
  //     const data: Blob = new Blob([buffer], {
  //       type: EXCEL_TYPE
  //     });
  //     FileSaver.saveAs(data, fileName + '_Report' + EXCEL_EXTENSION);
  //   });
  // }

  exportExcel() {
    const data = [];
    const data2 = [];
    //console.log(this.manageRWTDataTable);

    this.manageRWTDataTable.map(element => {
      const obj = {};
      obj['RWT Name'] = element.rwt_name;
      obj['Level'] = element.level_name;
      obj['Location'] = element.location_name;
      obj['Cost Per Hr'] = element.latest_cost_per_hr;
      data.push(obj);
      element.details.forEach(rwtDetail => {
        const combinedObj = {
          'Location': rwtDetail.location_name,
          'Rwt Name': rwtDetail.rwt_name,
          'Level': rwtDetail.level_name,
          'Cost Per Hour($)': rwtDetail.cost_per_hr,
          'Start Date': rwtDetail.start_date,
          'End Date': this.isEndDateValid(rwtDetail.end_date),
          // Add other fields from rwt_details as needed
        };
        data2.push(combinedObj);
      });

    });

    import('xlsx').then(xlsx => {
      const worksheet1 = xlsx.utils.json_to_sheet(data);
      const worksheet2 = xlsx.utils.json_to_sheet(data2);

      const workbook = {
        Sheets: {
          'RWT': worksheet1,  // Use 'RWT' instead of 'RWT Details'
          'RWT-Details': worksheet2  // Use 'RWT-Details' instead of 'CombinedRWTDetails'
        },
        SheetNames: ['RWT', 'RWT-Details']  // Adjust the sheet names accordingly
      };

      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'PRM_Resource');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_Report' + EXCEL_EXTENSION);
    });
  }


  onSearch(rwt, level, location) {
    this.rwt = rwt;
    this.location = location;
    this.level = level;
    this.getManageRWTData();
  }
  clearFilters() {
    this.rwt = 'All';
    this.location = 'All';
    this.level = 'All';
    this.getManageRWTData();
  }
  AddRWTPopup() {
    this.visibleSidebar2 = true;
    this.isAdd = true;
    this.popupHeader = 'Add RWT';
    this.isShowError = false;
    this.rwtItem = [];
    this.rwtData = {
      rwtid: '',
      rwt_level_id: '',
      rwt_name: '',
      level_id: '--Select--',
      level_name: '--Select--',
      location_id: '--Select--',
      location_name: '',
      cost_per_hour: '',
      rwt_cost_id: '',
      old_end_date: '',
      start_date: '',
      end_date: ''
    };
  }
  EditRWTPopup(rwtDataItem) {
    this.visibleSidebar2 = true;
    this.isAdd = false;
    this.popupHeader = 'Edit RWT';
    this.rwtData.rwtid = rwtDataItem.rwtid;
    this.rwtData.rwt_level_id = rwtDataItem.rwt_level_id;
    this.rwtData.level_id = rwtDataItem.level_id;
    this.rwtData.rwt_name = rwtDataItem.rwt_name;
    this.rwtData.location_id = rwtDataItem.location_id;
    this.rwtData.location_name = rwtDataItem.location_name;
    this.rwtData.level_name = rwtDataItem.level_name;
    this.rwtData.cost_per_hour = rwtDataItem.cost_per_hour;
    //this.rwtItem = this.rwts[3];
    for (let i = 0; i < this.rwts.length; i++) {
      if (this.rwts[i].rwt_name === rwtDataItem.rwt_name) {
        this.rwtItem = this.rwts[i];
      }
    }
  }
  SaveRWT() {
    this.isShowError = true;
    if (this.form.valid) {

      this.addUpdateRWT(this.rwtData, this.rwtItem, 2) // 2 as Draft Mode
    } else {
      // validate all form fields
      Object.keys(this.form.controls).forEach(field => { // {1}
        const control = this.form.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    }
  }
  SubmitRWT() {
    this.isShowError = true;
    if (this.form.valid && this.isErrorMsgBoolCheckDateRange == false && this.isErrorMsgBoolCheckDateValidity == false) {
      this.addUpdateRWT(this.rwtData, this.rwtItem, 1) // 1 as Submit Mode
    } else {
      // validate all form fields
      Object.keys(this.form.controls).forEach(field => { // {1}
        const control = this.form.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    }
  }
  // checkDuplicate(rwt_id, rwt_name, level_name, location_id, isAdd) {
  //   if (isAdd) {
  //     let duplicateData = this.manageRWTDataTable.filter((item: any) => {
  //       return item.rwt_name.toLowerCase() === rwt_name.toLowerCase() && item.level_name === level_name && item.location_id === Number(location_id);
  //     });
  //     if (duplicateData.length > 0) {
  //       return true;
  //     }
  //   } else {
  //     let duplicateData = this.manageRWTDataTable.filter((item: any) => {
  //       return item.rwt_name.toLowerCase() === rwt_name.toLowerCase() && item.level_name === level_name && item.location_id === Number(location_id);
  //     });
  //     if (duplicateData.length > 0 && duplicateData[0].rwtid !== rwt_id) {
  //       return true;
  //     }
  //   }
  //   return false;

  // }

  checkDuplicate(rwt_id, rwt_name, level_name, location_id, start_date, end_date, isAdd) {
    //rwtData.rwtid, rwt_name, rwtData.level_name, rwtData.location_id,rwtData.start_date,rwtData.end_date, this.isAdd
    if (isAdd) {
      let duplicateData = this.manageRWTDataTable.filter((item) => {
        return (
          item.rwt_name.toLowerCase() === rwt_name.toLowerCase() &&
          item.level_name === level_name &&
          item.location_id === Number(location_id) &&
          item.start_date === start_date &&
          item.end_date === end_date
        );
      });
      if (duplicateData.length > 0) {
        return true;
      }
    } else {
      let duplicateData = this.manageRWTDataTable.filter((item) => {
        return (
          item.rwt_name.toLowerCase() === rwt_name.toLowerCase() &&
          item.level_name === level_name &&
          item.location_id === Number(location_id) &&
          item.start_date === start_date &&
          item.end_date === end_date &&
          item.rwtid !== rwt_id
        );
      });
      if (duplicateData.length > 0) {
        return true;
      }
    }
    return false;
  }

  addUpdateRWT(rwtData, rwtItem, mode) {
    let rwt_name = '';
    if (rwtItem.rwt_name) {
      rwt_name = rwtItem.rwt_name;
    } else {
      rwt_name = rwtItem;
    }
    this.loading = true;
    if (!this.checkDuplicate(rwtData.rwtid, rwt_name, rwtData.level_name, rwtData.location_id, rwtData.start_date, rwtData.end_date, this.isAdd)) {
      this.visibleSidebar2 = false;
      let formData = {};
      let rwtid = 0;  // for add pas zero
      let addMessage = "Added";
      if (this.isAdd === false) {
        rwtid = rwtData.rwtid;
        addMessage = "Updated";
      }
      var filteredData = this.manageRWTDataTable.filter((item) => {
        return item.rwt_name == rwt_name && item.level_id == rwtData.level_name;
      });
      if (filteredData[0].details.length > 0) {
        if (filteredData[0].details[0].end_date == null || filteredData[0].details[0].end_date === '' || filteredData[0].details[0].end_date === '2050-12-31') {
          rwtData.old_end_date = this.getPreviousDate(rwtData.start_date);
        } else {
          rwtData.old_end_date = filteredData[0].details[0].end_date;
        }
      }


      formData = {
        'rwtid': filteredData[0].details[0].rwtid, //rwtid //'2002', //this.SelectBehalfOf ? this.submitResourceID : this.resourceId,
        // 'rwt_level_id': rwtData.rwt_level_id, //'NGP Test 2002',//moment(this.currentDate.value).format('MMM'),
        'rwt_name': rwt_name,// 'NGPtestnew new.2002@mresult.com',//moment(this.currentDate.value).format('MMM'),
        'level_id': rwtData.level_name, // '2020-05-11',//moment(this.currentDate.value).format('MMM'),
        'level_name': rwtData.level_name, //moment(this.currentDate.value).format('MMM'),
        'location_id': rwtData.location_id, // 'India', //moment(this.currentDate.value).format('MMM'),
        'cost_per_hour': rwtData.cost_per_hour, // 'Mangalore',// moment(this.currentDate.value).format('MMM'),
        'r_status': mode,
        'start_date': rwtData.start_date,
        'end_date': rwtData.end_date || null,
        'old_end_date': rwtData.old_end_date,
        'rwt_cost_id': rwtData.rwt_cost_id,
        'opType': 1,
        // 'Digital Engineering', //moment(this.currentDate.value).format('MMM'),
      };
      formData['type'] = "post"
      this.adminService.postManageRWTData(formData).subscribe(
        response => {
          this.loading = false;
          this.visibleSidebar2 = false;
          Swal.fire('RWT Data ' + addMessage + ' Successfully ', '', 'success');
          this.getManageRWTData();
        },
        err => {
          this.loading = false;
          console.log(err)
        });
    }
    else {
      // duplicate message
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'RWT detail already exists..', life: 3000 });
    }
  }
  getPreviousDate(dateString: string): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);

    // Convert the result back to a string in "yyyy-mm-dd" format
    const previousDateString = date.toISOString().split('T')[0];
    return previousDateString;
  }

  cancel() {
    this.visibleSidebar2 = false;
  }
  filterrwt(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.rwts.length; i++) {
      let rwtItem = this.rwts[i];
      if (rwtItem.rwt_name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(rwtItem);
      }
    }

    this.filteredRWTs = filtered;
  }
}
