import { Injectable } from '@angular/core';
import API from '@aws-amplify/api';
import * as ConfigJson from '../../assets/config.json';
import { Auth } from '@aws-amplify/auth';
// import { Auth, API } from 'aws-amplify';
Auth.configure({
  userPoolId: 'us-east-1_WrMJeNwoB', // Your user pool id here
  userPoolWebClientId: '6svgbadgar6kkj996ovdfc9fau' // Your client id here 
})

const config={
  region: ConfigJson.region,
  userPoolId: ConfigJson.userPoolId , // Your user pool id here
  userPoolWebClientId: ConfigJson.userPoolWebClientId, 
  oauth: {
    domain: ConfigJson.oauth.domain,//'mlt.auth.us-east-1.amazoncognito.com',// /oauth2/authorize?identity_provider=AzureADSSO&redirect_uri=http://localhost:4200&response_type=token&client_id=39phlndpjupg82nd67v9q7nav4&code_challenge=IhGwqW3cQvD1a-97dvlcQTiamr86apjDhiYf1yMSHhI&code_challenge_method=S256&scope=email openid',
    identity_provider: ConfigJson.oauth.identity_provider,// 'AzureADSSO',
    client_id: ConfigJson.userPoolWebClientId,
    scope: ConfigJson.oauth.scope ,
    redirectSignIn: ConfigJson.oauth.redirectSignIn ,
    redirectSignOut: ConfigJson.oauth.redirectSignOut,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code,
  }
}

Auth.configure(config)
API.configure(config)

@Injectable()
export class AuthorizationService {
  cognitoUser: any;

  constructor() { let nextToken;}



  async signIn(email, password) {
    
    try {
      window.location.href= 'https://'+ConfigJson.oauth.domain+'/oauth2/authorize?identity_provider='+ConfigJson.oauth.identity_provider+'&redirect_uri='+ConfigJson.oauth.redirectSignIn+'&response_type=code&client_id='+ConfigJson.userPoolWebClientId+'&scope=email openid aws.cognito.signin.user.admin';
    } catch (error) {
      //console.log('error signing in', error);
      return error;
    }
  }

  


  async isLoggedIn() {
    return await Auth.currentAuthenticatedUser();
  }

  getAuthenticatedUser() {
    // gets the current user from the local storage
    return Auth.currentAuthenticatedUser()
  }

  async getToken() {
    Auth.currentAuthenticatedUser()
      .then(session => {
        const token = session['accessToken']['jwtToken']
        return token;
      }).catch(err => {
        if (err) {
         // console.log(err);
          return;
        }
      })
  }

  userRoles() {
    return Auth.currentSession()
  }

  logOut() {
    Auth.signOut();
    sessionStorage.clear();
    this.cognitoUser = null;
  }

  async addToGroup() { 
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let myInit = {
        body: {
          "username" : "Testuser9@sharepointninjas.com",
          "groupname": "PowerUsers"
        }, 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    }
    return await API.post(apiName, path, myInit);
  }
  
  // async function listEditors(limit){
  //   let apiName = 'AdminQueries';
  //   let path = '/listUsersInGroup';
  //   let myInit = { 
  //       queryStringParameters: {
  //         "groupname": "editors",
  //         "limit": limit,
  //         "token": nextToken
  //       },
  //       headers: {
  //         'Content-Type' : 'application/json',
  //         Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  //       }
  //   }
  //   const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
  //   nextToken = NextToken;
  //   return rest;
  // }
}