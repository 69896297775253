
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as ConfigJson from '../../assets/config.json';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';

// export interface Product {
//     id?:string;
//     code?:string;
//     name?:string;
//     description?:string;
//     price?:number;
//     quantity?:number;
//     inventoryStatus?:string;
//     category?:string;
//     image?:string;
//     rating?:number;
// }

export interface Clients{
  id?:number;
  client_name:string;
}

export interface Activities{
  activity_id?:number;
  activity_name:string;
}

export interface Projects{
  project_id?:number;
  project_name:string;
}
export interface SubProject{
  project_id?:number;
  sub_project_id?:string;
  sub_project_name?:string;
  project_name?:string;
  project_code?:string;
  department_name?:string;
  client_name?:string;
}

export interface TimesheetSubmission 
{
    id?:string;
    client_name?:string;
    project_name?:string;
    sub_project_id?:string;
    sub_project_name?:string;
    month?:string;
    year?:Number;
    fte?:Number;
    remarks?:string;
    status?:string;
    EstimatedHours?:Number;

}

export interface Resource{
  is_active: boolean;
  resource_id?:number;
  resource_name?:string;
  resource_email?:string;
  billable_percent?:Number;
  rwtid?:Number;
  billing_rate?:Number;
  resource_rm_id?:Number;
  department_id?:Number;
  department_name?:string;
  resource_start_date?:string;
  resource_last_date?:string;
  cognito_user_name?:string;
  br_latest?:boolean;
  rwt_latest?:boolean;
}
@Injectable({
  providedIn: 'root'
})
export class TimesheetSubmissionService {
  base_url = ConfigJson.API_URL
  resource: any;
  constructor(private http: HttpClient,private storage: StorageService) {
    this.resource=JSON.parse(this.storage.getItem('user'))

  }

  
    getTimesheetData(resourceId,Month) {  
        return this.http.get<any>(this.base_url+'projectactualtimesheet?resourceID= \''+resourceId+'\'&Month=\''+Month+'\' ') 
             .toPromise()
             .then(res => <TimesheetSubmission[]>res).then(data => { return data; });
    }

    getSubprojects() {  
      return this.http.get<any>(this.base_url+'subprojects') 
           .toPromise()
           .then(res => <SubProject[]>res).then(data => { return data; });
    }

    getActivities(){
      return this.http.get<any>(this.base_url+'activities') 
           .toPromise()
           .then(res => <Activities[]>res).then(data => { return data; });
    }

    getResources(){
      return this.http.get<any>(this.base_url+'resources') 
           .toPromise()
           .then(res => <Resource[]>res).then(data => { return data; });
    }
    getResourceByRole(userRole,userName) {  
      return this.http.get<any>(this.base_url+ "getresourcelist?userRole=\'"+userRole+"\'") 
          .toPromise().then(res => <Resource[]>res).then(data => {  return data; });    }

    getRM_Resources(){
      return this.http.get<any>(this.base_url+'rm_resources?resourceID=\''+this.resource.userID+'\'') 
           .toPromise()
           .then(res => <Resource[]>res).then(data => { return data; });
    }
    
    postTimesheetData(timesheetData): Observable<any> {  
      let httpHeaders = new HttpHeaders({
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache'
      });    
      return this.http.post<any>(this.base_url+'insertTimesheet',timesheetData)
  }
  
}
