import { Component, OnInit } from '@angular/core';
import { AdminService, ResourceCognitoGroupModel } from '../../_services/admin.service';
import { FormControl } from '@angular/forms';
import { StorageService } from 'src/app/_services/storage.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';
import * as ConfigJson from 'src/assets/config.json';


@Component({
  selector: 'app-manage-group',
  templateUrl: './manage-group.component.html',
  styleUrls: ['./manage-group.component.scss'],
  providers: [MessageService]
})
export class ManageGroupComponent implements OnInit {
  loading = false;
  cognitoGroupHeader: string = 'Project Team Member';
  visibleSidebar2: boolean = false;
  responsiveOptions;
  toggle: boolean = false;
  cognitoID: number = 0;
  manageCognitoData: ResourceCognitoGroupModel[];
  manageCognitoDataTable = [];
  manageCognitoSourceDataTable = [];
  manageCognitoTargetDataTable = [];
  manageCognitoChangeDataTable = [];
  department: any = 'All';
  location: any = 'All';
  sub_location: any = 'All';
  departments: any[];
  locations: any[];
  sub_locations: any[];
  departmentControl = new FormControl();
  locationControl = new FormControl();
  subLocationControl = new FormControl();
  groupDetails = [];
  highlightGroup: string = 'Project Team Member';
  userRole: string;
  constructor(private adminService: AdminService, private storage: StorageService, private messageService: MessageService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }
  ngOnInit() {
    this.userRole=this.storage.getItem('selectedRole')
    this.getManageCognitoData();
  }

  getManageCognitoData() {
    this.loading = true;
    this.adminService.getManageCognitoGroup('Main', this.cognitoID).then(cognitoData => {
      this.departments = cognitoData.Main.filter(
        (thing, i, arr) => arr.findIndex(t => t.department_name === thing.department_name) === i
      ).sort((a, b) => a.department_name.localeCompare(b.department_name));
      this.locations = cognitoData.Main.filter(
        (thing, i, arr) => arr.findIndex(t => t.location_name === thing.location_name) === i
      );
      this.sub_locations = cognitoData.Main.filter(
        (thing, i, arr) => arr.findIndex(t => t.sub_location_name === thing.sub_location_name) === i
      ).sort((a, b) => a.sub_location_name.localeCompare(b.sub_location_name));

      let distinctCognitoGroup = cognitoData.Main.filter(
        (thing, i, arr) => arr.findIndex(t => t.cognito_group_id === thing.cognito_group_id) === i
      ).sort(function (a, b) { return a.cognito_group_id - b.cognito_group_id; });
      let details = [];
      // let grouppArrayToFill = {
      //   totalCount: cognitoData.Main.length,
      //   groupName: 'All Cognito Group',
      //   groupID: 0
      // };
      // details.push(grouppArrayToFill);
      for (let i = 0; i < distinctCognitoGroup.length; i++) {
        let grouppArrayToFill = {
          totalCount: cognitoData.Main.filter(function (item) {
            return item.role_name === distinctCognitoGroup[i].role_name;
          }).length,
          groupName: distinctCognitoGroup[i].role_name,
          groupID: distinctCognitoGroup[i].cognito_group_id
        };
        if ( grouppArrayToFill.groupName === "HR Admin") {
          if(this.userRole !== "PMO Admin") {
        details.push(grouppArrayToFill); 
          }
        } else {
          details.push(grouppArrayToFill);
        }
      }
      this.groupDetails = details;

      this.manageCognitoData = cognitoData.Main.filter((item: any) => {
        if (this.department !== 'All' && this.location !== 'All' && this.sub_location !== 'All') {
          return (item.department_name === this.department) && (item.location_name === this.location) && (item.sub_location_name === this.sub_location);
        }
        // one field
        else if (this.department !== 'All' && this.location === 'All' && this.sub_location === 'All') {
          return (item.department_name === this.department);
        }
        else if (this.department === 'All' && this.location !== 'All' && this.sub_location === 'All') {
          return (item.location_name === this.location);
        }
        else if (this.department === 'All' && this.location === 'All' && this.sub_location !== 'All') {

          return (item.sub_location_name === this.sub_location);
        }
        // two field
        else if (this.department !== 'All' && this.location !== 'All' && this.sub_location === 'All') {
          return (item.department_name === this.department) && (item.location_name === this.location);
        }
        else if (this.department !== 'All' && this.location === 'All' && this.sub_location !== 'All') {
          return (item.department_name === this.department) && (item.sub_location_name === this.sub_location);
        }
        else if (this.department === 'All' && this.location !== 'All' && this.sub_location !== 'All') {
          return (item.location_name === this.location) && (item.sub_location_name === this.sub_location);
        }

        // right more combination filter 
        else if (this.department === 'All' && this.location === 'All' && this.sub_location === 'All') {
          return item;
        }
      });
      this.manageCognitoDataTable = this.manageCognitoData.filter((item: any) => {
        if (item.role_name === this.highlightGroup) {
          return item;
        } 
        // else if (this.highlightGroup === 'All Cognito Group') {
        //   return item;
        // }
      }).sort((a, b) => a.resource_name.localeCompare(b.resource_name));
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.manageCognitoData = [];
      this.manageCognitoDataTable = [];
    });

  }

  onSearch(department, location, subLocation) {
    this.department = department;
    this.location = location;
    this.sub_location = subLocation
    this.getManageCognitoData();
  }
  clearFilters() {
    this.department = 'All';
    this.location = 'All';
    this.sub_location = 'All';
    this.highlightGroup = "Project Team Member"
    this.getManageCognitoData();
  }

  ManageResourcePopup(groupDetail) {
    this.cognitoGroupHeader = groupDetail.groupName;
    this.cognitoID = groupDetail.groupID;
    this.getCognitoSourceTargetData(groupDetail)
  }
  getCognitoSourceTargetData(groupDetail) {
    this.loading = true;
    this.adminService.getManageCognitoGroup('ST', groupDetail.groupID).then(cognitoData => {
      this.manageCognitoSourceDataTable = cognitoData.Source.sort((a, b) => a.resource_name.localeCompare(b.resource_name));
      this.manageCognitoTargetDataTable = cognitoData.Target.sort((a, b) => a.resource_name.localeCompare(b.resource_name));
      this.visibleSidebar2 = true;
      this.loading = false;

    }).catch((err) => {
      this.loading = false;
      this.manageCognitoSourceDataTable = [];
      this.manageCognitoTargetDataTable = [];
    });


  }
  addactive(groupName) {
    // this.toggle = !this.toggle; 
    this.highlightGroup = groupName;
    this.getManageCognitoData();
  }
  onMoveToTarget(event) {
    this.FunctionSourceTarget(event, 'Add');
    
  }
  onMoveToSource(event) {
    this.FunctionSourceTarget(event, 'Remove');
  }

  FunctionSourceTarget(event, type){
    if (event.items.length > 0) {
      for (let j = 0; j < event.items.length; j++) {
        let details = [];
        let resurcepArrayToFill = {
          cognito_user_id: event.items[j].resource_id,
          cognito_user_name:event.items[j].cognito_user_name,
          cognito_group_id: this.cognitoID,
          cognito_user_isadd: type
        };
        details.push(resurcepArrayToFill);
        if (this.manageCognitoChangeDataTable.length > 0) {
          for (let i = 0; i < this.manageCognitoChangeDataTable.length; i++) {
            if (event.items[j].resource_id === this.manageCognitoChangeDataTable[i].cognito_user_id) {
             
            this.manageCognitoChangeDataTable[i].cognito_user_isadd = type;
            } 
             else {
              resurcepArrayToFill = {
                cognito_user_id: this.manageCognitoChangeDataTable[i].cognito_user_id,
                cognito_user_name:this.manageCognitoChangeDataTable[i].cognito_user_name,
                cognito_group_id: this.cognitoID,
                cognito_user_isadd: this.manageCognitoChangeDataTable[i].cognito_user_isadd
              };
              details.push(resurcepArrayToFill);
             }
          }
          this.manageCognitoChangeDataTable = details;
        }  
        else {
          //details.push(resurcepArrayToFill);
          this.manageCognitoChangeDataTable = details;
        }

      }
    }
     this.manageCognitoSourceDataTable = this.manageCognitoSourceDataTable.sort((a, b) => a.resource_name.localeCompare(b.resource_name));
     this.manageCognitoTargetDataTable = this.manageCognitoTargetDataTable.sort((a, b) => a.resource_name.localeCompare(b.resource_name));
  }

  SaveResourceGroup(pl) {
    // console.log(this.manageCognitoChangeDataTable);
    
    if (this.manageCognitoChangeDataTable.length>0) {
      this.loading = true;
      let cognitoMain = [];
      let cognitoDetail = {};
      for (let i = 0; i < this.manageCognitoChangeDataTable.length; i++) {
        cognitoDetail = {
          cognito_user_name: this.manageCognitoChangeDataTable[i].cognito_user_name,
          userPoolID: ConfigJson.userPoolId
        };
        cognitoMain.push(cognitoDetail);
      }
      this.visibleSidebar2 = false;
      pl.resetFilter();
      this.adminService.postManageCognitoGroupData(this.manageCognitoChangeDataTable).subscribe(
        response => {
          this.loading = false;
          this.manageCognitoChangeDataTable = [];
          Swal.fire(this.highlightGroup+' Group updated Successfully ','','success');
          this.getManageCognitoData();
          this.adminService.postCognitoGroupData(cognitoMain).subscribe(
            cognitoResponse => {
              
            }
          );
        },
        err => {
          this.loading = false;
          pl.resetFilter();
          this.manageCognitoChangeDataTable = [];
          console.log(err)
        });
    } else {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'No Change', detail: 'No changes detected..', life: 3000 });
    }
  }
  CancelResourceGroup(pl) {
    this.manageCognitoChangeDataTable = [];
    this.visibleSidebar2 = false;
    pl.resetFilter();
  }
}
