import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Session } from 'protractor';

import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../_services/authorization.service';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class TokenInterceptor
 * @implements {HttpInterceptor}
 */

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public autho:AuthorizationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(Auth.currentAuthenticatedUser()).pipe(
        switchMap((auth: any) => {
            let request1 = request.clone({
            setHeaders: {
                Authorization: `Bearer ${auth.signInUserSession.idToken.jwtToken}`
            }
            });
            return next.handle(request1);
        }),
        catchError((err) => {
           // console.log("Error ", err);
            return next.handle(request);
        })
    );
  }
}