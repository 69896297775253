import { Pipe, PipeTransform } from '@angular/core';
 

@Pipe({
  name: 'string'
})
export class StringPipe implements PipeTransform {

  transform(input: any, sep: string, inx: number): string {
     
    try{
    return input.split(sep)[inx];
    }
    catch
    {
      return input;
    }
  }

}
