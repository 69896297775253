import { Component, OnInit } from '@angular/core';
import { AdminService, Resource, ResourceWithDetails } from '../../_services/admin.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StorageService } from 'src/app/_services/storage.service';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';
import * as ConfigJson from 'src/assets/config.json';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE,
  MatNativeDateModule, MatRippleModule
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'app-manage-resource',
  templateUrl: './manage-resource.component.html',
  styleUrls: ['./manage-resource.component.scss'],
  providers: [MessageService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class ManageResourceComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  startDate = new FormControl(moment());
  endDate = new FormControl(moment());
  form: FormGroup;
  loading = false;
  popupLoading = false;
  isShowError: boolean = true;
  isAdd: boolean = true;
  isActive: boolean = true;
  isReadonly: boolean = false;
  isEmailExists: boolean = false;
  isValidPercent: boolean = false;
  isBasicInfo: boolean = true;
  isDMChecked: boolean = false;
  activeIndex: number = 0;
  popupHeader: string = 'Add Resource';
  visibleSidebar2: boolean = false;
  manageResourceData: Resource[];
  manageResourceDataTable = [];
  manageResourceSabbaticalDataTable = [];
  manageResourceRWTDataTable
  department: any = 'All';
  rwt: any = 'All';
  subLocation: any = 'All';
  lineGroup: any = 'All';
  resource_RM: any = 'All';
  resource_RM_temp: any;
  departments: any[];
  businessUnits: any[];
  subBU: any[];
  subBUList: any[];
  rwts: any[];
  rwtsOrg: any[];
  roles: any[];
  levels: any[];
  locations: any[];
  subLocations: any[];
  lineGroups: any[];
  resource_RMs: any[];
  rm_Data: any[];
  rm_Cognito_id: any[];
  subLocationControl = new FormControl();
  departmentControl = new FormControl();
  lineGroupControl = new FormControl();
  rmControl = new FormControl();
  rwtControl = new FormControl();
  isDataExists: boolean = false;
  date = new FormControl(moment());
  selectedRoles: string[] = [];
  userRole: string;
  buList: any[];
  businessUnitFilter: any = 'All';
  businessUnitControl = new FormControl('');
  // resourceData: Resource;
  resourceData = {
    resource_id: '',
    employee_id: '',
    resource_name: '',
    resource_email: '',
    resource_start_date: '',
    resource_last_date: '',
    location_id: '',
    sub_location_id: '',
    billable_percent: '',
    rwtid: '',
    line_group_id: '',
    resource_rm_id: '',
    rm_name: '--Select--',
    rm_email: '',
    department_id: '',
    is_active: '',
    sebatical_id: '',
    start_date: '',
    end_date: '',
    rwt_start_date: new FormControl(moment()),
    rwt_end_date: new FormControl(moment('')),
    rwt_location_name: '--Select--',
    rwt_level_name: '--Select--',
    rwt_name: '--Select--',
    line_group_name: '--Select--',
    department_name: '--Select--',
    bu_name: '--Select--',
    subBU_name: '--Select--',
    sub_location_name: '--Select--',
    location_name: '--Select--',
    level_name: '--Select--',
    cognito_user_name: '',
    Cognito_Group: ''
  };
  // projectStatusObj={
  //   project_id:'',
  //   Remarks:'',
  //   file_name:'',
  //   week_no:0,
  //   year:'',
  //   entry_start_date:null,
  //   entry_end_date:null,
  //   status:'On Track',
  //   createdby:''
  // };
  submitted: boolean = false;
  constructor(private adminService: AdminService, private storage: StorageService, private messageService: MessageService, private formBuilder: FormBuilder) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 5, 0, 1);
    this.maxDate = new Date(currentYear + 5, 11, 31);
  }
  chosenstartYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.resourceData.rwt_start_date.value;
    ctrlValue.year(normalizedYear.year());
    this.resourceData.rwt_start_date.setValue(ctrlValue);
  }

  chosenstartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.resourceData.rwt_start_date.value;
    ctrlValue.month(normalizedMonth.month());
    this.resourceData.rwt_start_date.setValue(ctrlValue);
    datepicker.close();
  }

  expandedRow: any = null; // Variable to store expanded row data
  columnCount: number = 10; // Change this to your table's column count
  expandedRowIndex: number | null = null;

  toggleRow(data, index: number) {
    // this.getChevronIconClass(index)
    this.expandedRowIndex = this.expandedRowIndex === data ? null : data;

  }

  isExpanded(index: number): boolean {
    return this.expandedRowIndex === index;
  }

  getChevronIconClass(index: number): string {
    return this.isExpanded(index) ? 'pi fc pi-chevron-up' : 'pi fc pi-chevron-down';
  }


  chosenendYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.resourceData.rwt_end_date.value;
    ctrlValue.year(normalizedYear.year());
    this.resourceData.rwt_end_date.setValue(ctrlValue);
  }

  chosenendMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.resourceData.rwt_end_date.value;
    ctrlValue.month(normalizedMonth.month());
    this.resourceData.rwt_end_date.setValue(ctrlValue);

    datepicker.close();
  }
  ngOnInit(): void {

    this.userRole = this.storage.getItem('selectedRole')
    this.form = this.formBuilder.group({
      resource_name: [null, Validators.required],
      employee_id: [null],
      resource_email: [null, [Validators.required, Validators.email]],
      resource_start_date: [null, Validators.required],
      rwt_name: [null, Validators.required],
      level_name: [null, Validators.required],
      line_group_name: [null, Validators.required],
      department_name: [null, Validators.required],
      bu_name: [null, Validators.required],
      subBU_name: [null, Validators.required],
      location_name: [null, Validators.required],
      sub_location_name: [null, Validators.required],
      rm_name: [null, Validators.required],
      billable_percent: [null, Validators.required],
      selectedRoles: [null, Validators.required],
      resource_last_date: [null],
      start_date: [null],
      end_date: [null],
      rwt_start_date: [null],
      rwt_end_date: [null],
      rwt_location_name: [null],
      rwt_level_name: [null],
      isActive: new FormControl(true),
      isReadonly: new FormControl(false),
      isEmailExists: new FormControl(false),
      isDMChecked: new FormControl(false),
      isValidPercent: new FormControl(false),
      activeIndex: new FormControl(0),
      popupLoading: new FormControl(false)
    });
    this.loading = true;
    this.getRMMasterData();
    this.getMasterData();
    this.getManageResourceData();


  }
  isFieldValid(field: string) {
    if (this.isShowError && field !== 'start_date' && field !== 'end_date' && this.isBasicInfo) {
      if (field === 'rwt_name' || field === 'rm_name' || field === 'line_group_name' || field === 'department_name' || field === 'sub_location_name' || field === 'location_name' || field === 'level_name' || field === 'subBU_name' || field === 'bu_name') {
        if (this.form.get(field).value === '--Select--') {
          return this.form.get(field).valid && this.form.get(field).touched;
        }

      } else if (field === 'selectedRoles') {
        return !this.form.get(field).valid && this.form.get(field).touched;
      } else if (field === 'resource_email') {
        this.isEmailExists = false;
        if (this.isAdd) {
          this.manageResourceDataTable.filter((item: any) => {
            if (item.resource_email === this.form.get(field).value) {
              this.isEmailExists = true;
            }
          });
        }
        return !this.form.get(field).valid && this.form.get(field).touched;
      } else if (field === 'billable_percent') {
        this.isValidPercent = false;
        if (this.form.get(field).value > 1 || this.form.get(field).value < 0) {
          this.isValidPercent = true;
        }
        return !this.form.get(field).valid && this.form.get(field).touched;
      }
      return !this.form.get(field).valid && this.form.get(field).touched;
    } else if (this.isShowError && !this.isBasicInfo && (field === 'start_date' || field === 'end_date')) {
      return this.form.get(field).valid && this.form.get(field).touched;
    }
  }
  // displayFieldCss(field: string) {
  //   return {
  //     'has-error': this.isFieldValid(field),
  //     'has-feedback': this.isFieldValid(field)
  //   };
  // }
  // onSubmit() {
  //   if (this.form.valid) {
  //     console.log('form submitted');
  //   } else {
  //     // validate all form fields
  //     Object.keys(this.form.controls).forEach(field => { // {1}
  //       const control = this.form.get(field);            // {2}
  //       control.markAsTouched({ onlySelf: true });       // {3}
  //     });
  //   }
  // }
  getRMMasterData() {
    this.adminService.getResourceWithCognitoGroup().then(rmData => {
      this.rm_Data = rmData;
      this.rm_Cognito_id = this.rm_Data.filter(function (i) {
        return i.cognito_group_id == 9;
      });
    }).catch((err) => {
    });
  }

  getMasterData() {
    this.adminService.getAllMasterData().then(masterData => {
      this.departments = masterData.Department;
      this.businessUnits = masterData.BU;
      this.subBU = masterData.BU;
      this.rwts = masterData.RWT;
      this.rwtsOrg = masterData.RWT;
      this.locations = masterData.Location
      this.subLocations = masterData.SubLocation;
      this.lineGroups = masterData.LineGroup;
      this.roles = masterData.Role;
      this.levels = masterData.Level;
      this.departments = this.departments.sort((a, b) => a.department_name.localeCompare(b.department_name));
      this.businessUnits = this.businessUnits.filter(
        (thing, i, arr) => arr.findIndex(t => t.bu === thing.bu) === i
      ).sort((a, b) => a.bu.localeCompare(b.bu));
      this.buList = [...this.businessUnits];
      this.subBU = this.subBU.filter(
        (thing, i, arr) => arr.findIndex(t => t.sub_bu === thing.sub_bu) === i
      ).sort((a, b) => a.sub_bu.localeCompare(b.sub_bu));
      this.subBUList = this.subBU;
      this.rwts = this.rwts.filter(
        (thing, i, arr) => arr.findIndex(t => t.rwt_name === thing.rwt_name) === i
      ).sort((a, b) => a.rwt_name.localeCompare(b.rwt_name));
      this.locations = this.locations.sort((a, b) => a.location_name.localeCompare(b.location_name));
      this.subLocations = this.subLocations.sort((a, b) => a.sub_location_name.localeCompare(b.sub_location_name));
      this.lineGroups = this.lineGroups.sort((a, b) => a.line_group_name.localeCompare(b.line_group_name));
      this.roles = this.roles.sort((a, b) => a.role_name.localeCompare(b.role_name));
      this.levels = this.levels.sort((a, b) => a.level_name.localeCompare(b.level_name));

    }).catch((err) => {
      this.loading = false;
      this.departments = [];
      this.rwts = [];
      this.subLocations = [];
      this.lineGroups = [];
    });
  }
  getManageResourceData() {
    this.loading = true;
    this.adminService.getManageResource().then(resourceData => {
      this.manageResourceData = resourceData.filter((item: any) => {
        if (this.rwt !== 'All' && this.businessUnitFilter !== 'All' && this.subLocation !== 'All' && this.resource_RM !== 'All') {
          return (item.rwt_name === this.rwt) && (item.bu === this.businessUnitFilter) && (item.sub_location_name === this.subLocation) && (item.line_group_name === this.lineGroup);
        }
        // one field
        else if (this.rwt !== 'All' && this.businessUnitFilter === 'All' && this.subLocation === 'All' && this.resource_RM === 'All') {
          return (item.rwt_name === this.rwt);
        }
        else if (this.rwt === 'All' && this.businessUnitFilter !== 'All' && this.subLocation === 'All' && this.resource_RM === 'All') {
          return (item.bu === this.businessUnitFilter);
        }
        else if (this.rwt === 'All' && this.businessUnitFilter === 'All' && this.subLocation !== 'All' && this.resource_RM === 'All') {
          return (item.sub_location_name === this.subLocation);
        }
        else if (this.rwt === 'All' && this.businessUnitFilter === 'All' && this.subLocation === 'All' && this.resource_RM !== 'All') {
          return (item.rm_name === this.resource_RM);
        }
        // rwt combination field
        else if (this.rwt !== 'All' && this.businessUnitFilter !== 'All' && this.subLocation === 'All' && this.resource_RM === 'All') {
          return (item.rwt_name === this.rwt) && (item.bu === this.businessUnitFilter);
        }
        else if (this.rwt !== 'All' && this.businessUnitFilter === 'All' && this.subLocation !== 'All' && this.resource_RM === 'All') {
          return (item.rwt_name === this.rwt) && (item.sub_location_name === this.subLocation);
        }
        else if (this.rwt !== 'All' && this.businessUnitFilter === 'All' && this.subLocation === 'All' && this.resource_RM !== 'All') {
          return (item.rwt_name === this.rwt) && (item.rm_name === this.resource_RM);
        }
        // department combination field
        else if (this.rwt === 'All' && this.businessUnitFilter !== 'All' && this.subLocation !== 'All' && this.resource_RM === 'All') {
          return (item.bu === this.businessUnitFilter) && (item.sub_location_name === this.subLocation);
        }
        else if (this.rwt === 'All' && this.businessUnitFilter !== 'All' && this.subLocation === 'All' && this.resource_RM !== 'All') {
          return (item.bu === this.businessUnitFilter) && (item.rm_name === this.resource_RM);
        }

        // sublocation combination field
        else if (this.rwt === 'All' && this.businessUnitFilter === 'All' && this.subLocation !== 'All' && this.resource_RM !== 'All') {
          return (item.sub_location_name === this.subLocation) && (item.rm_name === this.resource_RM);
        }
        // 3 field comnbination
        else if (this.rwt !== 'All' && this.businessUnitFilter !== 'All' && this.subLocation !== 'All' && this.resource_RM === 'All') {
          return (item.rwt_name === this.rwt) && (item.bu === this.businessUnitFilter) && (item.sub_location_name === this.subLocation);
        }
        // 3 field comnbination
        else if (this.rwt === 'All' && this.businessUnitFilter !== 'All' && this.subLocation !== 'All' && this.resource_RM !== 'All') {
          return (item.bu === this.businessUnitFilter) && (item.sub_location_name === this.subLocation) && (item.rm_name === this.resource_RM);
        }
        // 3 field comnbination
        else if (this.rwt !== 'All' && this.businessUnitFilter === 'All' && this.subLocation !== 'All' && this.resource_RM !== 'All') {
          return (item.rwt_name === this.rwt) && (item.sub_location_name === this.subLocation) && (item.rm_name === this.resource_RM);
        }
        // right more combination filter 
        else if (this.rwt === 'All' && this.businessUnitFilter === 'All' && this.subLocation === 'All' && this.resource_RM === 'All') {
          return item;
        }
      });
      this.manageResourceDataTable = this.manageResourceData.sort((a, b) => a.resource_name.localeCompare(b.resource_name));
      // this.resource_RMs = this.manageResourceDataTable.sort((a, b) => a.rm_name.localeCompare(b.rm_name)).filter(
      //   (thing, i, arr) => arr.findIndex(t => t.rm_name === thing.rm_name) === i
      // );
      this.resource_RMs = this.manageResourceDataTable.sort((a, b) => a.resource_name.localeCompare(b.resource_name)).filter(
        (arr) => { return (arr.Cognito_Group.split(',').includes('9')) }
      );
      //console.log(this.resource_RMs);
      const transformedData: ResourceWithDetails[] = this.transformResourceData(this.manageResourceData);
      const sortedData: ResourceWithDetails[] = this.sortByEndDateDescending(transformedData);

      // Assuming this.manageResourceDataTable is assigned the sorted data
      this.manageResourceDataTable = this.combineRwtDetails(sortedData);
      console.log("Data after combining :", this.manageResourceDataTable);

      this.resource_RMs.sort();
      this.resource_RM_temp = this.resource_RMs;
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.manageResourceData = [];
      this.manageResourceDataTable = [];
    });

  }
  //rwt_start_date
  sortByEndDateDescending(arr: ResourceWithDetails[]): ResourceWithDetails[] {
    return arr.sort((a, b) => {
      const dateA = new Date(a.rwt_start_date).getTime();
      const dateB = new Date(b.rwt_start_date).getTime();
      return dateB - dateA;
    });
  }

  transformResourceData(data: Resource[]): ResourceWithDetails[] {
    const modifiedData: ResourceWithDetails[] = [];

    data.forEach((curr: Resource) => {
      const modifiedResource: ResourceWithDetails = {
        ...(curr as ResourceWithDetails),
        rwt_details: [{
          rwt_name_history: curr.rwt_name_history || '',
          level: curr.level || 0,
          rwt_start_date: curr.rwt_start_date || '',
          rwt_end_date: curr.rwt_end_date || '',
          location_name: curr.location_name || ''
        }]
      };

      modifiedData.push(modifiedResource);
    });

    return modifiedData;
  }

  combineRwtDetails(resources: ResourceWithDetails[]): ResourceWithDetails[] {
    const combinedData: { [key: number]: ResourceWithDetails } = {};

    resources.forEach((resource) => {
      if (!combinedData[resource.resource_id!]) {
        combinedData[resource.resource_id!] = { ...resource };
      } else {
        combinedData[resource.resource_id!].rwt_details.push(...resource.rwt_details);
      }
    });

    return Object.values(combinedData);
  }

  exportExcel() {
    const data = [];
    const data2 = [];
    console.log(
      "Data :",this.manageResourceDataTable);
    
    this.manageResourceDataTable.map(element => {
        const obj = {};
        obj['Resource Name'] = element.resource_name;
        obj['Resource Email'] = element.resource_email;
        obj['RWT'] = element.rwt_name;
        obj['Level'] = element.level_name;
        obj['Line Group'] = element.line_group_name;
        obj['Resource Manager'] = element.rm_name;
        obj['BU'] = element.bu;
        obj['Group Assigned'] = element.department_name;
        obj['Start Date'] = element.resource_start_date;
        obj['Sub Location'] = element.sub_location_name;
        data.push(obj);
        element.rwt_details.forEach(rwtDetail => {
          const combinedObj = {
            'Resource Email': element.resource_email,
            'Level': rwtDetail.level,
            'Location Name': rwtDetail.location_name,
            'RWT Start Date': rwtDetail.rwt_start_date,
            'RWT End Date': rwtDetail.rwt_end_date,
            // Add other fields from rwt_details as needed
          };
          data2.push(combinedObj);
        });

      });

    import('xlsx').then(xlsx => {
      const worksheet1 = xlsx.utils.json_to_sheet(data);
      const worksheet2 = xlsx.utils.json_to_sheet(data2);

      const workbook = {
        Sheets: {
          'PRM_Resource_Master': worksheet1,
          'PRM_Resource_RWT_detail': worksheet2
        },
        SheetNames: ['PRM_Resource_Master', 'PRM_Resource_RWT_detail']
      };

      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'PRM_Resource');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_Report' + EXCEL_EXTENSION);
    });
  }

  onRWTChange(rwt_name) {
    this.levels = this.rwtsOrg.filter((item: any) => {
      return item.rwt_name === rwt_name

    }).sort((a, b) => a.level_name.localeCompare(b.level_name));

    const uniqueRwtLevel = [];

    this.levels.map(x => uniqueRwtLevel.filter(a => a.level_id === x.level_id).length > 0 ? null : uniqueRwtLevel.push(x));
    this.levels = uniqueRwtLevel;
    // this.levels = this.rwtsOrg.filter(
    //   (thing, i, arr) => arr.findIndex(t => t.rwt_name === rwt_name) === i
    // ).sort((a, b) => a.level_name.localeCompare(b.level_name));
  }

  OnRwtLevelChange(rwt_name, level_name) {
    this.locations = this.rwtsOrg.filter((item: any) => {
      return (item.rwt_name === rwt_name && item.level_name === level_name)

    }).sort((a, b) => a.location_name.localeCompare(b.location_name));
  }

  onSearch(rwt, subLocation, bu, rm) {
    this.rwt = rwt;
    this.businessUnitFilter = bu;
    // this.department = department;
    this.subLocation = subLocation;
    this.resource_RM = rm;

    this.getManageResourceData();
  }
  clearFilters() {

    this.rwt = 'All';
    this.department = 'All';
    this.subLocation = 'All';
    this.resource_RM = 'All';
    //this.businessUnitControl.setValue('');
    this.businessUnitFilter = 'All';
    this.getManageResourceData();
  }
  cognitoChange() {
    //alert(this.selectedRoles);
    //this.addUpdateResource(true,resourceData)
  }
  AddResourcePopup() {
    this.visibleSidebar2 = true;
    this.isAdd = true;
    this.isActive = true;
    this.isReadonly = false;
    this.popupHeader = 'Add Resource';
    this.isShowError = false;
    this.selectedRoles = ['1'];
    this.isEmailExists = false;
    this.isDMChecked = false;
    this.isBasicInfo = true;
    this.activeIndex = 0;
    this.resourceData = {
      resource_id: '',
      employee_id: '',
      resource_name: '',
      resource_email: '',
      resource_start_date: '',
      resource_last_date: '',
      location_id: '',
      sub_location_id: '',
      billable_percent: '',
      rwtid: '',
      line_group_id: '',
      resource_rm_id: '',
      rm_name: '--Select--',
      rm_email: '',
      department_id: '',
      is_active: '',
      sebatical_id: '',
      start_date: '',
      end_date: '',
      rwt_start_date: new FormControl(moment()),
      rwt_end_date: new FormControl(moment()),
      rwt_location_name: '--Select--',
      rwt_level_name: '--Select--',
      rwt_name: '--Select--',
      line_group_name: '--Select--',
      department_name: '--Select--',
      bu_name: '--Select--',
      subBU_name: '--Select--',
      sub_location_name: '--Select--',
      location_name: '--Select--',
      level_name: '--Select--',
      cognito_user_name: '',
      Cognito_Group: ''
    };

  }
  onHide() {
    this.resource_RMs = this.resource_RM_temp;
  }
  EditResourcePopup(resourceDataItem) {
    this.resource_RMs = this.resource_RMs.filter(
      t => t.resource_name != resourceDataItem.resource_name
    );
    this.visibleSidebar2 = true;
    this.isAdd = false;
    this.activeIndex = 0;
    this.isBasicInfo = true;
    this.popupHeader = 'Edit Resource';
    this.isReadonly = true;
    this.resourceData.resource_id = resourceDataItem.resource_id;
    this.resourceData.employee_id = resourceDataItem.employee_id;
    this.resourceData.resource_name = resourceDataItem.resource_name;
    this.resourceData.resource_email = resourceDataItem.resource_email;
    this.resourceData.resource_start_date = moment(resourceDataItem.resource_start_date).format('YYYY-MM-DD'); //'2020-05-05' ;
    this.resourceData.resource_last_date = moment(resourceDataItem.resource_last_date).format('YYYY-MM-DD'); //'2020-05-05' ;
    this.resourceData.sub_location_name = resourceDataItem.sub_location_name;
    // this.resourceData.rwt_name = resourceDataItem.rwt_name;
    this.resourceData.line_group_name = resourceDataItem.line_group_name;
    this.resourceData.rm_name = resourceDataItem.rm_name;
    this.resourceData.department_name = resourceDataItem.department_name;
    this.resourceData.location_name = resourceDataItem.location_name;
    this.resourceData.billable_percent = resourceDataItem.billable_percent;
    this.resourceData.level_name = resourceDataItem.level_name;
    this.resourceData.bu_name = resourceDataItem.bu;
    this.resourceData.subBU_name = resourceDataItem.sub_bu;
    //this.resourceData.start_date = moment(resourceDataItem.start_date).format('YYYY-MM-DD'); //'2020-05-05' ;;
    //this.resourceData.end_date = moment(resourceDataItem.end_date).format('YYYY-MM-DD'); //'2020-05-05' ;
    this.resourceData.cognito_user_name = resourceDataItem.cognito_user_name;
    let c_group = resourceDataItem.Cognito_Group.split(",");
    this.selectedRoles = c_group;
    this.isActive = resourceDataItem.is_active;
    console.log('edit data', resourceDataItem);
  }
  SaveResource() {
    this.isShowError = true;
    this.isBasicInfo = true;
    if (this.form.valid) {
      this.addUpdateResource(this.resourceData)
    } else {
      // validate all form fields
      Object.keys(this.form.controls).forEach(field => { // {1}
        const control = this.form.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    }
    this.resource_RMs = this.resource_RM_temp;
  }
  addUpdateResource(resourceData) {

    this.loading = true;
    this.visibleSidebar2 = false;
    let formmain = [];
    let formData = {};
    let cognito = [];
    let cognito_details = [];
    let RM_email = this.resource_RMs.filter(
      (thing, i, arr) => arr.findIndex(t => t.resource_name === resourceData.rm_name) === i
    );
    let resource_id = '';
    let is_active = 'True';
    let resource_last_date = '';
    let addMessage = "Added";
    let cognito_user_name = ConfigJson.oauth.identity_provider + '_' + resourceData.resource_email;
    let userPoolID = ConfigJson.userPoolId;
    if (this.isAdd === false) {
      resource_id = resourceData.resource_id;
      cognito_user_name = resourceData.cognito_user_name;
      addMessage = "Updated";
      resource_last_date = this.resourceData.resource_last_date;
      if (!this.isActive) {
        is_active = 'False';
      }
    }
    formData = {
      'resource_id': resource_id, //'2002', //this.SelectBehalfOf ? this.submitResourceID : this.resourceId,
      'employee_id': resourceData.employee_id,
      'resource_name': resourceData.resource_name, //'NGP Test 2002',//moment(this.currentDate.value).format('MMM'),
      'resource_email': resourceData.resource_email,// 'NGPtestnew new.2002@mresult.com',//moment(this.currentDate.value).format('MMM'),
      'resource_start_date': resourceData.resource_start_date, // '2020-05-11',//moment(this.currentDate.value).format('MMM'),
      'resource_end_date': resource_last_date, //moment(this.currentDate.value).format('MMM'),
      'location': resourceData.location_name, // 'India', //moment(this.currentDate.value).format('MMM'),
      'sub_location': resourceData.sub_location_name, // 'Mangalore',// moment(this.currentDate.value).format('MMM'),
      'department': resourceData.department_name, // 'Digital Engineering', //moment(this.currentDate.value).format('MMM'),
      'billable_percent': resourceData.billable_percent, // '1',// moment(this.currentDate.value).format('MMM'),
      'is_active': is_active,//moment(this.currentDate.value).format('MMM'),
      // 'rwt_name': resourceData.rwt_name, // 'Application/Web Developer',//moment(this.currentDate.value).format('MMM'),
      'line_group': resourceData.line_group_name, // 'Web App 4',//moment(this.currentDate.value).format('MMM'),
      'RM_email': RM_email[0].resource_email, //'praveena.mundolimoole@mresult.com',// moment(this.currentDate.value).format('MMM'),
      'level_name': resourceData.level_name, //'3' //moment(this.currentDate.value).format('MMM'),
      'bu_name': resourceData.bu_name,
      'subBU_name': resourceData.subBU_name
    };

    cognito = [{
      'cognito_user_name': cognito_user_name,
      'userPoolID': userPoolID
    }];
    this.roles.map(roleData => {
      //this.checkEmptyActivity(timesheetData);
      //this.checkRemarkValues(timesheetData);
      let data = {};
      this.selectedRoles.map(selectedRoleData => {
        if (Number(roleData.role_id) === Number(selectedRoleData)) {
          let data = {
            // 'cognito_user_name': cognito_user_name,// 'azureadsso_testuser10@sharepointninjas.com',
            'cognito_user_group': roleData.role_id
          };
          cognito_details.push(data);
        }
      });
    });


    formData['cognito_details'] = cognito_details;
    formmain.push(formData);

    this.adminService.postManageResourceData(formmain).subscribe(
      response => {
        this.adminService.postCognitoGroupData(cognito).subscribe(
          cognitoResponse => {

          }
        );


        this.loading = false;
        // this.messageService.add({ severity: 'success', summary: 'Saved successfully', detail: 'Success..', life: 3000 });
        // this.router.navigate(['/resource/actuals']);

        this.visibleSidebar2 = false;
        Swal.fire('Resource Data ' + addMessage + ' Successfully ', '', 'success');
        this.getManageResourceData();
      },
      err => {
        this.loading = false;
        console.log(err)
      });

  }
  cancel() {
    this.visibleSidebar2 = false;
    this.resource_RMs = this.resource_RM_temp;
  }
  dmChange() {
    this.isDMChecked = false;
    this.selectedRoles.map(selectedRoleData => {
      if (Number(selectedRoleData) === 5) {
        this.isDMChecked = true;
        if (this.selectedRoles.indexOf('6') === -1) {
          this.selectedRoles.push('6');
        }
      }
    });
  }
  depChange(department) {
    if (department !== 'All') {
      this.resource_RMs = this.manageResourceDataTable.filter(
        (thing, i, arr) => arr.findIndex(t => t.rm_name === thing.rm_name && t.department_name === department) === i
      );
    }
    else {
      this.resource_RMs = this.manageResourceDataTable.filter(
        (thing, i, arr) => arr.findIndex(t => t.rm_name === thing.rm_name) === i
      );
    }
  }
  tabChange(event) {
    if (event.index == 2) {
      this.isShowError = false;
      this.resourceData.start_date = '';
      this.resourceData.end_date = '';
      this.getAddDeleteSabbaticalByResourceID(this.resourceData.resource_id, 0, '', '', 0);
      // console.log('sab', event.index)
    }
    if (event.index == 1) {
      this.isShowError = false;
      this.resourceData.rwt_name = '';
      this.resourceData.rwt_start_date = new FormControl(moment());
      let currentEndDate = new Date().getFullYear();
      let endDate = moment(currentEndDate + '-12-31');
      // let currentstartDate = new Date().getFullYear();
      // let currentMonth = new Date().getMonth() + 1;;

      // let startDate = moment(currentstartDate+'-'+currentMonth+'-01');

      this.resourceData.rwt_end_date = new FormControl(moment(endDate));
      this.resourceData.rwt_location_name = '';
      this.resourceData.rwt_level_name = '';
      // console.log('rwt', event.index)
      this.getResourceRWTData();
    }
    if (event.index == 0) {
      this.isShowError = false;
      this.resource_RMs = this.resource_RM_temp;
      this.resourceData.rwt_name = '1';
      this.resourceData.rwt_location_name = '1';
      this.resourceData.rwt_level_name = '1';
    }
  }
  SaveSabbatical() {
    this.isShowError = true;
    this.isBasicInfo = false;
    if (this.form.get('start_date').value !== "" && this.form.get('end_date').value !== "") {
      this.isShowError = false;
      this.getAddDeleteSabbaticalByResourceID(this.resourceData.resource_id, 0, this.form.get('start_date').value, this.form.get('end_date').value, 1);
    } else {
      // validate all form fields
      Object.keys(this.form.controls).forEach(field => {
        if (field === "start_date" || field === "end_date") {
          const control = this.form.get(field);
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }
  CancelSabbatical() {
    this.activeIndex = 0;
  }
  DeleteSabbatical(sabbaticalDataItem) {
    this.getAddDeleteSabbaticalByResourceID(sabbaticalDataItem.resource_id, sabbaticalDataItem.sebatical_id, '', '', 2);
  }
  getAddDeleteSabbaticalByResourceID(resourceID, SabaticalID, startDate, endDate, opType) {
    this.popupLoading = true;
    this.adminService.getAddDeleteSabbaticalByResourceID(resourceID, SabaticalID, startDate, endDate, opType).then(sabbaticalData => {
      this.manageResourceSabbaticalDataTable = sabbaticalData;
      this.popupLoading = false;

      this.resourceData.start_date = '';
      this.resourceData.end_date = '';
      if (opType === 1) {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Sabatical details added successfuly..', life: 3000 });
      } else if (opType === 2) {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Sabatical detail deleted successfuly..', life: 3000 });
      }
    }).catch((err) => {
      this.popupLoading = false;
      this.manageResourceSabbaticalDataTable = [];
    });;
  }

  SaveRWT() {
    this.submitted = true;
    this.isShowError = true;
    this.isBasicInfo = false;
    //  this.getAddDeleteRWTByResourceID(rwt_start_date,rwt_end_date);
    let rwtStartDate = moment(this.resourceData.rwt_start_date.value).startOf('month').format('YYYY-MM-DD');
    let rwtEndDate = moment(this.resourceData.rwt_end_date.value).endOf('month').format('YYYY-MM-DD');

    if (rwtStartDate !== rwtEndDate && this.form.get('rwt_name').value !== "" && this.form.get('rwt_level_name').value !== "" && this.form.get('rwt_location_name').value !== "" && !this.isDateValidate()) {
      this.isShowError = false;
      this.getAddDeleteRWTByResourceID(this.form.get('rwt_name').value, this.form.get('rwt_level_name').value, this.form.get('rwt_location_name').value, rwtStartDate, rwtEndDate);
    } else {
      // validate all form fields
      Object.keys(this.form.controls).forEach(field => {
        if (field === "rwt_level_name" || field === "rwt_location_name" || field === "rwt_name") {
          const control = this.form.get(field);
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }
  CancelRWT() {
    this.activeIndex = 0;
    this.submitted = false;
  }
  // DeleteRWT(sabbaticalDataItem) {
  //   this.getAddDeleteSabbaticalByResourceID(sabbaticalDataItem.resource_id, sabbaticalDataItem.sebatical_id, '', '', 2);
  // }
  getAddDeleteRWTByResourceID(rwt_name, rwt_level_name, rwt_location_name, rwt_start_date, rwt_end_date) {
    this.popupLoading = true;
    this.isDataExists = false;
    let dataList = [...this.manageResourceRWTDataTable]
    dataList.forEach(x => {
      let data_start_date = new Date(x.start_date);
      let data_end_date = new Date(x.end_date);
      let input_start_date = new Date(rwt_start_date);
      // console.log('test1',(data_start_date.getTime() >= input_start_date.getTime()))
      // console.log('test2',(data_end_date.getTime() >= input_start_date.getTime()))
      if ((data_start_date.getTime() <= input_start_date.getTime()) && (data_end_date.getTime() >= input_start_date.getTime())) {
        this.isDataExists = true;
        this.popupLoading = false;
        // return;
      }
    });

    // console.log('rwtEDupDate', this.manageResourceRWTDataTable);
    if (!this.isDataExists) {
      let formData = {
        'resource_id': this.resourceData.resource_id,
        'rwt_name': rwt_name,
        'rwt_level_name': rwt_level_name,
        'rwt_location_name': rwt_location_name,
        'rwt_start_date': rwt_start_date,
        'rwt_end_date': rwt_end_date,
        'opType': 2,
      };
      formData['type'] = "post"
      // console.log('form', formData);
      this.adminService.postManageRWTData(formData).subscribe(
        response => {
          // this.manageResourceRWTDataTable = response;
          // console.log('daresponse',response)
          this.loading = false;
          this.popupLoading = false;
          this.visibleSidebar2 = false;
          Swal.fire('Resource RWT Data Added Successfully ', '', 'success');
          this.submitted = false;
          this.getResourceRWTData();
        },
        err => {
          this.loading = false;
          this.submitted = false;
          console.log(err)
        });
    }
    else {
      // duplicate message
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Data for selected date range already exists..', life: 3000 });

    }
  }

  getResourceRWTData() {
    this.popupLoading = true;
    let formData = {
      'resource_id': this.resourceData.resource_id,
      'opType': 3,
    };
    formData['type'] = "get"
    this.adminService.postManageRWTData(formData).subscribe(
      response => {
        // console.log('darssesponse',response)
        this.manageResourceRWTDataTable = response['body'];
        this.popupLoading = false;
        // this.visibleSidebar2 = false;
        // Swal.fire('Resource RWT Data Added Successfully ','','success');
        // this.getManageRWTData();
      },
      err => {
        this.popupLoading = false;
        console.log(err)
      });
  }

  isDateValidate() {
    let rwtStartDate = moment(this.resourceData.rwt_start_date.value).startOf('month').format('YYYY-MM-DD');
    let rwtEndDate = moment(this.resourceData.rwt_end_date.value).startOf('month').format('YYYY-MM-DD');
    return moment(rwtStartDate).isAfter(moment(rwtEndDate));

  }
  onBUChange(bu) {
    this.subBU = this.subBUList.filter((item: any) => {
      return item.bu === bu

    }).sort((a, b) => a.sub_bu.localeCompare(b.sub_bu));

    console.log('ss', this.subBU)
    // const uniqueSubBU = [];

    // this.subBU.map(x => uniqueSubBU.filter(a => a.sub_bu === x.sub_bu).length > 0 ? null : uniqueSubBU.push(x));
    // this.subBU = uniqueSubBU;

  }
  buChange(event) {
    if (event.target.value === '') {
      this.businessUnitFilter = '';
    } else {
      this.businessUnitFilter = event.target.value; // this.departmentObj[event.target.value].name;
    }
  }
  // this.adminService.getAddDeleteSabbaticalByResourceID(resourceID,SabaticalID,startDate,endDate,opType).then(sabbaticalData => {
  //   this.manageResourceSabbaticalDataTable = sabbaticalData;
  //   this.popupLoading = false;

  //   this.resourceData.start_date = '';
  //   this.resourceData.end_date = '';
  //   if (opType ===1) {
  //     this.messageService.add({ severity: 'success', summary: 'success', detail: 'Sabatical details added successfuly..', life: 3000 });
  //   } else if(opType ===2) {
  //     this.messageService.add({ severity: 'success', summary: 'success', detail: 'Sabatical detail deleted successfuly..', life: 3000 });
  //   }
  // }).catch((err) => {
  //   this.popupLoading = false;
  //   this.manageResourceSabbaticalDataTable = [];
  // });;
  // }
  // chosenstartYearHandler(normalizedYear: Moment) {
  //   const ctrlValue = this.startDate.value;
  //   ctrlValue.year(normalizedYear.year());
  //   this.startDate.setValue(ctrlValue);
  // }

  // chosenstartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  //   const ctrlValue = this.startDate.value;
  //   ctrlValue.month(normalizedMonth.month());
  //   this.startDate.setValue(ctrlValue);
  //   datepicker.close();
  //   // this.filterChange()
  // }
}


