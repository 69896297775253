<ng-template #customLoadingTemplate>
  <div class="ctmloader">
    <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
  <div class="container-fluid pt-3">
    <div class="ctm-body">
      <div class="d-flex align-items-center">
        <h4>Reports</h4>
        <div class="ml-auto">
          <button (click)="exportExcel()"
            selectionMode="multiple" class="btn cstbtn btn2 ml-2 mb-1"><i class="fa fa-download" aria-hidden="true"></i>
            Download this report as Excel</button>
            <!-- <button *ngIf="!isUtilizationDetail" type="button" [disabled]="isUtilizationDetail" (click)="fireEvent()"
            selectionMode="multiple" class="btn cstbtn btn2 ml-2 mb-1"><i class="fa fa-download" aria-hidden="true"></i>
            Download this report as Excel</button> -->
        </div>
      </div>

      <div>

      </div>

      <div class="ctm-card">
        <p-tabView (onChange)="onChange($event)" [activeIndex]="selectedIndex">
          <p-tabPanel  header="Project View">

            <div class="d-flex align-items-end ctmfilter mb-2">
              <div>
                <label class="d-block text-left" for="exampleFormControlSelect1">Project</label>
                <select [(ngModel)]="selectedProject" class="form-control" style="max-width: 250px;"
                  id="exampleFormControlSelect1">
                  <option value="">Select Project</option>
                  <option *ngFor="let item of projectList" [value]="item.project_id">{{item.project_name}}</option>
                </select>

              </div>
              <div class="ml-3">
                <label class="d-block text-left" for="exampleFormControlSelect1">Year</label>
                <select [(ngModel)]="selectPYears" style="min-width: 250px;" class="form-control">
                  <!-- <option value="year==2">{{year}}</option> -->
                  <option *ngFor="let year of years" [value]="year">{{year}}</option>
                </select>
              </div>
              <button class="btn cstbtn btn1  ml-3 mb-1" (click)="onSubmit()">
                <i aria-hidden="true"></i> Search
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              </button>

              <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                  aria-hidden="true"></i> Reset</div>
            </div>

            <div class="d-flex">
              <p *ngIf="!selectField" class="mt-2 red">*Please Select Project!</p>
            </div>

            <div class="text-center" *ngIf="!isRecodExists">
              <p style="font-size: 16px;" class="mt-2 red">No Records Found !</p>
            </div>
            <p-table *ngIf="isTableView" class="bordertop" [value]="projectDetail" dataKey="project_name" #dt [globalFilterFields]=""
              [totalRecords]="projectDetail?.length" (onRowExpand)="onRowExpand()" (onRowCollapse)="onRowCollapse()" [columns]="cols" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
              [expandedRowKeys]="expandedRows" [rows]="10" pageLinks="3" [rowsPerPageOptions]="[5,10,15,20]">
              <ng-template pTemplate="caption">
                <div class="d-flex ctmfilter align-items-end">
                </div>
                <!-- <div class="row mt-3" *ngIf="isProject && isRecodExists ">
                  <div class="col-3">
                    <div class="headerdetailsp text-left">
                      <div>Client</div>
                      <label>{{projectDetail[0].client_name}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetailsp text-left">
                      <div>Project</div>
                      <label>{{projectDetail[0].project_name}}</label>
                    </div>
                  </div>
                </div> -->

              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th style="width: 5em">
                    <a style="cursor: pointer;font-size: 16px; color:teal;" (click)="expandAll()">
                      <i *ngIf="!isExpanded" style="font-size: 20px;" class="fa fg fa-plus-circle" aria-hidden="true"></i>
                      <i *ngIf="isExpanded" style="font-size: 20px;" class="fa fg fa-minus-circle" aria-hidden="true"></i>
                    </a>
                  </th>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <div>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                  <tr [class]="expanded ? 'row-active-header' : ''">
                    <td class="chevdownup">
                      <a href="#" [pRowToggler]="rowData">
                        <i [class]="expanded ? 'pi fc pi-chevron-up' : 'pi fc pi-chevron-down'"></i>
                      </a>
                    </td>
                    <td *ngFor="let col of columns">
                      <ng-container *ngIf="col.field === 'resource_name'">
                        <a href="#" [pRowToggler]="rowData">
                          {{rowData[col.field]}}
                        </a>
                      </ng-container>
                      <ng-container *ngIf="col.field !== 'resource_name'">
                        {{rowData[col.field]}}
                      </ng-container>
    
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData let-expanded="expanded" let-columns="columns">
                  <tr class="row-active-body">
                    <td [attr.colspan]="columns.length + 1" class="no-pad p-0">
                      <div class="p-p-3">
                        <table class="innerTable tablelayout" 
                        *ngIf="rowData['requestedresource'] && rowData['requestedresource'].length > 0 && rowData['requestedresource']['resource_name']!==null">
                          <thead class="innerHeader">
                            <tr>
                              <th pSortableColumn="rowData['requestedresource']['resource_name']">Resource<p-sortIcon field="rowData['requestedresource']['resource_name']"></p-sortIcon>
                              </th>
                              <th pSortableColumn="resource_email">Resource Email<p-sortIcon field="resource_email"></p-sortIcon>
                              </th>
                              <th pSortableColumn="employee_id">Employee Id<p-sortIcon field="employee_id"></p-sortIcon>
                              </th>
                              <th pSortableColumn="doj">DOJ<p-sortIcon field="doj"></p-sortIcon>
                              </th>
                              <th pSortableColumn="rwt_name">RWT<p-sortIcon field="rwt_name"></p-sortIcon>
                              </th>
                              <th pSortableColumn="rwt_level">Level<p-sortIcon field="rwt_level"></p-sortIcon>
                              </th>
                              <th pSortableColumn="rm_resource_name">Resource Manager<p-sortIcon field="rm_resource_name">
                                </p-sortIcon>
                              </th>
                              <th pSortableColumn="department_name">Department<p-sortIcon field="department_name"></p-sortIcon>
                              </th>
                              <th pSortableColumn="line_group_name">Line Group<p-sortIcon field="line_group_name"></p-sortIcon>
                              </th>
                              <th pSortableColumn="sub_project_id">Sub Project ID<p-sortIcon field="sub_project_id"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Jan">Jan<p-sortIcon field="Jan"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Feb">Feb<p-sortIcon field="Feb"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Mar">Mar<p-sortIcon field="Mar"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Apr">Apr<p-sortIcon field="Apr"></p-sortIcon>
                              </th>
                              <th pSortableColumn="May">May<p-sortIcon field="May"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Jun">Jun<p-sortIcon field="Jun"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Jul">Jul<p-sortIcon field="Jul"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Aug">Aug<p-sortIcon field="Aug"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Sep">Sep<p-sortIcon field="Sep"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Oct">Oct<p-sortIcon field="Oct"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Nov">Nov<p-sortIcon field="Nov"></p-sortIcon>
                              </th>
                              <th pSortableColumn="Dec">Dec<p-sortIcon field="Dec"></p-sortIcon>
                              </th>
                            <tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of rowData['requestedresource']" class="innerBody">
                              <td>{{row['resource_name']}}</td>
                              <td>{{row['resource_email']}}</td>
                              <td>{{row['employee_id']}}</td>
                              <td>{{row['doj']|date:'mediumDate'}}</td>
                              <td>{{row['rwt_name']}}</td>
                              <td>{{row['rwt_level']}}</td>
                              <td>{{row['rm_resource_name']}}</td>
                              <td>{{row['department_name']}}</td>
                              <td>{{row['line_group_name']}}</td>
                              <td>{{row['sub_project_id']}}</td>
                              <td>{{row['Jan']}}</td>
                              <td>{{row['Feb']}}</td>
                              <td>{{row['Mar']}}</td>
                              <td>{{row['Apr']}}</td>
                              <td>{{row['May']}}</td>
                              <td>{{row['Jun']}}</td>
                              <td>{{row['Jul']}}</td>
                              <td>{{row['Aug']}}</td>
                              <td>{{row['Sep']}}</td>
                              <td>{{row['Oct']}}</td>
                              <td>{{row['Nov']}}</td>
                              <td>{{row['Dec']}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div style="color: #ed4d4d;padding: 1rem;" *ngIf="rowData['requestedresource'].length===0 ">
                        No Projects Allocated for the Month
                      </div>
                    </td>
    
                  </tr>
                </ng-template>
                <!-- <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="resource_name">Resource<p-sortIcon field="resource_name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="resource_email">Resource Email<p-sortIcon field="resource_email"></p-sortIcon>
                    </th>
                    <th pSortableColumn="employee_id">Employee Id<p-sortIcon field="employee_id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="doj">DOJ<p-sortIcon field="doj"></p-sortIcon>
                    </th>
                    <th pSortableColumn="rwt_name">RWT<p-sortIcon field="rwt_name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="rwt_level">Level<p-sortIcon field="rwt_level"></p-sortIcon>
                    </th>
                    <th pSortableColumn="rm_resource_name">Resource Manager<p-sortIcon field="rm_resource_name">
                      </p-sortIcon>
                    </th>
                    <th pSortableColumn="department_name">Department<p-sortIcon field="line_group_name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="line_group_name">Line Group<p-sortIcon field="line_group_name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="sub_project_id">Sub Project ID<p-sortIcon field="sub_project_id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Jan">Jan<p-sortIcon field="Jan"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Feb">Feb<p-sortIcon field="Feb"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Mar">Mar<p-sortIcon field="Mar"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Apr">Apr<p-sortIcon field="Apr"></p-sortIcon>
                    </th>
                    <th pSortableColumn="May">May<p-sortIcon field="May"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Jun">Jun<p-sortIcon field="Jun"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Jul">Jul<p-sortIcon field="Jul"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Aug">Aug<p-sortIcon field="Aug"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Sep">Sep<p-sortIcon field="Sep"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Oct">Oct<p-sortIcon field="Oct"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Nov">Nov<p-sortIcon field="Nov"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Dec">Dec<p-sortIcon field="Dec"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template> -->
                <!-- <ng-template pTemplate="body" let-projectDetail>
                  <tr>
                    <td>{{projectDetail.resource_name}}</td>
                    <td>{{projectDetail.resource_email}}</td>
                    <td>{{projectDetail.employee_id}}</td>
                    <td>{{projectDetail.doj|date:'mediumDate'}}</td>
                    <td>{{projectDetail.rwt_name}}</td>
                    <td>{{projectDetail.rwt_level}}</td>
                    <td>{{projectDetail.rm_resource_name}}</td>
                    <td>{{projectDetail.department_name}}</td>
                    <td>{{projectDetail.line_group_name}}</td>
                    <td>{{projectDetail.sub_project_id}}</td>
                    <td>{{projectDetail.Jan}}</td>
                    <td>{{projectDetail.Feb}}</td>
                    <td>{{projectDetail.Mar}}</td>
                    <td>{{projectDetail.Apr}}</td>
                    <td>{{projectDetail.May}}</td>
                    <td>{{projectDetail.Jun}}</td>
                    <td>{{projectDetail.Jul}}</td>
                    <td>{{projectDetail.Aug}}</td>
                    <td>{{projectDetail.Sep}}</td>
                    <td>{{projectDetail.Oct}}</td>
                    <td>{{projectDetail.Nov}}</td>
                    <td>{{projectDetail.Dec}}</td>
                  </tr>
                </ng-template> -->
              </div>

            </p-table>
          </p-tabPanel>

          <p-tabPanel header="Resource View">
            <div class="d-flex align-items-end ctmfilter mb-2">
              <div>
                <label class="d-block text-left" for="exampleFormControlSelect1">Resource</label>
                <select [(ngModel)]="selectedResource" class="form-control" style="max-width: 250px;"
                  id="exampleFormControlSelect1">
                  <option value="">Select Resource</option>
                  <option *ngFor="let item of resourceList" [value]="item.resource_id">{{item.resource_name}}</option>
                </select>

              </div>
              <div class="ml-3">
                <label class="d-block text-left" for="exampleFormControlSelect1">Year</label>
                <select [(ngModel)]="selectYears" class="form-control" style="min-width: 250px;"
                  id="exampleFormControlSelect1">
                  <option *ngFor="let year of years" [value]="year">{{year}}</option>
                </select>
              </div>

              <button class="btn cstbtn btn1  ml-3 mb-1" (click)="onSubmitResource()">
                <i aria-hidden="true"></i> Search
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              </button>

              <div class="clrfilter" (click)="clearResourceFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                  aria-hidden="true"></i> Reset</div>
            </div>

            <div class="d-flex">
              <p *ngIf="!selectResourceField" class="mt-2 red">*Please Select Resource!</p>
            </div>
            <div class="text-center" *ngIf="!isResourceRecodExists">
              <p style="font-size: 16px;"  class="mt-2 red">No Records Found !</p>
            </div>

            <p-table *ngIf="isResourceTableView" class="bordertop" [value]="resourceDetail" #dt [globalFilterFields]=""
              [totalRecords]="resourceDetail.length" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
              [rows]="10" pageLinks="3" [rows]="10" [rowsPerPageOptions]="[5,10,15,20]">
              <ng-template pTemplate="caption">
                <div class="d-flex ctmfilter align-items-end">
                </div>
                <div class="row mt-3" *ngIf="isResource && isResourceRecodExists">
                  <div class="col-4">
                    <div class="headerdetails text-left">
                      <div>Resource</div>
                      <label>{{resourceDetail[0].resource_name}}</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="headerdetails text-left">
                      <div>Employee Id</div>
                      <label>{{resourceDetail[0].employee_id}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>Resource Manager</div>
                      <label>{{resourceDetail[0].rm_resource_name}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>Line Group</div>
                      <label>{{resourceDetail[0].line_group_name}}</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="headerdetails text-left">
                      <div>Resource Email</div>
                      <label>{{resourceDetail[0].resource_email}}</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="headerdetails text-left">
                      <div>DOJ</div>
                      <label>{{resourceDetail[0].doj}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>RWT</div>
                      <label>{{resourceDetail[0].rwt_name}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>Department</div>
                      <label>{{resourceDetail[0].department_name}}</label>
                    </div>
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="client_name">Client<p-sortIcon field="client_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="project_name">Project<p-sortIcon field="project_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="sub_project_id">Sub Project ID<p-sortIcon field="sub_project_id"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Jan">Jan<p-sortIcon field="Jan"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Feb">Feb<p-sortIcon field="Feb"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Mar">Mar<p-sortIcon field="Mar"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Apr">Apr<p-sortIcon field="Apr"></p-sortIcon>
                  </th>
                  <th pSortableColumn="May">May<p-sortIcon field="May"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Jun">Jun<p-sortIcon field="Jun"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Jul">Jul<p-sortIcon field="Jul"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Aug">Aug<p-sortIcon field="Aug"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Sep">Sep<p-sortIcon field="Sep"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Oct">Oct<p-sortIcon field="Oct"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Nov">Nov<p-sortIcon field="Nov"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Dec">Dec<p-sortIcon field="Dec"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-resourceDetail>
                <tr>
                  <td>{{resourceDetail.client_name}}</td>
                  <td>{{resourceDetail.project_name}}</td>
                  <td>{{resourceDetail.sub_project_id}}</td>
                  <td>{{resourceDetail.Jan}}</td>
                  <td>{{resourceDetail.Feb}}</td>
                  <td>{{resourceDetail.Mar}}</td>
                  <td>{{resourceDetail.Apr}}</td>
                  <td>{{resourceDetail.May}}</td>
                  <td>{{resourceDetail.Jun}}</td>
                  <td>{{resourceDetail.Jul}}</td>
                  <td>{{resourceDetail.Aug}}</td>
                  <td>{{resourceDetail.Sep}}</td>
                  <td>{{resourceDetail.Oct}}</td>
                  <td>{{resourceDetail.Nov}}</td>
                  <td>{{resourceDetail.Dec}}</td>

                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          <p-tabPanel header="Resource Project View">
            <div class="d-flex align-items-end ctmfilter mb-2">
              <!-- <div>
                <label class="d-block text-left" for="exampleFormControlSelect1">Resource</label>
                <select [(ngModel)]="dropdownResource" class="form-control" style="max-width: 250px;"
                  id="exampleFormControlSelect1">
                  <option value="">Select Resource</option>
                  <option *ngFor="let item of resourceList" [value]="item.resource_id">{{item.resource_name}}</option>
                </select>

              </div> -->
              <div class=" ml-4">
                <label class="d-block text-left" for="exampleFormControlSelect1">Resource</label>
                <select [ngModel]="dropdownResource" [formControl]="resourceControl" style="width: 200px;"
                  class="form-control">
                  <option value='All' selected>All</option>
                  <option *ngFor="let resource of resourceDropdownList" value="{{resource.resource_name}}">
                    {{ resource.resource_name }}
                  </option>
                </select>
              </div>
              <div class=" ml-4">
                <label class="d-block text-left" for="exampleFormControlSelect1">Project</label>
                <select [ngModel]="dropdownProject" [formControl]="projectControl" style="width: 200px;"
                  class="form-control">
                  <option value='All' selected>All</option>
                  <option *ngFor="let project of projectDropdownList" value="{{project.project_name}}">
                    {{ project.project_name }}
                  </option>
                </select>
              </div>
              <!-- <div class="ml-3">
                <label class="d-block text-left" for="exampleFormControlSelect1">Project</label>
                <select [(ngModel)]="selectYears" class="form-control" style="min-width: 250px;"
                  id="exampleFormControlSelect1">
                  <option *ngFor="let year of years" [value]="year">{{year}}</option>
                </select>
              </div> -->

              
              <button class="btn cstbtn btn1  ml-3 mb-1"  (click)="onFilter(resourceControl.value,projectControl.value)">
                <i class="fa fa-filter" aria-hidden="true"></i> Apply filters
              </button>
              <div class="clrfilter" (click)="clearFilter()"><i style="color: #ed4d4d;" class="fa fa-times red"
                  aria-hidden="true"></i> Reset</div>
            </div>

            <div class="d-flex">
              <p *ngIf="!selectResourceField" class="mt-2 red">*Please Select Resource!</p>
            </div>
            <div class="text-center" *ngIf="!isResourceRecodExists">
              <p style="font-size: 16px;"  class="mt-2 red">No Records Found !</p>
            </div>

            <p-table  class="bordertop" [value]="resourceProjectDetails" #dt [globalFilterFields]=""
              [totalRecords]="resourceProjectDetails.length" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
              [rows]="10" pageLinks="3" [rows]="10" [rowsPerPageOptions]="[5,10,15,20]">
              <ng-template pTemplate="caption">
                <div class="d-flex ctmfilter align-items-end">
                </div>
                <!-- <div class="row mt-3" >
                  <div class="col-4">
                    <div class="headerdetails text-left">
                      <div>Resource</div>
                      <label>{{resourceProjectDetails[0].resource_name}}</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="headerdetails text-left">
                      <div>Employee Id</div>
                      <label>{{resourceProjectDetails[0].employee_id}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>Resource Manager</div>
                      <label>{{resourceProjectDetails[0].rm_resource_name}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>Line Group</div>
                      <label>{{resourceProjectDetails[0].line_group_name}}</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="headerdetails text-left">
                      <div>Resource Email</div>
                      <label>{{resourceProjectDetails[0].resource_email}}</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="headerdetails text-left">
                      <div>DOJ</div>
                      <label>{{resourceProjectDetails[0].doj}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>RWT</div>
                      <label>{{resourceProjectDetails[0].rwt_name}}</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="headerdetails text-left">
                      <div>Department</div>
                      <label>{{resourceProjectDetails[0].department_name}}</label>
                    </div>
                  </div>
                </div> -->

              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="resource_name">Resource Name<p-sortIcon field="resource_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="resource_email">Resource Email<p-sortIcon field="resource_email"></p-sortIcon>
                  </th>
                  <th pSortableColumn="department_name">Client<p-sortIcon field="department_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="client_name">Client<p-sortIcon field="client_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="project_name">Project<p-sortIcon field="project_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="sub_project_name">Sub Project<p-sortIcon field="sub_project_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="start_date">Start Date<p-sortIcon field="start_date"></p-sortIcon>
                  </th>
                  <th pSortableColumn="end_date">End Date<p-sortIcon field="end_date"></p-sortIcon>
                  </th>
                  <th pSortableColumn="project_amount">Project Amount<p-sortIcon field="project_amount"></p-sortIcon>
                  </th>
                  <th pSortableColumn="month">Month<p-sortIcon field="month"></p-sortIcon>
                  </th>
                  <th pSortableColumn="year">Year<p-sortIcon field="year"></p-sortIcon>
                  </th>
                  <th pSortableColumn="allocated_hours">Allocated Hours<p-sortIcon field="allocated_hours"></p-sortIcon>
                  </th>
                  <th pSortableColumn="rwt_name">RWT<p-sortIcon field="rwt_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="level_name">Level<p-sortIcon field="level_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="billing_rate">Billing Rate<p-sortIcon field="billing_rate"></p-sortIcon>
                  </th>
                  <th pSortableColumn="cost">Cost($)<p-sortIcon field="cost"></p-sortIcon>
                  </th>
                  <!-- <th pSortableColumn="Jan">Jan<p-sortIcon field="Jan"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Feb">Feb<p-sortIcon field="Feb"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Mar">Mar<p-sortIcon field="Mar"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Apr">Apr<p-sortIcon field="Apr"></p-sortIcon>
                  </th>
                  <th pSortableColumn="May">May<p-sortIcon field="May"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Jun">Jun<p-sortIcon field="Jun"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Jul">Jul<p-sortIcon field="Jul"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Aug">Aug<p-sortIcon field="Aug"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Sep">Sep<p-sortIcon field="Sep"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Oct">Oct<p-sortIcon field="Oct"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Nov">Nov<p-sortIcon field="Nov"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Dec">Dec<p-sortIcon field="Dec"></p-sortIcon>
                  </th>
                </tr> -->
              </ng-template>
              <ng-template pTemplate="body" let-resourceProjectDetails>
                <tr>
                  <td>{{resourceProjectDetails.resource_name}}</td>
                  <td>{{resourceProjectDetails.resource_email}}</td>
                  <td>{{resourceProjectDetails.department_name}}</td>
                  <td>{{resourceProjectDetails.client_name}}</td>
                  <td>{{resourceProjectDetails.project_name}}</td>
                  <td>{{resourceProjectDetails.sub_project_name}}</td>
                  <td>{{resourceProjectDetails.start_date}}</td>
                  <td>{{resourceProjectDetails.end_date}}</td>
                  <td>{{resourceProjectDetails.project_amount}}</td>
                  <td>{{resourceProjectDetails.month}}</td>
                  <td>{{resourceProjectDetails.year}}</td>
                  <td>{{resourceProjectDetails.allocated_hours}}</td>
                  <td>{{resourceProjectDetails.rwt_name}}</td>
                  <td>{{resourceProjectDetails.level_name}}</td>
                  <td>{{resourceProjectDetails.billing_rate}}</td>
                  <td>{{resourceProjectDetails.cost}}</td>

                  <!-- <td>{{resourceProjectDetails.Jan}}</td>
                  <td>{{resourceProjectDetails.Feb}}</td>
                  <td>{{resourceProjectDetails.Mar}}</td>
                  <td>{{resourceProjectDetails.Apr}}</td>
                  <td>{{resourceProjectDetails.May}}</td>
                  <td>{{resourceProjectDetails.Jun}}</td>
                  <td>{{resourceProjectDetails.Jul}}</td>
                  <td>{{resourceProjectDetails.Aug}}</td>
                  <td>{{resourceProjectDetails.Sep}}</td>
                  <td>{{resourceProjectDetails.Oct}}</td>
                  <td>{{resourceProjectDetails.Nov}}</td>
                  <td>{{resourceProjectDetails.Dec}}</td> -->

                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>


          <p-tabPanel header="Utilization View" *ngIf="userRole==='Delivery Partner' || userRole==='Engagement Partner' || userRole==='Engagement Manager'|| userRole==='Billing Manager'">

            <div class="d-flex align-items-end ctmfilter mb-2">
              <div>
                <label class="d-block text-left" for="exampleFormControlSelect1">Month</label>
                <select [(ngModel)]="selectedMonth" style="min-width: 250px;" class="form-control">
                  <!-- <option value="year==2">{{year}}</option> -->
                <option *ngFor="let month of months" [value]="month.month">{{month.month}}</option>
                </select>

              </div>
              <div class="ml-3">
                <label class="d-block text-left" for="exampleFormControlSelect1">Year</label>
                <select [(ngModel)]="selectCurrentYear" style="min-width: 250px;" class="form-control">
                  <!-- <option value="year==2">{{year}}</option> -->
                <option *ngFor="let year of years" [value]="year">{{year}}</option>
                </select>
              </div>
              <button class="btn cstbtn btn1  ml-3 mb-1" (click)="onSubmitDepartment()">
                <i aria-hidden="true"></i> Search
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              </button>

              <div class="clrfilter" (click)="clearDepartmentFilters()" ><i style="color: #ed4d4d;" class="fa fa-times red"
                  aria-hidden="true"></i> Reset</div>
            </div>

            <table class="table ctmtable  table-bordered mt-3" #table>
              <thead>
                <tr>
                  <th scope="col">Department Names</th>
                  <th class="text-center" scope="col">Decision Science</th>
                  <th class="text-center" scope="col">Data Analytics</th>
                  <th class="text-center" scope="col">Digital Engineering</th>
                  <th class="text-center" scope="col">Quality Engineering</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Total Team Strength</th>
                  <td class="text-center">{{totalTeamStrength_DS}}</td>
                  <td class="text-center">{{totalTeamStrength_DA}}</td>
                  <td class="text-center">{{totalTeamStrength_DE}}</td>
                  <td class="text-center">{{totalTeamStrength_QE}}</td>
                </tr>
                <tr>
                  <th scope="row">Average Utilization %</th>
                  <td class="text-center">{{avgUtilization_DS | number : '1.0-2' }}</td>
                  <td class="text-center">{{avgUtilization_DA | number : '1.0-2'}}</td>
                  <td class="text-center">{{avgUtilization_DE | number : '1.0-2'}}</td>
                  <td class="text-center">{{avgUtilization_QE | number : '1.0-2'}}</td>
                </tr>
                <tr>
                  <th class="text-center" style="color: #f38425; font-size:16px;" colspan=5 scope="row">Utilization Count</th>
                  <!-- <td></td>
                  <td></td>
                  <td></td>
                  <td></td> -->
                </tr>
                <tr>
                  <th scope="row">0-50%</th>
                  <td class="text-center">{{utilizationCountZero_DS}}</td>
                  <td class="text-center">{{utilizationCountZero_DA}}</td>
                  <td class="text-center">{{utilizationCountZero_DE}}</td>
                  <td class="text-center">{{utilizationCountZero_QE}}</td>
                </tr>
                <tr>
                  <th scope="row">50-75%</th>
                  <td class="text-center">{{utilizationCountFifty_DS}}</td>
                  <td class="text-center">{{utilizationCountFifty_DA}}</td>
                  <td class="text-center">{{utilizationCountFifty_DE}}</td>
                  <td class="text-center">{{utilizationCountFifty_QE}}</td>
                </tr>
                <tr>
                  <th scope="row">75-100%</th>
                  <td class="text-center">{{utilizationCountSeventyFive_DS}}</td>
                  <td class="text-center">{{utilizationCountSeventyFive_DA}}</td>
                  <td class="text-center">{{utilizationCountSeventyFive_DE}}</td>
                  <td class="text-center">{{utilizationCountSeventyFive_QE}}</td>
                </tr>
                <tr>
                  <th scope="row">100+ %</th>
                  <td class="text-center">{{utilizationCountHundred_DS}}</td>
                  <td class="text-center">{{utilizationCountHundred_DA}}</td>
                  <td class="text-center">{{utilizationCountHundred_DE}}</td>
                  <td class="text-center">{{utilizationCountHundred_QE}}</td>
                </tr>
                <tr>
                  <th scope="row">No. of resources in Sabbatical</th>
                  <td class="text-center">{{sabbaticalCount_DS}}</td>
                  <td class="text-center">{{sabbaticalCount_DA}}</td>
                  <td class="text-center">{{sabbaticalCount_DE}}</td>
                  <td class="text-center">{{sabbaticalCount_QE}}</td>
                </tr>
              </tbody>
            </table>

          </p-tabPanel>


        </p-tabView>


      </div>


    </div>
  </div>
</div>