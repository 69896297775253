<ng-template #customLoadingTemplate>
  <div class="ctmloader">
    <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
  <div class="container-fluid pt-3">
    <div class="row">
      <div class="col-6">
        <h4>Manage RWT</h4>
      </div>
      <div class="col-6 text-right">
        <ng-container>
          <button (click)="AddRWTPopup()" class="btn crvdbtn cstbtn btn2">
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add RWT
          </button>
          <button type="button" selectionMode="multiple" (click)="exportExcel()" class="btn cstbtn btn2 ml-3"><i
              class="fa fa-download" aria-hidden="true"></i> Download this report as
            Excel</button>
        </ng-container>
      </div>
    </div>

    <div class="full-height">
      <p-sidebar [(visible)]="visibleSidebar2" position="right" [style]="{width:'40em'}" [baseZIndex]="10000">
        <form name="myForm" [formGroup]="form">
          <h5 style="font-weight:normal">{{popupHeader}}</h5>
          <p><span class="red">*</span> Please Fill Required Fields</p>
          <p-toast></p-toast>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> RWT Name</label>
                <!-- <input type="text" [(ngModel)]="rwtData.rwt_name" formControlName="rwt_name" name="rwt_name" class="form-control" id="rwt_nameid"> -->
                <p-autoComplete [(ngModel)]="rwtItem" formControlName="rwt_name" [suggestions]="filteredRWTs"
                  name="rwt_name" (completeMethod)="filterrwt($event)" field="rwt_name" [dropdown]="true">
                  <ng-template let-rwtItem pTemplate="item">
                    <div class="rwtItem-item">
                      <div>{{rwtItem.rwt_name}}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>


                <div *ngIf="isFieldValid('rwt_name')">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    Please enter the rwt name
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-3">
              <div class="form-group">
                <label>RWT Level</label>
                <input type="text" [(ngModel)]="rwtData.rwt_level_id" class="form-control" id="">
              </div>
            </div> -->
            <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> Level</label>
                <select [(ngModel)]="rwtData.level_name" name="level_name" formControlName="level_name"
                  class="form-control" id="exampleFormControlSelect1">
                  <option value='' selected>--Select--</option>
                  <option *ngFor="let level of levels" value="{{level.level_name}}">
                    {{ level.level_name }}
                  </option>
                </select>

                <div *ngIf="isFieldValid('level_name')">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    Please select the level
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> Location</label>
                <select [(ngModel)]="rwtData.location_id" name="location_id" formControlName="location_id"
                  class="form-control" id="exampleFormControlSelect1">
                  <option value='' selected>--Select--</option>
                  <option *ngFor="let location of locations" value="{{location.location_id}}">
                    {{ location.location_name }}
                  </option>
                </select>
                <div *ngIf="isFieldValid('location_id')">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    Please select the location
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-3">
              <div class="form-group">
                <label>Level Name</label>
                <input type="text" [(ngModel)]="rwtData.level_name" class="form-control" id="">
              </div>
            </div> -->

            <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> Cost Per Hour ($)</label>
                <input pInputText type="number" pattern="\d+(\.\d{1,2})?" maxlength="5"
                  onKeyPress="if(this.value.length==5) return false;" [(ngModel)]="rwtData.cost_per_hour"
                  name="cost_per_hour" formControlName="cost_per_hour" class="form-control" id="">
                <div *ngIf="isFieldValid('cost_per_hour')">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    Please enter the Cost per Hr rate
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label><span class="red">*</span> Start Date</label>
                <input pInputText type="date" (ngModelChange)="onDateSelectedStart($event)"
                  [(ngModel)]="rwtData.start_date" name="start_date" formControlName="start_date" class="form-control"
                  id="">

                <div *ngIf="isErrorMsgBoolCheckDateRange">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    RWT cost per hour already exists for the selected date range
                  </div>
                </div>

                <div *ngIf="isFieldValid('start_date')">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    Please select the start data
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label> End Date</label>
                <input pInputText type="date" [(ngModel)]="rwtData.end_date" name="end_date"
                  (ngModelChange)="onDateSelectedEnd($event);" formControlName="end_date" class="form-control" id="">
                <div *ngIf="isErrorMsgBoolCheckDateValidity">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    {{isErrorMsgForDateValidity}}
                  </div>
                </div>
                <!-- <div *ngIf="isFieldValid('cost_per_hour')">
                  <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                  <span class="sr-only">(error)</span>
                  <div class="error-msg">
                    Please enter the baseline rate
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row sidebtngroup text-center">
            <div class="col">
              <!-- <p-button type="button" (click)="SaveRWT()" label="Save" icon="fa fa-floppy-o"
                styleClass="p-button-success btn4"> </p-button> -->
              <p-button type="button" (click)="SubmitRWT()" label="Submit" icon="fa fa-paper-plane"
                styleClass="p-button-success btn1 ml-3"> </p-button>
              <p-button type="button" (click)="visibleSidebar2 = false" label="Cancel" icon="fa fa-times"
                styleClass="p-button-secondary ml-3"></p-button>
            </div>
          </div>
        </form>
      </p-sidebar>
    </div>

    <div class="ctm-card">
      <p-table class="bordertop" [value]="manageRWTDataTable" #dt [paginator]="true" [autoLayout]="true" [rows]="10"
        [rowsPerPageOptions]="[5,10,15,20]" pageLinks="3"
        [globalFilterFields]="['rwt_name','level_id','location_id','cost_per_hour','r_status']">
        <ng-template pTemplate="caption">
          <div class="d-flex ctmfilter align-items-end">
            <div>
              <label class="d-block text-left" for="exampleFormControlSelect1">RWT</label>
              <select [ngModel]="rwt" [formControl]="rwtControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let rwt of rwts" value="{{rwt.rwt_name}}">
                  {{ rwt.rwt_name }}
                </option>
              </select>
            </div>

            <div class=" ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Level</label>
              <select [ngModel]="level" [formControl]="levelControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let level of levels" value="{{level.level_name}}">
                  {{ level.level_name}}
                </option>
              </select>

            </div>

            <div class="ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Location</label>
              <select [ngModel]="location" [formControl]="locationControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let location of locations" value="{{location.location_name}}">
                  {{ location.location_name}}
                </option>
              </select>
            </div>

            <div class="ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Status</label>
              <select [(ngModel)]="status" [formControl]="statusControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option value='Submitted'>Submitted</option>
                <option value='Draft'>Draft</option>
              </select>
            </div>


            <button class="btn cstbtn btn1  ml-3 mb-1"
              (click)="onSearch(rwtControl.value,levelControl.value,locationControl.value)">
              <i class="fa fa-filter" aria-hidden="true"></i> Apply filters
            </button>
            <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                aria-hidden="true"></i> Reset Filters</div>

            <div class="ml-auto bl-1">
              <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <!-- <th pSortableColumn="resource_email">Resource Email<p-sortIcon field="resource_email"></p-sortIcon>
                </th> -->
            <th></th>
            <th pSortableColumn="rwt_name">RWT<p-sortIcon field="rwt_name"></p-sortIcon>
            </th>
            <th pSortableColumn="level_id">Level<p-sortIcon field="level_id"></p-sortIcon>
            </th>
            <th pSortableColumn="location_name">Location<p-sortIcon field="location_name"></p-sortIcon>
            </th>
            <th>Cost Per Hr($)</th>
            <th pSortableColumn="r_status">Status<p-sortIcon field="r_status"></p-sortIcon>
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rwtDataItem let-rowIndex="rowIndex">
          <tr>
            <td><i [ngClass]="getChevronIconClass(rwtDataItem)" (click)="toggleRow(rwtDataItem, rowIndex)"></i></td>
            <td>{{ rwtDataItem.rwt_name }}</td>
            <!-- <td>{{ item.resource_email }}</td> -->
            <td>{{ rwtDataItem.level_id }}</td>
            <td>{{ rwtDataItem.location_name }}</td>
            <td>{{rwtDataItem.latest_cost_per_hr}}</td>
            <td [ngStyle]="{'color':rwtDataItem.r_status === 'Submitted' ? 'green' : 'red'}">{{ rwtDataItem.r_status }}
            </td>
            <!-- <td *ngIf="rwtDataItem.r_status=='Draft'"><span (click)="EditRWTPopup(rwtDataItem)"><i
                  tooltipPosition="left" pTooltip="Click here to edit" class="fa faedit fa-pencil-square-o"
                  aria-hidden="true"></i></span></td>
            <td *ngIf="rwtDataItem.r_status=='Submitted'"><span (click)="EditRWTPopup(rwtDataItem)"></span></td> -->
            <!-- <td>{{ item.fte }} <span *ngIf="item.fte!==''">%</span></td>
              <td [ngStyle]="{'color':item.status === 'Submitted' ? 'green' : 'red'}">{{ item.status }}</td>
              <td>{{ item.remarks }}</td> -->
          </tr>
          <tr *ngIf="isExpanded(rwtDataItem)" class="expanded-row">
            <td [attr.colspan]="columnCount">
              <div class="expanded-content">
                <table class="expanded-table">
                  <thead>
                    <tr>
                      <th>RWT Name</th>
                      <th>Level</th>
                      <th>Location</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Cost Per Hr($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let detail of rwtDataItem.details; let i = index">
                      <tr>
                        <td>{{ detail.rwt_name }}</td>
                        <td>{{ detail.level_name }}</td>
                        <td>{{ detail.location_name }}</td>
                        <td>{{ detail.start_date }}</td>
                        <td>
                          {{ checkDateValidations(detail.end_date) }}
                        </td>
                        <td>{{detail.cost_per_hr}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>