<ng-template #customLoadingTemplate>
    <div class="ctmloader">
        <img src="./../../../assets/images/loader.svg">
    </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
    <div class="container-fluid pt-3">
        <div class="ctm-body">
            <div class="d-flex mb-1 align-items-center">
                <h4>Set Evaluator for SPDM</h4>

                <!-- <div class="ml-auto">
                    <button type="button" (click)="exportExcel()" selectionMode="multiple"
                        class="btn cstbtn btn2 ml-2 mb-1"><i class="fa fa-download" aria-hidden="true"></i> Download
                        this report as Excel</button>
                </div> -->
            </div>


            <div class="ctm-card headerwrap">
                <ng-container>
                    <p-table class="bordertop" [value]="reports" #dt dataKey="row_id" [paginator]="true"
                        (onRowExpand)="onRowExpand()" [rows]="10" (onRowCollapse)="onRowCollapse()" [columns]="cols"
                        [expandedRowKeys]="expandedRows" [rowsPerPageOptions]="[5,10,15,20]"
                        [totalRecords]="reports.length" pageLinkSize="3" (onPage)="onPage($event)"
                         [customSort]="false"
                        [globalFilterFields]="['delivery_manager','department_name','rwt_name']">
                        <ng-template pTemplate="caption">
                            <div class="d-flex ctmfilter align-items-end">
                                <div>
                                   <label for="dtPicker" id="lbldtpicker"
                                      class="d-block text-left control-label mr-2">Clients</label>
                                    <select [ngModel]="client" [formControl]="clientControl" style="width: 120px;" class="form-control"
                                      id="exampleFormControlSelect1" (change)="onClientChange(clientControl.value,$event)">
                                      <option value='All' selected>All</option>
                                      <option *ngFor="let client of clients" value="{{client.client_name}}">
                                        {{client.client_name }}
                                      </option>
                                    </select>
                                </div>
                                <div class="ml-3">
                                   <label class="d-block text-left" for="exampleFormControlSelect1">Projects</label>
                                   <select [ngModel]="project" [formControl]="projectControl" style="width: 120px;" class="form-control"
                                      id="exampleFormControlSelect1">
                                      <option value='All' selected>All</option>
                                      <option *ngFor="let project of projects" value="{{project.project_name}}">
                                        {{project.project_name }}
                                      </option>
                                    </select>
                                </div>
                                <div class="ml-3">
                                    <label class="d-block text-left" for="exampleFormControlSelect1">Evaluator</label>
                                    <select [ngModel]="evaluatorType" [formControl]="evaluatorTypeControl" style="width: 120px;" class="form-control"
                                       id="exampleFormControlSelect1" >
                                       <option value='All' selected>All</option>
                                       <option *ngFor="let evaluatorType of evaluatorTypes" value="{{evaluatorType.evaluator}}">
                                         {{evaluatorType.evaluator ===null?'Delivery Partner':evaluatorType.evaluator }}
                                       </option>
                                     </select>
                                </div>
                                <button type="button" class="btn cstbtn btn1 ml-3 mb-1" (click)="filterChange(clientControl.value,projectControl.value,evaluatorTypeControl.value)"><i
                                      class="fa fa-filter" aria-hidden="true"></i> Apply filters</button>
                                <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;"
                                      class="fa fa-times red" aria-hidden="true"></i> Reset Filters</div>
                                <div class="ml-auto  bl-1">
                                   <p style="padding-bottom: 10px;">Search the table below using this free text search
                                   </p>
                                   <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                   <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                                      (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                                </div>
                             </div>

                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width: 5em">
                                    <a style="cursor: pointer;font-size: 16px; color:teal;" (click)="expandAll()">
                                        <i *ngIf="!isExpanded" style="font-size: 20px;" class="fa fg fa-plus-circle"
                                            aria-hidden="true"></i>
                                        <i *ngIf="isExpanded" style="font-size: 20px;" class="fa fg fa-minus-circle"
                                            aria-hidden="true"></i>
                                    </a>
                                </th>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                            <tr [class]="expanded ? 'row-active-header' : ''">
                                <td class="chevdownup">
                                    <a href="#" [pRowToggler]="rowData">
                                        <i [class]="expanded ? 'pi fc pi-chevron-up' : 'pi fc pi-chevron-down'"></i>
                                    </a>
                                </td>
                                <td *ngFor="let col of columns">
                                    <ng-container *ngIf="col.field === 'delivery_manager'">
                                        <a href="#" [pRowToggler]="rowData">
                                            {{rowData[col.field]}}
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="col.field !== 'delivery_manager'">
                                        {{rowData[col.field]}}
                                    </ng-container>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-rowData let-expanded="expanded" let-columns="columns">
                            <tr class="row-active-body">
                                <td [attr.colspan]="columns.length + 1" class="no-pad p-0">
                                    <div class="p-p-3">
                                        <table class="table m-0 innerTable"
                                            *ngIf="rowData['projects'].length > 0 && rowData['projects']['project_name']!==null">
                                            <thead class="innerHeader">
                                                <tr>
                                                    <th>Client</th>
                                                    <th>Project</th>
                                                 
                                                    <th>Engagement Partner</th>
                                                    <th>Engagement Manager</th>
                                                    <th>Evaluator</th>
                                                <tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let row of rowData['projects']" class="innerBody">
                                                    <td>{{row['client_name']}}
                                                           
                                                    </td>
                                                    <td>{{row['project_name']}}
                                                        <p>({{row['sub_project_id']}})</p>
                                                    </td>
                                                    <td>{{row['engagement_partner']}}</td>
                                                    <td>{{row['engagement_manager']}}</td>
                                                    <td>
                                                        <!-- <select [(ngModel)]="row['delivery_manager']">
                                                        <option value="1">Delivery Partner</option>
                                                    </select> -->
                                                    <div class="dropdown">
                                                        <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         Delivery Partner
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                          <a class="dropdown-item" >Engagement Manager</a>
                                                          <a class="dropdown-item" >Engagement Panager</a>
                                                        </div> -->
                                                        <select [(ngModel)]="row.evaluator" class="form-control"
                                                        (change)="onEvaluatorChange(row,$event)">
                                                            <option value="Delivery Partner" >Delivery Partner</option>
                                                            <option *ngIf="row.engagement_manager!==null && row.engagement_manager_id !== rowData['delivery_manager_id'] && row.engagement_manager_id !== rowData['delivery_partner_id']"  value="Engagement Manager">Engagement Manager</option>
                                                            <option *ngIf="row.engagement_partner_id !== row['delivery_partner_id'] && row.engagement_partner_id !== rowData['delivery_manager_id']"  value="Engagement Partner">Engagement Partner</option>
                                                         </select>
                                                      </div>
                                                </td>
                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style="color: #ed4d4d;padding: 1rem;" *ngIf="rowData['projects'].length===0 ">
                                        No Projects Allocated for the Month
                                    </div>
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
            </div>
            <div class="col d-flex align-items-center">
                <!-- <label style="color: #5e94ff;cursor: pointer;" (click)="showDialog()" icon="pi pi-info-circle" label="Show"> Overall Comments/Remarks/Notes</label> -->
                  <div class="ml-auto">
                     <button  style="font-size: 14px;"  *ngIf="updateFlag==true"
                     class="btn fxdbtn cstbtn btn4 ml-2 bounce" (click)="saveButtonDialog()">
                     <i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp; Save Changes</button>
                  </div>
                  <!-- <label *ngIf="mandatoryFlag" class="mandatory">please Fill in all mandatory Field</label>
                  <label *ngIf="duplicateFlag" class="mandatory">Please Fill  Remove Duplicate Data </label> -->
               </div>
        </div>
    </div>
</div>