<ng-template #customLoadingTemplate>
  <div class="ctmloader">
     <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<p-toast></p-toast>
<div class="container-fluid pt-3">
  <div *ngIf="projectDetail">
     <div class="row d-flex align-items-center">
        <div class="col-4">
           <div style="padding-left: 17px;">Update Project Status for</div>
           <h4 style="border-left: 3px solid #F38425;padding-left: 13px;">{{ projectDetail.project_name }}</h4>
        </div>
        <div class="col">
           <div>Client</div>
           <label class="fc">{{ projectDetail.client_name }}</label>
        </div>
        <div class="col">
           <div>Project Code</div>
           <label class="fc">{{ projectDetail.project_code }}</label>
        </div>
        <div class="col">
           <div>Start Date</div>
           <label class="fc">{{ projectDetail.start_date|date:'mediumDate' }}</label>
        </div>
        <div class="col">
           <div>End Date</div>
           <label class="fc">{{ projectDetail.end_date|date:'mediumDate' }}</label>
        </div>
        <div class="col-1 ">
           <div style="font-size: 40px;color: #5e94ff;text-align: right;">
              <i style="position: relative; cursor: pointer;" (click)="changeStatus()" class="fa fa-info-circle"
                 aria-hidden="true"></i>
           </div>
           <div class="ctmside">
              <p-sidebar [modal]="false" [(visible)]="visibleStatus" position="right" [baseZIndex]="10000">
              <h5 style="font-weight:normal;border-left: 3px solid #F38425;padding-left: 7px;">RAG status of your project
              </h5>
              <div class="datapoint">
                 <div style="padding: 15px; font-size: 15px;">
                    <p><i style="color: #ed4d4d; font-size: 25px;" class="fa fa-circle" aria-hidden="true"></i> &nbsp;Red -
                       "Attention!"
                       <br>Serious issues, dates being missed, recovery plan required.
                    </p>
                    <p><i style="color:  #f9b538; font-size: 25px;" class="fa fa-circle mt-2" aria-hidden="true"></i> &nbsp;
                       Amber - "Issues , but managed "
                       Some issues, being managed,but needs to be closely monitored.
                    </p>
                    <p> <i style="color:#679c29; font-size: 25px; " class="fa fa-circle mt-2" aria-hidden="true"></i> &nbsp;
                       Green - "On Track" <br>All is well!, project is on track.
                    </p>
                 </div>
              </div>
              </p-sidebar>
           </div>
        </div>
     </div>
  </div>
</div>
<div style="min-height: 80vh;" class="back pt-3">
  <div class="container-fluid">
     <div class="statuslabel mt-3">
        <div class="row">
           <div class="col-1">
              <label>Choose Week :
              </label>
           </div>
           <div class="col-4 ctmcalender">
              <p-calendar #dateSelector [maxDate]="maximunDate" placeholder="Click to choose week of update" readonlyInput="true" [(ngModel)]="values"
              selectionMode="range" (onSelect)="select($event)" [showIcon]="true"  icon="fa fa-calendar-o"></p-calendar>
              <!-- <label style="font-size: 15px;">Date of Last Update:  </label> -->
           </div>
        </div>
        <div class="row mt-3">
           <div class="col-1">
              <label> Overall Status : </label>
           </div>
           <div class="col-4">
              <select (change)="onDropdownChange($event)" [ngStyle]="{'color':selectedColor}"
              style="background-color: #fff; font-weight: bold;font-size: 15px;" class="form-control cstm-form" [(ngModel)]="projectStatusObj.status">
              <option style="font-weight: bold; font-size: 15px;" *ngFor="let color of options" [value]="color.name" color="color.color"
              [ngStyle]="{'color':color.color}">{{color.name}}</option>
              </select>
           </div>
        </div>
        <div class="row mt-3">
           <div class="col-1">
              <label>Upload File : </label>
           </div>
           <div class="col-4">
              <div class="ctmfile">
                 <input class="ctmfilechild" type="file" (change)="onChange($event)">
              </div>
              <ng-container *ngIf="isFileDownload">
                  <span style="cursor: pointer;color: #5e94ff;margin-top: 5px;display: inline-block;"><a (click)="onDownloadClick()">
                     Download Status File
                  </a></span>
              </ng-container>
           </div>
        </div>
        <div class="row mt-3">
           <div class="col-1">
              <label>Remarks : </label>
           </div>
           <div class="col-4">
              <textarea maxlength="500" placeholder="Remarks are mandatory when project statsus is not 'On Track' (GREEN)" class="form-control"
              [(ngModel)]="projectStatusObj.Remarks" rows="3"></textarea>
           </div>
        </div>
     </div>
     <div class="row mt-3">
        <div class="col-1">
        </div>
        <div class="col-4 justify-content-center d-flex">
           <button style="border-radius: 30px; width: 50%;background-color: #627d44;" class="btn btn4"
              (click)="submit()"><i class="fa fa-file-o" aria-hidden="true"></i>&nbsp; Submit Project Status </button>
           <div style="width: 50%;" >
              <div class="clrfilter"   style="height: 100%; padding-bottom: 0px; display: flex; align-items: center;" (click)="Reset()">
                 <i style="color: #ed4d4d; margin-right: 5px;" class="fa fa-times red" aria-hidden="true"></i>Cancel
              </div>
              <!-- <button
                 style="padding: 7px 15px 4px 15px !important;border-radius: 30px; background-color: #ed4d4d;color: #fff;    min-width: 9.5rem;"
                 class="btn ml-3" (click)="Reset()"><i class="fa fa-times-circle" aria-hidden="true"></i> Cancel </button> -->
           </div>
        </div>
     </div>
  </div>
</div>