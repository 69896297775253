import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Resource, TimesheetSubmissionService } from 'src/app/_services/timesheet-submission.service';
import { AdminService, RwtModel } from 'src/app/_services/admin.service';
import { SharedService } from 'src/app/_services/shared.service';
import { ProjectService } from 'src/app/_services/project.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.scss']
})
export class HRComponent implements OnInit {
  @ViewChild("fileInput") fileInput;
  loading = false;
  visibleSidebar2: boolean = false;
  isCompleted: boolean = false;
  resourceRequestForm: FormGroup;
  submitted = false;

  clientDropDown: any[];
  projectDropDown: any[];
  clients: any[];
  projects: any[];
  clientSelected = '';
  projectSelected = '';
  dmSelected = '';
  epSelected = '';
  rwtSelected = '';
  levelSelected = '';
  requirement_details = '';
  requirement_description = '';
  additional_skill = '';
  primary_skill = '';
  no_of_resourcesRequired = '';
  allocationRequired = '';
  subprojTblData: any[];
  subprojData: any;
  timesheetDataObj: any = {};
  start_date = '';
  end_date = '';
  HrSelected = '';

  rwt: any = 'All';
  rwtItem: any[];
  level: any = 'All';
  location: any = 'All';
  status: any = 'All';
  rwts: any[];
  levels: any[];
  locations: any[];
  manageRWTData: RwtModel[];
  deliveryManagers: any[];
  engagementPartner: any[];
  engagementPartnerDropDown: any[];
  deliveryManagerDropDown: any[];
  skillList: any;
  skillSelected: any = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  resourceRequestTable: any[];
  isAdd = true;
  subDeptData: any[];
  coeSelected: any = '';
  resourceAvailablity: any = '';
  requestId: any = '';
  mgntStatus: any = '';
  mgntRemarks: any = '';
  roleName = '';
  userRole: any;
  resource: any;
  departments: any;
  duration: string;
  dynamicCount = 0;
  dynamicRow = [];
  // dynamicPnlType=[]
  // dynamicPnlResource=[]
  // dynamicPnlFeedback=[]

  public cols: any[];
  public isExpanded: boolean = false;
  public rows: number = 10;
  public expandedRows = {};
  public temDataLength: number = 0;
  resourceArray: any = [];
  hrResourceArray: any = [];
  resourceDropDown: any = [];
  downloadResumeUrl: any;
  resume: any;
  fileSource: any = '';
  // panelList: FormGroup;





  department: any = 'All';
  requestStatus: any = 'All';
  engagementFilter: any = 'All';
  requestStatusControl = new FormControl();
  departmentControl = new FormControl();
  engagementControl = new FormControl();
  requestStatusList = [
    { status: "Approval for Hiring" },
    { status: "Approved for Hiring" },
    { status: "Assigned Internal Resource" },
    { status: "Hiring" },
    { status: "Allocation Analysis" },
    { status: "On Hold" },
    { status: "Rejected by PDM" },
    { status: "Requested" },
    { status: "Request Completed"}
  ];
  engagementList = [
    {engagement:"Engagement Team 1"},
    {engagement:"Engagement Team 2"},
    {engagement:"Engagement Team 3"},
    {engagement:"Engagement Team 4"},
    {engagement:"Engagement Team 5"},
    {engagement:"Engagement Team 6"},
    {engagement:"Engagement Team 7"}];
  deliveryfunctions = [];
  practices : any[];
  // deliveryfunctions = [
  //   { department_name: "Digital Engineering" },
  //   { department_name: "Decision Science" },
  //   { department_name: "Data Analytics" },
  //   { department_name: "Quality Engineering" },
  // ];
  tempResourceDropdown: any = [];
  // technicalResourceDropDown: any = [];
  technicalResource_1_DropDown = [];
  technicalResource_2_DropDown = [];

  constructor(private fb: FormBuilder, private http: HttpClient, private tsService: TimesheetSubmissionService, private adminService: AdminService,
    private projectservice: ProjectService, private storage: StorageService,private sharedService:SharedService, private cd: ChangeDetectorRef) {
  }

  get resourceForm() { return this.resourceRequestForm.controls; }
  get panelList() { return this.resourceForm.panel_list as FormArray; }
  get panelListGroups() { return this.panelList.controls as FormGroup[]; }

  addPanelList(): void {
    this.panelList.push(this.fb.group({
      dynamicPnlType: '',
      dynamicPnlResource: '',
      dynamicPnlFeedback: ''
    }));
    // this.isTechnicalPanel[]
    // this.dynamicCount += 1;
  }

  deleteRow(index: number) {
    this.panelList.removeAt(index);
  }

  ngOnInit(): void {
    this.roleName = this.storage.getItem('selectedRole');
    // console.log("roleName", this.roleName);

    this.resourceRequestForm = this.fb.group({
      requirement_details: [{ value: null, disabled: true }, Validators.required],
      requirement_description: [{ value: null, disabled: true }, Validators.required],
      requirement_type: [{ value: null, disabled: true }, Validators.required],
      backfill_name:[{value: null,disabled:true}, Validators.required],
      client_id: [{ value: null, disabled: true }, Validators.required],
      project_id: [{ value: null, disabled: true }, Validators.required],
      primary_skill: [{ value: null, disabled: true }, Validators.required],
      additional_skill: '',
      practice: [{ value: null, disabled: true }, Validators.required],
      delivery_manager_id: [{ value: null, disabled: true }, Validators.required],
      engagement_partner_id: [{ value: null, disabled: true }, Validators.required],
      rwt: [{ value: null, disabled: true }, Validators.required],
      level: [{ value: null, disabled: true }, Validators.required],
      skillset: [{ value: null, disabled: true }, Validators.required],
      allocation_required: [{ value: null, disabled: true }, Validators.required],
      no_of_resource: [{ value: null, disabled: true }, Validators.required],
      resource_availability: [{ value: null, disabled: true }, Validators.required],
      coe: [{ value: null, disabled: true }, Validators.required],
      status: [null, Validators.required],
      remarks: [{ value: null, disabled: true }, Validators.required],
      priority: [{ value: null, disabled: true }, Validators.required],
      start_date: [{ value: null, disabled: true }, Validators.required],
      end_date: [{ value: null, disabled: true }, Validators.required],
      request_id: [{ value: null, disabled: true }, Validators.required],
      hr_spoc: [null, Validators.required],
      recruiter: [null, Validators.required],
      canditateName: [null, Validators.required],
      candidate_status: '',
      hr_mapping_id: null,
      resume: null,
      offered: [null, Validators.required],
      salary:[[2,50]],
      doj: [''],
      panel_list: new FormArray([])
    })

    // this.panelList.valueChanges.subscribe(() => {
    //   this.cd.detectChanges()
    // })

    this.loading = true;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'skill_master_id',
      textField: 'skill_name',
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    // this.tsService.getResources().then((resources) => {
    //   const uniqueResource = [];
    //   resources.map(x => uniqueResource.filter(a => a.resource_id === x.resource_id).length > 0 ? null : uniqueResource.push(x));
    //   // this.clients = uniqueResource;

    //   uniqueResource.map(data => {
    //     this.resourceDropDown.push({ 'label': data.resource_name, 'value': data.resource_id });
    //     this.multipleResourceDropdown.push({ 'label': data.resource_name, 'value': data.resource_id });
    //   });
    //   this.resourceArray = resources.filter(resource => resource.department_name === 'HR')
    //   this.recruiterArray = resources.filter(resource => resource.department_name === 'HR')
    //   this.resourceDropDown.splice(0, 0, { 'label': 'Select Resource', 'value': 'Select Resource' });
    //   // this.resourceSelected = 'Select Resource';
    // });

    this.tsService.getResources().then((resources) => {
      const uniqueResource = [];
      resources.map(x => uniqueResource.filter(a => a.resource_id === x.resource_id).length > 0 ? null : uniqueResource.push(x));

      uniqueResource.map(data => {
        this.resourceDropDown.push({ 'label': data.resource_name, 'value': data.resource_id });
      });
      this.resourceArray = uniqueResource.filter(resource => resource.department_name === 'HR')
      this.hrResourceArray = this.resourceArray;
      this.hrResourceArray.splice(0, 0, { 'resource_name': 'Select Resource', 'resource_id': '' });
      this.resourceDropDown.splice(0, 0, { 'label': 'Select Resource', 'value': 'Select Resource' });
      this.tempResourceDropdown = this.resourceDropDown
      // this.resourceSelected = 'Select Resource';
    });

    this.tsService.getSubprojects().then((subproj) => {
      this.subprojData = subproj;
      this.subprojData.map(data => {
        if (!this.timesheetDataObj[data.sub_project_id]) {
          this.timesheetDataObj[data.sub_project_id] = { data };
        }
      });

      this.getClients(this.subprojData);
      // this.getManageRWTData();
      // this.getSkillMasterList();
      this.loading = false;

    }).catch((err) => {
      this.subprojData = [];
      this.loading = false;
    });

    let formData = {};
    formData['postBody'] = { "opType": 3, "rolename": this.roleName }
    formData['type'] = 'get'
    this.projectservice.projectResourceRequest(formData).then(
      response => {
        const tempData = response['body']
        // const tempData = response['body'].filter(data=>{ data.hr_mapping_id === })
        let tempObj = {}
        tempData.map(data => {
          data['skill_name'] = data['skill_name'].replaceAll(',', ', ')
          if (!tempObj[data['request_id']]) {
            const resourceData = {}
            tempObj[data['request_id']] = {}
            tempObj[data['request_id']] = data
            tempObj[data['request_id']]['requestedresource'] = []
            tempObj[data['request_id']]['rwts'] = data['rwt_name'] + ' - ' + data['level']
            tempObj[data['request_id']]['client_name'] = data['client_name'] == null?'NA':data['client_name']
            tempObj[data['request_id']]['project_name'] = data['project_name'] == null?'NA':data['project_name']
            resourceData['resume'] = data['resume']
            resourceData['resource_availability'] = data['resource_availability']
            resourceData['remarks'] = data['remarks']
            resourceData['priority'] = data['priority']
            resourceData['candidate_status'] = data['candidate_status']
            resourceData['Technical_Round_1'] = data['Technical_Round_1']
            resourceData['Technical_Round_2'] = data['Technical_Round_2']
            resourceData['Manager'] = data['Manager']
            resourceData['HR_panel'] = data['HR_panel']
            resourceData['offered'] = data['offered']
            resourceData['doj'] = data['doj']            
            resourceData['salary'] =  [data['min_salary'],data['max_salary']],
            resourceData['hr'] = data['hr']
            resourceData['recruiter_name'] = data['recruiter_name']
            resourceData['name'] = data['name']
            resourceData['hr_mapping_id'] = data['hr_mapping_id']
            if (data['hr_mapping_id'])
              tempObj[data['request_id']]['requestedresource'].push(resourceData)
           
          } else {
            const resourceData = {}
            resourceData['resume'] = data['resume']
            resourceData['resource_availability'] = data['resource_availability']
            resourceData['remarks'] = data['remarks']
            resourceData['priority'] = data['priority']
            resourceData['offered'] = data['offered']
            resourceData['doj'] = data['doj']            
            resourceData['salary'] =  [data['min_salary'],data['max_salary']],
            resourceData['hr'] = data['hr']
            resourceData['recruiter_name'] = data['recruiter_name']
            resourceData['Technical_Round_1'] = data['Technical_Round_1']
            resourceData['Technical_Round_2'] = data['Technical_Round_2']
            resourceData['Manager'] = data['Manager']
            resourceData['HR_panel'] = data['HR_panel']
            resourceData['candidate_status'] = data['candidate_status']
            resourceData['name'] = data['name']
            resourceData['hr_mapping_id'] = data['hr_mapping_id']
            if (data['hr_mapping_id'])
              tempObj[data['request_id']]['requestedresource'].push(resourceData)
          }
          return data;
        })
        // console.log("resourceRequestTable", tempObj)
        this.resourceRequestTable = Object.values(tempObj)
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        this.resourceRequestTable = [];
      });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'skill_master_id',
      textField: 'skill_name',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.userRole = this.storage.getItem('selectedRole');
    this.resource = JSON.parse(this.storage.getItem('user'));
    this.departments = JSON.parse(this.storage.getItem('departments'));
    this.duration = '0';
    this.departments.map(department => {
      this.departments[department.department_id] = { 'name': department.department_name };
    });
    //  this.filterChange();



    this.cols = [{
      field: 'request_id',
      header: 'Request ID'
    },
    {
      field: 'client_name',
      header: 'Client Name'
    },
    {
      field: 'project_name',
      header: 'Project Name'
    },
    {
      field: 'start_date',
      header: 'From Date'
    },
    {
      field: 'end_date',
      header: 'To Date'
    },
    {
      field: 'rwts',
      header: 'RWT-(LEVEL)'
    },
    {
      field: 'requirement_details',
      header: 'Requirement Title'
    },
    {
      field: 'requirement_type',
      header: 'Requirement Type'
    },
    {
      field: 'skill_name',
      header: 'Primary Skills'
    },
    // {
    //   field: 'additional_skill',
    //   header: 'Additional Skill'
    // },
    {
      field: 'practice_name',
      header: 'Practice'
    },
    {
      field: 'requestor_name',
      header: 'Requested By'
    },
    {
      field: 'status',
      header: 'Status'
    },
    {
      field: 'action',
      header: 'Action'
    }
    ];

    this.resourceRequestTable?.length < this.rows ? this.temDataLength = this.resourceRequestTable?.length : this.temDataLength = this.rows;
    this.getDepartment();
  }

  onFileSelected(event) {
    // console.log("file", event);
    if (event.target.files.length > 0) {
      this.resourceRequestForm.patchValue({
        fileName: event.target.files[0]['name'],
      })
    }
  }


  expandAll() {
    if (!this.isExpanded) {
      this.resourceRequestTable.forEach(data => {
        this.expandedRows[data.request_id] = true;
      });
    } else {
      this.expandedRows = {};
    }
    this.isExpanded = !this.isExpanded;
  }

  onRowExpand() {
    if (Object.keys(this.expandedRows).length === this.temDataLength) {
      this.isExpanded = true;
    }
  }

  onRowCollapse() {
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

  onPage(event: any) {
    this.temDataLength = this.resourceRequestTable.slice(event.first, event.first + 10).length;
    this.isExpanded = false;
    this.expandedRows = {};
  }


  clientChange(ev) {
    this.projectDropDown = [];
    this.subprojTblData = [];
    this.projectDropDown.push({ 'label': 'Select Project', 'value': '' });
    let selectedValue = Number(ev.target.value)
    this.projects.map(x => {
      if (ev.target.value === '') {
        this.projectDropDown.push({ 'label': x.name, 'value': x.id });
      } else if (x.client_id === selectedValue) {
        this.projectDropDown.push({ 'label': x.name, 'value': x.id });
      }
    });

  }
  projectChange(ev) {

    let selectedValue = Number(ev.target.value)
    this.engagementPartner.map(x => {
      if (!selectedValue) {
        this.epSelected = '';
      }
      if (x.project_id === selectedValue) {
        this.epSelected = x.id;
      }
    });
    this.deliveryManagers.map(x => {
      if (!selectedValue) {
        this.dmSelected = ''
      }
      if (x.project_id === selectedValue) {
        this.dmSelected = x.id;
      }

    });
  }

  getManageRWTData() {
    this.rwts = [];
    this.levels = [];
    this.adminService.getManageRWT().then(resourceData => {
      resourceData = resourceData.filter(resource => resource.r_status === 'Submitted')
      this.rwts = resourceData.filter(
        (thing, i, arr) => arr.findIndex(t => t.rwt_name === thing.rwt_name) === i
      ).sort((a, b) => a.rwt_name.localeCompare(b.rwt_name));
      this.levels = resourceData.filter(
        (thing, i, arr) => arr.findIndex(t => t.level_name === thing.level_name) === i
      ).sort((a, b) => a.level_name.localeCompare(b.level_name));
    }).catch((err) => {
      this.manageRWTData = [];
    });



  }

  getClients(dataObj) {
    const clientsData = [];
    const ProjectsData = [];
    const DeliveryManagerData = [];
    const EngagementPartnerData = [];
    this.clientDropDown = [];
    this.clientDropDown.push({ 'label': 'Select Client', 'value': '' });
    this.projectDropDown = [];
    this.projectDropDown.push({ 'label': 'Select Project', 'value': '' });
    this.engagementPartnerDropDown = [];
    this.engagementPartnerDropDown.push({ 'label': 'Select Engagement Partner ', 'value': '' });
    this.deliveryManagerDropDown = [];
    this.deliveryManagerDropDown.push({ 'label': 'Select Delivery Manager', 'value': '' });
    dataObj.map(data => {
      clientsData.push({ 'id': data.client_id, 'name': data.client_name });
      ProjectsData.push({ 'id': data.project_id, 'name': data.project_name, 'client_id': data.client_id });
      DeliveryManagerData.push({ 'id': data.delivery_manager_id, 'name': data['Delivery Manager'], 'project_id': data.project_id })
      EngagementPartnerData.push({ 'id': data.engagement_partner_id, 'name': data['Engagement Manager'], 'project_id': data.project_id })
    });

    const uniqueClient = [];
    clientsData.map(x => uniqueClient.filter(a => a.id === x.id).length > 0 ? null : uniqueClient.push(x));
    this.clients = uniqueClient;

    this.clients.map(x => {
      this.clientDropDown.push({ 'label': x.name, 'value': x.id });
    });

    const uniqueProject = [];
    ProjectsData.map(x => uniqueProject.filter(a => a.id === x.id).length > 0 ? null : uniqueProject.push(x));
    this.projects = uniqueProject;
    this.projects.map(x => {
      this.projectDropDown.push({ 'label': x.name, 'value': x.id });
    });

    const uniqueDeliveryManger = [];
    let uniqueDeliveryMangerDropdown = [];
    DeliveryManagerData.map(x => uniqueDeliveryManger.filter(a => a.project_id === x.project_id).length > 0 ? null : uniqueDeliveryManger.push(x));
    this.deliveryManagers = uniqueDeliveryManger;
    this.deliveryManagers.map(x => {
      this.deliveryManagerDropDown.push({ 'label': x.name, 'value': x.id });
    });
    this.deliveryManagerDropDown.map(a => uniqueDeliveryMangerDropdown.filter(x => a.value === x.value).length > 0 ? null : uniqueDeliveryMangerDropdown.push(a));

    const uniqueEngagementPartner = [];
    let uniqueEngagementPartnerDropdown = [];
    EngagementPartnerData.map(x => uniqueEngagementPartner.filter(a => a.project_id === x.project_id).length > 0 ? null : uniqueEngagementPartner.push(x));
    this.engagementPartner = uniqueEngagementPartner;
    this.engagementPartner.map(x => {
      this.engagementPartnerDropDown.push({ 'label': x.name, 'value': x.id });
    });
    this.engagementPartnerDropDown.map(a => uniqueEngagementPartnerDropdown.filter(x => a.value === x.value).length > 0 ? null : uniqueEngagementPartnerDropdown.push(a));
    this.deliveryManagerDropDown = uniqueDeliveryMangerDropdown;
    this.engagementPartnerDropDown = uniqueEngagementPartnerDropdown;
    this.getManageRWTData();
    this.getSkillMasterList();
    // this.loading = false;
  }

  getSkillMasterList() {

    this.adminService.getSkillList().then(skillData => {
      this.skillList = skillData.sort((a, b) => a.skill_name.localeCompare(b.skill_name));
    }).catch(() => {
      this.skillList = [];
      this.loading = false;
    })
  }

  // getSubDepartment() {
  //   this.sharedserv.getSubDepartmentData().then(subdeptData => {
  //     this.subDeptData = subdeptData
  //     // this.loading = false;
  //   }).catch(() => {
  //     this.loading = false;
  //     this.subDeptData = [];
  //     // this.skillLists = [];

  //   })

  // }

  onItemSelect(item: any) {

  }
  onSelectAll(item: any) {
    this.skillSelected = this.skillList.map(x => x.skill_name);
    // console.log('this.selectedIds',this.selectedIds);
    // this.resetTable();
  }
  onDeSelectAll() {
    this.skillSelected = [];
  }
  onItemDeSelect(item: any) {
    // console.log('this.selectedIds',this.selectedIds);

  }

  //Add resource Button
  updateResourceRequest(item) {
    // this.visibleSidebar2 = true;
    this.loading = true;
    this.isAdd = true;
    this.submitted = false;
    this.technicalResource_1_DropDown = [];
    this.technicalResource_2_DropDown = [];

    let skillArray = item.skill_id.split(/[ ,]+/).map(Number)
    this.skillSelected = []
    let skills = this.skillList.filter(x => skillArray.includes(x.skill_master_id));
    skills.map(skill => {
      this.skillSelected.push({ "skill_master_id": skill.skill_master_id, "skill_name": skill.skill_name })
      return skill
    })
    this.panelList.clear();

    let technicalDataForm = {};
    technicalDataForm['postBody'] = { "opType": 5, "request_id": item.request_id }
    technicalDataForm['type'] = 'get'
    this.projectservice.projectResourceRequest(technicalDataForm).then(
      response => {
        // console.log("get response", response);
        const tempData = response['body'];
        tempData.map(data => {
          if(data.panel_type==="techincal round 1"){
            this.technicalResource_1_DropDown.push({ 'label': data.technical_panel_name, 'value': data.resource_id });
    
          }else if(data.panel_type==="techincal round 2"){
            this.technicalResource_2_DropDown.push({ 'label': data.technical_panel_name, 'value': data.resource_id });
          }
          // this.technicalResourceDropDown.push({ 'label': data.technical_panel_name, 'value': data.resource_id });
        });
        this.technicalResource_1_DropDown.splice(0, 0, { 'label': 'Select Resource', 'value': 'Select Resource' });
        this.technicalResource_2_DropDown.splice(0, 0, { 'label': 'Select Resource', 'value': 'Select Resource' });
        this.visibleSidebar2 = true;
        this.loading = false
      })

    this.resourceRequestForm.patchValue({
      requirement_details: item.requirement_details,
      requirement_description: item.requirement_description,
      requirement_type: item.requirement_type,
      backfill_name: item.backfill_name,
      additional_skill: item.additional_skill,
      client_id: item.client_id,
      project_id: item.project_id,
      primary_skill: item.primary_skill,
      practice: item.practice,
      delivery_manager_id: item.delivery_manager_id,
      engagement_partner_id: item.engagement_partner_id,
      rwt: item.rwt,
      level: item.level,
      skillset: this.skillSelected,
      allocation_required: item.allocation_required,
      no_of_resource: item.no_of_resource,
      resource_availability: item.resource_availability,
      coe: item.coe ? item.coe : null,
      status: item.status,
      remarks: item.remarks,
      priority:item.priority,
      start_date: item.start_date,
      end_date: item.end_date,
      request_id: item.request_id,
      hr_mapping_id: item.hr_mapping_id,
      hr_spoc: '',
      recruiter:'',
      canditateName: null,
      resume: null,
      offered: '',
      doj: null,
      salary:[item.min_salary,item.max_salary],
      name: null,
    })
    this.resume = ''
    if(item.status === 'Request Completed')
    {
      this.isCompleted = true;
    } else{
      this.isCompleted = false;
    }
  }

  DeleteResourceRequest(dataItem,HrMappingID) {
    this.loading = true
    let formData = {};
    // console.log("dataItem", dataItem)
    formData['postBody'] = {};
    formData['postBody']['opType'] = 3;
    formData['postBody']['hr_mapping_id'] = HrMappingID;
    formData['type'] = "HrPost";
    this.projectservice.projectResourceRequest(formData).then(response => {
      // console.log("resource response", response)
      this.loading = false;
     
      // this.visibleSidebar2 = false;
      // window.location.reload();
      Swal.fire('Resource Deleted', '', 'success');
      this.getResourceRequestList();
    }, err => {
      this.loading = false;
      Swal.fire('Something went wrong', '', 'error');
      console.log(err)
    });
    // console.log("Delete item", dataItem)
  }

  // edit resource Row
  EditResourceRequestPopup(dataItem, hr_ID) {
    this.loading = true;
    this.submitted = false;
    
    let skillArray = dataItem.skill_id.split(/[ ,]+/).map(Number)
    this.skillSelected = []
    let skills = this.skillList.filter(x => skillArray.includes(x.skill_master_id));
    skills.map(skill => {
      this.skillSelected.push({ "skill_master_id": skill.skill_master_id, "skill_name": skill.skill_name })
      return skill
    })
    this.panelList.clear();
    this.technicalResource_1_DropDown = [];
    this.technicalResource_2_DropDown = [];
    // this.technicalResourceDropDown = [];
    this.resume = dataItem.resume;
    this.isAdd = false;
    let singleFormData = {};
    singleFormData['postBody'] = { "opType":4 , "request_id": dataItem.request_id }
    singleFormData['type'] = 'get'
    const tempdataObj = {}

    let technicalDataForm = {};
    technicalDataForm['postBody'] = { "opType": 5, "request_id": dataItem.request_id }
    technicalDataForm['type'] = 'get'
    this.projectservice.projectResourceRequest(technicalDataForm).then(
      response => {
        // console.log("get response", response);
        const tempData = response['body'];
        tempData.map(data => {
          if(data.panel_type==="techincal round 1"){
            this.technicalResource_1_DropDown.push({ 'label': data.technical_panel_name, 'value': data.resource_id });
    
          }else if(data.panel_type==="techincal round 2"){
            this.technicalResource_2_DropDown.push({ 'label': data.technical_panel_name, 'value': data.resource_id });
          }
          // this.technicalResourceDropDown.push({ 'label': data.technical_panel_name, 'value': data.resource_id });
        });
        this.technicalResource_1_DropDown.splice(0, 0, { 'label': 'Select Resource', 'value': 'Select Resource' });
        this.technicalResource_2_DropDown.splice(0, 0, { 'label': 'Select Resource', 'value': 'Select Resource' });
        
        
      }).catch(() => {
        this.loading = false;
      })

    this.projectservice.projectResourceRequest(singleFormData).then(
      response => {
        // console.log("get response", response);
        // const tempData = response['body'];
        const tempData = response['body'].filter(data => { if (data.hr_mapping_id === hr_ID) return true })
        tempData.map(data => {
          if (!tempdataObj[data['request_id']]) {
            tempdataObj[data['request_id']] = {}
            tempdataObj[data['request_id']] = data
            tempdataObj[data['request_id']]['panelDetails'] = []
            const panelData = {}
            if (data['Technical_Round_1'] || data['Technical_Round_2'] ) {
              panelData['dynamicPnlResource'] = data['panel_member']
              panelData['dynamicPnlFeedback'] = data['panel_member_feedback']
              panelData['dynamicPnlType'] = data['panel_type']
            } else if (data['Manager']) {
              panelData['dynamicPnlResource'] = data['panel_member']
              panelData['dynamicPnlFeedback'] = data['panel_member_feedback']
              panelData['dynamicPnlType'] = data['panel_type']
            }
            else if (data['HR_panel']) {
              panelData['dynamicPnlResource'] = data['panel_member']
              panelData['dynamicPnlFeedback'] = data['panel_member_feedback']
              panelData['dynamicPnlType'] = data['panel_type']
            }
            if (data['Technical_Round_1'] || data['Technical_Round_2'] || data['Manager'] || data['HR_panel'])
              tempdataObj[data['request_id']]['panelDetails'].push(panelData)
          } else {
            const panelData = {}
            if (data['Technical_Round_1'] || data['Technical_Round_2'] ) {
              panelData['dynamicPnlResource'] = data['panel_member']
              panelData['dynamicPnlFeedback'] = data['panel_member_feedback']
              panelData['dynamicPnlType'] = data['panel_type']
            } else if (data['Manager']) {
              panelData['dynamicPnlResource'] = data['panel_member']
              panelData['dynamicPnlFeedback'] = data['panel_member_feedback']
              panelData['dynamicPnlType'] = data['panel_type']
            }
            else if (data['HR_panel']) {
              panelData['dynamicPnlResource'] = data['panel_member']
              panelData['dynamicPnlFeedback'] = data['panel_member_feedback']
              panelData['dynamicPnlType'] = data['panel_type']
            }
            if (data['Technical_Round_1'] || data['Technical_Round_2'] || data['Manager'] || data['HR_panel'])
              tempdataObj[data['request_id']]['panelDetails'].push(panelData)
          }
          return data;

        })
        const item = Object.values(tempdataObj)
        // console.log("resourceRequestTable", item)
        this.resourceRequestForm.patchValue({
          requirement_details: item[0]['requirement_details'],
          requirement_description: item[0]['requirement_description'],
          requirement_type: item[0]['requirement_type'],
          backfill_name: item[0]['backfill_name'],
          candidate_status: item[0]['candidate_status'],
          practice: item[0]['practice'],
          additional_skill: item[0]['additional_skill'],
          client_id: item[0]['client_id'],
          project_id: item[0]['project_id'],
          primary_skill: item[0]['primary_skill'],
          delivery_manager_id: item[0]['delivery_manager_id'],
          engagement_partner_id: item[0]['engagement_partner_id'],
          rwt: item[0]['rwt'],
          level: item[0]['level'],
          skillset: this.skillSelected,
          allocation_required: item[0]['allocation_required'],
          no_of_resource: item[0]['no_of_resource'],
          resource_availability: item[0]['resource_availability'],
          coe: item[0]['coe'] ? item[0]['coe'] : null,
          status: item[0]['status'] ? item[0]['status'] : null,
          remarks: item[0]['remarks'] ? item[0]['remarks'] : null,
          priority: item[0]['priority'] ? item[0]['priority'] : null,
          start_date: item[0]['start_date'],
          end_date: item[0]['end_date'],
          request_id: item[0]['request_id'],
          hr_spoc: item[0]['hr_spoc'] ? item[0]['hr_spoc'] : '',
          recruiter: item[0]['recruiter'] ? item[0]['recruiter'] :'',
          canditateName: item[0]['name'] ? item[0]['name'] : null,
          hr_mapping_id: item[0]['hr_mapping_id'],
          resume: item[0]['resume'],
          offered: item[0]['offered'] ? item[0]['offered'] : '',
          doj: item[0]['doj'] ? item[0]['doj'] : null,
          salary: [item[0]['min_salary'],item[0]['max_salary']],
          panel_list: []
        })
        item[0]['panelDetails'].map(item => {
          this.panelList.push(this.fb.group({
            dynamicPnlType: item.dynamicPnlType,
            dynamicPnlResource: item.dynamicPnlResource,
            dynamicPnlFeedback: item.dynamicPnlFeedback,
          }));
          return item
        })
      this.loading = false;
      this.visibleSidebar2 = true;
      this.cd.detectChanges()
    }).catch(() => {
      this.loading = false;
    })
    if(dataItem.status === 'Request Completed')
    {
      this.isCompleted = true;
    } else{
      this.isCompleted = false;
    }
    
  }

  dynamicPanelChange(event, i) {
    console.log(event)

    // if(event.target.value=== "Technical"){
    //   this.isTechinal[i] =true
    //   // this.resourceDropDown= this.technicalResourceDropDown
    // }else{
    //   this.isTechinal[i] =false
    //   // this.resourceDropDown= this.tempResourceDropdown
    // }
  }

  submit() {
    this.submitted = true;
    let fi = this.fileInput.nativeElement;

    if (this.resourceRequestForm.errors) {
      this.resourceRequestForm.markAllAsTouched()
      return;
    }
    
    // console.log(JSON.stringify(this.resourceRequestForm.value, null, 2));


    let formData = {};
    formData['postBody'] = this.resourceRequestForm.getRawValue()
    formData['postBody']['remarks'] = formData['postBody']['remarks']
    formData['postBody']['priority'] = formData['postBody']['priority']
    formData['postBody']['min_salary'] =formData['postBody']['salary'][0]
    formData['postBody']['max_salary'] =formData['postBody']['salary'][1]
    // this.salary=[item[0]['min_salary'],item[0]['max_salary']];
    formData['postBody']['status'] = formData['postBody']['status']
    formData['postBody']['client_id'] = formData['postBody']['clientSelected']

    // console.log("",)
    let skillsIds = []
    formData['postBody']['skillset'].map(skill => {
      skillsIds.push(skill.skill_master_id)
      return skill
    })
    formData['postBody']['skillset'] = skillsIds.toString()
    if (this.isAdd) {
      formData['postBody']['opType'] = 1;
      formData['type'] = "HrPost";
    } else {
      formData['postBody']['opType'] = 2;
      formData['type'] = "HrPost";
    }

    if (formData['postBody']['canditateName'] && formData['postBody']['hr_spoc']) {
      this.loading = true;
      this.visibleSidebar2 = false;

      if (fi.files && fi.files[0]) {
        let timestamp = moment().format("DDMMYYYYHHMMSS")
        let fileToUpload = fi.files[0];
        formData['postBody']['resume'] = timestamp + '_' + fileToUpload.name;
        this.adminService.getPresignedUrl(formData['postBody']['resume']).then(dataObj => {
          const presigned_url = dataObj['url'];
          const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
          const requestOptions = {
            headers: headers
          };
          // console.log("presigned_url", presigned_url)
          // unsigned url issue reloads when posting

          this.http.put(presigned_url, fileToUpload, requestOptions).toPromise().then(uploaded => {
            // console.log("submit else ", formData)
            this.projectservice.projectResourceRequest(formData).then(response => {
              // console.log("resource response", response)
              this.loading = false;
              this.visibleSidebar2 = false;
              window.location.reload();
              // this.getResourceRequestList();
              Swal.fire('Request for resource Created', '', 'success');
            }, err => {
              this.loading = false;
              console.log(err)
            });
          }).catch(err => {
            // this.loading = false;
            console.log('error: ', JSON.stringify(err));
            return false;
          })
        }).catch(err => {
          this.loading = false;
          // console.log('error: ', JSON.stringify(err));
          return false;
        })
      } else {
        // console.log("submit else ", formData)
        this.projectservice.projectResourceRequest(formData).then(response => {
          // console.log("resource response", response)
          this.visibleSidebar2 = false;
          this.loading = false;
          this.getResourceRequestList();
          Swal.fire('Request for resource Created', '', 'success');
          // window.location.reload();
          
        }, err => {
          this.loading = false;
          console.log(err)
        });
      }
    }

  }

  onDownloadClick(resume) {
    if (resume) {
      this.adminService.getDownloadURl(resume).then(data => {
        this.downloadResumeUrl = data['url']
        window.open(this.downloadResumeUrl, '_blank');
      })
    }
  }

  getResourceRequestList() {
    let formData = {};
    this.loading = true;
    // let role = 'HR Admin'
    formData['postBody'] = { "opType": 3, "rolename": this.roleName }
    formData['type'] = 'get'
    this.projectservice.projectResourceRequest(formData).then(
      response => {
        let tempData = response['body']
        const tempObj = {}
        tempData.map(data => {
          data['skill_name'] = data['skill_name'].replaceAll(',', ', ')
          if (!tempObj[data['request_id']]) {
            const resourceData = {}
            tempObj[data['request_id']] = {}
            tempObj[data['request_id']] = data
            tempObj[data['request_id']]['requestedresource'] = []
            tempObj[data['request_id']]['rwts'] = data['rwt_name'] + ' - ' + '(' + data['level'] + ')'
            tempObj[data['request_id']]['client_name'] = data['client_name'] == null?'NA':data['client_name']
            tempObj[data['request_id']]['project_name'] = data['project_name'] == null?'NA':data['project_name']
            resourceData['resume'] = data['resume']
            resourceData['resource_availability'] = data['resource_availability']
            resourceData['remarks'] = data['remarks']
            resourceData['priority'] = data['priority']            
            resourceData['candidate_status'] = data['candidate_status']
            resourceData['Technical_Round_1'] = data['Technical_Round_1']
            resourceData['Technical_Round_2'] = data['Technical_Round_2']
            resourceData['Manager'] = data['Manager']
            resourceData['HR_panel'] = data['HR_panel']
            resourceData['offered'] = data['offered']
            resourceData['doj'] = data['doj']
            resourceData['salary'] =  [data['min_salary'],data['max_salary']],
            resourceData['hr'] = data['hr']
            resourceData['recruiter_name'] = data['recruiter_name']
            resourceData['name'] = data['name']
            resourceData['hr_mapping_id'] = data['hr_mapping_id']
            if (data['hr_mapping_id'])
            tempObj[data['request_id']]['requestedresource'].push(resourceData)
          } else {
            const resourceData = {}
            resourceData['resume'] = data['resume']
            resourceData['resource_availability'] = data['resource_availability']
            resourceData['remarks'] = data['remarks']
            resourceData['priority'] = data['priority']
            
            resourceData['offered'] = data['offered']
            resourceData['doj'] = data['doj']
            resourceData['salary'] =  [data['min_salary'],data['max_salary']],
            resourceData['hr'] = data['hr']
            resourceData['recruiter_name'] = data['recruiter_name']
            resourceData['Technical_Round_1'] = data['Technical_Round_1']
            resourceData['Technical_Round_2'] = data['Technical_Round_2']
            resourceData['Manager'] = data['Manager']
            resourceData['HR_panel'] = data['HR_panel']
            resourceData['candidate_status'] = data['candidate_status']
            resourceData['hr_mapping_id'] = data['hr_mapping_id']
            resourceData['name'] = data['name']

            if (data['hr_mapping_id'])
            tempObj[data['request_id']]['requestedresource'].push(resourceData)
          }
          return data;
        })
        // console.log("resourceRequestTable", tempObj)
        const resourceRequestTableList = Object.values(tempObj)
        // this.resourceRequestTable = resourceRequestTableList;

        this.resourceRequestTable = resourceRequestTableList.filter((item: any) => {
          if (this.requestStatus !== 'All' && this.department !== 'All' && this.engagementFilter !== 'All') {
            return (item.status === this.requestStatus) && (item.department_name === this.department)  && (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus !== 'All' && this.department === 'All' && this.engagementFilter === 'All') {
            return (item.status === this.requestStatus);
          }
          else if (this.requestStatus === 'All' && this.department !== 'All' && this.engagementFilter === 'All') {
            return (item.department_name === this.department);
          }
          else if (this.requestStatus === 'All' && this.department === 'All' && this.engagementFilter !== 'All') {
            return (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus !== 'All' && this.department !== 'All' && this.engagementFilter === 'All') {
            return  (item.status === this.requestStatus) && (item.department_name === this.department) ;
          } 
          else if (this.requestStatus !== 'All' && this.department === 'All' && this.engagementFilter !== 'All') {
            return (item.status === this.requestStatus) && (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus === 'All' && this.department !== 'All' && this.engagementFilter !== 'All') {
            return (item.department_name === this.department) && (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus === 'All' && this.department === 'All' && this.engagementFilter === 'All') {
            return item;
          }
        });

        // console.log('list', this.resourceRequestTable)
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        this.resourceRequestTable = [];
      });

  }

  onSearch(requestStatus, department,engagementName) {
    this.requestStatus = requestStatus;
    this.department = department;
    this.engagementFilter = engagementName;
    this.getResourceRequestList();
  }
  clearFilters() {
    this.requestStatus = 'All';
    this.department = 'All';
    this.engagementFilter = 'All';
    this.getResourceRequestList();
  }

  close() {
    this.visibleSidebar2 = false;
    this.resourceRequestForm.reset();
    this.panelList.clear();
  }

  getDepartment() {
    this.practices = [];
    
    this.sharedService.getDepartmentData().then(data => {
      // resourceData = rsourceData.filter(resource => resource.r_status === 'Submitted')
      this.practices = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.department_name === thing.department_name) === i
      ).sort((a, b) => a.department_name.localeCompare(b.department_name));
      // console.log('practicesd',this.practices)
      this.deliveryfunctions = [...this.practices]
    }).catch((err) => {
      this.manageRWTData = [];
    });



  }
  onStatus(){
    
    if(this.resourceRequestForm.value.status === 'Request Completed') {
      
      return this.resourceRequestForm.value.resource_availability = 'Yes';
      
    } 
    
  }

}

