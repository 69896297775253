import { Injectable } from '@angular/core';
import * as ConfigJson from '../../assets/config.json';
@Injectable()
export class StorageService {
    CryptoJS = require("crypto-js");
    SECRET_KEY = ConfigJson.secret_key;
    constructor() { 

    }

    setItem(key: string, value){
        var encryptedData = this.CryptoJS.AES.encrypt(value, this.SECRET_KEY).toString();
        // this.secureStorage.setItem(key, value);
        sessionStorage.setItem(key, encryptedData);
    }

    getItem(key){
        var encryptedData = sessionStorage.getItem(key);

        if (encryptedData !==null)
            return this.CryptoJS.AES.decrypt(encryptedData, this.SECRET_KEY).toString(this.CryptoJS.enc.Utf8);

        return null
    }
    
}