<ng-template #customLoadingTemplate>
    <div class="ctmloader">
        <img src="./../../../assets/images/loader.svg">
    </div>
</ng-template>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>

<div class="back">
    <div class="container-fluid pt-3">
        <div class="row">
            <div class="col">
                <h4>Manage Group</h4>
            </div>
        </div>

        <div class="full-height">
            <p-sidebar [(visible)]="visibleSidebar2" position="right"  [style]="{width:'70em'}" [baseZIndex]="10000">
                <h5>Manage Resource - {{cognitoGroupHeader}}</h5>
                <p-toast ></p-toast>
                <p-pickList #pl [source]="manageCognitoSourceDataTable" [target]="manageCognitoTargetDataTable"   sourceHeader="Available" targetHeader="Assigned" [dragdrop]="true"
                [responsive]="true" [sourceStyle]="{'height':'20rem'}" [targetStyle]="{'height':'20rem'}" filterBy="name"
                filterBy="resource_name" (onMoveToTarget)="onMoveToTarget($event)" (onMoveToSource)="onMoveToSource($event)"
                sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name">
                <ng-template let-resource pTemplate="item">
                    <div class="resource-item">
                        <div class="product-list-detail">
                            <p class="p-mb-2">{{resource.resource_name}}</p>
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
            <div class="row sidebtngroup text-center">
                <div class="col">
                    <p-button type="button" (click)="SaveResourceGroup(pl)" label="Save" icon="fa fa-floppy-o" styleClass="p-button-success btn4"></p-button>
                  <p-button type="button" (click)="CancelResourceGroup(pl)" label="Cancel" icon="fa fa-times" styleClass="p-button-secondary ml-3"></p-button>
                </div>
              </div>
            </p-sidebar>
        </div>

        <div class="ctm-card">
            <div class="ctm-tab">
                <p-carousel [value]="groupDetails" styleClass="custom-carousel" [numVisible]="3" [numScroll]="3" [circular]="true" [autoplayInterval]="10000" [responsiveOptions]="responsiveOptions">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <ng-template let-groupDetail pTemplate="item">
                        <div class="groupDetail-item"  >
                            <div class="groupDetail-item-content" (click)="addactive(groupDetail.groupName)" [ngClass]="{'active': groupDetail.groupName===highlightGroup}" >
                                <div class="p-mb-3">
                                </div>
                                <div>
                                    <h5 class="p-mb-1">{{groupDetail.groupName}}</h5>
                                    <div class="d-flex">
                                    <button *ngIf="groupDetail.groupName !=='All Cognito Group'" (click)="ManageResourcePopup(groupDetail)"  class="btn crvdbtn cstbtn btn2 mb-2">
                                        <i class="fa fa-plus-circle" aria-hidden="true"></i> Assign Resource
                                      </button>
                                      <div class="ml-auto">
                                      <p>Total Resource: <span class="count-data">{{groupDetail.totalCount}}</span></p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </ng-template>
                </p-carousel> 
                <p-table class="bordertop" [value]="manageCognitoDataTable"  #dt [paginator]="true" [autoLayout]="true" [rows]="10"
                            [rowsPerPageOptions]="[5,10,15,20]" pageLinks="3"
                            [globalFilterFields]="['resource_name','resource_email','department_name','location_name','sub_location_name']">
                            <ng-template pTemplate="caption">
                                <div class="d-flex ctmfilter align-items-end">
                                    <div>
                                        <label class="d-block text-left" for="exampleFormControlSelect1">Department</label>
                                        <select [ngModel]="department" [formControl]="departmentControl" style="width: 120px;"
                                        class="form-control" id="exampleFormControlSelect1">
                                        <option value='All' selected>All</option>
                                        <option *ngFor="let dept of departments" value="{{dept.department_name}}">
                                          {{ dept.department_name }}
                                        </option>
                                      </select>
                                    </div>
                                    <div class=" ml-4">
                                        <label class="d-block text-left" for="exampleFormControlSelect1">Location</label>
                                        <select [ngModel]="location" [formControl]="locationControl" style="width: 120px;"
                                          class="form-control" id="exampleFormControlSelect1">
                                          <option value='All' selected>All</option>
                                          <option *ngFor="let location of locations" value="{{location.location_name}}">
                                            {{ location.location_name }}
                                          </option>
                                        </select>
                          
                                    </div>
                                    <div class=" ml-4">
                                        <label class="d-block text-left" for="exampleFormControlSelect1">Sub Location</label>
                                        <select [ngModel]="sub_location" [formControl]="subLocationControl" style="width: 120px;"
                                          class="form-control" id="exampleFormControlSelect1">
                                          <option value='All' selected>All</option>
                                          <option *ngFor="let sub_location of sub_locations" value="{{sub_location.sub_location_name}}">
                                            {{ sub_location.sub_location_name }}
                                          </option>
                                        </select>
                          
                                    </div>

                                    <button class="btn cstbtn btn1  ml-3 mb-1"
                                    (click)="onSearch(departmentControl.value,locationControl.value,subLocationControl.value)">
                                    <i class="fa fa-filter" aria-hidden="true"></i> Apply filters
                                  </button>
                                  <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                                      aria-hidden="true"></i> Reset Filters</div>

                                    <div class="ml-auto bl-1">
                                        <p style="padding-bottom: 10px;">Search the table below using this free text
                                            search</p>
                                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                        <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            style="width:auto">
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="resource_name">Resource Name<p-sortIcon field="resource_name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="resource_email">Resource Email<p-sortIcon field="resource_email"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="department_name">Department<p-sortIcon field="department_name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="location_name">Location<p-sortIcon field="location_name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="sub_location_name">Sub Location<p-sortIcon field="sub_location_name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="role_name">Group<p-sortIcon field="role_name"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-cognitoDataItem>
                                <tr>
                                    <td>{{ cognitoDataItem.resource_name }}</td>
                                    <td>{{ cognitoDataItem.resource_email }}</td>
                                    <td>{{ cognitoDataItem.department_name }}</td>
                                    <td>{{ cognitoDataItem.location_name }}</td>
                                    <td>{{ cognitoDataItem.sub_location_name }}</td>
                                    <td>{{ cognitoDataItem.role_name }}</td>

                                </tr>
                            </ng-template>
                        </p-table>

            </div>
        </div>
    </div>
</div>