<ng-template #customLoadingTemplate>
  <div class="ctmloader">
    <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
  <div class="container-fluid pt-3">
    <div class="row">
      <div class="col-6">
        <h4>Resource Request</h4>
      </div>
      <div class="col-6 text-right">
        <ng-container
          *ngIf="roleName==='Delivery Manager' || roleName==='Engagement Partner' || roleName==='Delivery Partner' || roleName==='Engagement Manager'">
          <button (click)="openNewRequest()" class="btn crvdbtn cstbtn btn2">
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Request
          </button>
        </ng-container>
        <button type="button"  selectionMode="multiple"  (click)="exportExcel()"  
            class="btn cstbtn btn2 ml-3"><i class="fa fa-download" aria-hidden="true"></i> Download this report as
            Excel</button>
      </div>
    </div>

    <div class="full-height">
      <p-sidebar class="sidescroll" (onHide)="close()" [(visible)]="visibleSidebar2" position="right"
        [style]="{width:'90em'}" [baseZIndex]="10000">
        <form name="myForm">
          <h5 style="font-weight:normal"></h5>
          <p><span class="red">*</span> Please Fill Required Fields</p>
          <p-toast></p-toast>
          <div *ngIf="isView" class="d-flex">
            <div class="mr-auto">
              <h5 style="cursor: pointer; color: #5e94ff;" (click)="toggleDisplay()">
                Resource Request</h5>
            </div>
            <div class="crsr-pointr fs-16 fb" (click)="toggleDisplay()">
              <div *ngIf="!isDisplay && isView">Show <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
              <div *ngIf="isDisplay && isView">Hide <i class="fa fa-minus-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>

          <div *ngIf="isDisplay" class="row">
            <ng-container *ngIf="!isAdd">
              <div class="col-3">
                <div>
                  <label>Request ID</label>
                  <p style="font-size:16px;">{{requestId}}</p>
                </div>
              </div>
              <div class="col-3">
                <div>
                  <label>Requested On</label>
                  <p style="font-size:16px;">{{requested_date}}</p>
                </div>
              </div>
              <div class="col-3">
                <div>
                  <label>Requested By</label>
                  <p style="font-size:16px;">{{requestor}}</p>
                </div>
              </div>
            </ng-container>
            <ng-container >
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>RWT</label>
                  <select [disabled]="!disableForm" [(ngModel)]="rwtSelected" class="form-control" name="rwt">
                    <!--(change)="projectChange($event)"-->
                    <option value=''>--Select RWT--</option>
                    <option *ngFor="let rwt of rwts" value="{{rwt.rwtid}}">
                      {{ rwt.rwt_name }}
                    </option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && rwtSelected==='' ">RWT is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Level</label>
                  <select [disabled]="!disableForm" [(ngModel)]="levelSelected" class="form-control" name="level">
                    <!--(change)="projectChange($event)"-->
                    <option value=''>--Select Level--</option>
                    <option *ngFor="let level of levels" value="{{level.level_id}}">
                      {{ level.level_name }}
                    </option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && levelSelected==='' ">Level is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label>Job Title</label>
                  <textarea [(ngModel)]="requirement_details" class="form-control" id=""
                    name="requirement_details" [disabled]="!disableForm"></textarea>
                  <!-- <small class="p-invalid" *ngIf="submitted && requirement_details==='' ">Requirement Title is
                    required.</small> -->
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Job Description</label>
                  <textarea [(ngModel)]="requirement_description" class="form-control" id=""
                    name="requirement_description" [disabled]="!disableForm"></textarea>
                  <small class="p-invalid" *ngIf="submitted && requirement_description==='' ">Job Description is
                    required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Requirement Type</label>
                  <select class="form-control" [(ngModel)]="requirement_type" class="form-control" id="" #t
                    name="requirement_type" (change)="OnBackFill()" [disabled]="!disableForm">
                    <option value=''>--Select Type--</option>
                    <option value='Back Fill'>Back Fill</option>
                    <option value='New Hire'>New Hire</option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && requirement_type==='' ">Requirement Type is
                    required.</small>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Client Name</label>
                  <select [disabled]="!disableForm" [(ngModel)]="clientSelected" (change)="clientChange($event)" class="form-control"
                    name="client">
                    <option *ngFor="let client of clientDropDown" [value]="client.value">{{ client.label }}</option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && clientSelected==='' ">Client Name is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Project Name</label>
                  <select [disabled]="!disableForm" [(ngModel)]="projectSelected" class="form-control" name="project"
                    (change)="projectChange($event)">
                    <option *ngFor="let project of projectDropDown" value="{{project.value}}">
                      {{ project.label }}
                    </option>
                  </select>

                  <small class="p-invalid" *ngIf="submitted && projectSelected==='' ">Project is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Project Category</label>
                  <select [disabled]="!disableForm" class="form-control" [(ngModel)]="project_category" class="form-control" id=""
                    name="project_category">
                    <option value=''>--Select Project Category--</option>
                    <option value='Development'>Development</option>
                    <option value='Support'>Support</option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && project_category==='' ">Project Category is
                    required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Project Type</label>
                  <select [disabled]="!disableForm" class="form-control" [(ngModel)]="project_type" class="form-control" id=""
                    name="project_type">
                    <option value=''>--Select Project Type--</option>
                    <option value='Fixed'>Fixed</option>
                    <option value='T & M - Managed Capacity'>T & M - Managed Capacity</option>
                    <option value='T & M - Staffing'>T & M - Staffing</option>
                    <option value='Outsourced'>Outsourced</option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && project_type==='' ">Project Type is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Engagement</label>
                  <select [disabled]="!disableForm" class="form-control" [(ngModel)]="engagement" class="form-control" id="" name="engagement">
                    <option value=''>--Select Engagement--</option>
                    <option value='Engagement Team 1'>Engagement Team 1</option>
                    <option value='Engagement Team 2'>Engagement Team 2</option>
                    <option value='Engagement Team 3'>Engagement Team 3</option>
                    <option value='Engagement Team 4'>Engagement Team 4</option>
                    <option value='Engagement Team 5'>Engagement Team 5</option>
                    <option value='Engagement Team 6'>Engagement Team 6</option>
                    <option value='Engagement Team 7'>Engagement Team 7</option>
                    <option value='NA'>NA</option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && engagement ==='' ">Project Category is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Primary skill set</label>
                  <ng-multiselect-dropdown [placeholder]="'Select Skills'"  class="resourceside" [settings]="dropdownSettings"
                    [(ngModel)]="skillSelected" name="skillSelected" [data]="skillList"
                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll()" [disabled]="!disableForm">
                  </ng-multiselect-dropdown>
                  <small class="p-invalid" *ngIf="submitted && skillSelected.length === 0 ">Primary skill set is
                    required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label>Additional Skills</label>
                  <textarea [(ngModel)]="additional_skill" class="form-control" id=""
                    name="additional_skill" [disabled]="!disableForm"></textarea>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Department</label>
                  <!-- <select class="form-control" [(ngModel)]="practice" class="form-control" id="" name="practice">
                  <option value=''>--Select Practice--</option>
                  <option value='16'>Digital Engineering</option>
                  <option value="1">Data Analytics</option>
                  <option value='2'>Decision Science</option>
                  <option value="17">Quality Analyst</option>
                </select> -->
                  <select [disabled]="!disableForm" [(ngModel)]="practice" class="form-control" name="practice">
                    <!--(change)="projectChange($event)"-->
                    <option value=''>--Select Department--</option>
                    <option *ngFor="let practice of practices" value="{{practice.department_id}}">
                      {{ practice.department_name }}
                    </option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && practice==='' ">Department is required.</small>

                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Reporting Manager</label>
                  <select [disabled]="!disableForm" [(ngModel)]="dmSelected" class="form-control" name="dm">
                    <!--(change)="projectChange($event)"-->
                    <option *ngFor="let dm of deliveryManagerDropDown" value="{{dm.value}}">
                      {{ dm.label }}
                    </option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && dmSelected==='' ">Reporting Manager is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Engagement Partner</label>
                  <select [disabled]="!disableForm" [(ngModel)]="epSelected" class="form-control" name="ep">
                    <!--(change)="projectChange($event)"-->
                    <option *ngFor="let ep of engagementPartnerDropDown" value="{{ep.value}}">
                      {{ ep.label }}
                    </option>
                  </select>
                  <small class="p-invalid" *ngIf="submitted && epSelected==='' ">Engagement Partner is required.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>Allocation Required (%)</label>
                  <input [disabled]="!disableForm" pInputText type="number" class="form-control" min="1" [(ngModel)]="allocationRequired"
                    class="form-control" name="allocationRequired" (keypress)="onlyDigits($event)">
                  <small class="p-invalid" *ngIf="submitted && allocationRequired =='' ">Allocation(%) Required is
                    required.</small>
                </div>
              </div>
              <!-- <div class="col-3">
              <div class="form-group">
                <label><span class="red">*</span>Number of Resources</label>
                <input pInputText type="number" class="form-control" [(ngModel)]="no_of_resourcesRequired"
                  name="no_of_resourcesRequired" id="">
                <small class="p-invalid" *ngIf="submitted && no_of_resourcesRequired==='' ">Number of resources
                  required.</small>
              </div>
              </div> -->
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>From Date</label>
                  <input [disabled]="!disableForm" pInputText type="date" class="form-control" [(ngModel)]="start_date" name="start_date" id="">
                  <small class="p-invalid" *ngIf="submitted && start_date==='' ">From Date is required.</small>
                  <small class="p-invalid" *ngIf="isDateValidate(start_date,end_date)  ">End Date is greater than Start
                    Date.</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label><span class="red">*</span>To Date</label>
                  <input [disabled]="!disableForm" pInputText type="date" class="form-control" [(ngModel)]="end_date" name="end_date" id="">
                  <small class="p-invalid" *ngIf="submitted && end_date==='' ">To Date is required.</small>
                  <small class="p-invalid" *ngIf="isDateValidate(start_date,end_date) ">End Date is greater than Start
                    Date.</small>
                </div>
              </div>
            </ng-container>
            <div class="col-3" *ngIf='!isAdd'>
              <div class="form-group">
                <label>Priority</label>
                <select class="form-control" [(ngModel)]="priority" name="priority"
                  [disabled]="roleName !=='Practice Delivery Manager'">
                  <option value="">--Select Priority--</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
            </div>

            <div class="col-3" *ngIf='!isAdd'>
              <div class="form-group">
                <label>Status</label>
                <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="mgntStatus" name="mgntStatus"
                  #s (change)="onStatus()">
                  <option
                    [disabled]="roleName === 'Practice Delivery Manager' || roleName === 'Delivery Ops Lead' || roleName === 'Talent Acquisition Manager'"
                    value="Requested">Requested</option>
                  <option [disabled]="roleName !== 'Practice Delivery Manager'" value="Allocation Analysis">Allocation
                    Analysis</option>
                  <option [disabled]="roleName !== 'Delivery Ops Lead'" value="Approval for Hiring">Approval for Hiring
                  </option>
                  <option [disabled]="roleName !== 'Practice Delivery Manager'" value="Approved for Hiring">Approved for
                    Hiring</option>
                  <option [disabled]="roleName !== 'Delivery Ops Lead'" value="Assigned Internal Resource">Assigned
                    Internal Resource</option>
                  <option
                    [disabled]="roleName === 'Practice Delivery Manager' || roleName === 'Delivery Ops Lead' || roleName === 'Talent Acquisition Manager'"
                    value="On Hold">On Hold</option>
                  <option [disabled]="roleName !== 'Practice Delivery Manager'" value="Rejected by PDM">Rejected by PDM
                  </option>
                  <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Hiring">Hiring</option>
                  <!-- <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Hired">Hired</option> -->
                  <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Offered">Offered</option>
                  <option [disabled]="roleName !== 'Talent Acquisition Manager'" value="Request Completed">Request
                    Completed</option>
                </select>

              </div>
            </div>
            <div class="col-3">
              <!-- <div class="col-3" *ngIf="t.value === 'Back Fill' || t.value === 'New Hire'"> -->
              <!-- <div class="form-group">
                <label>Back Fill Resource Name</label>
                <input type="text" class="form-control" name="backfill_name" formControlName="backfill_name" maxlength="200" />
              </div> -->
              <div class="form-group">
                <label>Special Instruction</label>
                <textarea type="text" [(ngModel)]="backfill_name" class="form-control" id=""
                  name="backfill_name"></textarea>
              </div>
            </div>
            <!-- <div class="col-3" *ngIf="salary && roleName==='Delivery Partner'|| roleName==='Talent Acquisition Manager'">
              <div class="form-group">
                <label>Excepted Salary : {{salary[0]}} - {{salary[1]}} (Lakhs)</label>
                <p-slider [(ngModel)]="salary" [style]="{'width':'200px'}" [range]="true" [animate]='true' [min]="2" [max]="75" name="salary" [step]="0.5" ></p-slider>
              </div>
            </div> -->

          </div>
          <div *ngIf="isDisplay">
            <ng-container *ngIf="!isAdd">
              <div class="sidebargroup">
                <div class="row">
                  <div class="col-4">
                    <ng-container *ngIf="roleName !=='Practice Delivery Manager'; else notEditable">
                      <div class="form-group">
                        <label>Resource Availability</label>
                        <select *ngIf="resourceAvailablity != ''" class="form-control" id="exampleFormControlSelect1"
                          [(ngModel)]="resourceAvailablity" name="resourceAvailablity"
                          [disabled]="roleName !=='Practice Delivery Manager'">
                          <option value=''>--SELECT--</option>
                          <option [disabled]="disabledYData === true" value="Yes">Yes</option>
                          <option [disabled]="disabledNData === true" value="No"> No</option>
                        </select>
                        <div>
                          <span *ngIf="resourceAvailablity == ''">Not Updated Yet</span>
                        </div>
                        <!-- <small class="p-invalid" *ngIf="submitted && !isAdd && resourceAvailablity==='' ">Resource Availablity is required.</small> -->
                      </div>
                    </ng-container>
                    <ng-template #notEditable>
                      <div class="form-group">
                        <label>Resource Availability</label>
                        <!-- <input pInputText type="date" class="form-control" [(ngModel)]="resourceAvailablity" name="resourceAvailablity" id=""> -->
                        <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="resourceAvailablity"
                          name="resourceAvailablity" [disabled]="roleName !=='Practice Delivery Manager'">
                          <option value=''>--Select--</option>
                          <option [disabled]="disabledYData === true" value="Yes">Yes</option>
                          <option [disabled]="disabledNData === true" value="No"> No</option>
                        </select>
                        <!-- <small class="p-invalid" *ngIf="submitted && !isAdd && resourceAvailablity==='' ">Resource Availablity is required.</small> -->
                      </div>
                    </ng-template>

                  </div>
                  <div class="col-6" *ngIf="mgntStatus ==='Rejected by PDM'">
                    <div class="form-group">
                      <label>Remarks</label>
                      <textarea class="form-control" id="" [(ngModel)]="mgntRemarks" name="mgntRemark"
                        [disabled]="roleName !=='Practice Delivery Manager'"></textarea>
                    </div>
                  </div>
                  <div class="col-4" *ngIf="mgntStatus ==='Approved for Hiring'">
                    <div class="form-group">
                      <label><span class="red">*</span>Techincal Panel Round 1</label>
                      <ng-multiselect-dropdown [placeholder]="'Select Techincal Panel resource'"
                        [settings]="resourceDropdownSettings" [(ngModel)]="technical_Resource_Panel_1_Selected"
                        name="technical_Resource_Panel_1_Selected" [data]="multipleResourceDropdown"
                        (onSelect)="onResourceSelect($event)" (onSelectAll)="onResourceSelectAll($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onResourceDeSelectAll()"
                        [disabled]="roleName !=='Practice Delivery Manager'">
                      </ng-multiselect-dropdown>
                      <small class="p-invalid"
                        *ngIf="submitted && technical_Resource_Panel_1_Selected && technical_Resource_Panel_1_Selected.length === 0">Please
                        select technical panel member.
                      </small>
                    </div>
                  </div>
                  <div class="col-4" *ngIf="mgntStatus ==='Approved for Hiring'">
                    <div class="form-group">
                      <label><span class="red">*</span>Techincal Panel Round 2</label>
                      <ng-multiselect-dropdown [placeholder]="'Select Techincal Panel resource'"
                        [settings]="resourceDropdownSettings" [(ngModel)]="technical_Resource_Panel_2_Selected"
                        name="technical_Resource_Panel_2_Selected" [data]="multipleResourceDropdown"
                        (onSelect)="onResourceSelect($event)" (onSelectAll)="onResourceSelectAll($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onResourceDeSelectAll()"
                        [disabled]="roleName !=='Practice Delivery Manager'">
                      </ng-multiselect-dropdown>
                      <small class="p-invalid"
                        *ngIf="submitted && technical_Resource_Panel_2_Selected && technical_Resource_Panel_2_Selected.length === 0">Please
                        select technical panel member.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="isView">
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label>TA SPOC</label>
                  <select class="form-control" [(ngModel)]="hr_spoc" [disabled]="isView" name="hr_spoc">
                    <option value=''>--Select TA--</option>
                    <option *ngFor="let resource of resourceArray" value="{{resource.resource_id}}">
                      {{ resource.resource_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Recruiter</label>
                  <select class="form-control" [(ngModel)]="recruiter" [disabled]="isView" name="recruiter">
                    <option value=''>--Select Recruiter--</option>
                    <option *ngFor="let resource of recruiterArray" value="{{resource.resource_id}}">
                      {{ resource.resource_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Candidate Name</label>
                  <input type="text" class="form-control" [disabled]="isView" [(ngModel)]="canditateName"
                    name="canditateName">

                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Resume</label>
                  <!-- <input #fileInput type="file" style="height: auto;" class="form-control" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="fileSource" (change)="onFileSelected($event)"> -->
                  <ng-container *ngIf='resume && resume!=="null"'>
                    <div class="d-flex">
                      <span style="cursor: pointer;color: #5e94ff;margin-top: 5px;display: inline-block;"><a
                          (click)="onDownloadClick(resume)">
                          Download Resume
                        </a></span>
                    </div>

                  </ng-container>
                </div>
              </div>
              <div class="col-4" *ngIf="!isAdd">
                <div class="form-group">
                  <label>Candidate Status</label>
                  <select class="form-control" id="exampleFormControlSelect1" [disabled]="isView"
                    name="candidate_status" [(ngModel)]="candidate_status"
                    [disabled]="roleName !=='Talent Acquisition Manager'">
                    <option value='To be Interviewed'>To be Interviewed</option>
                    <option value='Offered'>Offered</option>
                    <option value='Accepted'>Accepted</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='On Hold'>On hold</option>
                    <option value='Declined'>Declined</option>
                    <option value='No Show'>No Show</option>
                  </select>
                </div>
              </div>
              <div class="col-4" *ngIf="!isAdd">
                <div class="form-group">
                  <label>Date of Joining</label>
                  <input type="date" class="form-control" id="" [disabled]="isView" [(ngModel)]="doj" name="doj">

                </div>
              </div>
              <form class="w-100" [formGroup]="resourceRequestForm">
                <div class="col-12">
                  <div *ngFor="let item of panelListGroups; let idx = index;">
                    <div [formGroup]="item" class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <label><span class="red">*</span>Interview Panel Type</label>
                          <select class="form-control" disabled="isView" formControlName="dynamicPnlType">
                            <option value=''>--Select--</option>
                            <option value='Technical Round 1'>Technical Round 1</option>
                            <option value='Technical Round 2'>Technical Round 2</option>
                            <option value='Managerial'>Managerial</option>
                            <option value='HR'>HR</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label><span class="red">*</span>Interview Panel Resource</label>
                          <p-dropdown [options]="resourceDropDown" [disabled]="isView"
                            selectedItem="item['dynamicPnlResource']" [style]="{'width':'100%'}" scrollHeight="400px"
                            [filter]="true" filterBy="label" optionLabel="label" optionValue="value"
                            resetFilterOnHide="true" formControlName="dynamicPnlResource">
                            <ng-template let-resource pTemplate="item">
                              <div class="item-drop1">
                                <mat-list-item>
                                  <span class="item-value1">{{resource.label}}</span>
                                </mat-list-item>
                              </div>
                            </ng-template>
                          </p-dropdown>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label>Interview Panel Feedback</label>
                          <textarea type="text" class="form-control" class="form-control"
                            formControlName="dynamicPnlFeedback" disabled="isView"> </textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </ng-container>
          <i class="mt-2 d-block">Note: The minimum time period for fulfillment is 4 weeks.</i>
          <div class="row sidebtngroup text-center">
            
            <div class="col">
              <ng-container *ngIf="isAdd">
                <p-button type="button" (click)="submit()" label="Submit" icon="fa fa-floppy-o"
                  styleClass="p-button-success btn4"> </p-button>
              </ng-container>
              <ng-container *ngIf="!isAdd && !isView">
                <p-button *ngIf="mgntStatus !=='Request Completed'" type="button" (click)="submit()" label="Update" icon="fa fa-floppy-o"
                  styleClass="p-button-success btn4"> </p-button>
              </ng-container>
              <p-button type="button" (click)="close()" label="Cancel" icon="fa fa-times"
                styleClass="p-button-secondary ml-3"></p-button>
            </div>
          </div>
        </form>
      </p-sidebar>
    </div>

    <div class="ctm-card">
      <p-table class="bordertop" [value]="resourceRequestTable" #dt dataKey="request_id" [autoLayout]="true"
        [paginator]="true" (onRowExpand)="onRowExpand()" [rows]="10" (onRowCollapse)="onRowCollapse()" [columns]="cols"
        [expandedRowKeys]="expandedRows" [rowsPerPageOptions]="[5,10,15,20]"
        [totalRecords]="resourceRequestTable?.length" pageLinkSize="3" (onPage)="onPage($event)"
        [globalFilterFields]="['request_id','client_name','project_name','start_date','end_date','rwts','requirement_details','no_of_resource','primary_skill_name','skill_name','requestor_name','status','priority','requirement_type','practice_name']">




        <ng-template pTemplate="caption">
          <div class="d-flex filterwidth  align-items-end">
            <div class=" ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Status</label>

              <select [ngModel]="requestStatus" [formControl]="requestStatusControl" style="width: 200px;"
                class="form-control">
                <option value='All' selected>All</option>
                <option *ngFor="let requestStatus of requestStatusList" value="{{requestStatus.status}}">
                  {{ requestStatus.status }}
                </option>
              </select>
            </div>

            <div class=" ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Department</label>
              <select [ngModel]="department" [formControl]="departmentControl" style="width: 200px;"
                class="form-control">
                <option value='All' selected>All</option>
                <option *ngFor="let department of deliveryfunctions" value="{{department.department_name}}">
                  {{ department.department_name }}
                </option>
              </select>

            </div>

            <div class=" ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Engagement</label>
              <select [ngModel]="engagementFilter" [formControl]="engagementControl" style="width: 200px;"
                class="form-control">
                <option value='All' selected>All</option>
                <option *ngFor="let engagementFilter of engagementList" value="{{engagementFilter.engagement}}">
                  {{ engagementFilter.engagement }}
                </option>
              </select>

            </div>

            <button class="btn cstbtn btn1  ml-3 mb-1"
              (click)="onSearch(requestStatusControl.value,departmentControl.value,engagementControl.value)">
              <i class="fa fa-filter" aria-hidden="true"></i> Apply filters
            </button>
            <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                aria-hidden="true"></i> Reset
              Filters</div>

            <div class="ml-auto bl-1">
              <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
              <input type="text" size="40" placeholder="What are you looking for ?"
                (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 5em">
              <a style="cursor: pointer;font-size: 16px; color:teal;" (click)="expandAll()">
                <i *ngIf="!isExpanded" style="font-size: 20px;" class="fa fg fa-plus-circle" aria-hidden="true"></i>
                <i *ngIf="isExpanded" style="font-size: 20px;" class="fa fg fa-minus-circle" aria-hidden="true"></i>
              </a>
            </th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
          <tr [class]="expanded ? 'row-active-header' : ''">
            <td class="chevdownup" >
              <a href="#" [pRowToggler]="rowData" *ngIf="rowData.status !== 'Assigned Internal Resource'">
                <i [class]="expanded ? 'pi fc pi-chevron-up' : 'pi fc pi-chevron-down'"></i>
              </a>
            </td>
            <td *ngFor="let col of columns">
              <ng-container *ngIf="col.field === 'action'">
                <a href="#" style="font-size: 16px; display: flex; text-decoration: none;" [pRowToggler]="rowData">
                  <!--  [ngClass]="isAdd ? 'col-4':'col-1'"  -->
                  <i class="fa fa-pencil-square-o" pTooltip="Click here to edit" tooltipPosition="left"
                    aria-hidden="true" (click)="EditResourcerequest(rowData)"></i>
                  &nbsp;&nbsp;

                  <i class="fa fa-clone" pTooltip="Click here to clone" tooltipPosition="left"
                    *ngIf="roleName !=='Practice Delivery Manager' && roleName !=='Delivery Ops Lead'"
                    (click)="cloneResourcerequest(rowData)" aria-hidden="true"></i>
                  <!-- </button> -->
                </a>
              </ng-container>

              <ng-container *ngIf="col.field !== 'action'">
                {{rowData[col.field]}}
              </ng-container>
              <ng-container *ngIf="col.field === 'rwts'">
                {{rowData[field]}}
              </ng-container>

            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-rowData let-expanded="expanded" let-columns="columns">
          <tr class="row-active-body">
            <td [attr.colspan]="columns.length + 1" class="no-pad p-0">
              <div class="p-p-3">
                <table class="innerTable"
                  *ngIf="rowData['requestedresource'] && rowData['requestedresource'].length > 0 && rowData['requestedresource']['name']!==null">
                  <thead class="innerHeader">
                    <tr>
                      <th>Name</th>
                      <th>Resume</th>
                      <th>TA SPOC</th>
                      <th>Recruiter</th>
                      <th>Technical Round 1 Panel</th>
                      <th>Technical Round 2 Panel</th>
                      <th>Manager Panel</th>
                      <th>HR Panel</th>
                      <th>Status</th>
                      <th>DOJ</th>
                      <th>Action</th>
                    <tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of rowData['requestedresource']" class="innerBody">
                      <td>{{row['name']}}</td>
                      <td>
                        <ng-container *ngIf='row["resume"] && row["resume"]!=="null" '>
                          <span style="cursor: pointer;color: #5e94ff;margin-top: 5px;display: inline-block;">
                            <a (click)="onDownloadClick(row['resume'])">
                              Download
                            </a></span>
                        </ng-container>
                      </td>
                      <td>{{row['hr'] === 'null'? '':row['hr']}}</td>
                      <td>{{row['recruiter_name']}}</td>
                      <td>{{row['Technical_Round_1']}}</td>
                      <td>{{row['Technical_Round_2']}}</td>
                      <td>{{row['Manager']}}</td>
                      <td>{{row['HR_panel']}}</td>
                      <td>{{row['candidate_status'] === 'null'? '':row['candidate_status']}}</td>
                      <td>{{row['doj'] === '0000-00-00'? '' :row['doj']}}</td>
                      <td>

                        <span (click)="viewResourceRequestPopup(rowData,row['hr_mapping_id']);">
                          <i tooltipPosition="left" pTooltip="Click here to view" class="fa faedit fa-eye"
                            aria-hidden="true"></i>
                        </span>&nbsp;
                        <!-- <span (click)="DeleteResourceRequest(rowData);">
                        <i tooltipPosition="left" pTooltip="Click here to delete" class="fa fa-trash"
                          aria-hidden="true"></i>
                      </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="color: #ed4d4d;padding: 1rem;" *ngIf="rowData['requestedresource'].length===0 ">
                No Resources Allocated
              </div>
            </td>

          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</div>