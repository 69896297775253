import {
  Injectable
} from '@angular/core';
import {
  Observable
} from 'rxjs';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import * as ConfigJson from '../../assets/config.json';
@Injectable({
  providedIn: 'root'
})
export class ProjectDetailService {

  base_url = ConfigJson.API_URL



  constructor(private http: HttpClient) { }

  getAllProjects(projectId) {
    return this.http.get<any>(this.base_url + 'getAlldata?projectID=' + projectId)
      .toPromise().then(data => { return data; });
  }

  getRWT() {
    return this.http.get<any>(this.base_url + 'rwt')
      .toPromise().then(data => { return data; });
  }



  postSubProjectData(subprojectsData): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    return this.http.post<any>(this.base_url + 'projectDetails', subprojectsData)
  }


  getAllProjectsByResource(projectId, userRole, userName) {
    return this.http.get<any>(this.base_url + "getAlldataByResource?projectID=" + projectId + "&userRole=\'" + userRole + "\'")
      .toPromise().then(data => {
        return data;
      });
  }

  // getAllProjectsByResourceCopy(projectId, userRole, userName) {
  //   return this.http.get<any>(this.base_url + "getAlldataByUser?projectID=" + projectId + "&userName=\'" + userName + "\'")
  //     .toPromise().then(data => {
  //       return data;
  //     });
  // }



  deleteSubprojects(projectId,subProjectID, userName) {
    return this.http.get<any>(this.base_url + "deletesubproject?projectID=\'" + projectId + "\'&subProjectID=\'" + subProjectID + "\'")
      .toPromise().then(data => {
        return data;
      });

  }
  getProjectActualsBySubProjectID(SubProjectID) {
    return this.http.get(this.base_url+ "GetProjectActualsBySubProjectID?SubProjectID="+ SubProjectID +"")
        .toPromise()
        .then(res => { return res; });
  }


}
