import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { AuthService } from './auth.service';
import { SharedService } from './_services/shared.service';
import { StorageService } from './_services/storage.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  user: { id: string; username: string; email: string };
  userRole: string;

  constructor(private ngZone: NgZone, private authService: AuthService, private router: Router, private storage: StorageService, private sharedServ: SharedService) { }
  emailVerificationMessage: boolean = false;

  ngOnInit(): void {

    // this.authService.isLoggedIn$.subscribe(
    //   (isLoggedIn) => {
    //     this.isLoggedIn = isLoggedIn;
    //   }
    // );
    // get and set timesheet config values
      this.sharedServ.getConfigValues('timesheet').then(configValues => {
        this.storage.setItem('timesheetConfigValues', JSON.stringify(configValues));         
      })
    this.userRole = this.storage.getItem("selectedRole");
    this.authService.auth$.subscribe(({ id, username, email }) => {
      this.ngZone.run(async() => {
        this.user = { id, username, email };
        // get and set timesheet config values
        // this.sharedServ.getConfigValues('timesheet').then(configValues => {
        //   this.storage.setItem('timesheetConfigValues', JSON.stringify(configValues));         
        // })
        if (this.user && !this.userRole) {
          let userRolesgroup = await Auth.currentSession()
          let usergroups = {};
          let options = [];
          userRolesgroup['accessToken']['payload']['cognito:groups'].sort().map((group, index) => {
            group = group.replaceAll('_', ' ')
            options.push(group)
            usergroups[index] = {
              "group": group
            }
            // this.loading = false;
          })
          if(options.length===1 && options[0] ==='Project Team Member'){
            this.sharedServ.userLoggedIn(this.user);
            if (!this.storage.getItem('departments')) {
              this.sharedServ.getDepartmentData().then(department => {
                this.storage.setItem('departments', JSON.stringify(department));
              })
            }
            this.storage.setItem('selectedRole', usergroups[0].group)

            this.router.navigateByUrl('/home')
          }else{
            await Swal.fire({
              title: 'Select Role',
              input: 'select',
              inputOptions: options,
              inputPlaceholder: 'Select a Role',
              showCancelButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result) => {
              if (result.isConfirmed) {
                let res = result.value
                this.sharedServ.userLoggedIn(this.user);
                if (!this.storage.getItem('departments')) {
                  this.sharedServ.getDepartmentData().then(department => {
                    this.storage.setItem('departments', JSON.stringify(department));
                  })
                }
                this.storage.setItem('selectedRole', usergroups[res].group)
  
                this.router.navigateByUrl('/home')
              }
            })
          }
         
        } else {
          if (!this.storage.getItem('departments')) {
            this.sharedServ.getDepartmentData().then(department => {
              this.storage.setItem('departments', JSON.stringify(department));
            })
          }
        }
      });
    });
    // if(!this.user ){
    //   Auth.federatedSignIn();
    // }
  }
  title = 'NGP';
}
