<ng-template #customLoadingTemplate>
    <div class="ctmloader">
       <img src="./../../../assets/images/loader.svg">
    </div>
 </ng-template>
 <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
 </ngx-loading>
 <div class="back">
    <div class="container-fluid pt-3">
       <div class="ctm-body">
          <div class="d-flex mb-1 align-items-center">
             <h4>{{linkText}} for </h4>
             <div class="ml-3 pb-3 d-flex  align-items-center">
                <button style="font-size: 25px;padding: 2px 10px 2px 10px;" class="btn2 btn arrowhover"  [disabled]="previousMonthClickDisable" (click)="onPrevClick()">
                < 
                </button>
                <span style="font-size: 20px;font-weight: 600;"> &nbsp;{{ currentDate | date: 'MMM yyyy'}}  &nbsp;</span>
                <button style="font-size: 25px;padding: 2px 10px 2px 10px;" class="btn2 btn arrowhover"
                (click)="onNextClick()" [disabled]="futureMonthClickDisable"> > 
                </button>
             </div>
             <div class="ml-auto">
                <b style="font-size: 1rem">My Projects Resource Count : <span
                   class="fc">{{resourceCount}}</span></b>
             </div>
          </div>
          <div class="ctm-card">
             <p-table class="bordertop"  dataKey="evaluation_id"
             [value]="evaluationData" #dt [paginator]="true" [rows]="20" editMode="row"
             [rowsPerPageOptions]="[30,40,50]" pageLinks="3" 
             [totalRecords]="evaluationData.length" [editingRowKeys]="editingRows" 
             
             [globalFilterFields]="['resource_name','project_name','client_name','overall_score','delivery_performance','demonstrated_solutioning','project_escalations',
             'showcased_dependability','demonstrated_initiative','remarks']"
            
             >
             <ng-template pTemplate="caption">
                <div class="d-flex ctmfilter align-items-end">
                   <div>
                      <label for="dtPicker" id="lbldtpicker"
                         class="d-block text-left control-label mr-2">Clients</label>
                      <select style="width: 200px;" [ngModel]="selectedClient" (ngModelChange)="onClientChange($event)"  [formControl]="clientControl" class="form-control">
                      <!-- (change)="onClientChange(selectedClient)"> -->
                      <option value="0">All</option>
                      <option *ngFor="let item of clients" [value]="item">{{item}}</option>
                      </select>
                   </div>
                   <div class="ml-3">
                      <label for="dtPicker" id="lbldtpicker"
                         class="d-block text-left control-label mr-2">Projects </label>
                      <select style="width: 200px;" [ngModel]="selectedProject"  [formControl]="projectControl" class="form-control">
                      <!-- //(change)="onClientChange(selectedClient)"> (ngModelChange)="onProjectChange($event)" -->
                      <option value="0">All</option>
                      <option *ngFor="let prj of projects" [value]="prj">{{prj}}</option>
                      </select>
                   </div>
                   <div class="ml-3" hidden>
                     <label for="dtPicker" id="lbldtpicker"
                        class="d-block text-left control-label mr-2">OverAll Score </label>
                     <select style="max-width: 100px;" [ngModel]="selectedOverallscore" [formControl]="overallscoreControl" class="form-control">
                     <!-- //(change)="onClientChange(selectedClient)">-->
                     <option value="0">All</option>
                     <option *ngFor="let os of overallscores" [value]="os">
                        <span *ngIf="os == 1"> &nbsp;Poor&nbsp;</span>
                                  <span *ngIf="os == 2"> &nbsp;Average&nbsp;</span>
                                    <span *ngIf="os == 3"> &nbsp;Good&nbsp;</span>
                                       <span *ngIf="os == 4"> &nbsp;Exceptional&nbsp;</span>
                     </option>
                     </select>
                  </div>
                  <div class="ml-3" hidden>
                     <label for="dtPicker" id="lbldtpicker"
                        class="d-block text-left control-label mr-2">Delivery Performance </label>
                     <select style="max-width: 100px;" [ngModel]="selectedDeliveryPerformance" [formControl]="deliveryPerformanceControl" class="form-control">
                     <option value="0">All</option>
                     <option *ngFor="let dp of deliveryPerformances" [value]="dp">
                        <span *ngIf="dp == 1"> &nbsp;Poor&nbsp;</span>
                                  <span *ngIf="dp == 2"> &nbsp;Average&nbsp;</span>
                                    <span *ngIf="dp == 3"> &nbsp;Good&nbsp;</span>
                                       <span *ngIf="dp == 4"> &nbsp;Exceptional&nbsp;</span>
                     </option>
                     </select>
                  </div>
                   <!-- <div class="ml-3">
                      <label class="d-block text-left" for="exampleFormControlSelect1">Overall Score</label>
                      <select class="form-control">
                          <option>All</option>
                      </select>
                      </div> -->
                   <div class="ml-3" hidden>
                      <label class="d-block text-left" for="exampleFormControlSelect1">Escalations</label>
                      <select style="max-width: 100px;" class="form-control" [formControl]="escalationControl"
                      [ngModel]="selectedEscalation">
                      <option value="0">All</option>
                      <option value="Yes">YES</option>
                      <option value="No">NO</option>
                      </select>
                   </div>
                   <button type="button" class="btn cstbtn btn1 ml-3 mb-1" (click)="applyFilters()"><i
                      class="fa fa-filter" aria-hidden="true"></i> Apply filters</button>
                   <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;"
                      class="fa fa-times red" aria-hidden="true"></i> Reset Filters</div>
                   <!-- <div class="ml-auto  bl-1">
                      <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
                      <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                      <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                         (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                      
                   </div> -->
                   <div class="ml-auto  bl-1">
                     <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
                     <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                   <input type="text" pInputText size="40" [(ngModel)]="freeText" style="width:auto" placeholder="What are you looking for ?" (ngModelChange)="onFreeTextChange(freeText)" >
                  </div>
                </div>
             </ng-template>
             <!-- <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                   <col>
                   <col style="width:160px;">

                </colgroup>
             </ng-template> -->
             <ng-template pTemplate="header">
                <tr>
                   <th pSortableColumn="resource_name">
                      Name,<br> <span style="color: #8e908a;">RWT
                      (Level)</span>
                      <p-sortIcon field="client"></p-sortIcon>
                   </th>
                   <th pSortableColumn="project_name">
                      Project<br> <span style="color: #8e908a;">(Sub
                      Project)</span><br>(Client)
                      <p-sortIcon field="client"></p-sortIcon>
                   </th>
                   <th>Overall Score</th>
                   <th>Delivery Performance </th>
                   <th>Demonstrated Solutioning</th>
                   <th>Project Escalation</th>
                   <th>Showcased Dependability</th>
                   <th>Demonstrated Initiative</th>
                   <th>Detailed Remarks</th>
                   <th style="padding: 0;">Action</th>
                </tr>
             </ng-template>
             <ng-template pTemplate="body" let-project let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="project">
                   <td class="namerwt">
                      <div>{{project.resource_name}},</div>
                      <p>{{project.rwt_name}} ({{project.level_name}})</p>
                      <div *ngIf="userRole === 'Engagement Partner'"> {{project.role}} </div>
                   </td>
                   <td class="namerwt">
                      <div>{{project.project_name}}</div>
                      <p *ngIf="project.sub_project_id === ' '">(NA)</p>
                      <p *ngIf="project.sub_project_id !== ' '">({{project.sub_project_id}})</p>
                      <div>({{project.client_name}})</div>
                   </td>
                   <td style="font-weight: bold;">
                      <p-cellEditor>
                         <ng-template pTemplate="input" >
                            <div style="width: 80px;" class="text-center mb-2">
                               <span [ngClass]="{
                                  'text-danger':project.overall_score === 1,
                                  'text-warning':project.overall_score === 2,
                                  'text-success':project.overall_score === 3,
                                  'text-green':project.overall_score === 4
                                  }">{{project.overall_score}}</span>
                            </div>
                            <!-- <p-slider [(ngModel)]="project.overall_score"  (onChange)="changeFn(project)"  [min]="1" [max]="4" [step]="1">
                            </p-slider> -->
                            <p-rating [iconOnStyle]="{'color':'#ffce54'}" (onRate)="changeFn(project)" [ngClass]="{
                              'hide':project.overall_score == '-2',
                              'poor-rating':project.overall_score == '0',
                              'average-rating':project.overall_score == '1',
                              'text-secondary':project.overall_score == '2',
                              'text-success':project.overall_score == '3',
                              'text-green':project.overall_score == '4'
                              }" [(ngModel)]="project.overall_score" [stars]="4" [cancel]="false">
                              </p-rating>
                            <div class="mt-2">
                               <div *ngIf="project.overall_score === 1" class="text-danger">
                                  &nbsp;Poor&nbsp;
                               </div>
                               <div *ngIf="project.overall_score === 2" class="text-warning">
                                  &nbsp;Average&nbsp;
                               </div>
                               <div *ngIf="project.overall_score === 3" class="text-success">
                                  &nbsp;Good&nbsp;
                               </div>
                               <div *ngIf="project.overall_score === 4" class="text-success">
                                  &nbsp;Exceptional&nbsp;
                               </div>
                            </div>
                         </ng-template>
                         <ng-template pTemplate="output">
                            <div class="text-center">
                               <span [ngClass]="{
                                 'text-danger':project.overall_score == 1,
                                 'text-warning':project.overall_score == 2,
                                 'text-success':project.overall_score == 3,
                                 'text-green':project.overall_score == 4
                                 }">
                                  <span *ngIf="project.overall_score == 1"> &nbsp;Poor&nbsp;</span>
                                  <span *ngIf="project.overall_score == 2"> &nbsp;Average&nbsp;</span>
                                    <span *ngIf="project.overall_score == 3"> &nbsp;Good&nbsp;</span>
                                       <span *ngIf="project.overall_score == 4"> &nbsp;Exceptional&nbsp;</span>
                                 </span>
                            </div>
                         </ng-template>
                      </p-cellEditor>
                   </td>
                   <td style="font-weight: bold;">
                      <ng-container *ngIf="project.demonstrated_solutioning == '-2'">
                         NA
                      </ng-container>
                      <ng-container *ngIf="project.demonstrated_solutioning != '-2'">
                         <p-cellEditor>
                            <ng-template pTemplate="input">
                               <div style="width: 80px;" class="text-center mb-2">
                                  <span [ngClass]="{
                                     'text-danger':project.delivery_performance == 1,
                                     'text-warning':project.delivery_performance == 2,
                                     'text-success':project.delivery_performance == 3,
                                     'text-green':project.delivery_performance == 4
                                     }">{{project.delivery_performance}}</span>
                               </div>
                               <p-rating [iconOnStyle]="{'color':'#ffce54'}" (onRate)="changeFn(project)" [ngClass]="{
                               'hide':project.delivery_performance == '-2',
                               'poor-rating':project.delivery_performance == '0',
                               'average-rating':project.delivery_performance == '1',
                               'text-secondary':project.delivery_performance == '2',
                               'text-success':project.delivery_performance == '3',
                               'text-green':project.delivery_performance == '4'
                               }" [(ngModel)]="project.delivery_performance" [stars]="4" [cancel]="false">
                               </p-rating>
                               <div *ngIf="project.delivery_performance === 1" class="text-danger">
                                  &nbsp;Poor&nbsp;
                               </div>
                               <div *ngIf="project.delivery_performance=== 2" class="text-warning">
                                  &nbsp;Average&nbsp;
                               </div>
                               <div *ngIf="project.delivery_performance=== 3" class="text-success">
                                  &nbsp;Good&nbsp;
                               </div>
                               <div *ngIf="project.delivery_performance=== 4" class="text-success">
                                  &nbsp;Exceptional&nbsp;
                               </div>
                               <!-- <div class="p-field-radiobutton">
                                  <p-radioButton name="score" value="Exceptional" inputId="rdlexeptional1"
                                      [(ngModel)]="project.delivery_performance">
                                  </p-radioButton>
                                  <label for="rdlexeptional1">Exceptional</label>
                                  </div>
                                  <div class="p-field-radiobutton">
                                  <p-radioButton name="score" value="Good" inputId="rdlgood1"
                                      [(ngModel)]="project.delivery_performance">
                                  </p-radioButton>
                                  <label for="rdlgood1">Good</label>
                                  </div>
                                  <div class="p-field-radiobutton">
                                  <p-radioButton name="score" value="Average" inputId="rdlAverage1"
                                      [(ngModel)]="project.delivery_performance">
                                  </p-radioButton>
                                  <label for="rdlAverage1">Average</label>
                                  </div>
                                  <div class="p-field-radiobutton">
                                  <p-radioButton name="score" value="Poor" inputId="rdlPoor1"
                                      [(ngModel)]="project.delivery_performance">
                                  </p-radioButton>
                                  <label for="rdlPoor1">Poor</label>
                                  </div> -->
                            </ng-template>
                            <ng-template pTemplate="output">
                               <div class="text-center">
                                  <span [ngClass]="{
                                     'hide':project.delivery_performance == -2,
                                     'text-danger':project.delivery_performance == 1,
                                     'text-warning':project.delivery_performance == 2,
                                     'text-success':project.delivery_performance == 3,
                                     'text-green':project.delivery_performance == 4
                                     }">
                                     <span *ngIf="project.delivery_performance == 1"> &nbsp;Poor&nbsp;</span>
                                     <span *ngIf="project.delivery_performance == 2"> &nbsp;Average&nbsp;</span>
                                       <span *ngIf="project.delivery_performance == 3"> &nbsp;Good&nbsp;</span>
                                          <span *ngIf="project.delivery_performance == 4"> &nbsp;Exceptional&nbsp;</span>
                                    </span>
                               </div>
                            </ng-template>
                         </p-cellEditor>
                      </ng-container>
                   </td>
                   <td class="switchalign">
                      <ng-container *ngIf="project.demonstrated_solutioning == '-2'">
                         <b>NA</b>
                      </ng-container>
                      <ng-container *ngIf="project.demonstrated_solutioning !== '-2'">
                         <p-cellEditor>
                            <ng-template pTemplate="input">
                               <ng-toggle (change)="changeDemonstratedSolutioningToggle(ri,project,$event)"
                               [value]="project?.toggleDemonstratedSolutioning"
                               [disabled]="project.is_demonstrated_solutioning"
                               [(ngModel)]="project.toggleDemonstratedSolutioning"
                               [color]="{ unchecked: '#ed4d4d', checked: '#8cc152' }"
                               [labels]="{ unchecked: 'NO', checked: 'YES'}" [margin]="6"></ng-toggle>
                               <br />
                               <input type="checkbox" [checked]="project.is_demonstrated_solutioning"
                               (change)="changeDemonstratedSolutioning(ri,project,$event)"> NA
                            </ng-template>
                            <ng-template pTemplate="output">
                               <b *ngIf="project.demonstrated_solutioning ==='0'"
                                  class="text-danger">NO</b>
                               <b *ngIf="project.demonstrated_solutioning==='1'"
                                  class="text-success">&nbsp;YES&nbsp;</b>
                               <b *ngIf="project.demonstrated_solutioning==='-1'"
                                  class="">&nbsp;NA&nbsp;</b>
                               <b *ngIf="project.demonstrated_solutioning==='-2'"
                                  class="">&nbsp;NA&nbsp;</b>
                            </ng-template>
                         </p-cellEditor>
                      </ng-container>
                      <!-- <p-cellEditor>
                         <ng-template pTemplate="input">
                             <ng-toggle [value]="project.demonstrated_solutioning" 
                                 [(ngModel)]="project.demonstrated_solutioning" [color]="{ unchecked: '#ed4d4d',
                               checked: '#8cc152'
                             }" [labels]="{ unchecked: 'No', checked: 'Yes'}" [margin]="6"></ng-toggle>&nbsp;
                            
                             
                         </ng-template>
                         <ng-template pTemplate="output">
                             <span *ngIf="!project.demonstrated_solutioning" class="text-danger">No</span>
                             <span *ngIf="project.demonstrated_solutioning" class="text-success">&nbsp;Yes&nbsp;</span>
                         </ng-template>
                         </p-cellEditor> -->
                   </td>
                   <td class="switchalign">
                      <ng-container *ngIf="project.project_escalations == '-2'">
                         <b> NA</b>
                      </ng-container>
                      <ng-container *ngIf="project.project_escalations !== '-2'">
                         <p-cellEditor>
                            <ng-template pTemplate="input">
                               <ng-toggle (change)="changeProjectEscalationToggle(ri,project,$event)"
                               [value]="project?.toggleProjectEscalations"
                               [disabled]="project.is_project_escalations"
                               [(ngModel)]="project.toggleProjectEscalations"
                               [color]="{ unchecked: '#8cc152' , checked: '#ed4d4d' }"
                               [labels]="{ unchecked: 'NO', checked: 'YES'}" [margin]="6"></ng-toggle>
                               <br />
                               <input type="checkbox" [checked]="project.is_project_escalations"
                               (change)="changeProjectEscalation(ri,project,$event)"> NA
                               <!-- <ng-toggle 
                                  [value]="project.project_escalations==='1'?'1':'0'"
                                  [disabled]="project.project_escalations==='-1'"
                                  [(ngModel)]="project.project_escalations" [color]="{ 
                                      unchecked: '#8cc152',
                                      checked: '#ed4d4d'
                                  }" [labels]="{ unchecked: 'No', checked: 'Yes'}" [margin]="6"></ng-toggle>
                                  <input 
                                  type="checkbox" [checked]="project.project_escalations==='-1'" 
                                  (change)="project.project_escalations==='-1'? project.project_escalations='0': (project.project_escalations==='0'? project.project_escalations='-1': project.project_escalations='-1')"
                                  > NA -->
                            </ng-template>
                            <ng-template pTemplate="output">
                               <b *ngIf="project.project_escalations ==='0'" class="text-success">NO</b>
                               <b *ngIf="project.project_escalations==='1'"
                                  class="text-danger">&nbsp;YES&nbsp;</b>
                               <b *ngIf="project.project_escalations==='-1'" class="">&nbsp;NA&nbsp;</b>
                               <b *ngIf="project.project_escalations==='-2'" class="">&nbsp;NA&nbsp;</b>
                            </ng-template>
                         </p-cellEditor>
                      </ng-container>
                   </td>
                   <td>
                      <p-cellEditor>
                         <ng-template pTemplate="input">
                            <ng-toggle [value]="project.showcased_dependability"  (change)="changeFn(project)"
                            [(ngModel)]="project.showcased_dependability" [color]="{ unchecked: '#ed4d4d',
                            checked: '#8cc152'
                            }" [labels]="{ unchecked: 'NO', checked: 'YES'}" [margin]="6"></ng-toggle>
                         </ng-template>
                         <ng-template pTemplate="output">
                            <b *ngIf="!project.showcased_dependability" class="text-danger">NO</b>
                            <b *ngIf="project.showcased_dependability" class="text-success">YES</b>
                         </ng-template>
                      </p-cellEditor>
                   </td>
                   <td>
                      <p-cellEditor>
                         <ng-template pTemplate="input">
                            <ng-toggle [value]="project.demonstrated_initiative" (change)="changeFn(project)"
                            [(ngModel)]="project.demonstrated_initiative" [color]="{ unchecked: '#ed4d4d',
                            checked: '#8cc152'
                            }" [labels]="{ unchecked: 'NO', checked: 'YES'}" [margin]="6"></ng-toggle>
                         </ng-template>
                         <ng-template pTemplate="output">
                            <b *ngIf="!project.demonstrated_initiative" class="text-danger">NO</b>
                            <b *ngIf="project.demonstrated_initiative" class="text-success">YES</b>
                         </ng-template>
                      </p-cellEditor>
                   </td>
                   <td>
                      <p-cellEditor>
                         <ng-template pTemplate="input">
                            <textarea style="width: 130px;" pInputTextarea class="form-control" (change)="changeFn(project)"
                            [(ngModel)]="project.remarks"></textarea>
                         </ng-template>
                         <ng-template pTemplate="output">
                            <span [innerHTML]="project.remarks" class="preserve-newlines"></span>
                         </ng-template>
                      </p-cellEditor>
                   </td>
                   <td style="padding: 0;">
                      <span *ngIf="!editing" pRipple type="button" pInitEditableRow
                      (click)="onRowEditInit(project)" style="color: #5e94ff;"
                      class="p-button-rounded p-button-text"><i class="fa fa-pencil-square-o"
                         aria-hidden="true"></i> Edit&nbsp;</span>
                      <!-- <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow
                         icon="pi pi-check" (click)="onRowEditSave(project)"
                         class="p-button-rounded p-button-text p-button-success p-mr-2">Save</button> -->
                      <span *ngIf="editing && project.evaluation_id!== null" pRipple type="button" pCancelEditableRow
                      (click)="onRowEditCancel(project, ri)" style="color: #ed4d4d;white-space: nowrap;"
                      class="p-button-rounded p-button-text p-button-danger"><i class="fa fa-times"
                         aria-hidden="true"></i> Cancel&nbsp;</span>
                   </td>
                </tr>
             </ng-template>
             </p-table>
          </div>
       </div>
    </div>
 </div>
 <!-- <div style="position: fixed; bottom:0; right: 15px;">
    <button (click)="onSave()">Save</button>
    </div> -->
 <button *ngIf="saveFlag" style="font-size: 20px;" class="btn fxdbtn cstbtn btn4 ml-2 bounce" (click)="onSave()">
 <i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp; Save</button>
 
 <!-- <button class="btn cstbtn btn2 mr-1"  (click)="evaluationCheckDialog()" ><i class="fa fa-plus" aria-hidden="true"></i>
   Evaluation Check</button> -->
    
   <!-- <p-confirmDialog ></p-confirmDialog> -->
    
    

  <p-dialog appendTo="body" styleClass="dialogscroll"  [style]="{width: '50vw'}" (onHide)="rejectSave()" [(visible)]="displayPopup"  header="Confirmation" icon="pi pi-exclamation-triangle" [draggable]="false" [resizable]="false">
   <div class="mb-2">
      Default evaluation will get submitted for all the resources in the list below. If you do not wish to submit the evaluation now for any of the resources , you may uncheck their names and click OK to proceed
   </div>
         <p-table   [(selection)]="evaluationCheckDataSelection"   class="bordertop" [autoLayout]="true" 
         [value]="evaluationCheckData" #dt [paginator]="true" [rows]="10" editMode="row"
         [rowsPerPageOptions]="[5,10,15,20]" pageLinks="3" [lazy]="false" 
         [totalRecords]="evaluationCheckData.length" [editingRowKeys]="editingRows" [globalFilterFields]="['resource_name']">
         
         <ng-template pTemplate="header">
            <tr>
               <th>
                  <!-- <p-tableHeaderCheckbox  (click)="tableheaderCheckboxClickClient()"></p-tableHeaderCheckbox> -->
                  <!-- <p-checkbox  [(ngModel)]="isChecked" name="group" [value]="project"></p-checkbox> -->
                  <p-tableHeaderCheckbox (click)="onChkChangeHeader()"></p-tableHeaderCheckbox>
               </th>
               <th pSortableColumn="resource_name">
                  Name,<br> <span style="color: #8e908a;">RWT
                  (Level)</span>
                  <p-sortIcon field="client"></p-sortIcon>
               </th>
               <th pSortableColumn="project_name">
                  Project<br> <span style="color: #8e908a;">(Sub
                  Project)</span>
                  <p-sortIcon field="client"></p-sortIcon>
               </th>
               </tr>
         </ng-template>
         <ng-template pTemplate="body" let-project let-editing="editing" let-ri="rowIndex">
            <tr >
               <td>
                 
                  <!-- <p-tableCheckbox (click)="tablCheckboxClickClient()"  ></p-tableCheckbox> -->
                  <!-- <p-checkbox  (onChange)="onChkChange(project)"  name="group" [value]="project"></p-checkbox> -->
                  <p-tableCheckbox  (click)="onChkChange(project)" [value]="project" ></p-tableCheckbox>
               </td>
               <td class="namerwt">
                  <div>{{project.resource_name}},</div>
                  <p>{{project.rwt_name}} ({{project.level_name}})</p>
               </td>
               <td class="namerwt">
                  <div>{{project.project_name}},</div>
                  <p>({{project.sub_project_id}})</p>
               </td>
            </tr>
         </ng-template>
         </p-table>
     

      <p-footer>
         <div class="mt-3 d-flex justify-content-center align-items-center">
            <button type="button" pButton icon="pi pi-check" label="Ok" (click)="acceptSave()"></button>
            <!-- <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="cd.reject()" ></button> -->
            <div class="clrfilter"   (click)="rejectSave()"  style="height: 100%; padding-bottom: 0px; display: flex; align-items: center;"  >
              <i style="color: #ed4d4d; margin-right: 10px;" class="fa fa-times red" aria-hidden="true"></i>
               Cancel
            </div>
           </div>
      </p-footer>
</p-dialog>
