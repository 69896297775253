import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { adminComponent } from './admin.component';
import { ManageResourceComponent } from './manage-resource/manage-resource.component';
import { ManageRwtComponent } from './manage-rwt/manage-rwt.component';
import { ManageGroupComponent } from './manage-group/manage-group.component';
import { EvaluationReportComponent } from './evaluation-report/evaluation-report.component';
import { AdminRoutingModule } from './admin.routing.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ProjectsModule } from '../projects/projects.module';
import { TableModule } from 'primeng/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import { ToastModule } from 'primeng/toast';
import { SliderModule } from 'primeng/slider';
import { RatingModule } from 'primeng/rating';
import { NgToggleModule } from 'ng-toggle-button';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { ChartModule } from 'primeng/chart';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {SidebarModule} from 'primeng/sidebar';
import { MessageService } from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AccordionModule} from 'primeng/accordion';
import {PickListModule} from 'primeng/picklist';
import {TabViewModule} from 'primeng/tabview';
import {CarouselModule} from 'primeng/carousel';
import {RadioButtonModule} from 'primeng/radiobutton';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResourceUtilizationChartComponent } from '../shared/resource-utilization-chart/resource-utilization-chart.component';
import { ManageSkillMatrixComponent } from './manage-skill-matrix/manage-skill-matrix.component';
import { HRComponent } from './hr/hr.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  
  imports: [
    CommonModule,
    AdminRoutingModule,
    TableModule,
    FormsModule,
    ToolbarModule,
    TooltipModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    SliderModule,
    RatingModule,
    NgToggleModule,
    InputTextModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    CheckboxModule,
    CalendarModule,
    FormsModule,
    PaginatorModule,
    ChartModule,
    SidebarModule,
    AutoCompleteModule,
    AccordionModule,
    ProjectsModule,
    PickListModule,
    TabViewModule,
    CarouselModule,
    RadioButtonModule,
    NgxLoadingModule.forRoot({}),
    NgMultiSelectDropDownModule,
    MatListModule
  ],
  exports: [
    adminComponent,
    HRComponent,
    ManageResourceComponent,
    ManageRwtComponent,
    ManageGroupComponent,
    EvaluationReportComponent
  ],
  declarations: [ HRComponent,adminComponent,ManageResourceComponent,ManageRwtComponent,ManageGroupComponent,EvaluationReportComponent, ManageSkillMatrixComponent],
  providers: [MessageService],
})

export class AdminModule { }
