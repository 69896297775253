
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsComponent } from './projects.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { SecondaryNavbarComponent } from '../shared/secondary-navbar/secondary-navbar.component';
import { AddProjectsStatusComponent } from './add-projects-status/add-projects-status.component';
import { HealthStatusComponent } from './health-status/health-status.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { UtilizationReportComponent } from './utilization-report/utilization-report.component';
import { EvaluationConfigurationComponent } from './evaluation-configuration/evaluation-configuration.component';
// import { AppModule } from '../app.module';
import { SidebarModule } from 'primeng/sidebar';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgToggleModule } from 'ng-toggle-button';
import { SliderModule } from 'primeng/slider';
import { RatingModule } from 'primeng/rating';
import { NgxLoadingModule } from 'ngx-loading';
import {MatListModule} from '@angular/material/list';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { StringPipe } from '../_pipes/string.pipe';
import {TooltipModule} from 'primeng/tooltip';
import { ReportsComponent } from './reports/reports.component';
import {TabViewModule} from 'primeng/tabview';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ResourceRequestComponent } from './resource-request/resource-request.component';
import { AdditionalCostComponent } from './project-detail/additional-cost/additional-cost.component';


@NgModule({
  imports: [
    ButtonModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    CalendarModule,
    CheckboxModule,
    MatListModule,
    CommonModule,
    ToastModule,
    DialogModule,
    ProjectsRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SidebarModule,
    MatInputModule,
    MatFormFieldModule,
    RatingModule,
    FormsModule,
    ReactiveFormsModule,
    PaginatorModule,
    TableModule,
    RadioButtonModule,
    NgToggleModule,
    SliderModule,
    TooltipModule,
    NgxLoadingModule,
    TabViewModule, 
    NgMultiSelectDropDownModule
  ],
  exports: [
    SecondaryNavbarComponent ,
    AdditionalCostComponent
  ],
  declarations: [
   SecondaryNavbarComponent,
    ProjectsComponent,
    EvaluationComponent,
    UtilizationReportComponent,
    ProjectDetailComponent,
    DashboardComponent,
    AddProjectsStatusComponent,
    HealthStatusComponent ,
    StringPipe,
    EvaluationConfigurationComponent,
    ReportsComponent,
    ResourceRequestComponent,
    AdditionalCostComponent
  ],
  providers: [ 
    StringPipe
  ],
})
export class ProjectsModule {}