<ng-container *ngIf="locationPath==='projects'">
   <nav class="navbar  subnav navbar-expand-lg navbar-dark">
      <a class="navbar-brand pl-3" href="#">Projects App:</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
         aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
         <ul class="navbar-nav">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
               <a class="nav-link" routerLink="/projects">Lookup</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']">
               <a class="nav-link" routerLink="/projects/allocation-report">Resource Allocations on Projects</a>
            </li>
            <!-- <li class="nav-item bl-1" [routerLinkActive]="['active']"
               *ngIf="userRole==='Delivery Partner' || userRole==='Engagement Partner' || userRole==='Engagement Manager' || userRole==='Sub Project Delivery Manager' ">
               <a class="nav-link" routerLink="/projects/evaluation">{{linkText}}</a>
            </li> -->
            <!-- <li class="nav-item bl-1" [routerLinkActive]="['active']" *ngIf="userRole==='Delivery Partner'">
               <a class="nav-link" routerLink="/projects/evaluation-config">Set Evaluator for SPDM</a>
            </li> -->
            <!-- <li class="nav-item bl-1" [routerLinkActive]="['active']"
               *ngIf="userRole==='Delivery Partner' || userRole==='Engagement Partner'">
               <a class="nav-link" routerLink="/admin/evaluation-report">Evaluation Report</a>
            </li> -->
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
               *ngIf="userRole==='Delivery Manager' || userRole==='Engagement Partner' || userRole==='Delivery Partner' || userRole==='Engagement Manager'">
               <a class="nav-link" routerLink="/projects/resources-request">Manage Request Resource </a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
               *ngIf="userRole==='Delivery Ops Lead' || userRole==='Practice Delivery Manager'">
               <a class="nav-link" routerLink="/projects/resources-request">Manage Resource Request</a>
            </li>
            <li class="nav-item bl-1" routerLinkActive="active"
            *ngIf="userRole==='Delivery Ops Lead' || userRole==='Practice Delivery Manager'">
               <a class="nav-link" routerLink="/admin/manage-resource">Manage Resource</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']">
               <a class="nav-link" routerLink="/projects/status-report">Project Status History</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
               *ngIf="userRole==='Delivery Partner' || userRole==='Engagement Partner' || userRole==='Delivery Manager' || userRole==='Engagement Manager'|| userRole==='Billing Manager'">
               <a class="nav-link" routerLink="/projects/reports"> Reports </a>
            </li>
         </ul>
      </div>
   </nav>
</ng-container>

<ng-container *ngIf="locationPath==='resource_manager'">
   <nav class="navbar subnav navbar-expand-lg navbar-dark">
      <a class="navbar-brand pl-3" href="#">Resource App:</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
         aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
         <ul class="navbar-nav">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
               <a class="nav-link" routerLink="/resource_manager">Dashboard</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']">
               <a class="nav-link" routerLink="/resource_manager/allocation-report">Resource Allocations on Projects</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']">
               <a class="nav-link" routerLink="/resource_manager/submissionreport">Actuals Submission Status</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']">
               <a class="nav-link" routerLink="/resource_manager/actuals-report">Project Actuals History</a>
            </li>
         </ul>
      </div>
   </nav>
</ng-container>

<ng-container *ngIf="locationPath==='admin'">
   <nav class="navbar subnav navbar-expand-lg navbar-dark">
      <a class="navbar-brand pl-3" href="#">Admin App:</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
         aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
         <ul class="navbar-nav">
            <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            *ngIf="userRole==='HR Admin' || userRole==='PMO Admin'">
               <a class="nav-link" routerLink="/resource/submissionreport">Actuals Submission Status</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
            *ngIf="userRole==='HR Admin' || userRole==='PMO Admin'">
               <a class="nav-link" routerLink="/resource/projectactuals">Project Actuals </a>
            </li>
            <li class="nav-item bl-1" routerLinkActive="active">
               <a class="nav-link" routerLink="/admin/manage-resource">Manage Resource</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
            *ngIf="userRole==='HR Admin' || userRole==='PMO Admin'">
               <a class="nav-link" routerLink="/admin/manage-rwt">Manage RWT</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
            *ngIf="userRole==='HR Admin' || userRole==='PMO Admin'">
               <a class="nav-link" routerLink="/admin/manage-group">Manage Group</a>
            </li>
            
            <!-- <li class="nav-item bl-1" [routerLinkActive]="['active']" *ngIf="userRole==='HR Admin'">
               <a class="nav-link" routerLink="/admin/evaluation-report">Evaluation Report</a>
            </li> -->
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
               *ngIf="userRole==='HR Admin' || userRole==='PMO Admin'">
               <a class="nav-link" routerLink="/admin/manage-skill-matrix">Manage Skill Matrix</a>
            </li>
            <li class="nav-item bl-1" [routerLinkActive]="['active']"
               *ngIf="userRole==='Talent Acquisition Manager'">
               <a class="nav-link" routerLink="/admin/hr">Manage Requested Resource</a>
            </li>
         </ul>
      </div>
   </nav>
</ng-container>