import { Component, OnInit } from '@angular/core';
import { ProjectService  } from '../../_services/project.service';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_services/storage.service';
import { MatCalendar } from "@angular/material/datepicker";
import { EvaluationDropDown } from 'src/app/_interfaces/EvaluationDropDown';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";


const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
@Component({
  selector: 'app-evaluation-configuration',
  templateUrl: './evaluation-configuration.component.html',
  styleUrls: ['./evaluation-configuration.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  styles: [`
        :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
        }

        @media screen and (max-width: 64em) {
            :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
                top: 99px;
            }
        }
  `]
})
export class EvaluationConfigurationComponent implements OnInit {
  loading = false;
  updateFlag = false;
  updateFlagCnt = 0;
  public cols: any[];
  public isExpanded: boolean = false;
  public rows: number = 10;
  public expandedRows = {};
  public temDataLength: number = 0;
  public reportData = {};
  public reports = [];
  public reportsOrg = [];
  evaluatorTypes: any;
  innerPage: any[];
  clientControl = new FormControl();
  projectControl = new FormControl();
  evaluatorTypeControl = new FormControl();
  client: any = 'All';
  project: any = 'All';
  evaluatorType: any = 'All';
  clients: any[];
  projects: any[];
  projectsOrg: any[];
  selectedEvaluator:any = 'Delivery Partner';
  // resource: any;
  departments: any;
  AllreportsData: any[];
  userRole: string;
  filterClicked:boolean=false;

  constructor(private projectService: ProjectService, private storage: StorageService) {
    const currentYear = new Date().getFullYear();
    this.evaluatorTypes =  EvaluationDropDown.evaluatorTypes;
  }


  ngOnInit() {
    
    this.userRole = this.storage.getItem('selectedRole');
    this.departments = JSON.parse(this.storage.getItem('departments'));
    this.filterChange(this.client,this.project,this.evaluatorType);
    this.cols = [{
      field: 'delivery_manager',
      header: 'Name'
    },
    {
      field: 'department_name',
      header: 'Department'
    },
    {
      field: 'rwt_name',
      header: 'RWT'
    },
    ];

    this.reports.length < this.rows ? this.temDataLength = this.reports.length : this.temDataLength = this.rows;
  }

  expandAll() {
    if (!this.isExpanded) {
      this.reports.forEach(data => {
        this.expandedRows[data.row_id] = true;
      });
    } else {
      this.expandedRows = {};
    }
    this.isExpanded = !this.isExpanded;
  }

  onRowExpand() {
    if (Object.keys(this.expandedRows).length === this.temDataLength) {
      this.isExpanded = true;
    }
  }

  onRowCollapse() {
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

  onPage(event: any) {
    this.temDataLength = this.reports.slice(event.first, event.first + 10).length;
    this.isExpanded = false;
    this.expandedRows = {};
  }

  

  filterChange(client,project,evaluatorType) {
    this.updateFlagCnt = 0;
    this.client = client;
    this.project = project;
    this.evaluatorType = evaluatorType;
    this.getData();
  }


  clearFilters(){
    this.updateFlagCnt = 0;
    this.client = 'All';
    this.project = 'All';
    this.evaluatorType = 'All';
    this.getData();
  }

  getData() {
    
    this.loading = true;
    this.innerPage = [];
    this.reportData = [];
    this.reports = [];
   
    this.projectService.getEvaluatorForDM().then((reports) => {
      // this.evaluatorTypes = reports.filter(
      //   (thing, i, arr) => arr.findIndex(t => t.evaluator === thing.evaluator) === i
      // ).sort((a, b) => a.evaluator.localeCompare(b.evaluator));
      this.clients = reports.filter(
        (thing, i, arr) => arr.findIndex(t => t.client_name === thing.client_name) === i
      ).sort((a, b) => a.client_name.localeCompare(b.client_name));
      this.projects = reports.filter(
        (thing, i, arr) => arr.findIndex(t => t.project_name === thing.project_name) === i
      ).sort((a, b) => a.project_name.localeCompare(b.project_name));

      this.projectsOrg = reports.filter(
        (thing, i, arr) => arr.findIndex(t => t.project_name === thing.project_name) === i
      ).sort((a, b) => a.project_name.localeCompare(b.project_name));
      let filterdReports = reports.filter((item: any) => {
        if (this.client !== 'All' && this.project !== 'All' && this.evaluatorType !== 'All') {
          return (item.client_name === this.client) && (item.project_name === this.project) && (item.evaluator === this.evaluatorType);
        } 
         // one field
         else if (this.client !== 'All' && this.project === 'All' && this.evaluatorType === 'All') {
          return (item.client_name === this.client);
        }
        else if (this.client === 'All' && this.project !== 'All' && this.evaluatorType === 'All') {
          return (item.project_name === this.project);
        }
        else if (this.client === 'All' && this.project === 'All' && this.evaluatorType !== 'All') {
          return ((item.evaluator ===null?'Delivery Partner':item.evaluator)  === this.evaluatorType);
        }
        else {
          return item;
        }
      });
      
      this.innerPage = filterdReports;// reports;
      this.reportsOrg = filterdReports; //reports;
      filterdReports.map((report, index) => {
      let project = {};
            if (!this.reportData[report.delivery_manager_id]) {
              this.reportData[report.delivery_manager_id] = {};
              this.reportData[report.delivery_manager_id][report.delivery_manager_id] = {
                'row_id': index,
                'delivery_manager_id': report.delivery_manager_id,
                'delivery_manager': report.delivery_manager,
                'department_name': report.department_name,
                'rwt_name': report.rwt_name,
                'projects': []
              };
              if (report.project_name !== null) {
                project = {
                  'delivery_manager': report.delivery_manager,
                  'client_name': report.client_name,
                  'project_name': report.project_name,
                  'sub_project_id': report.sub_project_id,
                  'project_id': report.project_id,
                  'engagement_manager_id': report.engagement_manager_id,
                  'engagement_partner_id':report.engagement_partner_id,
                  'engagement_manager': report.engagement_manager,
                  'engagement_partner': report.engagement_partner,
                  'delivery_partner_id':report.delivery_partner_id,
                  'evaluator':report.evaluator===null?'Delivery Partner':report.evaluator,
                  'evaluator_id':report.evaluator_id,
                  'isChanged':0
                };
                this.reportData[report.delivery_manager_id][report.delivery_manager_id]['projects'].push(project);
              }
            } 
              else {
                //code added for projects
                  if (report.project_name !== null) {
                    project = {
                      'delivery_manager': report.delivery_manager,
                      'client_name': report.client_name,
                      'project_name': report.project_name,
                      'sub_project_id': report.sub_project_id,
                      'project_id': report.project_id,
                      'engagement_manager_id': report.engagement_manager_id,
                      'engagement_partner_id':report.engagement_partner_id,
                      'engagement_manager': report.engagement_manager,
                      'engagement_partner': report.engagement_partner,
                      'delivery_partner_id':report.delivery_partner_id,
                      'evaluator':report.evaluator===null?'Delivery Partner':report.evaluator,
                      'evaluator_id':report.evaluator_id,
                      'isChanged':0
                    };
                    this.reportData[report.delivery_manager_id][report.delivery_manager_id]['projects'].push(project);
                  } 

              }
        });
      Object.keys(this.reportData).map(item => {
        Object.values(this.reportData[item]).map(monthitem => {
          this.reports.push(monthitem);
        });
      });
      // console.log( JSON.stringify(this.reports));
      // this.AllreportsData = this.reports.sort((a, b) => a.delivery_manager.localeCompare(b.delivery_manager));
      this.loading = false;
    }).catch(err => {
      this.reports = [];
      this.loading = false;
    });
  }
  onEvaluatorChange(row,event) {
      // console.log(row);
      this.updateFlag = true;
      this.updateFlagCnt = this.updateFlagCnt+1;
      row.isChanged = 1;
      this.reportsOrg.filter((item: any) => {
        let evl = item.evaluator===null?'Delivery Partner':item.evaluator;
        if (item.sub_project_id === row.sub_project_id && evl === row.evaluator)
        {
          row.isChanged = 0;
          this.updateFlag = false;
          this.updateFlagCnt = this.updateFlagCnt-2;
        }
      });
      if(this.updateFlagCnt>0){
        this.updateFlag = true;
      }
  }
  saveButtonDialog() {
   // console.log(this.reports);
   this.loading = true;
    let evaluatorMain = [];
    let evaluatorDetail = {};
    for (let i = 0; i < this.reports.length; i++) {
      this.reports[i].projects?.filter((item: any) => {
        if(item.isChanged === 1) {
          evaluatorDetail = {
            project_id:item.project_id,
            sub_project_id:item.sub_project_id,
            evaluator: item.evaluator,
            evaluator_id: item.evaluator==='Engagement Partner'?item.engagement_partner_id:item.engagement_manager_id,
          };
          evaluatorMain.push(evaluatorDetail);
      }
      });
    }
    this.projectService.postEvaluatorForDMData(evaluatorMain).subscribe(
      response => {
        this.loading = false;
        this.updateFlag = false;
        evaluatorMain = [];
        Swal.fire('Evaluator set Successfully ','','success');
        this.filterChange(this.client,this.project,this.evaluatorType);
      },
      err => {
        this.loading = false;
        evaluatorMain = [];
        console.log(err)
      });
 
  }
  onClientChange(client, event) {
    if(client !== 'All') {
    this.projects = this.projectsOrg.filter(
      (thing, i, arr) => arr.findIndex(t => t.project_name === thing.project_name && t.client_name === client) === i
    ).sort((a, b) => a.project_name.localeCompare(b.project_name));
    }
    else {
      this.projects = this.projectsOrg;
    }
  }
}
