import { Component, NgZone, OnInit } from '@angular/core';
import { StorageService } from '../../_services/storage.service'
import { AuthorizationService } from '../../_services/authorization.service';
import { SharedService } from "../../_services/shared.service";
import { Router, ActivatedRoute } from '@angular/router';

import Auth from '@aws-amplify/auth';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  userRole;
  userRoles: Promise<any>;
  userdataSubscription: any;
  userlogged = false;
  locationPath: string;
  userName: string;
  linkText: string;
  constructor(private ngZone: NgZone, private storage: StorageService, private auth: AuthorizationService,
    private sharedServ: SharedService, private router: Router, private route: ActivatedRoute) {
    this.userdataSubscription = this.sharedServ.getloggedInData()
  }

  async ngOnInit(): Promise<void> {
    this.sharedServ.getloggedInData().subscribe(item => {
      this.loggedIn(item)
    })

  }

  ngOnDestroy() {
    this.userdataSubscription.unsubscribe();
  }

  changeUrl(uri) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  async loggedIn(event) {
    this.userRole = this.storage.getItem("selectedRole");
    this.linkText = 'Resource Evaluation On Projects';
    if(this.userRole === 'Delivery Partner') {
      this.linkText = 'SPDM Evaluation On Projects';
    }
    Auth.currentAuthenticatedUser().then(session => {
      this.ngZone.run(() => {
        this.userRole = this.storage.getItem("selectedRole");
        let groups = session['signInUserSession']['accessToken']['payload']['cognito:groups'].sort(); //Added sort function march 4th 21
        this.userName = session['attributes']['given_name'];
        let userDetails = {
          name: session['attributes']['given_name'],
          userID: session['signInUserSession']['idToken']['payload']['cognito:username'],
          email: session['signInUserSession']['idToken']['payload']['email'],
          groups: session['signInUserSession']['idToken']['payload']['cognito:groups']
        }
      
        this.storage.setItem('user', JSON.stringify(userDetails));
        groups = groups.map(group => {
          let group_name = group.replaceAll('_', ' ');
          return group_name
        })
        this.userRoles = groups;
      })
    }).catch(err => {

    });
  }

  roleSwitcher(user) {
    this.userRole = user;
    this.storage.setItem('selectedRole', user);
    if (this.userRole === 'Project Team Member' ) {
      this.changeUrl('resource/actuals');
    }
    else if(this.userRole === 'PMO Admin' || this.userRole==='HR Admin'){
     // this.router.navigate(['resource/submissionreport'])
      this.changeUrl('resource/submissionreport');
    }
    else if (this.userRole === 'Billing Manager' || this.userRole === 'Delivery Manager' || this.userRole === 'Delivery Partner' || this.userRole === 'Sub Project Delivery Manager'
      || this.userRole === 'Engagement Partner' || this.userRole === 'Invoice Manager' || this.userRole==='Engagement Manager') {
      this.changeUrl('projects');
    }
    else if (this.userRole === 'Resource Manager') {
      this.changeUrl('/resource_manager');
    } else {
      this.changeUrl('/home');
    }
  }

  logout() {
    this.auth.logOut();
  }


}

