<ng-template #customLoadingTemplate>
  <div class="ctmloader">
    <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>

<div class="back">
  <div class="container-fluid pt-3">
    <div class="row">
      <div class="col-6">
        <h4>Manage Resourcess</h4>
      </div>
      <div class="col-6 text-right">

        <ng-container>
          <button (click)="AddResourcePopup()" class="btn crvdbtn cstbtn btn2">
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Resource
          </button>

          <button type="button" selectionMode="multiple" (click)="exportExcel()" class="btn cstbtn btn2 ml-3"><i
              class="fa fa-download" aria-hidden="true"></i> Download this report as
            Excel</button>

        </ng-container>
      </div>
    </div>
    <div class="full-height">

      <p-sidebar (onHide)="onHide()" [(visible)]="visibleSidebar2" position="right" [style]="{width:'90em'}">
        <form name="myForm" [formGroup]="form">
          <ngx-loading [show]="popupLoading" [config]="{ backdropBorderRadius: '3px' }"
            [template]="customLoadingTemplate">
          </ngx-loading>
          <h5 style="font-weight:normal">{{popupHeader}}</h5>
          <p class="mb-1"><span class="red">*</span> Please Fill Required Fields</p>
          <p-toast></p-toast>

          <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabChange($event)">
            <p-tabPanel header="Basic Info">
              <div class="row">
                <div class="col">
                  <!-- <div class="form-group">
                  <div class="col-sm-12">
                    <label for="email" class="control-label">Email</label>
                    <input type="text" id="email" [(ngModel)]="email" class="form-control" name="email" formControlName="email">
                  </div>
                </div>
              
                <div class="form-group">
                  <div class="col-sm-12">
                    <label for="password" class="control-label">Password</label>
                    <input type="password" id="password" [(ngModel)]="password"  name="password"  class="form-control" formControlName="password">
                  </div>
                </div> -->
                  <div class="form-group">
                    <label><span class="red">*</span> Resource Name</label>
                    <input type="text" [(ngModel)]="resourceData.resource_name" formControlName="resource_name" required
                      minlength="4" name="resource_name" class="form-control" id="resource_nameid">
                    <div *ngIf="isFieldValid('resource_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please enter name
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Resource Email</label>
                    <input type="text" [(ngModel)]="resourceData.resource_email" [readonly]="isReadonly"
                      formControlName="resource_email" name="resource_email" class="form-control" id="resource_emailid">
                    <div *ngIf="isFieldValid('resource_email')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div *ngIf="resourceData.resource_email.length === 0" class="error-msg">
                        Please enter email Id
                      </div>
                      <div *ngIf="resourceData.resource_email.length > 0" class="error-msg">

                        Please enter valid email
                      </div>
                    </div>
                    <div *ngIf="isEmailExists">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Entered email already exists
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Resource Start Date</label>
                    <input type="Date" [(ngModel)]="resourceData.resource_start_date"
                      formControlName="resource_start_date" name="resource_start_date" class="form-control"
                      id="resource_start_dateid">
                    <div *ngIf="isFieldValid('resource_start_date')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please enter start date
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Resource End Date</label>
                    <input type="Date" [(ngModel)]="resourceData.resource_last_date"
                      formControlName="resource_last_date" name="resource_last_date" class="form-control"
                      id="resource_last_dateid">
                  </div>
                </div>


                <!-- <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> RWT</label>
                    <select [(ngModel)]="resourceData.rwt_name" name="rwt_name" formControlName="rwt_name"
                      (change)="onRWTChange(resourceData.rwt_name)" class="form-control">
                      <option value='' selected>--Select--</option>
                      <option *ngFor="let rwt of rwts" value="{{rwt.rwt_name}}">
                        {{ rwt.rwt_name }}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('rwt_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select RWT
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Level Name</label>
                    <select [(ngModel)]="resourceData.level_name" name="level_name" formControlName="level_name"
                      class="form-control" id="exampleFormControlSelect1">
                      <option value='--Select--' selected>--Select--</option>
                      <option *ngFor="let level of levels" value="{{level.level_name}}">
                        {{ level.level_name }}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('level_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select level name
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Line Group</label>
                    <select [(ngModel)]="resourceData.line_group_name" name="line_group_name"
                      formControlName="line_group_name" class="form-control">
                      <option value='' selected>--Select--</option>
                      <option *ngFor="let lineGroup of lineGroups" value="{{lineGroup.line_group_name}}">
                        {{ lineGroup.line_group_name }}
                      </option>
                    </select>
                    <!-- <div *ngIf="isFieldValid('line_group_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select line group name
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Group Assigned</label>
                    <select [(ngModel)]="resourceData.department_name" name="department_name"
                      formControlName="department_name" class="form-control" id="exampleFormControlSelect1">
                      <option value='--Select--' selected>--Select--</option>
                      <option *ngFor="let dept of departments" value="{{dept.department_name}}">
                        {{ dept.department_name }}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('department_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select department
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> BU</label>
                    <select [(ngModel)]="resourceData.bu_name" name="bu_name" formControlName="bu_name"
                      (change)="onBUChange(resourceData.bu_name)" class="form-control" id="exampleFormControlSelect1">
                      <option value='--Select--' selected>--Select--</option>
                      <option *ngFor="let bu of businessUnits" value="{{bu.bu}}">
                        {{ bu.bu }}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('bu_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select BU
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Sub BU</label>
                    <select [(ngModel)]="resourceData.subBU_name" name="subBU_name" formControlName="subBU_name"
                      class="form-control" id="exampleFormControlSelect1">
                      <option value='--Select--' selected>--Select--</option>
                      <option *ngFor="let sub_BU of subBU" value="{{sub_BU.sub_bu}}">
                        {{ sub_BU.sub_bu }}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('subBU_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select Sub BU
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Location</label>
                    <select [(ngModel)]="resourceData.location_name" name="location_name"
                      formControlName="location_name" class="form-control" id="exampleFormControlSelect1">
                      <option value='--Select--' selected>--Select--</option>
                      <option *ngFor="let location of locations" value="{{location.location_name}}">
                        {{ location.location_name }}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('location_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select location
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Sub Location</label>
                    <select [(ngModel)]="resourceData.sub_location_name" name="sub_location_name"
                      formControlName="sub_location_name" class="form-control">
                      <option value='--Select--' selected>--Select--</option>
                      <option *ngFor="let subLocation of subLocations" value="{{subLocation.sub_location_name}}">
                        {{ subLocation.sub_location_name }}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('sub_location_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select sub location
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Resource Manager</label>
                    <select [(ngModel)]="resourceData.rm_name" name="rm_name" formControlName="rm_name"
                      class="form-control">
                      <option value='--Select--' selected>--Select--</option>
                      <option *ngFor="let resource_RM of resource_RMs" value="{{resource_RM.resource_name}}">
                        {{ resource_RM.resource_name}}
                      </option>
                    </select>
                    <div *ngIf="isFieldValid('resource_name')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please select RM
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label><span class="red">*</span> Billable Percentage</label>
                    <!-- <input type="text" [(ngModel)]="resourceData.billable_percent" name="billable_percent" formControlName="billable_percent" class="form-control" id=""> -->
                    <input pInputText type="number" pattern="\d+(\.\d{1,2})?" maxlength="4"
                      onKeyPress="if(this.value.length==4) return false;" [(ngModel)]="resourceData.billable_percent"
                      name="billable_percent" formControlName="billable_percent" class="form-control" id="">

                    <div *ngIf="isFieldValid('billable_percent')">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please enter billable %
                      </div>
                    </div>
                    <div *ngIf="isValidPercent">
                      <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                      <span class="sr-only">(error)</span>
                      <div class="error-msg">
                        Please enter a billable % between 0 and 1
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col">
                  <label><span class="red"></span> Employee ID</label>

                  <input pInputText type="number" pattern="\d+(\.\d{1,2})?" maxlength="6"
                    onKeyPress="if(this.value.length==6) return false;" [(ngModel)]="resourceData.employee_id"
                    name="employee_id" formControlName="employee_id" class="form-control" id="employee_id">


                  <!-- <div *ngIf="isFieldValid('employee_id')">
                    <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                    <span class="sr-only">(error)</span>
                    <div class="error-msg">
                      Please enter employee id
                    </div>
                  </div> -->
                </div>

                <div class="col">
                  <div style="padding-top: 28px;" class="form-group">
                    <p-checkbox name="isActive" label="Active" [(ngModel)]="isActive" binary="true"
                      formControlName="isActive"> </p-checkbox>
                  </div>
                </div>
                <div class="col">
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group mb-0">
                    <label><span class="red">*</span> User Group</label>
                  </div>
                </div>
              </div>
              <div class="side-data">
                <div class="row">
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="1" label="Project Team Member" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="2" label="Billing Manager" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="3" label="PMO Admin" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col ">
                    <p-checkbox name="cognitoGroup" value="4" label="Engagement Partner" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="5" (onChange)="dmChange()" label="Delivery Manager"
                      [(ngModel)]="selectedRoles" formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="6" [disabled]="isDMChecked"
                      label="Sub Project Delivery Manager" [(ngModel)]="selectedRoles" formControlName="selectedRoles">
                    </p-checkbox>
                  </div>
                </div>

                <div class="row mt-2">

                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="7" label="Invoice Manager" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"> </p-checkbox>
                  </div>

                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="8" label="Engagement Manager" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="9" label="Resource Manager" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="10" label="Delivery Partner" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="12" label="Talent Acquisition Manager"
                      [(ngModel)]="selectedRoles" formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="13" label="Practice Delivery Manager"
                      [(ngModel)]="selectedRoles" formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox name="cognitoGroup" value="14" label="Delivery Ops Lead" [(ngModel)]="selectedRoles"
                      formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                    <p-checkbox *ngIf="userRole !=='PMO Admin'" name="cognitoGroup" value="11" label="HR Admin"
                      [(ngModel)]="selectedRoles" formControlName="selectedRoles"></p-checkbox>
                  </div>
                  <div class="col">
                  </div>
                </div>

              </div>
              <div *ngIf="isFieldValid('selectedRoles')">
                <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                <span class="sr-only">(error)</span>
                <div class="error-msg">
                  Please select atleast one group
                </div>
              </div>

              <div class="row sidebtngroup text-center">
                <div class="col">
                  <!-- <p-button type="button" (click)="visibleSidebar2 = false" label="Save" styleClass="p-button-success btn4"> -->
                  <p-button type="button" (click)="SaveResource()" label="Save" icon="fa fa-floppy-o"
                    styleClass="p-button-success btn4">
                  </p-button>
                  <p-button type="button" (click)="cancel()" label="Cancel" icon="fa fa-times"
                    styleClass="p-button-secondary ml-3"></p-button>
                  <!-- [disabled]="!form.valid" -->
                  <!-- <button  (click)="onSubmit()" class="btn btn-primary" >
                    Submit 
                  </button> -->
                </div>
              </div>
            </p-tabPanel>

            <p-tabPanel [disabled]="isAdd" header="RWT">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label><span class="red">*</span> RWT</label>
                    <select [(ngModel)]="resourceData.rwt_name" name="rwt_name" formControlName="rwt_name"
                      (change)="onRWTChange(resourceData.rwt_name)" class="form-control">
                      <option value='' selected>--Select--</option>
                      <option *ngFor="let rwt of rwts" value="{{rwt.rwt_name}}">
                        {{ rwt.rwt_name }}
                      </option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && resourceData.rwt_name ==='' ">RWT Name is
                      required.</small>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label><span class="red">*</span> Level Name</label>
                    <select [(ngModel)]="resourceData.rwt_level_name" name="rwt_level_name"
                      formControlName="rwt_level_name"
                      (change)="OnRwtLevelChange(resourceData.rwt_name,resourceData.rwt_level_name)"
                      class="form-control" id="exampleFormControlSelect1">
                      <option value='' selected>--Select--</option>
                      <option *ngFor="let level of levels" value="{{level.level_name}}">
                        {{ level.level_name }}
                      </option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && resourceData.rwt_level_name ==='' ">RWT Level is
                      required.</small>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label><span class="red">*</span> Location</label>
                    <select [(ngModel)]="resourceData.rwt_location_name" name="rwt_location_name"
                      formControlName="rwt_location_name" class="form-control" id="exampleFormControlSelect1">
                      <option value='' selected>--Select--</option>
                      <option *ngFor="let location of locations" value="{{location.location_name}}">
                        {{ location.location_name }}
                      </option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && resourceData.rwt_location_name ==='' ">RWT Location is
                      required.</small>
                  </div>
                </div>

              </div>


              <div class="row">
                <div class="col-2">
                  <label for="dtPicker" id="lbldtpicker" class="d-block text-left mr-2">From Date</label>
                  <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="dp1" [formControl]="resourceData.rwt_start_date"
                      class="form-control" id="rwt_start_dateid" [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1 startView="year" (yearSelected)="chosenstartYearHandler($event)"
                      (monthSelected)="chosenstartMonthHandler($event, dp1)" panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
                  <small class="p-invalid" *ngIf="isDateValidate()  ">End
                    Date is greater than Start Date.</small>
                </div>
                <div class="col-2">
                  <label for="dtPicker" id="lbldtpicker" class="d-block text-left mr-2">To Date</label>
                  <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="dp2" [formControl]="resourceData.rwt_end_date"
                      formControlName="rwt_end_date" name="rwt_end_date" class="form-control" id="rwt_end_dateid"
                      [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2 startView="year" (yearSelected)="chosenendYearHandler($event)"
                      (monthSelected)="chosenendMonthHandler($event, dp2)" panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
                  <small class="p-invalid" *ngIf="isDateValidate()  ">End
                    Date is greater than Start
                    Date.</small>
                </div>
                <div class="col-8 text-center mt-2">

                  <div class="sidebtngroup text-center">

                    <!-- <p-button type="button" (click)="visibleSidebar2 = false" label="Save" styleClass="p-button-success btn4"> -->
                    <p-button type="button" (click)="SaveRWT()" label="Save" icon="fa fa-floppy-o"
                      styleClass="p-button-success btn4">
                    </p-button>
                    <p-button type="button" (click)="cancel()" label="Cancel" icon="fa fa-times"
                      styleClass="p-button-secondary ml-3">
                    </p-button>
                    <!-- [disabled]="!form.valid" -->
                    <!-- <button  (click)="onSubmit()" class="btn btn-primary" >
                          Submit 
                        </button> -->

                  </div>

                </div>
              </div>

              <div class="mt-2">
                <p-table [value]="manageResourceRWTDataTable" class="bordertop" #dt [paginator]="true"
                  [autoLayout]="true" [rows]="5" [rowsPerPageOptions]="[5,10,15,20]" pageLinks="3">

                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="rwt_name">RWT Name<p-sortIcon field="rwt_name"></p-sortIcon>
                      </th>
                      <th pSortableColumn="start_date">Level<p-sortIcon field="start_date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="start_date">Location<p-sortIcon field="start_date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="start_date">RWT Start Date<p-sortIcon field="start_date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="end_date">RWT End Date<p-sortIcon field="end_date"></p-sortIcon>
                      </th>
                      <!-- <th pSortableColumn="">Action<p-sortIcon field=""></p-sortIcon>
                      </th> -->

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rwtDataItem>
                    <tr>
                      <td>{{rwtDataItem.rwt_name}}</td>
                      <td>{{rwtDataItem.level_name}}</td>
                      <td>{{rwtDataItem.location_name}}</td>
                      <td>{{rwtDataItem.start_date}}</td>
                      <td>{{rwtDataItem.end_date === '0000-00-00'? '' :rwtDataItem.end_date}}</td>
                      <!-- <td>{{sabbaticalDataItem.start_date}}</td>
                      <td>{{sabbaticalDataItem.end_date}}</td> -->
                      <!-- <td><span><i class="fa red fa-trash-o"
                            aria-hidden="true"></i></span></td> -->
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-tabPanel>
            <p-tabPanel [disabled]="isAdd" header="Sabbatical">
              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <label><span class="red">*</span> Sabbatical Start Date</label>
                    <input type="Date" [(ngModel)]="resourceData.start_date" name="start_date"
                      formControlName="start_date" class="form-control" id="">
                  </div>
                  <div *ngIf="isFieldValid('start_date')">
                    <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                    <span class="sr-only">(error)</span>
                    <div class="error-msg">
                      Please enter start date
                    </div>
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label><span class="red">*</span> Sabbatical End Date</label>
                    <input type="Date" [(ngModel)]="resourceData.end_date" name="end_date" formControlName="end_date"
                      class="form-control" id="">

                  </div>
                  <div *ngIf="isFieldValid('end_date')">
                    <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
                    <span class="sr-only">(error)</span>
                    <div class="error-msg">
                      Please enter start date
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="sidebtngroup text-center">

                    <!-- <p-button type="button" (click)="visibleSidebar2 = false" label="Save" styleClass="p-button-success btn4"> -->
                    <p-button type="button" (click)="SaveSabbatical()" formcont label="Save" icon="fa fa-floppy-o"
                      styleClass="p-button-success btn4">
                    </p-button>
                    <p-button type="button" (click)="CancelSabbatical()" label="Cancel" icon="fa fa-times"
                      styleClass="p-button-secondary ml-3"></p-button>
                    <!-- [disabled]="!form.valid" -->
                    <!-- <button  (click)="onSubmit()" class="btn btn-primary" >
                        Submit 
                      </button> -->

                  </div>
                </div>

              </div>

              <div class="mt-2">
                <p-table [value]="manageResourceSabbaticalDataTable" class="bordertop" #dt [paginator]="true"
                  [autoLayout]="true" [rows]="5" [rowsPerPageOptions]="[5,10,15,20]" pageLinks="3">

                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="start_date">Sabbatical Start Date<p-sortIcon field="start_date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="end_date">Sabbatical End Date<p-sortIcon field="end_date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="">Action<p-sortIcon field=""></p-sortIcon>
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-sabbaticalDataItem>
                    <tr>
                      <td>{{sabbaticalDataItem.start_date}}</td>
                      <td>{{sabbaticalDataItem.end_date}}</td>
                      <td><span (click)="DeleteSabbatical(sabbaticalDataItem)"><i class="fa red fa-trash-o"
                            aria-hidden="true"></i></span></td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>

            </p-tabPanel>
          </p-tabView>
        </form>

      </p-sidebar>
    </div>

    <div class="ctm-card">
      <p-table class="bordertop" [value]="manageResourceDataTable" #dt [paginator]="true" [autoLayout]="true"
        [rows]="10" [rowsPerPageOptions]="[5,10,15,20]" pageLinks="3"
        [globalFilterFields]="['resource_name','resource_email','rwt_name','line_group_name','department_name','rm_name','resource_start_date','sub_location_name']">
        <ng-template pTemplate="caption">
          <div class="d-flex ctmfilter align-items-end">
            <!-- <div>
              <label class="d-block text-left" for="exampleFormControlSelect1">Department</label>
              <select [ngModel]="department" (change)="depChange(departmentControl.value)"
                [formControl]="departmentControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let dept of departments" value="{{dept.department_name}}">
                  {{ dept.department_name }}
                </option>
              </select>
            </div> -->
            <div>
              <label class="d-block text-left" for="exampleFormControlSelect1">BU</label>
              <select [ngModel]="businessUnitFilter" [formControl]="businessUnitControl" style="width: 120px;"
                class="form-control  mb-1" id="exampleFormControlSelect1" (change)="buChange($event)">
                <option value='All'>All</option>
                <option *ngFor="let bunit of buList" value="{{bunit.bu}}">
                  {{ bunit.bu }}
                </option>
              </select>
            </div>
            <div class="ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Resource Manager</label>
              <select [ngModel]="resource_RM" [formControl]="rmControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let resource_RM of resource_RMs" value="{{resource_RM.resource_name}}">
                  {{ resource_RM.resource_name }}
                </option>
              </select>
            </div>
            <div class="ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">RWT</label>
              <select [ngModel]="rwt" [formControl]="rwtControl" style="width: 120px;" class="form-control"
                id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let rwt of rwts" value="{{rwt.rwt_name}}">
                  {{ rwt.rwt_name }}
                </option>
              </select>
            </div>

            <div class=" ml-4">
              <label class="d-block text-left" for="exampleFormControlSelect1">Sub Location</label>
              <select [ngModel]="subLocation" [formControl]="subLocationControl" style="width: 120px;"
                class="form-control" id="exampleFormControlSelect1">
                <option value='All' selected>All</option>
                <option *ngFor="let subLocation of subLocations" value="{{subLocation.sub_location_name}}">
                  {{ subLocation.sub_location_name }}
                </option>
              </select>
            </div>

            <button class="btn cstbtn btn1  ml-3 mb-1"
              (click)="onSearch(rwtControl.value,subLocationControl.value,businessUnitControl.value,rmControl.value)">
              <i class="fa fa-filter" aria-hidden="true"></i> Apply filters
            </button>
            <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                aria-hidden="true"></i> Reset Filters</div>

            <div class="ml-auto bl-1">
              <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="40" placeholder="What are you looking for ?"
                (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th></th>

            <th pSortableColumn="employee_id">Employee Id<p-sortIcon field="employee_id"></p-sortIcon>
            </th>
            <th pSortableColumn="resource_name">Resource Name<p-sortIcon field="resource_name"></p-sortIcon>
            </th>

            <th pSortableColumn="rwtid">RWT<p-sortIcon field="rwtid"></p-sortIcon>
            </th>
            <th pSortableColumn="level_name">Level<p-sortIcon field="level_name"></p-sortIcon>
            </th>
            <th pSortableColumn="line_group_id">Line Group<p-sortIcon field="line_group_id"></p-sortIcon>
            </th>
            <th pSortableColumn="resource_rm_id">Resource Manager<p-sortIcon field="resource_rm_id"></p-sortIcon>
            </th>
            <th pSortableColumn="bu">BU<p-sortIcon field="bu"></p-sortIcon>
            </th>
            <th pSortableColumn="resource_start_date">Start Date<p-sortIcon field="resource_start_date"></p-sortIcon>
            </th>
            <th pSortableColumn="sub_location_id">Sub Location<p-sortIcon field="sub_location_id"></p-sortIcon>
            </th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-resourceDataItem let-i="index" let-rowIndex="rowIndex">
          <tr>
            <td>
              <!-- Add the "+" button here -->

              <i [ngClass]="getChevronIconClass(resourceDataItem)" (click)="toggleRow(resourceDataItem, rowIndex)"></i>

            </td>

            <td>{{ resourceDataItem.employee_id }}</td>
            <td>

              <div>{{ resourceDataItem.resource_name }}</div>
              <p>({{resourceDataItem.resource_email}})</p>
            </td>

            <td>{{ resourceDataItem.rwt_name }}</td>
            <td>{{ resourceDataItem.level_name}}</td>
            <td>{{ resourceDataItem.line_group_name }}</td>
            <td>{{ resourceDataItem.rm_name }}</td>
            <td>{{ resourceDataItem.bu }}</td>
            <td>{{ resourceDataItem.resource_start_date }}</td>
            <td>{{ resourceDataItem.sub_location_name }}</td>

            <td><span (click)="EditResourcePopup(resourceDataItem)"><i tooltipPosition="left"
                  pTooltip="Click here to edit" class="fa faedit fa-pencil-square-o" aria-hidden="true"></i></span></td>

          </tr>
          <tr *ngIf="isExpanded(resourceDataItem)" class="expanded-row">
            <td [attr.colspan]="columnCount">
              <div class="expanded-content">
                <table class="expanded-table">
                  <thead>
                    <tr>
                      <th>RWT Name</th>
                      <th>Level</th>
                      <th>Location</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let detail of resourceDataItem.rwt_details; let i = index">
                      <tr>
                        <td>{{ detail.rwt_name_history }}</td>
                        <td>{{ detail.level }}</td>
                        <td>{{ detail.location_name }}</td>
                        <td>{{ detail.rwt_start_date }}</td>
                        <td>{{ detail.rwt_end_date }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>