import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ProjectService } from '../../_services/project.service';
import { SortEvent } from 'primeng/api';
import { StringPipe } from '../../_pipes/string.pipe';
@Component({
  selector: 'app-health-status',
  templateUrl: './health-status.component.html',
  styleUrls: ['./health-status.component.scss'],
  styles: [`
        :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index:9999;
        }

        @media screen and (max-width: 64em) {
            :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
                top: 99px;
            }
        }
  `]
})
export class HealthStatusComponent implements OnInit {

  originalData: any;
  cols = [];
  totalIssues = 0;
  totalOntrack = 0;
  totalCritical = 0;
  clients: any;
  selectedClient = '0';
  selectedProject = '0';
  projectsDataTable: any;
  projects: any;
  loading: boolean;
  current_Week: any;
  constructor(private apiservice: ProjectService, private StringPipe: StringPipe) { }

  ngOnInit(): void {
    this.loading = true;
    this.getProjectStatus();
  }

  getClass(item: any, index: number) {
    try {
      item = item.split(':')[0];
      if (item == 'On Track') {
        return 'bg-green';
      }
      if (item == 'Issues') {
        if (index == 11) {
          return 'bg-orange boxborder';
        }
        return 'bg-orange';
      }
      if (item == 'Attention') {
        if (index == 11) {
          return 'bg-red boxborder';
        }
        return 'bg-red';
      }

    } catch
    {

    }
    if (index == 0) {
      return 'd-none';
    }

  }
  remarksCallback(item: any) {
    
    let remarks : string;
    try {
      remarks = item.split(':')[1];
    } catch
    {
      remarks = 'No Remarks';
    }
    if (remarks == '' || remarks == undefined) {
      remarks = 'No Remarks';
    }
    return remarks;
  }
  isFailed(item: any) : boolean {
     
    try {
     return  item.split(':')[1]?true:false;
    } catch
    {
      return false
    }
    return true;

}
isURLFailed(item: any) : boolean {
     
  try {
   return  item.split(':')[2]?true:false;
  } catch
  {
    return false
  }
  return true;

}
  getProjectStatus() {

    const currentyear = moment().format('YYYY');
    this.apiservice.getProjectStatusReport(currentyear).then((statusReports) => {
      this.projectsDataTable = statusReports
      this.originalData = this.projectsDataTable;
      //console.log(this.projectsDataTable); 

      this.getColumns();
      this.getLegendData();
      this.getClients();
      this.getProjects();
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.projectsDataTable = []
      this.originalData = this.projectsDataTable;
    })
  }
  getClients() {
    this.clients = this.projectsDataTable.map((item: any) => item.Client_Name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index).sort();
  }
  getProjects() {
    this.projects = this.projectsDataTable.map((item: any) => item.Project_Name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index).sort();
  }
  getLegendData() {
    this.totalIssues = 0;
    this.totalOntrack = 0;
    this.totalCritical = 0;
    this.projectsDataTable.forEach((item: any, index: number) => {
      this.cols.forEach((head: any, ind: number) => {

        if (this.cols.length === (ind + 1)) {
          try {
            let cellItem = item[head.header].split(':')[0];
            if (cellItem === 'Issues') {
              this.totalIssues++;
            }
            if (cellItem === 'On Track') {
              this.totalOntrack++;
            }
            if (cellItem === 'Attention') {
              this.totalCritical++;
            }
          }
          catch
          { }
        }
      });
    });
    // this.current_Week += this.totalIssues
  }
  getColumns() {
    const header = Object.keys(this.projectsDataTable[0]);
    header.forEach((item, index) => {
      this.cols.push({ header: item })
    });
    this.current_Week = (this.cols[11].header).replace(/_/gi, ' ').replace(/to/gi, ' - ');
  }
  replaceUnderscore(text: string) {
    return text.replace(/_/gi, ' ').replace(/to/gi, '<br />');
  }

  applyFilters() {
    if (this.selectedProject !== '0' && this.selectedClient !== '0') {
      this.projectsDataTable = this.originalData.filter((item: any) => {
        if (item.Client_Name === this.selectedClient && item.Project_Name === this.selectedProject) {
          return item;
        }
      });
    }
    else if (this.selectedClient !== '0') {
      this.projectsDataTable = this.originalData.filter((item: any) => {
        if (item.Client_Name === this.selectedClient) {
          return item;
        }
      });
    } else if (this.selectedProject !== '0') {
      this.projectsDataTable = this.originalData.filter((item: any) => {
        if (item.Project_Name === this.selectedProject) {
          return item;
        }
      });
    } else {
      this.projectsDataTable = this.originalData;
    }


    this.getLegendData();
  }

  clearFilters() {
    this.selectedClient = '0';
    this.selectedProject = '0';
    this.projectsDataTable = this.originalData;
  }

  onClientChange(name: any) {
    if (name !== '0') {
      this.projectsDataTable = this.originalData.filter((item: any) => {
        if (item.Client_Name === name) {
          return item;
        }
      });
    } else {
      this.projectsDataTable = this.originalData;
    }
    this.getLegendData();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 === null && value2 !== null) {
        result = -1;
      } else if (value1 != null && value2 === null) {
        result = 1;
      } else if (value1 === null && value2 === null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  exportExcel() {
    const ProjectDetail = [];

    this.projectsDataTable.map(report => {
      let tempData = {};

      this.cols.map(column => {
        let tempHeader = (column.header).replace(/_/gi, ' ').replace(/to/gi, ' - ');
        tempData[tempHeader] = report[column.header]
      })
      ProjectDetail.push(tempData)
    })
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(ProjectDetail);
      const workbook = {
        Sheets: {
          'data': worksheet
        },
        SheetNames: ['data']
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      this.saveAsExcelFile(excelBuffer, 'ProjectStatus');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const currentyear = moment().subtract(1, 'year').format('YYYY');
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_Report_' + currentyear + EXCEL_EXTENSION);
    });
  }
  onDDLClientChange(e) {
  //  console.log(e);
   if(e==='0'){
    this.projects = this.projectsDataTable.map((item: any) => item.Project_Name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index).sort();
   } 
   else {
   this.projects = this.projectsDataTable.filter((cl:any)=> cl.Client_Name === e).map((item: any) => item.Project_Name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index).sort();
   }
  }
  onDownloadClick(downloadProjectStatusUrl){
    //console.log(downloadProjectStatusUrl);
    let fileName = downloadProjectStatusUrl.split(':')[2];
    //console.log(fileName);
    this.apiservice.getDownloadURl(fileName).then(data=>{
    //  console.log(data['url']);
      window.open(data['url'], '_blank');
    })
   
  }


}
