import { Component, OnInit } from '@angular/core';
import { Resource, TimesheetSubmissionService } from 'src/app/_services/timesheet-submission.service';
import { AdminService, RwtModel } from 'src/app/_services/admin.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SharedService } from 'src/app/_services/shared.service';
import { ProjectService } from 'src/app/_services/project.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_services/storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-resource-request',
  templateUrl: './resource-request.component.html',
  styleUrls: ['./resource-request.component.scss']
})
export class ResourceRequestComponent implements OnInit {
  loading = false;
  resourceRequestForm: FormGroup;
  visibleSidebar2: boolean = false;
  clientDropDown: any[];
  projectDropDown: any[];
  clients: any[];
  projects: any[];
  clientSelected = '';
  projectSelected = '';
  dmSelected = '';
  epSelected = '';
  rwtSelected = '';
  levelSelected = '';
  requirement_details = '';
  requirement_description = '';
  requirement_type = '';
  project_category = '';
  project_type = '';
  engagement = '';
  backfill_name = '';
  additional_skill = '';
  practice = '';
  primary_skill_Selected = '';
  no_of_resourcesRequired = '1';
  allocationRequired = 0;
  subprojTblData: any[];
  subprojData: any;
  timesheetDataObj: any = {};
  start_date = '';
  end_date = '';

  rwt: any = 'All';
  rwtItem: any[];
  level: any = 'All';
  location: any = 'All';
  status: any = 'All';
  rwts: any[];
  practices: any[];
  levels: any[];
  locations: any[];
  manageRWTData: RwtModel[];
  deliveryManagers: any[];
  engagementPartner: any[];
  engagementPartnerDropDown: any[];
  deliveryManagerDropDown: any[];
  skillList: any;
  skillSelected: any = [];
  technical_Resource_Panel_1_Selected: any = [];
  technical_Resource_Panel_2_Selected: any = [];
  // resourcesSelected: any = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  resourceDropdownSettings: IDropdownSettings = {};
  // resourceRequestTable: Object;
  isAdd = true;
  isDisplay = true;
  disableForm = true;
  subDeptData: any[];
  coeSelected: any = '';
  resourceAvailablity: any = '';
  requestId: any = '';
  requestor: any = '';
  requested_date: any = '';
  mgntStatus: any = '';
  mgntRemarks: any = '';
  roleName = '';

  department: any = 'All';
  requestStatus: any = 'All';
  engagementFilter: any = 'All';
  requestStatusControl = new FormControl();
  departmentControl = new FormControl();
  engagementControl = new FormControl();
  requestStatusList = [
    { status: "Approval for Hiring" },
    { status: "Approved for Hiring" },
    { status: "Assigned Internal Resource" },
    { status: "Hiring" },
    { status: "Allocation Analysis" },
    { status: "On Hold" },
    { status: "Rejected by PDM" },
    { status: "Requested" },
    { status: "Request Completed" },
  ];
  engagementList = [
    {engagement:"Engagement Team 1"},
    {engagement:"Engagement Team 2"},
    {engagement:"Engagement Team 3"},
    {engagement:"Engagement Team 4"},
    {engagement:"Engagement Team 5"},
    {engagement:"Engagement Team 6"},
    {engagement:"Engagement Team 7"}]
  deliveryfunctions = [];
  //   { department_name: "Digital Engineering" },
  //   { department_name: "Decision Science" },
  //   { department_name: "Data Analytics" },
  //   { department_name: "Quality Engineering" },
  // ];
  submitted: boolean = false;

  resourceRequestTable: any[];
  userRole: any;
  resource: any;
  departments: any;

  isBackFill: boolean = false;
  disabledYData: boolean = false;
  disabledNData: boolean = false;

  public cols: any[];
  public isExpanded: boolean = false;
  public rows: number = 10;
  public expandedRows = {};
  public temDataLength: number = 0;
  resourceArray: Resource[];
  recruiterArray: Resource[];
  resourceDropDown: any = [];
  multipleResourceDropdown: any = [];
  downloadResumeUrl: any;
  resume: any;
  fileSource: any = '';
  isView: boolean;
  // panelList: any = [];
  hr_spoc: any;
  recruiter: any;
  canditateName: any;
  hr_mapping_id: any;
  doj: any;
  candidate_status: any;
  salary=[2,50];
  priority: string;
  min_salary: number = 0;
  max_salary: number = 0;




  constructor(private fb: FormBuilder, private tsService: TimesheetSubmissionService, private adminService: AdminService, private sharedService:SharedService,
    private projectservice: ProjectService, private storage: StorageService) { }

    
  ngOnInit(): void {
    this.roleName = this.storage.getItem('selectedRole');
    this.resourceRequestForm = this.fb.group({
      requirement_details: [{ value: null, disabled: true }, Validators.required],
      requirement_description: [{ value: null, disabled: true }, Validators.required],
      requirement_type: [{ value: null, disabled: true }, Validators.required],
      backfill_name: [{ value: null, disabled: true }, Validators.required],
      client_id: [{ value: null, disabled: true }, Validators.required],
      project_id: [{ value: null, disabled: true }, Validators.required],
      project_category: [{ value: null, disabled: true }, Validators.required],
      project_type : [{ value: null, disabled: true }, Validators.required],
      engagement : [{ value: null, disabled: true }, Validators.required],
      primary_skill: [{ value: null, disabled: true }, Validators.required],
      additional_skill: '',
      practice: [{ value: null, disabled: true }, Validators.required],
      
      delivery_manager_id: [{ value: null, disabled: true }, Validators.required],
      engagement_partner_id: [{ value: null, disabled: true }, Validators.required],
      rwt: [{ value: null, disabled: true }, Validators.required],
      level: [{ value: null, disabled: true }, Validators.required],
      skillset: [{ value: null, disabled: true }, Validators.required],
      allocation_required: [{ value: null, disabled: true }, Validators.required],
      no_of_resource: [{ value: null, disabled: true }, Validators.required],
      resource_availability: [{ value: null, disabled: true }, Validators.required],
      coe: [{ value: null, disabled: true }, Validators.required],
      status: [null, Validators.required],
      remarks: [null, Validators.required],
      start_date: [{ value: null, disabled: true }, Validators.required],
      end_date: [{ value: null, disabled: true }, Validators.required],
      request_id: [{ value: null, disabled: true }, Validators.required],
      hr_spoc: [null, Validators.required],
      recruiter:[null, Validators.required],
      canditateName: [null, Validators.required],
      candidate_status: '',
      hr_mapping_id: null,
      resume: null,
      offered: [null, Validators.required],
      doj: [''],
      priority:[''],
      salary:[[2,50]],
      panel_list: new FormArray([])
    }, { updateOn: 'submit' })

    this.loading = true;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'skill_master_id',
      textField: 'skill_name',
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.resourceDropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'label',
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
      limitSelection: 10
    };

    this.tsService.getResources().then((resources) => {
      const uniqueResource = [];
      resources.map(x => uniqueResource.filter(a => a.resource_id === x.resource_id).length > 0 ? null : uniqueResource.push(x));
      // this.clients = uniqueResource;

      uniqueResource.map(data => {
        this.resourceDropDown.push({ 'label': data.resource_name, 'value': data.resource_id });
        this.multipleResourceDropdown.push({ 'label': data.resource_name, 'value': data.resource_id });
      });
      this.resourceArray = resources.filter(resource => resource.department_name === 'HR')
      this.recruiterArray = resources.filter(resource => resource.department_name === 'HR')
      this.resourceDropDown.splice(0, 0, { 'label': 'Select Resource', 'value': 'Select Resource' });
      // this.resourceSelected = 'Select Resource';
    });

    this.tsService.getSubprojects().then((subproj) => {
      this.subprojData = subproj;
      this.subprojData.map(data => {
        if (!this.timesheetDataObj[data.sub_project_id]) {
          this.timesheetDataObj[data.sub_project_id] = { data };
        }
      });

      this.getClients(this.subprojData);
      this.loading = false;

    }).catch((err) => {
      this.subprojData = [];
      this.loading = false;
    });

    this.userRole = this.storage.getItem('selectedRole');
    this.resource = JSON.parse(this.storage.getItem('user'));
    this.departments = JSON.parse(this.storage.getItem('departments'));
    this.departments.map(department => {
      this.departments[department.department_id] = { 'name': department.department_name };
    });
    //  this.filterChange();
    this.getResourceRequestList()


    this.cols = [{
      field: 'request_id',
      header: 'Request ID'
    },
    {
      field: 'client_name',
      header: 'Client Name'
    },
    {
      field: 'project_name',
      header: 'Project Name'
    },
    {
      field: 'start_date',
      header: 'From Date'
    },
    {
      field: 'end_date',
      header: 'To Date'
    },
    {
      field: 'rwts',
      header: 'RWT-(Level)'
    },
    {
      field: 'requirement_details',
      header: 'Job Title'
    },
    {
      field: 'requirement_type',
      header: 'Requirement Type'
    },
    {
      field: 'skill_name',
      header: 'Primary Skills'
    },
    // {
    //   field: 'additional_skill',
    //   header: 'Additional Skill'
    // },
    
    {
      field: 'practice_name',
      header: 'Practice'
    },
    {
      field: 'priority',
      header: 'Priority'
    },
    {
      field: 'requestor_name',
      header: 'Requested By'
    },
    {
      field: 'status',
      header: 'Status'
    },
    {
      field: 'action',
      header: 'Action'
    }
    ];

    this.resourceRequestTable?.length < this.rows ? this.temDataLength = this.resourceRequestTable?.length : this.temDataLength = this.rows;
    if(this.isView) {
      this.isDisplay = false;
      // this.toggleDisplay();
    }
    this.getDepartment();
    if (this.roleName === 'Practice Delivery Manager'  || this.roleName === 'Delivery Ops Lead') {
      this.disableForm = false;
    } 
  }

  getResourceRequestList() {
    let formData = {};
    // let role = 'HR Admin'
    formData['postBody'] = { "opType": 3, "rolename": this.roleName }
    formData['type'] = 'get'
    this.loading = true;
    this.projectservice.projectResourceRequest(formData).then(
      response => {
        const tempData = response['body']
        const tempObj = {}
        tempData.map(data => {
          data['skill_name'] = data['skill_name'].replaceAll(',', ', ')
          if (!tempObj[data['request_id']]) {
            const resourceData = {}
            tempObj[data['request_id']] = {}
            tempObj[data['request_id']] = data
            // tempObj[data['request_id']]['reqid'] = 'MR_RR00'+ data['request_id']
            tempObj[data['request_id']]['requestedresource'] = []
            tempObj[data['request_id']]['rwts'] = data['rwt_name'] + ' - ' + '(' + data['level'] + ')'
            tempObj[data['request_id']]['client_name'] = data['client_name'] == null?'NA':data['client_name']
            tempObj[data['request_id']]['project_name'] = data['project_name'] == null?'NA':data['project_name']
            resourceData['resume'] = data['resume']
            resourceData['resource_availability'] = data['resource_availability']
            resourceData['remarks'] = data['remarks']
            resourceData['candidate_status'] = data['candidate_status']
            // resourceData['Technical'] = data['Technical']
            resourceData['Technical_Round_1'] = data['Technical_Round_1']
            resourceData['Technical_Round_2'] = data['Technical_Round_2']
            resourceData['Manager'] = data['Manager']
            resourceData['HR_panel'] = data['HR_panel']
            resourceData['offered'] = data['offered']
            resourceData['doj'] = data['doj']
            resourceData['hr'] = data['hr']
            resourceData['recruiter_name'] = data['recruiter_name']
            resourceData['hr_mapping_id'] = data['hr_mapping_id']
            resourceData['name'] = data['name']
            if (data['hr_mapping_id'])
              tempObj[data['request_id']]['requestedresource'].push(resourceData)
          } else {
            const resourceData = {}
            resourceData['resume'] = data['resume']
            resourceData['resource_availability'] = data['resource_availability']
            resourceData['remarks'] = data['remarks']
            resourceData['offered'] = data['offered']
            resourceData['doj'] = data['doj']
            resourceData['hr'] = data['hr']
            resourceData['recruiter_name'] = data['recruiter_name']
            // resourceData['Technical'] = data['Technical']
            resourceData['Technical_Round_1'] = data['Technical_Round_1']
            resourceData['Technical_Round_2'] = data['Technical_Round_2']
            resourceData['Manager'] = data['Manager']
            resourceData['hr_mapping_id'] = data['hr_mapping_id']
            resourceData['HR_panel'] = data['HR_panel']
            resourceData['candidate_status'] = data['candidate_status']
            resourceData['name'] = data['name']
            
            if (data['hr_mapping_id'])
              tempObj[data['request_id']]['requestedresource'].push(resourceData)
          }
          return data;
        })
        // console.log("resourceRequestTable", tempObj)
        const resourceRequestTableList = Object.values(tempObj)
        // this.resourceRequestTable = resourceRequestTableList;

        this.resourceRequestTable = resourceRequestTableList.filter((item: any) => {
          if (this.requestStatus !== 'All' && this.department !== 'All' && this.engagementFilter !== 'All') {
            return (item.status === this.requestStatus) && (item.department_name === this.department)  && (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus !== 'All' && this.department === 'All' && this.engagementFilter === 'All') {
            return (item.status === this.requestStatus);
          }
          else if (this.requestStatus === 'All' && this.department !== 'All' && this.engagementFilter === 'All') {
            return (item.department_name === this.department);
          }
          else if (this.requestStatus === 'All' && this.department === 'All' && this.engagementFilter !== 'All') {
            return (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus !== 'All' && this.department !== 'All' && this.engagementFilter === 'All') {
            return  (item.status === this.requestStatus) && (item.department_name === this.department) ;
          } 
          else if (this.requestStatus !== 'All' && this.department === 'All' && this.engagementFilter !== 'All') {
            return (item.status === this.requestStatus) && (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus === 'All' && this.department !== 'All' && this.engagementFilter !== 'All') {
            return (item.department_name === this.department) && (item.engagement === this.engagementFilter);
          } 
          else if (this.requestStatus === 'All' && this.department === 'All' && this.engagementFilter === 'All') {
            return item;
          }
        });

        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        this.resourceRequestTable = [];
      });
    
  }

  clientChange(ev) {
    this.projectDropDown = [];
    this.subprojTblData = [];
    this.projectDropDown.push({ 'label': '--Select Project--', 'value': '' });
    let selectedValue = Number(ev.target.value)
    this.projects.map(x => {
      if (ev.target.value === '') {
        this.projectDropDown.push({ 'label': x.name, 'value': x.id });
      } else if (x.client_id === selectedValue) {
        this.projectDropDown.push({ 'label': x.name, 'value': x.id }); 
      }
    });
    if(!this.isBackFill){
      this.projectDropDown.push({ 'label': 'NA', 'value': '0' });
    }
    
  }

  projectChange(ev) {

    let selectedValue = Number(ev.target.value)
    this.engagementPartner.map(x => {
      if (!selectedValue) {
        this.epSelected = '';
      }
      if (x.project_id === selectedValue) {
        this.epSelected = x.id;
      }
    });
    this.deliveryManagers.map(x => {
      if (!selectedValue) {
        this.dmSelected = ''
      }
      if (x.project_id === selectedValue) {
        this.dmSelected = x.id;
      }

    });
  }

  getManageRWTData() {
    this.rwts = [];
    this.levels = [];
    // this.rwts.push({ 'label': 'Select Rwt', 'value': '' });
    // this.levels.push({ 'label': 'Select Level', 'value': '' });
    this.adminService.getManageRWT().then(resourceData => {
      resourceData = resourceData.filter(resource => resource.r_status === 'Submitted')
      this.rwts = resourceData.filter(
        (thing, i, arr) => arr.findIndex(t => t.rwt_name === thing.rwt_name) === i
      ).sort((a, b) => a.rwt_name.localeCompare(b.rwt_name));
      // this.locations = resourceData.filter(
      //   (thing, i, arr) => arr.findIndex(t => t.location_name === thing.location_name) === i
      // );
      this.levels = resourceData.filter(
        (thing, i, arr) => arr.findIndex(t => t.level_name === thing.level_name) === i
      ).sort((a, b) => a.level_name.localeCompare(b.level_name));
    }).catch((err) => {
      this.manageRWTData = [];
    });



  }

  getClients(dataObj) {
    const clientsData = [];
    const ProjectsData = [];
    const DeliveryManagerData = [];
    const EngagementPartnerData = [];
    this.clientDropDown = [];
    this.clientDropDown.push({ 'label': '--Select Client--', 'value': '' });


    this.projectDropDown = [];
    this.projectDropDown.push({ 'label': '--Select Project--', 'value': '' });
    this.engagementPartnerDropDown = [];
    this.engagementPartnerDropDown.push({ 'label': 'Select Engagement Partner ', 'value': '' });
    this.deliveryManagerDropDown = [];
    this.deliveryManagerDropDown.push({ 'label': 'Select Delivery Manager', 'value': '' });
    dataObj.map(data => {
      clientsData.push({ 'id': data.client_id, 'name': data.client_name });
      ProjectsData.push({ 'id': data.project_id, 'name': data.project_name, 'client_id': data.client_id });
      DeliveryManagerData.push({ 'id': data.delivery_manager_id, 'name': data['Delivery Manager'], 'project_id': data.project_id })
      EngagementPartnerData.push({ 'id': data.engagement_partner_id, 'name': data['Engagement Manager'], 'project_id': data.project_id })
    });

    const uniqueClient = [];
    clientsData.map(x => uniqueClient.filter(a => a.id === x.id).length > 0 ? null : uniqueClient.push(x));
    this.clients = uniqueClient;

    this.clients.map(x => {
      this.clientDropDown.push({ 'label': x.name, 'value': x.id });
    });
    
    const uniqueProject = [];
    ProjectsData.map(x => uniqueProject.filter(a => a.id === x.id).length > 0 ? null : uniqueProject.push(x));
    this.projects = uniqueProject;
    this.projects.map(x => {
      this.projectDropDown.push({ 'label': x.name, 'value': x.id });
    });
    if(!this.isBackFill) {
      this.clientDropDown.push({ 'label': 'NA', 'value': '0' });
      this.projectDropDown.push({ 'label': 'NA', 'value': '0' });
    }
    const uniqueDeliveryManger = [];
    let uniqueDeliveryMangerDropdown = [];
    DeliveryManagerData.map(x => uniqueDeliveryManger.filter(a => a.project_id === x.project_id).length > 0 ? null : uniqueDeliveryManger.push(x));
    this.deliveryManagers = uniqueDeliveryManger;
    this.deliveryManagers.map(x => {
      this.deliveryManagerDropDown.push({ 'label': x.name, 'value': x.id });
    });
    this.deliveryManagerDropDown.map(a => uniqueDeliveryMangerDropdown.filter(x => a.value === x.value).length > 0 ? null : uniqueDeliveryMangerDropdown.push(a));

    const uniqueEngagementPartner = [];
    let uniqueEngagementPartnerDropdown = [];
    EngagementPartnerData.map(x => uniqueEngagementPartner.filter(a => a.project_id === x.project_id).length > 0 ? null : uniqueEngagementPartner.push(x));
    this.engagementPartner = uniqueEngagementPartner;
    this.engagementPartner.map(x => {
      this.engagementPartnerDropDown.push({ 'label': x.name, 'value': x.id });
    });
    this.engagementPartnerDropDown.map(a => uniqueEngagementPartnerDropdown.filter(x => a.value === x.value).length > 0 ? null : uniqueEngagementPartnerDropdown.push(a));
    this.deliveryManagerDropDown = uniqueDeliveryMangerDropdown;
    this.engagementPartnerDropDown = uniqueEngagementPartnerDropdown;
    this.deliveryManagerDropDown.push({ 'label': 'Priyanka Padhi', 'value':'989'})
    this.engagementPartnerDropDown.push({ 'label': 'NA', 'value':'0'})
    this.getManageRWTData();
    this.getSkillMasterList();
    this.getDepartment();

  }

  expandAll() {
    if (!this.isExpanded) {
      this.resourceRequestTable.forEach(data => {
        if(data.status !== 'Assigned Internal Resource')
          this.expandedRows[data.request_id] = true;
      });
    } else {
      this.expandedRows = {};
    }
    this.isExpanded = !this.isExpanded;
  }

  onRowExpand() {
    if (Object.keys(this.expandedRows).length === this.temDataLength) {
      this.isExpanded = true;
    }
  }

  onRowCollapse() {
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

  onPage(event: any) {
    this.temDataLength = this.resourceRequestTable.slice(event.first, event.first + 10).length;
    this.isExpanded = false;
    this.expandedRows = {};
  }

  getSkillMasterList() {
    let formData = {};
    formData['type'] = 'get'
    formData['postBody'] = {
      'opType': 5
    };

    this.adminService.postResourceSkillData(formData).subscribe(
      response => {
        this.skillList = response['body'].sort((a, b) => a.skill_name.localeCompare(b.skill_name));
        // this.getSubDepartment();
      },
      err => {

        console.log(err)
        this.skillList = [];
      });
  }

  onItemSelect(item: any) {
   
  }
  onSelectAll(item: any) {
    this.skillSelected = this.skillList.map(x => x.skill_name);
  }
  onDeSelectAll() {
    this.skillSelected = [];
  }
  onItemDeSelect(item: any) {

  }
  onResourceSelect(item: any) {
    
  }
  // onResourceSelectAll(item: any) {
  //   this.resourcesSelected = this.multipleResourceDropdown.map(x => x.value);
  // }
  // onResourceDeSelectAll() {
  //   this.resourcesSelected = [];
  // }

  onResource1_SelectAll(item: any) {
    this.technical_Resource_Panel_1_Selected = this.multipleResourceDropdown.map(x => x.value);
  }
  onResource1_DeSelectAll() {
    this.technical_Resource_Panel_1_Selected = [];
  }
  onResource2_SelectAll(item: any) {
    this.technical_Resource_Panel_2_Selected = this.multipleResourceDropdown.map(x => x.value);
  }
  onResource2_DeSelectAll() {
    this.technical_Resource_Panel_2_Selected = [];
  }

  EditResourcerequest(item) {
    this.isView = false;
    let skillArray = item.skill_id ? item.skill_id.split(/[ ,]+/).map(Number): []
    this.skillSelected = []
    // let resourcesArray = item.technical_panel_id? item.technical_panel_id?.split(/[ ,]+/).map(Number) : []
    let resources_1_Array = item.technical_panel_1_id? item.technical_panel_1_id?.split(/[ ,]+/).map(Number) : []
    let resources_2_Array = item.technical_panel_2_id? item.technical_panel_2_id?.split(/[ ,]+/).map(Number) : []
    this.technical_Resource_Panel_1_Selected= [];
    this.technical_Resource_Panel_2_Selected= [];
    // this.resourcesSelected = []
   
    let skills = this.skillList.filter(x => skillArray.includes(x.skill_master_id));
    skills.map(skill => {
      this.skillSelected.push({ "skill_master_id": skill.skill_master_id, "skill_name": skill.skill_name })
      return skill
    })
    // let resources = this.multipleResourceDropdown.filter(x => resourcesArray.includes(x.value));
    // resources.map(resource => {
    //   this.resourcesSelected.push({ "value": resource.value, "label": resource.label })
    //   return resource
    // })
    let resources_1 = this.multipleResourceDropdown.filter(x => resources_1_Array.includes(x.value));
    resources_1.map(resource => {
      this.technical_Resource_Panel_1_Selected.push({ "value": resource.value, "label": resource.label })
      return resource
    })
    let resources_2 = this.multipleResourceDropdown.filter(x => resources_2_Array.includes(x.value));
    resources_2.map(resource => {
      this.technical_Resource_Panel_2_Selected.push({ "value": resource.value, "label": resource.label })
      return resource
    })
    this.visibleSidebar2 = true;
    this.isAdd = false;
    this.isDisplay = true;
    this.submitted = false;
    // Swal.fire('Request for resource Created', '', 'success');
    this.requirement_details = item.requirement_details;
    this.requirement_description = item.requirement_description;
    this.requirement_type = item.requirement_type;
    this.backfill_name = item.backfill_name;
    this.additional_skill = item.additional_skill;
    this.practice = item.practice;
    this.priority = this.priority == null ? 'High':item.priority;
    this.clientSelected = item.client_id;
    this.projectSelected = item.project_id;
    this.project_category = item.project_category;
    this.project_type = item.project_type;
    this.engagement = item.engagement;
    this.primary_skill_Selected = item.primary_skill;
    this.dmSelected = item.delivery_manager_id;
    this.epSelected = item.engagement_partner_id;
    this.rwtSelected = item.rwt;
    this.levelSelected = item.level;
    this.allocationRequired = item.allocation_required;
    this.no_of_resourcesRequired = item.no_of_resource;
    // this.skillSelected=item.skill_id?.split(/[ ,]+/).map(Number);
    this.resourceAvailablity = item.resource_availability;
    this.coeSelected = item.coe;
    this.mgntStatus = item.status;
    this.mgntRemarks = item.remarks;
    this.start_date = item.start_date;
    this.end_date = item.end_date;
    this.requestId = item.request_id;
    this.requestor = item.requestor_name;
    this.requested_date = item.requested_on;
    // this.salary = [item.min_salary,item.max_salary];
    this.min_salary = item.min_salary;
    this.max_salary = item.min_salary;
    this.salary = item.max_salary? [item.min_salary,item.max_salary] : [2,50];
  }

  viewResourceRequestPopup(item,hr_ID) {
    
    this.loading = true;
    this.submitted = false;
    this.isView = true;
    this.isDisplay = false;
    let skillArray = item.skill_id ? item.skill_id.split(/[ ,]+/).map(Number): []
    

    // let resourcesArray = item.technical_panel_id? item.technical_panel_id?.split(/[ ,]+/).map(Number) : []
    // this.skillSelected = []
    // let skills = this.skillList.filter(x => skillArray.includes(x.skill_master_id));
    // skills.map(skill => {
    //   this.skillSelected.push({ "skill_master_id": skill.skill_master_id, "skill_name": skill.skill_name })
    //   return skill
    // })
    // this.resourcesSelected=[]
    // let resources = this.multipleResourceDropdown.filter(x => resourcesArray.includes(x.value));
    // resources.map(resource => {
    //   this.resourcesSelected.push({ "value": resource.value, "label": resource.label })
    //   return resource
    // })

    this.skillSelected = []
    let resources_1_Array = item.technical_panel_1_id? item.technical_panel_1_id?.split(/[ ,]+/).map(Number) : []
    let resources_2_Array = item.technical_panel_2_id? item.technical_panel_2_id?.split(/[ ,]+/).map(Number) : []
    this.technical_Resource_Panel_1_Selected= [];
    this.technical_Resource_Panel_2_Selected= [];
    let skills = this.skillList.filter(x => skillArray.includes(x.skill_master_id));
    skills.map(skill => {
      this.skillSelected.push({ "skill_master_id": skill.skill_master_id, "skill_name": skill.skill_name })
      return skill
    })
    let resources_1 = this.multipleResourceDropdown.filter(x => resources_1_Array.includes(x.value));
    resources_1.map(resource => {
      this.technical_Resource_Panel_1_Selected.push({ "value": resource.value, "label": resource.label })
      return resource
    })
    let resources_2 = this.multipleResourceDropdown.filter(x => resources_2_Array.includes(x.value));
    resources_2.map(resource => {
      this.technical_Resource_Panel_2_Selected.push({ "value": resource.value, "label": resource.label })
      return resource
    })

    this.panelList.clear();
    this.resume = item.resume;
    this.isAdd = false;
    let formData = {};
    formData['postBody'] = { "opType": 4, "request_id": item.request_id }
    formData['type'] = 'get'
    const tempdataObj = {}
    this.projectservice.projectResourceRequest(formData).then(
      response => {
       
        // const tempData = response['body'];
        const tempData = response['body'].filter(data => { if (data.hr_mapping_id === hr_ID) return true })
        tempData.map(data => {
          if (!tempdataObj[data['request_id']]) {
            tempdataObj[data['request_id']] = {}
            tempdataObj[data['request_id']] = data
            tempdataObj[data['request_id']]['panelDetails'] = []
            const resourceData = {}
            if (data['Technical_Round_1'] || data['Technical_Round_2'] ) {
              resourceData['dynamicPnlResource'] = data['panel_member']
              resourceData['dynamicPnlFeedback'] = data['panel_member_feedback']
              resourceData['dynamicPnlType'] = data['panel_type']
            } else if (data['Manager']) {
              resourceData['dynamicPnlResource'] = data['panel_member']
              resourceData['dynamicPnlFeedback'] = data['panel_member_feedback']
              resourceData['dynamicPnlType'] = data['panel_type']
            }
            else if (data['HR_panel']) {
              resourceData['dynamicPnlResource'] = data['panel_member']
              resourceData['dynamicPnlFeedback'] = data['panel_member_feedback']
              resourceData['dynamicPnlType'] = data['panel_type']
            }
            if (data['Technical_Round_1'] || data['Technical_Round_2'] || data['Manager'] || data['HR_panel'])
              tempdataObj[data['request_id']]['panelDetails'].push(resourceData)
          } else {
            const resourceData = {}
            if (data['Technical_Round_1'] || data['Technical_Round_2'] ) {
              resourceData['dynamicPnlResource'] = data['panel_member']
              resourceData['dynamicPnlFeedback'] = data['panel_member_feedback']
              resourceData['dynamicPnlType'] = data['panel_type']
            } else if (data['Manager']) {
              resourceData['dynamicPnlResource'] = data['panel_member']
              resourceData['dynamicPnlFeedback'] = data['panel_member_feedback']
              resourceData['dynamicPnlType'] = data['panel_type']
            }
            else if (data['HR_panel']) {
              resourceData['dynamicPnlResource'] = data['panel_member']
              resourceData['dynamicPnlFeedback'] = data['panel_member_feedback']
              resourceData['dynamicPnlType'] = data['panel_type']
            }
            if (data['Technical_Round_1'] || data['Technical_Round_2'] || data['Manager'] || data['HR_panel'])
              tempdataObj[data['request_id']]['panelDetails'].push(resourceData)
          }
          return data;

        })
        const item = Object.values(tempdataObj)

        this.requirement_details = item[0]['requirement_details'];
        this.requirement_description = item[0]['requirement_description'];
        this.requirement_type = item[0]['requirement_type'];
        this.backfill_name = item[0]['backfill_name'];
        this.additional_skill = item[0]['additional_skill'];
        this.practice = item[0]['practice'];
        this.priority = item[0]['priority'];
        this.clientSelected = item[0]['client_id'];
        this.projectSelected = item[0]['project_id'];
        this.project_category = item[0]['project_category'];
        this.project_type = item[0]['project_type'];
        this.engagement = item[0]['engagement'];
        this.primary_skill_Selected = item[0]['primary_skill'];
        this.dmSelected = item[0]['delivery_manager_id'];
        this.epSelected = item[0]['engagement_partner_id'];
        this.rwtSelected = item[0]['rwt'];
        this.levelSelected = item[0]['level'];
        this.allocationRequired = item[0]['allocation_required'];
        this.no_of_resourcesRequired = item[0]['no_of_resource'];
        this.candidate_status = item[0]['candidate_status'];
        // this.skillSelected=item[0]['skill_id?.split(/[ ,]+/).map(Number)'];
        this.resourceAvailablity = item[0]['resource_availability'];
        this.coeSelected = item[0]['coe'];
        this.mgntStatus = item[0]['status'];
        this.mgntRemarks = item[0]['remarks'];
        this.start_date = item[0]['start_date'];
        this.end_date = item[0]['end_date'];
        this.requestId = item[0]['request_id'];
        this.salary=item[0]['max_salary'] ? [item[0]['min_salary'],item[0]['max_salary']] :  [2,50];
        this.hr_spoc= item[0]['hr_spoc'] ? item[0]['hr_spoc'] : '',
        this.recruiter= item[0]['recruiter'] ? item[0]['recruiter'] : '',
        this.canditateName= item[0]['name'] ? item[0]['name'] : null,
        this.hr_mapping_id= item[0]['hr_mapping_id'],
        this.resume= item[0]['resume'],
        // this.offered: item[0]['offered'] ? item[0]['offered'] : '',
        this.doj= item[0]['doj'] ? item[0]['doj'] : null,

        item[0]['panelDetails'].map(item => {
          this.panelList.push(this.fb.group({
            dynamicPnlType: item.dynamicPnlType,
            dynamicPnlResource: item.dynamicPnlResource,
            dynamicPnlFeedback: item.dynamicPnlFeedback,
          }));
          return item
        })
        this.visibleSidebar2 = true;
        this.loading = false
      })
  }

  onDownloadClick(resume) {
    if (resume) {
      this.adminService.getDownloadURl(resume).then(data => {
        this.downloadResumeUrl = data['url']
        window.open(this.downloadResumeUrl, '_blank');
      })
    }
  }

  submit() {
    this.submitted = true;
    let operationType = '';
    if (this.roleName === 'Delivery Manager' || this.roleName === 'Engagement Partner' || this.roleName === 'Delivery Partner' || this.roleName === 'Engagement Manager' || this.roleName === 'Delivery Ops Lead') {
      operationType = '1'
    } else if (this.roleName === 'Practice Delivery Manager') {
      operationType = '2'
    }
    let skillsIds = []
    let resourceIds = []
    let technical_Resource_Panel_1_Selected_Ids= [];
    let technical_Resource_Panel_2_Selected_Ids= [];
    this.skillSelected.map(skill => {
      skillsIds.push(skill.skill_master_id)
      return skill
    })
    // this.resourcesSelected.map(skill => {
    //   resourceIds.push(skill.value)
    //   return skill
    // })

      const technical_form=[];

    this.technical_Resource_Panel_1_Selected.map(skill => {
      technical_Resource_Panel_1_Selected_Ids.push(skill.value)
      technical_form.push({
        "panelType": "techincal round 1",
        "panelResource":skill.value
      })
      return skill
    })

    this.technical_Resource_Panel_2_Selected.map(skill => {
      technical_Resource_Panel_2_Selected_Ids.push(skill.value)
      technical_form.push({
        "panelType": "techincal round 2",
        "panelResource":skill.value
      })
      return skill
    })
    
    this.loading = true;
    // let resource = JSON.parse(this.storage.getItem('user'));
    if (this.requirement_description === '' || this.requirement_type === ''|| this.project_category === '' ||
      this.project_type === '' || this.engagement === '' || this.clientSelected === '' || this.projectSelected === '' ||
      skillsIds.length === 0 || this.dmSelected === '' || this.epSelected === '' ||
      this.rwtSelected === '' || this.levelSelected === '' ||  this.allocationRequired === 0 || 
      this.start_date === '' || this.end_date === '' || this.practice === '' ) {
        // this.no_of_resourcesRequired === '' ||
      // if(!this.isAdd && (this.coeSelected  === '' || this.resourceAvailablity === '')){
      this.loading = false;
      // }
    } else if((this.mgntStatus === 'Approved for Hiring') && (technical_Resource_Panel_1_Selected_Ids.length === 0 || technical_Resource_Panel_2_Selected_Ids.length === 0)){
      this.loading = false;

    }
    else {
      // console.log('requirement_description',this.requirement_description.replace('\n',''));
      let re = /\n/gi;
      let formData = {};
      formData['postBody'] = {
        requirement_details: this.requirement_details,
        requirement_description: this.requirement_description.replace(re,' '),
        requirement_type: this.requirement_type,
        project_category: this.project_category,
        project_type: this.project_type,
        engagement: this.engagement,
        backfill_name: this.backfill_name,
        additional_skill: this.additional_skill,
        practice: this.practice,
        priority:this.priority,
        client_id: this.clientSelected,
        project_id: this.projectSelected,
        primary_skill: this.primary_skill_Selected,
        skillset: skillsIds.toString(),
        delivery_manager_id: this.dmSelected,
        engagement_partner_id: this.epSelected,
        rwt: this.rwtSelected,
        level: this.levelSelected,
        allocation_required: this.allocationRequired,
        rolename:this.roleName,
        no_of_resource: 1,
        start_date: this.start_date,
        end_date: this.end_date,
        // salary:this.salary,
        min_salary:this.salary[0],
        max_salary:this.salary[1],
        //no_of_resource: this.no_of_resourcesRequired,
        // requested_by: resource.userID,
        opType: operationType,
        input_type: this.isAdd ? "Insert" : "Update",
      }
      // console.log('formData',formData);

      if (!this.isAdd) {
        formData['postBody']['request_id'] = this.requestId
        formData['postBody']['status'] = this.mgntStatus
        formData['postBody']['remarks'] = this.mgntRemarks
        formData['postBody']['resource_availability'] = this.resourceAvailablity
        formData['postBody']['Techincal_list'] = technical_form
      }
      formData['type'] = "post";
      this.projectservice.projectResourceRequest(formData).then(
        response => {
          this.loading = false;
          this.visibleSidebar2 = false;

          this.requirement_details = '';
          this.requirement_description = '';
          this.practice = '';
          this.priority='';
          this.clientSelected = '';
          this.projectSelected = '';
          this.primary_skill_Selected = '';
          this.skillSelected = []
          // this.resourcesSelected = [];
          this.technical_Resource_Panel_1_Selected =[];
          this.technical_Resource_Panel_2_Selected =[];
          this.dmSelected = '';
          this.epSelected = '';
          this.rwtSelected = '';
          this.levelSelected = '';
          this.allocationRequired = 0;
          this.no_of_resourcesRequired = '1';
          this.start_date = '';
          this.end_date = '';
          this.requestId = '';
          this.mgntStatus = '';
          this.mgntRemarks = '';
          this.coeSelected = '';
          this.salary= [2,50];
          this.resourceAvailablity = '';
          this.getResourceRequestList();
          if(!this.isAdd) {
            Swal.fire('Request for Resource Updated', '', 'success');
          } else {
            Swal.fire('Request for Resource Submitted', '', 'success');
          }
        },
        err => {
          this.loading = false;
          console.log(err)
        });
    }
  }

  openNewRequest() {
    this.visibleSidebar2 = true;
    this.isAdd = true;
    this.submitted = false;
    this.requirement_details = '';
    this.requirement_description = '';
    this.practice = '';
    this.priority='';
    this.clientSelected = '';
    this.projectSelected = '';
    this.primary_skill_Selected = '';
    this.skillSelected = []
    // this.resourcesSelected = [];
    this.technical_Resource_Panel_1_Selected =[];
    this.technical_Resource_Panel_2_Selected =[];
    this.dmSelected = '';
    this.epSelected = '';
    this.rwtSelected = '';
    this.levelSelected = '';
    this.allocationRequired = 0;
    this.no_of_resourcesRequired = '1';
    this.start_date = '';
    this.end_date = '';
    this.requestId = '';
    this.mgntStatus = '';
    this.mgntRemarks = '';
    this.coeSelected = '';
    this.salary=[2,50];
    this.resourceAvailablity = '';
  }
  onSearch(requestStatus, department,engagementName) {
   
    this.engagementFilter = engagementName;
    this.requestStatus = requestStatus;
    this.department = department;
    this.getResourceRequestList();
  }
  clearFilters() {
    this.requestStatus = 'All';
    this.department = 'All';
    this.engagementFilter = 'All';
    this.getResourceRequestList();
  }

  get resourceForm() { return this.resourceRequestForm.controls; }
  get panelList() { return this.resourceForm.panel_list as FormArray; }
  get panelListGroups() { return this.panelList.controls as FormGroup[]; }

  addPanelList(): void {
    this.panelList.push(this.fb.group({
      dynamicPnlType: [{ value: '' }, Validators.required],
      dynamicPnlResource: [{ value: '' }, [Validators.required]],
      dynamicPnlFeedback: ['']
    }));
    // this.dynamicCount += 1;
  }

  deleteRow(index: number) {
    this.panelList.removeAt(index);
  }

  isDateValidate(start, end) {
    // your date logic here, recommend moment.js;
    return moment(start).isAfter(moment(end));
  }
  toggleDisplay() {
    this.isDisplay = !this.isDisplay;
  }
  close(){

    this.visibleSidebar2 = false;

    this.resourceRequestForm.reset();

    this.panelList.clear();
    this.isView = false;

    this.requirement_type = '';
    this.project_category = '';
    this.project_type = '';
    this.engagement = '';

    this.requirement_details = '';

    this.requirement_description = '';

    this.practice = '';

    this.clientSelected = '';

    this.projectSelected = '';

    this.primary_skill_Selected = '';

    this.skillSelected = []
    this.salary=[2,50];
    this.priority='';
    // this.resourcesSelected = [];
    this.technical_Resource_Panel_1_Selected =[];
    this.technical_Resource_Panel_2_Selected =[];

    this.dmSelected = '';

    this.epSelected = '';

    this.rwtSelected = '';

    this.levelSelected = '';

    this.allocationRequired = 0;

    this.no_of_resourcesRequired = '1';

    this.start_date = '';

    this.end_date = '';

    this.requestId = '';

    this.mgntStatus = '';

    this.mgntRemarks = '';

    this.canditateName = '';

    this.hr_mapping_id='';

    this.hr_spoc = '';

    this.recruiter = '';

  }

  getDepartment() {
    this.practices = [];
    
    this.sharedService.getDepartmentData().then(data => {
      // resourceData = rsourceData.filter(resource => resource.r_status === 'Submitted')
      this.practices = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.department_name === thing.department_name) === i
      ).sort((a, b) => a.department_name.localeCompare(b.department_name));
      this.deliveryfunctions = [...this.practices]
    }).catch((err) => {
      this.manageRWTData = [];
    });



  }
  // requirementTypeChanged(value){
  //   // this.selectedCountry = value;
  //   if (value == "Mexico") {
  //     let temp =  <FormGroup>(<FormGroup>this.selectForm.get('persons')).controls[i];
  //     temp.addControl('remark', new FormControl(''));
  //   }
  //   console.log('this.selectedCountry',value);
  // }
  cloneResourcerequest(item) {
    this.isView = false;
    let skillArray = item.skill_id ? item.skill_id.split(/[ ,]+/).map(Number): []
    this.skillSelected = []
    // let resourcesArray = item.technical_panel_id? item.technical_panel_id?.split(/[ ,]+/).map(Number) : []
    let resources_1_Array = item.technical_panel_1_id? item.technical_panel_1_id?.split(/[ ,]+/).map(Number) : []
    let resources_2_Array = item.technical_panel_2_id? item.technical_panel_2_id?.split(/[ ,]+/).map(Number) : []
    this.technical_Resource_Panel_1_Selected= [];
    this.technical_Resource_Panel_2_Selected= [];
    // this.resourcesSelected = []
    let skills = this.skillList.filter(x => skillArray.includes(x.skill_master_id));
    skills.map(skill => {
      this.skillSelected.push({ "skill_master_id": skill.skill_master_id, "skill_name": skill.skill_name })
      return skill
    })
    // let resources = this.multipleResourceDropdown.filter(x => resourcesArray.includes(x.value));
    // resources.map(resource => {
    //   this.resourcesSelected.push({ "value": resource.value, "label": resource.label })
    //   return resource
    // })
    let resources_1 = this.multipleResourceDropdown.filter(x => resources_1_Array.includes(x.value));
    resources_1.map(resource => {
      this.technical_Resource_Panel_1_Selected.push({ "value": resource.value, "label": resource.label })
      return resource
    })
    let resources_2 = this.multipleResourceDropdown.filter(x => resources_2_Array.includes(x.value));
    resources_2.map(resource => {
      this.technical_Resource_Panel_2_Selected.push({ "value": resource.value, "label": resource.label })
      return resource
    })
    this.visibleSidebar2 = true;
    this.isAdd = true;
    this.isDisplay = true;
    this.submitted = false;
    // Swal.fire('Request for resource Created', '', 'success');
    this.requirement_details = item.requirement_details;
    this.requirement_description = item.requirement_description;
    this.requirement_type = item.requirement_type;
    this.backfill_name = item.backfill_name;
    this.additional_skill = item.additional_skill;
    this.practice = item.practice;
    this.clientSelected = item.client_id;
    this.projectSelected = item.project_id;
    this.project_category = item.project_category;
    this.project_type = item.project_type;
    this.engagement = item.engagement;
    this.primary_skill_Selected = item.primary_skill;
    this.dmSelected = item.delivery_manager_id;
    this.epSelected = item.engagement_partner_id;
    this.rwtSelected = item.rwt;
    this.levelSelected = item.level;
    this.allocationRequired = item.allocation_required;
    this.no_of_resourcesRequired = item.no_of_resource;
    // this.skillSelected=item.skill_id?.split(/[ ,]+/).map(Number);
    // this.resourceAvailablity = item.resource_availability;
    // this.coeSelected = item.coe;
    // this.mgntStatus = item.status;
   //  this.mgntRemarks = item.remarks;
    this.start_date = item.start_date;
    this.end_date = item.end_date;
   // this.requestId = item.request_id;
    // this.salary = item.max_salary? [item.min_salary,item.max_salary] : [2,50];
  }

  onlyDigits(event) {
    let code = event.charCode;
    return (code >= 48 && code <= 57);
  }
  onStatus(){
    this.disabledYData = false;
    this.disabledNData = false;
    if(this.mgntStatus === 'Assigned Internal Resource') {
      this.disabledNData = true;
      return this.resourceAvailablity = 'Yes';
      
    } else if(this.mgntStatus === 'Rejected by PDM') {
      this.disabledYData = true;
      return this.resourceAvailablity = 'No' ;
    } else{
      this.disabledYData = false;
      this.disabledNData = false;
      return  this.resourceAvailablity = '';
    }
    
  }
  OnBackFill(){
    if(this.requirement_type === 'Back Fill') {
      this.projectDropDown.pop();
      this.clientDropDown.pop();
      this.isBackFill = true
    } else {
      let len = this.clientDropDown.length;
      if(this.clientDropDown[len-1].label ==='NA') {
        this.isBackFill = false;
      }else{
        this.clientDropDown.push({ 'label': 'NA', 'value': '0' });
        this.projectDropDown.push({ 'label': 'NA', 'value': '0' });
        this.isBackFill = false;
      }
      
      
      

    }  

  }

  exportExcel() {
    const data = [];
    // products.map(product => product.id);

    this.resourceRequestTable.map(element => {
      const obj = {};
      obj['Request ID'] = element.request_id;    
      obj['Client Name'] = element.client_name;   
      obj['Project Name'] = element.project_name;    
      obj['From Date'] = element.start_date;   
      obj['To Date'] = element.end_date;    
      obj['RWT'] = element.rwt_name;   
      obj['Level'] = element.level;    
      obj['Job Title'] = element.requirement_details;   
      obj['Requirement Type'] = element.requirement_type;    
      obj['Primary Skills'] = element.skill_name;   
      obj['Practice'] = element.practice_name;    
      obj['Priority'] = element.priority;   
      obj['Requested By'] = element.requestor_name;
      obj['Status'] = element.status;
    
      data.push(obj);
    });
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'ResourceRequest_Report');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_Report'+ EXCEL_EXTENSION);
    });
  }
}
