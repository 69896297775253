import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '../auth.service';

import { AuthorizationService } from '../_services/authorization.service';
import {StorageService} from '../_services/storage.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate,CanActivateChild {
    user: boolean;
    constructor(
        private router: Router,
        private authenticationService: AuthorizationService,
        private storage:StorageService,
        private authService: AuthService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = await this.authenticationService.isLoggedIn().then(logged=>{
            return logged;
        }).catch((err)=>{
            return false;
        });
    
        if (user) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(this.storage.getItem('selectedRole')) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            // authorised so return true
            return true;
        }
       
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/']);
        return false;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {​​​​​​​
        return true;
    }​​​​​​​
}