import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl   } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Moment } from "moment";
import { Table } from 'primeng/table';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_services/storage.service';
import { ConfirmationService, SortEvent } from 'primeng/api';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
export interface Evaluation {
  resource_estimate_master_id?: number;
  resource_name?: string;
  RWT?: string;
  client_name?: string;
  project_name?: string;
  sub_project_name?: string;
  sub_project_id?:string;
  project_id?:number;
  overall_score?: number;
  delivery_performance?: number;
  demonstrated_solutioning?: string;
  toggleDemonstratedSolutioning?: boolean;
  is_demonstrated_solutioning?: boolean;
  toggleProjectEscalations?: boolean;
  is_project_escalations?: boolean;
  project_escalations?: string;
  showcased_dependability?: string;
  demonstrated_initiative?: string;
  remarks?: string;
  IsEditable?: boolean;
  year:string;
  month:string;
  createdby:string;
}

@Component({
  selector: 'app-evaluation-report',
  templateUrl: './evaluation-report.component.html',
  styleUrls: ['./evaluation-report.component.scss'],
  styles: [`
  :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
  }

  @media screen and (max-width: 64em) {
      :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
          top: 99px;
      }
  }
`],
 providers: [
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  ConfirmationService,
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
]
})
export class EvaluationReportComponent implements OnInit {
  loading = false;
  minDate: Date;
  maxDate: Date;
  startDate = new FormControl(moment());
  editing = false;
  @ViewChild('dt', { static: false }) private dataTable: Table;
  editingRows: any = [];
  evaluationData: any = []; //: Evaluation[] ; 
  clonedEvaluation: { [s: number]: Evaluation; } = {};
  currentDate;   //.subtract(1,'year');
  monthYear: Date;
  evaluationObject: {};
  resource: any;
  clientControl = new FormControl();
  projectControl = new FormControl();
  client: any = 'All';
  project: any = 'All';
  clients: any[];
  projects: any[];
  overallscores: any;
  deliveryPerformances: any;
  projectsOrg: any[];
  selectedEscalation ='0';
  selectedClient = '0';
  selectedProject = '0';
  selectedOverallscore = '0';
  selectedDeliveryPerformance = '0';
  originalData: any;
  escalationControl = new FormControl();
  overallscoreControl = new FormControl();
  deliveryPerformanceControl = new FormControl();
  resourceCount: number;
  resourceObj: any;
  futureMonthClickDisable: boolean=false;
  previousMonthClickDisable: boolean=false;
  evaluationEditedData: any;
  evaluationCheckData: any = [];
  checkAllEvaluationData: boolean=false;
  displayPopup: boolean=false;
  evaluationCheckDataSelection: any = [];
  userRole: string;
  month: any;
  year: any;
  monthyear: string;
  month_number:string;
  month_name: string;
  currentyear: string;
  constructor(private apiService:AdminService,private storage: StorageService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.resource=JSON.parse(this.storage.getItem('user'));
 
    this.projectControl.setValue('0');
    this.clientControl.setValue('0');
    this.checkCurrentDate();
    this.fnCallMain(false);
  
  }
  fnCallMain (isFilterapplied) {
    if(!isFilterapplied) {
    this.currentDate = moment().set('date', 15).subtract(1,'month').format('MM/DD/YYYY')
    var datefilter=moment(this.currentDate).clone();
    datefilter=moment(datefilter)
    this.month_number= moment(datefilter,'MM').format('MM');
    this.month_name= moment(datefilter,'MMM').format('MMM')
    this.currentyear = moment(datefilter,'YYYY').format('YYYY');
    }
    this.getEvaluationReport(this.currentyear,this.month_number,this.month_name);
  }
  checkCurrentDate(){
    const prevMonth=moment().set('date', 15).subtract(1,'month').format('MM/DD/YYYY')
    if(moment(this.currentDate).isSameOrAfter(prevMonth)){
      this.futureMonthClickDisable=true
    }else{
      this.futureMonthClickDisable=false
    }
    // const oneYearOldDate=moment().set('date', 15).subtract(1,'year').format('MM/DD/YYYY')
    const afterJulyDate='07/15/2021'
    if((moment(this.currentDate).isSameOrBefore(afterJulyDate))){
      this.previousMonthClickDisable=true
    }else{
      this.previousMonthClickDisable=false
    }
  }
  onPrevClick() {
    
    var datefilter=moment(this.currentDate).clone();
    datefilter=moment(datefilter).subtract(1,'month')
    this.month_number= moment(datefilter,'MM').format('MM');
    this.month_name= moment(datefilter,'MMM').format('MMM')
    this.currentyear = moment(datefilter,'YYYY').format('YYYY');
    this.currentDate =moment(this.month_number+'-15-'+this.currentyear,'MM/DD/YYYY').format('MM/DD/YYYY')
    this.checkCurrentDate()
    this.getEvaluationReport(this.currentyear,this.month_number,this.month_name);

  }
  onNextClick() {
    var datefilter=moment(this.currentDate).clone();
    datefilter=moment(datefilter).add(1,'month')
    this.month_number= moment(datefilter,'MM').format('MM');
    this.month_name= moment(datefilter,'MMM').format('MMM')
    this.currentyear = moment(datefilter,'YYYY').format('YYYY');
    this.currentDate =moment(this.month_number+'-15-'+this.currentyear,'MM/DD/YYYY').format('MM/DD/YYYY')
    this.checkCurrentDate()
    this.getEvaluationReport(this.currentyear,this.month_number,this.month_name);

  }
  getEvaluationReport(currentyear,month_number,month_name){
    this.month = month_name;
    this.year = currentyear;
    this.monthyear = this.month.substr(0, 3) + ' ' + this.year;
    this.loading=true;
    this.resourceCount=0;
    this.resourceObj={};
    this.evaluationData =[];
    this.originalData=this.evaluationData;
    this.userRole = this.storage.getItem("selectedRole");
    this.apiService.getProjectEvaluationReportByAdmin(currentyear,month_number,month_name,this.userRole).then((evaluationReports) => {
      this.clients = evaluationReports.filter(
        (thing, i, arr) => arr.findIndex(t => t.client_name === thing.client_name) === i
      ).sort((a, b) => a.client_name.localeCompare(b.client_name));
      this.projects = evaluationReports.filter(
        (thing, i, arr) => arr.findIndex(t => t.project_name === thing.project_name) === i
      ).sort((a, b) => a.project_name.localeCompare(b.project_name));
      this.projectsOrg = evaluationReports.filter(
        (thing, i, arr) => arr.findIndex(t => t.project_name === thing.project_name) === i
      ).sort((a, b) => a.project_name.localeCompare(b.project_name));
      this.evaluationData = evaluationReports.filter((item: any) => {
        if (this.client !== 'All' && this.project !== 'All') {
          return (item.client_name === this.client) && (item.project_name === this.project);
        } 
        else if (this.client !== 'All' && this.project === 'All') {
          return (item.client_name === this.client);
        }
        else if (this.client === 'All' && this.project !== 'All') {
          return (item.project_name === this.project);
        }
        else {
          return item;
        }
      });
    
      this.evaluationData.forEach((item: Evaluation, index: number) => {
        item.month =moment(this.currentDate).format('MMM'); 
        item.year =moment(this.currentDate).format('YYYY');
        item.createdby=this.resource.userID;
        //demonsatrated Solutioning
        if(item.demonstrated_solutioning==='-1'){
          item.is_demonstrated_solutioning=true;
          item.toggleDemonstratedSolutioning=true;
        }else{
          item.is_demonstrated_solutioning=false;
        }

        if(item.demonstrated_solutioning==='1'){
          item.toggleDemonstratedSolutioning=true;
        }
        if(item.demonstrated_solutioning==='0'){
          item.toggleDemonstratedSolutioning=false;
        }
        //projectEscalation
        if(item.project_escalations==='-1'){
          item.is_project_escalations=true;
          item.toggleProjectEscalations=false;
        }else{
          item.is_project_escalations=false;
        }

        if(item.project_escalations==='1'){
          item.toggleProjectEscalations=true;
        }
        if(item.project_escalations==='0'){
          item.toggleProjectEscalations=false;
        }
        
        if(!this.resourceObj[item.resource_name]){
          this.resourceObj[item.resource_name]=item.resource_name;
          this.resourceCount+=1;
        }

        // if (item.IsEditable) {
        //   this.onRowEditInit(item);
        //   this.dataTable.initRowEdit(item);
        // }else{
          
          // this.onRowEditCancel(item,index);
          this.dataTable.cancelRowEdit(item);
        // }
      });
      this.originalData=this.evaluationData;
     
      this.loading=false;
    }).catch(data=>{
      this.evaluationData =[];
      this.originalData=this.evaluationData;
      this.loading=false;
    })
  }
 
  filterChange(client,project) {
  
    this.client = client;
    this.project = project;
    this.fnCallMain(true);
  }
  clearFilters(){
    this.client = 'All';
    this.project = 'All';
    this.fnCallMain(true);
  }
  exportExcel() {
    const evaluationDataReport = [];
    const monthyear = this.month.substr(0, 3) + ' ' + this.year;
    this.evaluationData.map(element => {
      const obj = {};
      obj['Resource Name'] = element.resource_name;
      obj['Client Name'] = element.client_name;
      obj['Project Name'] = element.project_name;
      obj['Sub Project Name'] = element.sub_project_name;
      obj['RWT'] = element.rwt_name;
      obj['Level'] = element.level_name;
      obj['Role'] = element.role;
      obj['Month Year'] = monthyear;
      obj['Overall Score'] = element.overall_score === 1?'Poor':element.overall_score === 2?'Average':element.overall_score === 3?'Good':'Exceptional';
      obj['Delivery Performance'] = element.delivery_performance === 1?'Poor':element.delivery_performance === 2?'Average':element.delivery_performance === 3?'Good':'Exceptional';
      obj['Demonstrated Solutioning'] = element.demonstrated_solutioning === "0"?'NO':element.demonstrated_solutioning === "1"?'YES':element.demonstrated_solutioning === "-1"?'NA':'NA';
      obj['Project Escalations'] = element.project_escalations=== "0"?'NO':element.project_escalations === "1"?'YES':element.project_escalations === "-1"?'NA':'NA';
      obj['Showcased Dependability'] = element.showcased_dependability?'YES':'NO';
      obj['Demonstrated Initiative'] = element.demonstrated_initiative?'YES':'NO';
      obj['Remarks'] = element.remarks;
      evaluationDataReport.push(obj);
    });
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(evaluationDataReport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Evaluation_Report_for_');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    // const monthyear = this.month.substr(0, 3) + ' ' + this.year;
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + this.monthyear + EXCEL_EXTENSION);
    });
  }
  onClientChange(client, event) {
    if(client !== 'All') {
    this.projects = this.projectsOrg.filter(
      (thing, i, arr) => arr.findIndex(t => t.project_name === thing.project_name && t.client_name === client) === i
    ).sort((a, b) => a.project_name.localeCompare(b.project_name));
    }
    else {
      this.projects = this.projectsOrg;
    }
  }
  onFreeTextChange(freeText){
    // console.log(freeText);
    this.evaluationData = this.evaluationData.filter(o =>
      Object.keys(o).some(k => 
        o.client_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.resource_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.rwt_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.project_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.remarks?.toLowerCase().includes(freeText.toLowerCase()) 
        ));
        if(freeText === ''){
          this.evaluationData= this.originalData;
        }
  }
}
