<ng-template #customLoadingTemplate>
  <div class="ctmloader">
    <img src="./../../../assets/images/loader.svg">
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
</ngx-loading>
<div class="back">
  <div class="container-fluid pt-3">
    <div class="ctm-body">

      <div class="d-flex mb-1 align-items-center">
        <h4>Resource Allocations Report for {{filterText}} </h4>

        <div class="ml-auto">
          <button type="button" (click)="exportExcel()" selectionMode="multiple" class="btn cstbtn btn2 ml-2 mb-1"><i
              class="fa fa-download" aria-hidden="true"></i> Download this report as Excel</button>
        </div>
      </div>


      <div class="ctm-card">
        <!-- <div class="p-grid" style="margin-top:15px; padding:10px;"> -->
        <ng-container>
          <p-table class="bordertop lastleft" [value]="reports" #dt dataKey="row_id" [paginator]="true"
            (onRowExpand)="onRowExpand()" [rows]="10" (onRowCollapse)="onRowCollapse()" [columns]="cols"
            [expandedRowKeys]="expandedRows" [rowsPerPageOptions]="[5,10,15,20]" [totalRecords]="reports.length"
            pageLinkSize="3" (onPage)="onPage($event)" (sortFunction)="sortColumn($event)" [customSort]="true"
            [globalFilterFields]="['resource_name','department_name','rwt_name','resource_manager_name','month','year','WorkingHrs','SabbaticalLeaveHrs','skill_name','employee_id','monthyear','prev_exp','Expr','total_exp']">


            <ng-template pTemplate="body" let-columns="columns">
              <colgroup>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col style="width: 350px;">
              </colgroup>
            </ng-template>


            <!-- [paginator]="true"[rows]="10" [totalRecords]="cars.length" pageLinkSize="3" (onPage)="onPage($event)" -->
            <ng-template pTemplate="caption">
              <div class=" ctmfilter ">
                <div class="d-flex  align-items-end">
                <!-- <ng-container *ngIf="userRole!=='Resource Manager'">
                  <div class="ml-3">
                    <label class="d-block text-left" for="exampleFormControlSelect1">Department</label>
                    <select [ngModel]="departmentFilter" [formControl]="departmentControl" class="form-control  mb-1"
                      id="exampleFormControlSelect1" (change)="deptChange($event)">
                      <option value=''>All Departments</option>
                      <option *ngFor="let dept of departments" value="{{dept.department_id}}">
                        {{ dept.department_name }}
                      </option>
                    </select>
                  </div>
                </ng-container> -->
                
                <ng-container *ngIf="userRole!=='Resource Manager'">
                  <div class="ml-3 mb-1" style="width:350px">
                    <label class="d-block text-left" for="exampleFormControlSelect1">Skills</label>
                    <ng-multiselect-dropdown [placeholder]="'Select Skills'" [settings]="dropdownSettings"
                      [(ngModel)]="selectedIds" [data]="dropdownList" (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                      (onDeSelectAll)="onDeSelectAll()">
                    </ng-multiselect-dropdown>
                  </div>
                </ng-container>
                <ng-container *ngIf="userRole!=='Resource Manager'">
                  <div class="ml-3">
                    <label class="d-block text-left" for="exampleFormControlSelect1">BU</label>
                    <select [ngModel]="businessUnitFilter" [formControl]="businessUnitControl" class="form-control  mb-1"
                      id="exampleFormControlSelect1" (change)="buChange($event)">
                      <option value=''>All BU</option>
                      <option *ngFor="let bunit of bu_name" value="{{bunit.bu}}">
                        {{ bunit.bu }}
                      </option>
                    </select>
                  </div>
                </ng-container>
               
                <div class="ml-3 mb-2">
                  <a type="button" style="text-decoration: underline;" (click)="onAdvanced()" >Advanced <b style="font-size: 14px;"><i class="fa fa-angle-down" style="color:#f28a35" aria-hidden="true"></i></b></a>
                </div>
                <button type="button" (click)="filterChange()" class="btn cstbtn btn1 ml-3 mb-1"><i class="fa fa-filter"
                    aria-hidden="true"></i> Apply Filters</button>
                <div class="clrfilter" (click)="clearFilters()"><i style="color: #ed4d4d;" class="fa fa-times red"
                    aria-hidden="true"></i> Reset Filters</div>
                <div style="max-width: 220px;" class="ml-auto  bl-1">
                  <p style="padding-bottom: 10px;">Search the table below using this free text search</p>
                  <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                  <input type="text" pInputText size="20" placeholder="What are you looking for ?"
                    (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                </div>
               
                
              </div>
              <div class="d-block d-flex align-items-end ml-3 mt-3" *ngIf="showAdvancedFilter">
               <p  style="font-weight: 100;" class="mr-3 mb-2">Allocation</p>
                <div>
                  <label for="dtPicker" id="lbldtpicker" class="d-block text-left mr-2">Starting From</label>
                  <mat-form-field style="max-width: 110px;" appearance="fill">
                    <input matInput [matDatepicker]="dp" [formControl]="startDate" [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp startView="year" (yearSelected)="chosenstartYearHandler($event)"
                      (monthSelected)="chosenstartMonthHandler($event, dp)" panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="ml-3">
                  <label id="lbldtpicker1" class="d-block text-left mr-2">For</label>
                  <select [ngModel]="duration" class="form-control mb-1" (change)="durationChange($event)">
                    <!--value -1 since excluding current month-->
                    <option value='0' selected>--Select--</option>
                    <option value='2'>3 months</option>
                    <option value='5'>6 months</option>
                    <!-- <option *ngFor="let duration of durations" value="{{duration.duration_value}}">
                      {{ duration.duration_name }} 
                    </option> -->
                  </select>
                </div>
                
              </div>
            </div>

            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 5em">
                  <a style="cursor: pointer;font-size: 16px; color:teal;" (click)="expandAll()">
                    <i *ngIf="!isExpanded" style="font-size: 20px;" class="fa fg fa-plus-circle" aria-hidden="true"></i>
                    <i *ngIf="isExpanded" style="font-size: 20px;" class="fa fg fa-minus-circle" aria-hidden="true"></i>
                  </a>
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
              <tr [class]="expanded ? 'row-active-header' : ''">
                <td class="chevdownup">
                  <a href="#" [pRowToggler]="rowData">
                    <i [class]="expanded ? 'pi fc pi-chevron-up' : 'pi fc pi-chevron-down'"></i>
                  </a>
                </td>
                <td *ngFor="let col of columns">
                  <ng-container *ngIf="col.field === 'resource_name'">
                    <a href="#" [pRowToggler]="rowData">
                      {{rowData[col.field]}}
                    </a>
                  </ng-container>
                  <ng-container *ngIf="col.field !== 'resource_name'">
                    {{rowData[col.field]}}
                  </ng-container>

                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData let-expanded="expanded" let-columns="columns">
              <tr class="row-active-body">
                <td [attr.colspan]="columns.length + 1" class="no-pad p-0">
                  <div class="p-p-3">
                    <table class="innerTable"
                      *ngIf="rowData['projects'].length > 0 && rowData['projects']['project_name']!==null">
                      <thead class="innerHeader">
                        <tr>
                          <th>Client</th>
                          <th>Project</th>
                          <th>Sub Project</th>
                          <th>Hours</th>
                          <th>Delivery Manager</th>
                          <th>Engagement Partner</th>
                        <tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of rowData['projects']" class="innerBody">
                          <td>{{row['client_name']}}</td>
                          <td>{{row['project_name']}}</td>
                          <td>{{row['sub_project_name']}}</td>
                          <td>{{row['billing_hours']}}</td>
                          <td>{{row['delivery_manager']}}</td>
                          <td>{{row['engagement_partner']}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style="color: #ed4d4d;padding: 1rem;" *ngIf="rowData['projects'].length===0 ">
                    No Projects Allocated for the Month
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>
        </ng-container>

        <!-- <ng-container *ngIf="reports.length===0">
          <p style="color: #ed4d4d; font-size: 16px; margin-top: 20px;">No resources allocated</p>
        </ng-container> -->
      </div>

    </div>
  </div>
</div>