import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_helpers/auth.guard';
import { adminComponent } from './admin.component';
import { ManageResourceComponent } from './manage-resource/manage-resource.component';
import { ManageRwtComponent } from './manage-rwt/manage-rwt.component';
import { ManageGroupComponent } from './manage-group/manage-group.component';
import { EvaluationReportComponent } from './evaluation-report/evaluation-report.component';
import { ManageSkillMatrixComponent } from './manage-skill-matrix/manage-skill-matrix.component';
import { HRComponent } from './hr/hr.component';
const routes: Routes = [
  { path:'',
  component:adminComponent,
  canActivate: [AuthGuard],
  data: { roles: ['PMO Admin','HR Admin','Talent Acquisition Manager','Delivery Partner',"Engagement Partner","Practice Delivery Manager","Delivery Ops Lead"]},
  children: [
    { 
      path: 'manage-resource', component: ManageResourceComponent,
      canActivate: [AuthGuard],
      data: { roles: [ "PMO Admin","HR Admin","Talent Acquisition Manager","Practice Delivery Manager","Delivery Ops Lead"] },
      runGuardsAndResolvers: 'always'
    },
    { 
      path: 'manage-rwt', component: ManageRwtComponent,
      canActivate: [AuthGuard],
      data: { roles: [ "PMO Admin","HR Admin"] } ,
      runGuardsAndResolvers: 'always'
    },
    { 
      path: 'manage-group', component: ManageGroupComponent,
      canActivate: [AuthGuard],
      data: { roles: [ "PMO Admin","HR Admin"] } ,
      runGuardsAndResolvers: 'always'
    },
    { 
      path: 'evaluation-report', component: EvaluationReportComponent,
      canActivate: [AuthGuard],
      data: { roles: [ "HR Admin","Delivery Partner","Engagement Partner"] } ,
      runGuardsAndResolvers: 'always'
    },
    { 
      path: 'manage-skill-matrix', component: ManageSkillMatrixComponent,
      canActivate: [AuthGuard],
      data: { roles: [ "PMO Admin","HR Admin"] } ,
      runGuardsAndResolvers: 'always'
    },
    { 
      path: 'hr', component: HRComponent,
      canActivate: [AuthGuard],
      data: { roles: [ "PMO Admin","HR Admin","Talent Acquisition Manager"] } ,
      runGuardsAndResolvers: 'always'
    }
  ],
  runGuardsAndResolvers: 'always'
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
