import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDetailService } from '../../_services/project-detail.service';
import * as moment from 'moment';
import { levelDropDown } from './../../_interfaces/levelDropDown';
import { rwtDropDownData } from './../../_interfaces/rwtDropDownData';
import { locationDropDown } from './../../_interfaces/locationDropDown';
import { TimesheetSubmissionService } from '../../_services/timesheet-submission.service';
import { MessageService } from 'primeng/api';
import { StorageService } from 'src/app/_services/storage.service';
import { ConfirmationService, SortEvent } from 'primeng/api';
import Swal from 'sweetalert2';
import * as ConfigJson from 'src/assets/config.json';
import { ViewChild, ElementRef } from '@angular/core';
import { element } from 'protractor';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  providers: [MessageService, ConfirmationService],
})

export class ProjectDetailComponent implements OnInit {
  mileStoneShowm = true;
  workValuesShow = true;
  workValueShown = false;
  isRecodExists = true;
  internalCostShown = true;
  resourceEstimateShown = true;
  projectActualsShown = true;
  workvaluetable;
  projectStartDate;
  projectendDate;
  projectId: any;
  allProjects: any;
  selectedProject: any;
  projectDetail: any;
  monthsCol: any;
  projectActualsmonthsCol: any;
  allProjectDetail: any;
  filteredRWT: any;
  filteredResources: any;
  selectedRwtName: any;
  allRwt: any;
  levelDropDownDataForWorkValue: any;
  levelDropDownDataForInternalEstimate: any;
  locationDropdownDataForWorkValue: any;
  locationDropdownDataForInternalEstimate: any;
  selectedRwtLevelName: any;
  MonthsColsDataValue: any;
  projectActualsMonthsColsDataValue: any;
  internalMonthsColsDataValue: any;
  resourceMonthsColsDataValue = [];
  monthlyValue: any;
  projectActualsmonthlyValue: any;
  clientEstimatesColumn: any;
  internalEstimatesColumn: any;
  resourceEstimatesColumn: any;
  additionalCostColumns: any;
  filteredLocation: any;
  filteredBaseline: any;
  selectedRowsClient: any;
  selectedRowsInternal: any;
  selectedRowsResources: any;
  tdcolumns = [];
  tdInternalColumns = [];
  tdResourceColumns = [];
  tdResourceReadOnlyColData = [];
  tdResourceAColData = [];
  allClientEstimates = [];
  allInternalEstimates = [];
  allResourcesEstimates = [];
  selectedSubProject;
  filteredRWTName: any[];
  probabiltyColumns = false;
  inter;
  probalityColumnAddedCheck = false;
  resourcesArray = [];
  totalCostClientEstimate: any = 0;
  totalCostInternalEstimate: any = 0;
  totalCostResourceEstimate: any = 0;
  totalHoursClientEstimate: any = 0;
  totalHoursInternalEstimate: any = 0;
  newSubProjectFlag = true;
  allDepartments: boolean;
  allDeliveryManager: boolean;
  onInitFlag = false;
  clientEstimationColumnLabel = [];
  internalEstimationColumnLabel = [];
  resourceEstimationColumnLabel = [];
  projectActualsColumnLabel = [];
  hideFooterFlagClient = false;
  hideFooterFlagInternal = false;
  hideFooterFlagResource = false;
  copyToNextGridClientButtonFlag = true;
  copyToNextGridInternalButtonFlag = true;
  mandatoryFlag = false;
  comments: any;
  loading = false;
  colHeaderClientFixed = [];
  colHeaderInternalFixed = [];
  colHeaderResourceFixed = [];
  probabiltyColumnsLabel = [];
  scrollableCols: any[];
  scrollableColsProjectActuals: any[];
  frozenCols: any[];
  frozenColsClient: any[];
  frozenColsInternal: any[];
  scrollableColsResources: any[];
  frozenColsResourceEstimate: any[];
  frozenProjectActuals: any[];
  duplicateFlag = false;
  duplicateFlagResource = false;
  updateFlag: boolean;
  subprojectEvent: any;
  totalHistoryClientEstimate: any;
  totalHistoryInternalEstimate: any;
  totalHistoryResourceEstimate: any;
  deleteClientFlag = false;
  deleteInternalFlag = false;
  deleteResoureFlag = false;
  deleteAdditionalCost = false;
  cloneSelectedClientFlag = false;
  cloneSelectedInternalFlag = false;
  cloneSelectedResoureFlag = false;
  selectedSubProjectControl: any;
  subProjectDEliveryManagerRoleFlag = false;
  userRole;
  filteredSubPro = [];
  allResources = [];
  resource: any;
  resourceObj: any = {};
  deleteThisSubProjectFlag = false;
  footerResourceData = [];
  footerResourceHoursData = [];
  estimateObjectData = {};
  monthlyHoursFooterData = {};
  objectKeys = Object.keys;
  resourceEstimatesColumnFooter: any;
  footerData: any;
  probalityColumnAddedCheckFooter: boolean = false;
  resourceProfitability: any;
  isChecked: boolean = true;
  monthlyResourceEstimate = [];
  addditionalExpenseType = [{ expenseType: 'Asset' }, { expenseType: 'Infrastructure' }, { expenseType: 'Software/Tool' }, { expenseType: 'Travel' }, { expenseType: 'Ceritification' }]
  currentDateForValidation = moment().format('MMMYYYY');
  @ViewChild('pTable', { static: false, read: ElementRef }) pTable: ElementRef;
  @ViewChild('pTable1', { static: false, read: ElementRef }) pTable1: ElementRef;
  @ViewChild('pTable2', { static: false, read: ElementRef }) pTable2: ElementRef;
  @ViewChild('pTable3', { static: false, read: ElementRef }) pTable3: ElementRef;

  @ViewChild('myCheckbox') myCheckbox!: ElementRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectDetailService: ProjectDetailService,
    private timesheetServie: TimesheetSubmissionService,
    private messageService: MessageService,
    private storage: StorageService,
    private confirmationService: ConfirmationService
  ) { }
  ngOnInit(): void {

    this.allClientEstimates = [];
    this.allInternalEstimates = [];
    this.allResourcesEstimates = [];
    this.selectedSubProjectControl = '';

    this.loading = true;
    this.onInitFlag = true;
    this.updateFlag = false;
    this.MonthsColsDataValue = [];
    this.projectActualsMonthsColsDataValue = [];
    this.internalMonthsColsDataValue = [];
    this.resourceMonthsColsDataValue = [];
    this.footerResourceData = [];
    this.footerResourceHoursData = [];
    this.resource = JSON.parse(this.storage.getItem('user'));
    const userRole = this.storage.getItem('selectedRole');
    this.projectId = this.activatedRoute.snapshot.params['id'];
    this.projectDetailService
      .getAllProjectsByResource(this.projectId, userRole, this.resource.userID)
      .then((project) => {
        if (project.project.length > 0) {
          this.projectStartDate = project.project[0].start_date;
          this.projectendDate = project.project[0].end_date;
          this.comments =
            project.project[0].comments === null
              ? ''
              : project.project[0].comments;
          this.getResources();
          this.allProjectDetail = project;
          this.filteredSubPro = this.allProjectDetail['project'];
          if (this.filteredSubPro.length == 1) {
            this.deleteThisSubProjectFlag = false;
          }
          let sub_project_id = this.activatedRoute.snapshot.params[
            'subProjectId'
          ];
          const checkUsername = obj => obj.sub_project_id === sub_project_id;
          if (this.filteredSubPro.some(checkUsername) == false) {
            this.router.navigateByUrl('projects');

            return false;
          }
          let filteredSubProject = project.project.filter(
            (item) => item.sub_project_id == sub_project_id
          );
          this.selectedSubProjectControl = sub_project_id;
          this.selectedSubProject = filteredSubProject[0];
          this.selectedProject = filteredSubProject[0];
          this.projectDetail = filteredSubProject[0];
          this.allDeliveryManager = this.allProjectDetail['deliveryManagers'];
          this.allDepartments = this.allProjectDetail['departments'];
          if (this.allProjectDetail['internalEstimates'].length > 0) {

            this.toggleTableVisibility({ target: { checked: this.isChecked } });
            // this.myCheckbox.nativeElement.checked = true;
          } else {
            this.isChecked = false
            this.toggleTableVisibility({ target: { checked: this.isChecked } });

          }

          this.monthsCol = this.dateRange(
            project.project[0].start_date,
            project.project[0].end_date
          );
          let currentDate = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate();
          // let lastMonthEndDate = moment(currentDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
          this.projectActualsmonthsCol = this.dateRange(
            project.project[0].start_date,
            currentDate
          );
        } else {
          this.loading = false;
          Swal.fire({
            title: 'Access Denied',
            text: 'You do not have the access to view this project!',
            icon: 'error',
            //input: 'select',
            //inputOptions: options,
            //inputPlaceholder: 'Select a Role',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
          }
          ).then((result) => {
            if (result.isConfirmed) {
              let res = result.value
              // this.sharedServ.userLoggedIn(this.user);
              // if (!this.storage.getItem('departments')) {
              //   this.sharedServ.getDepartmentData().then(department => {
              //     this.storage.setItem('departments', JSON.stringify(department));
              //   })
              // }
              // this.storage.setItem('selectedRole', usergroups[res].group)

              this.router.navigateByUrl('/home')
            }
          })
        }
      });
    this.recalculateTotalAmount()

    this.hideFooterFunction();
    // this.frozenCols = ['checkBox', 'RWT', 'Level', 'Location', 'Baseline Rate'];
    this.frozenColsClient = ['checkBox', 'RWT', 'Level', 'Location', 'Billing Rate']; // Added for client grid march 4 21
    this.frozenColsInternal = ['checkBox', 'RWT', 'Level', 'Location', 'Baseline Rate']; // Added for internal grid march 4 21
    this.scrollableCols = ['Total Hours', 'Total Cost'];
    this.frozenProjectActuals = ['Resource Name'];
    this.frozenColsResourceEstimate = [{ label: 'checkBox', show: true },
    { label: 'Resource Name', show: true }];

    this.scrollableColsResources = [
      { label: 'Total Hours', show: true },
    ];
    this.isHidden();

  }

  selectText(ev) {
    ev.target.focus();
    ev.target.select();

  }
  updateFlagTrue() {
    this.updateFlag = true;
  }
  isDisabled(): boolean {
    let userRole = this.storage.getItem('selectedRole');
    if (userRole == 'Billing Manager') {
      return true;
    } else {
      return false;
    }
  }

  // exportExcel() {

  //   const data = [];
  //   const data2 = [];
  //   const data3 = [];


  //   this.allProjectDetail['internalEstimates'].map(element => {
  //     const obj = {};
  //     obj['Sub Project ID'] = element.sub_project_id;
  //     obj['Expense Type'] = element.expense_type;
  //     obj['Details'] = element.details;
  //     obj['Expense Date'] = element.expense_date;
  //     obj['Amount'] = element.amount;
  //     data2.push(obj);
  //   });

  //   // Define the desired key order excluding excluded keys
  //   const excludedKeys = new Set<string>(['resource_id', 'billing_rate', 'index']);
  //   const desiredKeyOrder = [
  //     'resource_name', 'resource_email', 'total_hours', 'total_cost',
  //     'Oct2023_hours', 'Nov2023_hours', 'Dec2023_hours',
  //     'Jan2024_hours', 'Feb2024_hours', 'Mar2024_hours'
  //   ].filter(key => !excludedKeys.has(key));

  //   // Creating the data for the 'Resources' worksheet with desired key order and excluding excluded keys
  //   this.resourceMonthsColsDataValue.forEach(ele => {
  //     const obj: any = {};
  //     desiredKeyOrder.forEach(key => {
  //       if (ele[key] !== undefined) {
  //         obj[key] = ele[key];
  //       } else {
  //         obj[key] = ''; // Set undefined values to an empty string or any default value you prefer
  //       }
  //     });
  //     data3.push(obj);
  //   });
  //   import('xlsx').then(xlsx => {
  //     const worksheet1 = xlsx.utils.json_to_sheet(data);
  //     const worksheet2 = xlsx.utils.json_to_sheet(data2);
  //     const worksheet3 = xlsx.utils.json_to_sheet(data3);
  //     const workbook = {
  //       Sheets: {
  //         'WorkValues': worksheet1,
  //         'Resources': worksheet2,
  //         'Additional Cost': worksheet3
  //       },
  //       SheetNames: ['WorkValues', 'Resources', 'Additional Cost']
  //     };

  //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //     this.saveAsExcelFile(excelBuffer, 'RWT_History');
  //   });
  // }

  exportExcel() {

    const data = [];
    const data2 = [];
    const data3 = [];
    const data4 = [];

    this.allProjectDetail['internalEstimates'].map(element => {
      const obj = {};
      obj['Sub Project ID'] = element.sub_project_id;
      obj['Expense Type'] = element.expense_type;
      obj['Details'] = element.details;
      obj['Expense Date'] = element.expense_date;
      obj['Amount'] = element.amount;
      data2.push(obj);
    });

    // // Define the desired key order excluding excluded keys
    // const excludedKeys = new Set<string>(['resource_id', 'billing_rate', 'index']);
    // const desiredKeyOrder = [
    //   'resource_name', 'resource_email', 'total_hours', 'total_cost',
    //   'Oct2023_hours', 'Nov2023_hours', 'Dec2023_hours',
    //   'Jan2024_hours', 'Feb2024_hours', 'Mar2024_hours'
    // ].filter(key => !excludedKeys.has(key));

    // // Creating the data for the 'Resources' worksheet with desired key order and excluding excluded keys
    // this.resourceMonthsColsDataValue.forEach(ele => {
    //   const obj: any = {};
    //   desiredKeyOrder.forEach(key => {
    //     if (ele[key] !== undefined) {
    //       obj[key] = ele[key];
    //     } else {
    //       obj[key] = ''; // Set undefined values to an empty string or any default value you prefer
    //     }
    //   });
    //   data3.push(obj);
    // });
    if (this.resourceMonthsColsDataValue.length > 0) {
      const element = this.resourceMonthsColsDataValue[0];
      const excludedKeys = new Set<string>(['resource_id', 'resource_email', 'billing_rate', 'index', 'show', 'probabilityFlag'
      ])
      const keys = Object.keys(element);
      const desiredKeyOrder = keys.filter(key => !excludedKeys.has(key));
      this.resourceMonthsColsDataValue.forEach(ele => {
        const obj: any = {};
        desiredKeyOrder.forEach(key => {
          if (ele[key] !== undefined) {
            obj[key] = ele[key];
          } else {
            obj[key] = ''; // Set undefined values to an empty string or any default value you prefer
          }
        });
        data3.push(obj);
      });


    }
    if (this.allProjectDetail['clientEstimates'].length > 0) {
      const element = this.allProjectDetail['clientEstimates'][0];
      const keys = Object.keys(element);

      const excludedKeys = new Set<string>(['index', 'level_name', 'location_name', 'rwt_name', 'slectBoxDisabledFlag', 'client_estimate_master_id']);
      const desiredKeyOrder = keys.filter(key => !excludedKeys.has(key));
      this.allProjectDetail['clientEstimates'].forEach(ele => {
        const obj: any = {};
        desiredKeyOrder.forEach(key => {
          if (ele[key] !== undefined) {
            if (key === 'location_id') {
              obj[key] = ele[key] === 1 ? 'India' : 'Onsite';
            } else if (key === 'rwt_level_id') {
              const rwt = this.allRwt.find(rwt => rwt.rwt_level_id === ele[key]);
              obj[key] = rwt ? rwt.rwt_name : ''; // Use an appropriate default value if not found
            } else {
              obj[key] = ele[key];
            }
          } else {
            obj[key] = ''; // Set undefined values to an empty string or any default value you prefer
          }
        });
        data4.push(obj);
      });
    }

    import('xlsx').then(xlsx => {
      const worksheet1 = xlsx.utils.json_to_sheet(data);
      const worksheet2 = xlsx.utils.json_to_sheet(data2);
      const worksheet3 = xlsx.utils.json_to_sheet(data3);
      const worksheet4 = xlsx.utils.json_to_sheet(data4);
      const workbook = {
        Sheets: {
          'WorkValues': worksheet4,
          'Resources': worksheet3,
          'Additional Cost': worksheet2
        },
        SheetNames: ['WorkValues', 'Resources', 'Additional Cost']
      };

      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'RWT_History');
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_Report' + EXCEL_EXTENSION);
    });
  }
  isHidden() {
    let userRole = this.storage.getItem('selectedRole');
    if (userRole == 'Sub Project Delivery Manager') {
      this.subProjectDEliveryManagerRoleFlag = true;
    } else {
      this.subProjectDEliveryManagerRoleFlag = false;
    }
  }
  filterSubProjects(resources) {
    let userRole = this.storage.getItem('selectedRole');
    this.userRole = userRole;
    if (userRole === 'Sub Project Delivery Manager') {
      let cognitoUserRole = JSON.parse(this.storage.getItem('user'));
      let filterResourceUsingCognito = resources.filter((item) => {
        if (item.cognito_user_name == cognitoUserRole.userID) return item;
      });

      let filterP = this.allProjectDetail['project'].filter((item) => {
        if (
          item.delivery_manager_id == filterResourceUsingCognito[0].resource_id
        ) {
          return item;
        }
      });
      this.filteredSubPro = filterP;

    }
  }
  getResources() {
    this.timesheetServie.getResources().then((resources) => {
      this.getRwt();
      this.filterSubProjects(resources);
      this.allResources = resources;
      resources.map((resource) => {
        if (!this.resourceObj[resource.resource_id]) {
          this.resourceObj[resource.resource_id] = resource
        }
        // else{
        //   this.resourceObj[resource.resource_id]
        // }
      })


      this.resourcesArray = resources.filter((resource) => {
        let lastDate = resource.resource_last_date;
        let startDate = resource.resource_start_date;
        if (((moment(startDate).isBefore(this.projectendDate) && resource.resource_last_date === null) || moment(lastDate).isAfter(this.projectStartDate)) && resource.br_latest && resource.rwt_latest) {

          return resource;
        }
      });
      console.log("resources Obj", this.resourcesArray)
    });
  }
  hideFooterFunction() {
    if (this.MonthsColsDataValue) {
      if (this.MonthsColsDataValue.length == 0) {
        this.hideFooterFlagClient = true;
        this.selectedRowsClient = [];
        this.totalCostClientEstimate = 0;
        this.totalHoursClientEstimate = 0;
      } else {
        this.hideFooterFlagClient = false;
      }
    }
    if (this.internalMonthsColsDataValue) {
      if (this.internalMonthsColsDataValue.length == 0) {
        this.hideFooterFlagInternal = true;
        this.selectedRowsInternal = [];
        this.totalHoursInternalEstimate = 0;
        this.totalCostInternalEstimate = 0;
      } else {
        this.hideFooterFlagInternal = false;
      }
    }
    if (this.resourceMonthsColsDataValue) {
      if (this.resourceMonthsColsDataValue.length == 0) {
        this.hideFooterFlagResource = true;
        this.selectedRowsResources = [];
        this.totalCostResourceEstimate = 0;
      } else {
        this.hideFooterFlagResource = false;
        this.calculateTotalResourceEstimate();

      }
    }
  }

  // Initialize totalAmount to 0
  totalAmountAdditionalCost: number = 0;
  totalProjectMargin: number = 0;

  // Function to recalculate total amount
  recalculateTotalAmount() {
    if (this.allProjectDetail && this.allProjectDetail.internalEstimates) {
      this.totalAmountAdditionalCost = this.allProjectDetail.internalEstimates.reduce(
        (total, estimate) => total + (estimate.amount || 0),
        0
      );
      this.totalProjectMargin = this.totalAmountAdditionalCost + this.getTotalResourceEstimate
      console.log("Data 1 :", this.totalProjectMargin);
      console.log("Data 2 :", this.totalAmountAdditionalCost);
    }
    // else {
    //   this.totalAmountAdditionalCost = 0; // Handle scenario where internalEstimates is undefined
    //   this.totalProjectMargin = this.totalAmountAdditionalCost + this.getTotalResourceEstimate
    //   console.log("Data 2 :", this.totalProjectMargin);
    // }
  }

  onAmountChange() {
    this.recalculateTotalAmount();
  }

  disableCopyGrid() {

    if (this.MonthsColsDataValue && this.MonthsColsDataValue.length == 0) {
      this.copyToNextGridClientButtonFlag = false;
    }

    if (
      this.internalMonthsColsDataValue && this.MonthsColsDataValue && this.MonthsColsDataValue.length > 0 &&
      this.internalMonthsColsDataValue.length == 0
    ) {
      this.copyToNextGridClientButtonFlag = true;
    } else {
      this.copyToNextGridClientButtonFlag = false;
    }
    if (
      this.resourceMonthsColsDataValue &&
      this.resourceMonthsColsDataValue.length == 0
    ) {
      this.copyToNextGridInternalButtonFlag = true;
    } else {
      this.copyToNextGridInternalButtonFlag = false;
    }

    if (
      this.internalMonthsColsDataValue.length == 0) {
      this.copyToNextGridInternalButtonFlag = false;
    }
    if (
      this.internalMonthsColsDataValue.length > 0
      && this.resourceMonthsColsDataValue.length == 0) {
      this.copyToNextGridInternalButtonFlag = true;
    }
  }

  newClientRow() {
    let obj = Object.create({
      rwt_id: '',
      level_id: '',
      location_id: '',
      location_name: '',
      filteredBaseline: '',
      totalCost: '',
      totalHours: '',
      monthsCol: '',
      slectBoxDisabledFlag: true,
      index: this.MonthsColsDataValue.length,
    });
    let x1 = [...this.clientEstimatesColumn];
    x1.splice(0, 3);
    x1.map((element) => {
      obj[element] = 0;
    });
    this.MonthsColsDataValue.push(obj);
    this.hideFooterFunction();
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
    this.scrollToBottom(this.pTable);
  }

  scrollToBottom(table: any) {
    const child = table.nativeElement.querySelectorAll('.p-datatable-scrollable-body');
    setTimeout(() => {
      child[1].scrollTop = (child[1].scrollHeight - child[1].clientHeight) + 132;
    }, 500)
  }
  newInternalRow() {
    let obj = Object.create({
      rwt_id: '',
      level_id: '',
      location_id: '',
      location_name: '',
      filteredBaseline: '',
      totalCost: '',
      totalHours: '',
      monthsCol: '',
      slectBoxDisabledFlag: true,
      index: this.internalMonthsColsDataValue.length,
    });
    let x1 = [...this.internalEstimatesColumn];
    x1.splice(0, 3);
    x1.map((element) => {
      obj[element] = 0;
    });
    this.internalMonthsColsDataValue.push(obj);
    this.hideFooterFunction();
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
    this.disableCopyGrid();
    this.scrollToBottom(this.pTable1);
  }

  addResourceRow() {
    this.onInitFlag = false;
    let obj = Object.create({
      resource_id: '',
      resource_email: '',
      total_hours: '',
      billing_rate: '',
      monthsCol: '',
      slectBoxDisabledFlag: true,
      index: this.resourceMonthsColsDataValue.length,
    });

    let x1 = [...this.resourceEstimatesColumn];

    x1.splice(0, 1);
    x1.map((element) => {
      obj[element] = 0;
      this.monthlyValue.map((item) => {
        let y = item + '_hours';
        let x = item + '_probability';
        if (y == element) {
          obj[element] = 0;
        } else if (x == element) {
          obj[element] = 100;
        }
      });
    });

    (obj.resource_id = ''),
      (obj.total_hours = ''),
      (obj.monthsCol = ''),
      (obj.slectBoxDisabledFlag = true),
      (obj.index = this.resourceMonthsColsDataValue.length),
      this.resourceMonthsColsDataValue.push(obj);
    // console.log(this.resourceMonthsColsDataValue);

    // console.log('resourceMonthsColsDataValue',this.resourceMonthsColsDataValue)
    this.hideFooterFunction();
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
    if (this.probalityColumnAddedCheck == true) {
      this.tdResourceColumns.map((item) => {
        item.show = true;
      });
    } else {
      this.tdResourceColumns.map((item) => {
        if (item.probs != undefined) {
          item.show = false;
        }
      });
    }
    this.disableCopyGrid();
    this.scrollToBottom(this.pTable2);
  }

  addAdditionalCost() {
    const newRow = {
      expense_type: '', // Default value for expense type
      details: '', // Default value for details
      amount: 0, // Default value for amount
      expense_date: '', // Default value for expense date
    };

    // // Check if any of the fields in the new row are empty and apply the CSS class
    // if (
    //   newRow.expense_type === '' ||
    //   newRow.details === '' ||
    //   newRow.amount === 0 || // Adjust the condition for your requirements
    //   newRow.expense_date === ''
    // ) {
    //   Object.keys(newRow).forEach(key => {
    //     if (newRow[key] === '') {
    //       newRow[key + '_required'] = true; // Add a flag to highlight empty fields
    //     }
    //   });
    // }


    (newRow.expense_type = ''),
      (newRow.details = ''),
      (newRow.amount = 0),
      (newRow.expense_date = '')
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
    this.allProjectDetail.internalEstimates.push(newRow);
  }



  hidebuttonsFlag() {
    if (this.selectedRowsClient.length == 0) {
      this.deleteClientFlag = false;
      this.cloneSelectedClientFlag = false;
    }
    if (this.selectedRowsInternal.length == 0) {
      this.deleteInternalFlag = false;
      this.cloneSelectedInternalFlag = false;
    }
    if (this.selectedRowsResources.length == 0) {
      this.deleteResoureFlag = false;
      this.cloneSelectedResoureFlag = false;
    }
  }
  copyValues() {
    if (this.selectedRowsClient) {
      let obj1 = [...this.selectedRowsClient];
      obj1.map((item) => {
        let object = {};
        for (let key in item) {
          object[key] = item[key];
        }
        object['index'] = this.MonthsColsDataValue.length;
        object['slectBoxDisabledFlag'] = true;
        object['rwt_id'] = '';
        object['level_id'] = '';
        object['location_id'] = '';
        object['rwt_name'] = '';
        object['level_name'] = '';
        object['location_name'] = '';
        object['baseline_rate'] = 0;
        object['total_cost'] = 0;
        this.MonthsColsDataValue.push(object);
      });
      this.selectedRowsClient = [];
      this.hidebuttonsFlag();
    }
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
  }
  async copyToNextGrid() {
    if (this.selectedRowsClient) {
      let obj1 = [...this.selectedRowsClient];
      await obj1.forEach(async (item, i) => {
        let object = {};
        for (let key in item) {
          object[key] = item[key];
        }
        object['index'] = i;
        object['slectBoxDisabledFlag'] = true;
        object['level_id'] = parseInt(object['level_id']);
        object['location_id'] = parseInt(object['location_id']);

        await this.internalMonthsColsDataValue.push(object);
        this.onRwtLocationSelectionInternal(object, i, '')
      });
    }
    if (this.selectedRowsClient.length == 0) {
      let obj1 = [...this.MonthsColsDataValue];
      await obj1.forEach(async (item, i) => {
        let object = {};
        for (let key in item) {
          object[key] = item[key];
        }
        object['index'] = i;
        object['slectBoxDisabledFlag'] = true;
        object['level_id'] = parseInt(object['level_id']);
        object['location_id'] = parseInt(object['location_id']);
        await this.internalMonthsColsDataValue.push(object);
        this.onRwtLocationSelectionInternal(object, i, '')
      });
    }
    this.selectedRowsClient = [];
    this.getDropDownDataForLevel();
    this.getDropDownDataForLocation();
    this.hidebuttonsFlag();
    this.hideFooterFlagInternal = false;

    this.disableCopyGrid();
    if (
      this.selectedRowsInternal.length > 0 &&
      this.resourceMonthsColsDataValue.length == 0
    ) {
      this.copyToNextGridInternalButtonFlag = true;
    } else {
      this.copyToNextGridInternalButtonFlag = false;
    }
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
    this.disableCopyGrid();
  }
  display: boolean = false;
  showDialog() {
    this.display = true;
  }

  saveButtonDialog() {


    this.confirmationService.confirm({
      message: 'Do you want to save all the changes to this Project?',
      accept: () => {
        this.saveSubProject();
      },
    });
  }
  areRequiredPropertiesTrue(): boolean {
    for (const estimate of this.allProjectDetail.internalEstimates) {
      if (
        estimate.amount_required ||
        estimate.expense_type_required ||
        estimate.expense_date_required
      ) {
        return true; // If any of the required properties are true, return true
      }
    }
    return false; // If none of the required properties are true, return false
  }



  highlightEmptyTextInput(textarea: HTMLTextAreaElement): void {
    if (textarea) {
      textarea.classList.toggle('highlight-empty', !textarea.value.trim());
      this.onInitFlag == true;
    }
  }
  highlightEmptyInput(dateInput: HTMLInputElement): void {
    if (dateInput) {
      dateInput.classList.toggle('highlight-empty', !dateInput.value);

    }
  }



  highlightEmptySelect(selectElement: ElementRef) {
    if (selectElement && selectElement.nativeElement) {
      const selectedValue = selectElement.nativeElement.value;

      // Check if no option is selected and apply CSS class for highlighting
      if (!selectedValue) {
        selectElement.nativeElement.classList.add('highlight-empty');


      } else {

        selectElement.nativeElement.classList.remove('highlight-empty');
      }
    }
  }

  addNewSubProjectPopup: boolean = false;
  addNewButtonDialog() {
    this.checkDataUpdationBeforeSaving();
    if (this.updateFlag == true) {
      this.addNewSubProjectPopup = true;
      this.confirmationService.confirm({
        message:
          'You may loose your unsaved data. You still want to create new project?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.addNewSubProject();
        },
      });
    } else {
      this.addNewSubProjectPopup = false;
      this.addNewSubProject();
    }
  }
  switchSubProjectPopup: boolean = false;
  switchButtonDialog() {
    this.switchSubProjectPopup = true;
  }
  deleteSubProjectPopup: boolean = false;
  deleteSubProjectButtonDialog() {
    this.deleteSubProjectPopup = true;
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this subproject?',
      accept: () => {
        this.deleteSubProject();
      },
    });
  }
  deleteSubProject() {
    let resource = JSON.parse(this.storage.getItem('user'));
    this.projectDetailService
      .deleteSubprojects(this.projectId,
        this.selectedSubProject.sub_project_id,
        resource.userID
      )
      .then((status) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Subproject Deleted Successfully ',
          life: 3000,
        });
        window.location.reload();
        // }
      });
    this.deleteSubProjectPopup = false;
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
  }
  copyValuesForInternal() {
    if (this.selectedRowsInternal) {
      let obj1 = [...this.selectedRowsInternal];
      obj1.map((item) => {
        let object = {};
        for (let key in item) {
          object[key] = item[key];
        }
        object['index'] = this.internalMonthsColsDataValue.length;
        object['slectBoxDisabledFlag'] = true;
        object['rwt_id'] = '';
        object['level_id'] = '';
        object['location_id'] = '';
        object['baseline_rate'] = 0;
        object['total_cost'] = 0;
        this.internalMonthsColsDataValue.push(object);
      });
      this.selectedRowsInternal = [];
      this.hidebuttonsFlag();
      this.copyToNextGridInternalButtonFlag = false;
      //  this.hideFooterFlagInternal = true;
    }
    this.checkDataUpdationBeforeSaving();
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
  }
  copyToNextGridInternal() {
    if (this.selectedRowsInternal) {
      let obj1 = [...this.selectedRowsInternal];
      obj1.map((item1) => {
        let object = {};
        for (let key in item1) {
          object[key] = item1[key];
        }
        object['index'] = this.resourceMonthsColsDataValue.length;
        object['slectBoxDisabledFlag'] = true;
        object['resource_id'] = '';

        this.monthlyValue.map((item) => {
          object[item + '_hours'] = item1[item];
          object[item + '_probability'] = 100;
        });
        if (this.probalityColumnAddedCheck == true) {
          object['show'] = true;
          object['probabilityFlag'] = true;
          this.tdResourceColumns.map((item) => {
            item.show = true;
          });
        } else {
          object['show'] = true;
          object['probabilityFlag'] = false;
          this.tdResourceColumns.map((item) => {
            if (item.probs != undefined) {
              item.show = false;
            }
          });
        }
        this.resourceMonthsColsDataValue.push(object);
      });
    }

    if (this.selectedRowsInternal.length == 0) {
      let obj1 = [...this.internalMonthsColsDataValue];
      obj1.map((item1) => {
        let object = {};
        for (let key in item1) {
          object[key] = item1[key];
        }
        object['index'] = this.resourceMonthsColsDataValue.length;
        object['slectBoxDisabledFlag'] = true;
        object['resource_id'] = '';

        this.monthlyValue.map((item) => {
          object[item + '_hours'] = item1[item];
          object[item + '_probability'] = 100;
        });
        if (this.probalityColumnAddedCheck == true) {
          object['show'] = true;
          object['probabilityFlag'] = true;
          this.tdResourceColumns.map((item) => {
            item.show = true;
          });
        } else {
          object['show'] = true;
          object['probabilityFlag'] = false;
          this.tdResourceColumns.map((item) => {
            if (item.probs != undefined) {
              item.show = false;
            }
          });
        }
        this.resourceMonthsColsDataValue.push(object);
      });
    }

    this.hideFooterFlagResource = false;
    this.selectedRowsInternal = [];
    if (this.resourceMonthsColsDataValue.length > 0) {
      let totalCostResourceEstimate = 0;
      this.resourceMonthsColsDataValue.map((item) => {
        totalCostResourceEstimate =
          totalCostResourceEstimate + item.total_hours;
      });
      this.totalCostResourceEstimate = totalCostResourceEstimate;
    }
    this.hidebuttonsFlag();
    this.checkDataUpdationBeforeSaving();
    this.copyToNextGridInternalButtonFlag = false;
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
  }
  copyValuesForResource() {
    if (this.selectedRowsResources) {
      let item = [...this.selectedRowsResources];
      item.map((item1) => {
        let object = {};
        for (let key in item1) {
          object[key] = item1[key];
        }
        object['index'] = this.resourceMonthsColsDataValue.length;
        object['slectBoxDisabledFlag'] = true;
        object['resource_id'] = '';
        this.resourceMonthsColsDataValue.push(object);
      });

      this.selectedRowsResources = [];
    }

    this.checkDataUpdationBeforeSaving();

    if (this.resourceMonthsColsDataValue.length > 0) {
      let totalCostResourceEstimate = 0;
      this.resourceMonthsColsDataValue.map((item) => {
        totalCostResourceEstimate =
          totalCostResourceEstimate + item.total_hours;
      });
      this.totalCostResourceEstimate = totalCostResourceEstimate;
    }
    this.hidebuttonsFlag();
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
  }
  // mapResourceValue() {
  //   this.resourceMonthsColsDataValue = [];
  //   console.log("Project Details :", this.allProjectDetail);

  //   this.allProjectDetail['resourceEstimates'].map((elements) => {
  //     let estimateObject1 = {};
  //     console.log("Resource estimate column", this.resourceEstimatesColumn);

  //     this.resourceEstimatesColumn.map((item, i) => {
  //       if (elements[item]) {
  //         estimateObject1[item] = elements[item];
  //         estimateObject1['total_hours'] + elements[item];
  //       } else {
  //         estimateObject1[item] = 0;
  //       }
  //       this.monthlyValue.map((month) => {
  //         if (elements[month + '_hours']) {
  //           estimateObject1['total_hours'] =
  //             estimateObject1['total_hours'] + elements[month + '_hours'];
  //         }
  //       });

  //       estimateObject1['billing_rate'] = elements['billing_rate'];
  //       estimateObject1['resource_id'] = elements['resource_id'];
  //       estimateObject1['resource_email'] = elements['resource_email'];
  //       estimateObject1['index'] = this.resourceMonthsColsDataValue.length;
  //       estimateObject1['show'] = false;

  //     });
  //   });
  //   // console.log('data11',this.allProjectDetail)
  // }

  getSubProjectNameById(sub_project_id) {
    let filter = this.allProjectDetail.project.filter((item) => {
      item.sub_project_id == sub_project_id;
    });
    return filter;
  }
  checkDataUpdationBeforeSaving() {
    //console.log("hellow");

    this.updateFlag = false;
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }

    let arr = this.allClientEstimates.filter(
      (element) =>
        element.sub_project_id == this.selectedSubProject.sub_project_id
    );

    // Assuming allProjectDetail.internalEstimates is an array of objects
    // Assuming allProjectDetail.internalEstimates is an array of objects
    this.allProjectDetail.internalEstimates = this.allProjectDetail.internalEstimates.filter(estimate => {
      return (
        estimate.amount !== '' &&
        estimate.details.trim() !== '' &&
        estimate.expense_date !== '0000-00-00' &&
        estimate.expense_type.trim() !== ''
      );
    });



    let internalArr = this.allInternalEstimates.filter(
      (element) =>
        element.sub_project_id == this.selectedSubProject.sub_project_id
    );
    let ResourceArr = this.allResourcesEstimates.filter(
      (element) =>
        element.sub_project_id == this.selectedSubProject.sub_project_id
    );
    let TempArrayCLient = [];
    arr.map((elements) => {
      let estimateObject = {};
      this.clientEstimatesColumn.map((item, i) => {
        if (elements[item]) {
          estimateObject[item] = elements[item];
          estimateObject['total_hours'] =
            estimateObject['total_hours'] + elements[item];
          estimateObject['total_cost'] =
            estimateObject['total_hours'] * elements['baseline_rate'];
        } else {
          estimateObject[item] = 0;
        }
      });
      estimateObject['index'] = TempArrayCLient.length;
      estimateObject['rwt_id'] = elements.rwt_level_id;
      estimateObject['level_id'] = elements.level_id;
      estimateObject['location_id'] = elements.location_id;
      TempArrayCLient.push(estimateObject);
      let totalHistoryClientEstimate = 0;
      TempArrayCLient.map((item) => {
        totalHistoryClientEstimate =
          totalHistoryClientEstimate + item.total_cost;
      });
      this.totalHistoryClientEstimate = totalHistoryClientEstimate;
    });
    if (
      JSON.stringify(TempArrayCLient) !=
      JSON.stringify(this.MonthsColsDataValue)
    ) {
      this.updateFlag = true;
    }
    let TempArrayInternal = [];
    internalArr.map((elements) => {
      let estimateObject = {};
      this.internalEstimatesColumn.map((item, i) => {
        if (elements[item]) {
          estimateObject[item] = elements[item];
          estimateObject['total_hours'] =
            estimateObject['total_hours'] + elements[item];
          estimateObject['total_cost'] =
            estimateObject['total_hours'] * elements['baseline_rate'];
        } else {
          estimateObject[item] = 0;
        }
      });
      estimateObject['index'] = TempArrayInternal.length;
      estimateObject['rwt_id'] = elements.rwt_level_id;
      estimateObject['level_id'] = elements.level_id;
      estimateObject['location_id'] = elements.location_id;
      TempArrayInternal.push(estimateObject);
      let totalHistoryInternalEstimate = 0;
      TempArrayInternal.map((item) => {
        totalHistoryInternalEstimate =
          totalHistoryInternalEstimate + item.total_cost;
      });
      this.totalHistoryInternalEstimate = totalHistoryInternalEstimate;
    });
    if (
      JSON.stringify(TempArrayInternal) !=
      JSON.stringify(this.internalMonthsColsDataValue)
    ) {
      this.updateFlag = true;
    }
    let TempArrayResource = [];
    ResourceArr.map((elements) => {
      let estimateObject = {};
      this.resourceEstimatesColumn.map((item, i) => {
        if (elements[item]) {
          estimateObject[item] = elements[item];
        } else {
          estimateObject[item] = 0;
        }
      });
      this.monthlyValue.map((month) => {
        if (elements[month + '_hours']) {
          estimateObject['total_hours'] =
            estimateObject['total_hours'] + elements[month + '_hours'];
          // estimateObject['total_cost'] = 
          //   (estimateObject['total_hours'] * elements['billing_rate']) ;
        }
      });
      estimateObject['total_cost'] = (estimateObject['total_hours'] * elements['billing_rate']);
      estimateObject['billing_rate'] = elements.billing_rate;
      estimateObject['resource_id'] = elements.resource_id;
      estimateObject['resource_email'] = elements.resource_email;
      estimateObject['index'] = TempArrayResource.length;
      TempArrayResource.push(estimateObject);
      // console.log('data22',estimateObject)
      let totalHistoryResourceEstimate = 0;
      TempArrayResource.map((item) => {
        totalHistoryResourceEstimate =
          totalHistoryResourceEstimate + item.total_hours;
      });
      this.totalHistoryResourceEstimate = totalHistoryResourceEstimate;
      if (this.probalityColumnAddedCheck == true) {
        estimateObject['show'] = true;
        estimateObject['probabilityFlag'] = true;
        this.tdResourceColumns.map((item) => {
          item.show = true;
        });
        this.tdResourceReadOnlyColData.map((item) => {
          item.show = true;
        });
      } else {
        estimateObject['show'] = true;
        estimateObject['probabilityFlag'] = false;
        this.tdResourceColumns.map((item) => {
          if (item.probs != undefined) {
            item.show = false;
          }
        });
        this.tdResourceReadOnlyColData.map((item) => {
          if (item.probs != undefined) {
            item.show = false;
          }
        });
      }
    });
    if (
      JSON.stringify(TempArrayResource) !=
      JSON.stringify(this.resourceMonthsColsDataValue)
    ) {
      this.updateFlag = true;
    }
  }
  addNewSubProject() {
    this.newSubProjectFlag = true;
    this.MonthsColsDataValue = [];
    this.internalMonthsColsDataValue = [];
    this.resourceMonthsColsDataValue = [];
    this.selectedProject = [];
    this.selectedSubProject = [];
    this.probalityColumnAddedCheck = false;
    this.deleteThisSubProjectFlag = false;
    let str = this.allProjectDetail.project[
      this.allProjectDetail.project.length - 1
    ].sub_project_id;
    let res = str.slice(-2);
    if (parseInt(res) < 9) {
      this.selectedSubProject.sub_project_id =
        this.projectDetail.project_code + '-' + '0' + (parseInt(res) + 1);
    } else {
      this.selectedSubProject.sub_project_id =
        this.projectDetail.project_code + '-' + (parseInt(res) + 1);
    }
    this.selectedSubProject.department_id = '';
    this.selectedSubProject.delivery_manager_id = '';
    this.selectedSubProject.sub_project_name = '';
    this.totalCostClientEstimate = 0;
    this.totalCostInternalEstimate = 0;
    this.totalCostResourceEstimate = 0;
    this.totalHoursClientEstimate = 0;
    this.totalHoursInternalEstimate = 0;
    this.newClientRow();
    this.newInternalRow();
    this.addResourceRow();
    this.addNewSubProjectPopup = false;
    this.scrollableColsResources.map((item) => {
      this.monthsCol.map((element) => {
        const x = moment(element).format('MMM YYYY');
        if (item.label == x + ' Prob(%)') {
          item.show = false;
        }
      });
    });
    //console.log("ScrollableColsResuouces :", this.scrollableColsResources);

    this.tdResourceColumns.map((item) => {
      if (item.probs != undefined) {
        item.show = false;
      }
    });
    this.disableCopyGrid();
  }
  checkIfDuplicateExists(w) {
    return new Set(w).size !== w.length;
  }

  resourceIdChange(row, event, ri) {
    this.duplicateCheckFlag();
    if (this.duplicateFlagResource == true) {
      event.value = '';
      this.resourceMonthsColsDataValue[ri].resource_id = '';
      this.duplicateFlagResource = false;
    }
    row.slectBoxDisabledFlag = true;
  }

  duplicateCheckFlag() {
    this.duplicateFlag = false;
    let arrClient = [];
    let result = this.MonthsColsDataValue.filter((o) => {
      var key = ['rwt_id', 'level_id', 'location_id'].map((k) => o[k]).join('');
      if (key != '') {
        arrClient.push(key);
      }
    });
    if (this.checkIfDuplicateExists(arrClient)) {
      this.duplicateFlag = true;
      Swal.fire(
        'Selected combination of RWT, Level and Location already exists!, please choose other',
        '',
        'warning'
      );
    }
    let arrInternal = [];
    let result1 = this.internalMonthsColsDataValue.filter((o) => {
      var key = ['rwt_id', 'level_id', 'location_id'].map((k) => o[k]).join('');
      if (key != '') {
        arrInternal.push(key);
      }
    });
    if (this.checkIfDuplicateExists(arrInternal)) {
      this.duplicateFlag = true;
      Swal.fire(
        'Selected combination of RWT, Level and Location already exists!, please choose other',
        '',
        'warning'
      );
    }
    var valueArr = this.resourceMonthsColsDataValue.map(function (item) {
      return parseInt(item.resource_id);
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      if (!isNaN(item)) return valueArr.indexOf(item) != idx;
    });
    if (isDuplicate == true) {
      this.duplicateFlagResource = true;
      Swal.fire(
        'Selected Resource is already added , please choose other',
        '',
        'warning'
      );
    }
  }

  showAdditionalCostTable = false;

  toggleTableVisibility(event: any) {
    // if(event.target.checked==false){
    //   this.showAdditionalCostTable=false;
    // }
    console.log(event.target);
    let isChecked = event.target.checked;

    if (!isChecked) {
      this.showAdditionalCostTable = false
    } else {
      this.showAdditionalCostTable = event.target.checked;

    }
  }
  saveSubProject() {

    this.selectedSubProject.sub_project_id;
    this.mandatoryFlag = false;
    this.updateFlag = false;
    this.deleteAdditionalCost = false;
    this.checkDataUpdationBeforeSaving();
    if (
      this.selectedSubProject.department_id == '' || this.selectedSubProject.department_id === null
      || this.selectedSubProject.delivery_manager_id == '' || this.selectedSubProject.delivery_manager_id === null
    ) {
      this.mandatoryFlag = true;
    }
    let clientEstimateFlag = 0;
    if (this.MonthsColsDataValue.length > 0) {
      let totalCostClientEstimate = 0;
      let totalHoursClientEstimate = 0;
      this.MonthsColsDataValue.map((item) => {
        totalCostClientEstimate = totalCostClientEstimate + item.total_cost;
        totalHoursClientEstimate = totalHoursClientEstimate + item.total_hours;
      });
      this.totalCostClientEstimate = totalCostClientEstimate;
      this.totalHoursClientEstimate = totalHoursClientEstimate;
    }
    let internalEstimateFlag = 0;
    if (this.internalMonthsColsDataValue.length > 0) {
      let totalCostInternalEstimate = 0;
      let totalHoursInternalEstimate = 0;
      this.internalMonthsColsDataValue.map((item) => {
        totalCostInternalEstimate = totalCostInternalEstimate + item.total_cost;
        totalHoursInternalEstimate =
          totalHoursInternalEstimate + item.total_hours;
      });
      this.totalCostInternalEstimate = totalCostInternalEstimate;
      this.totalHoursInternalEstimate = totalHoursInternalEstimate;
    }
    let resourceEstimateFlag = 0;
    if (this.resourceMonthsColsDataValue.length > 0) {
      let totalCostResourceEstimate = 0;
      this.resourceMonthsColsDataValue.map((item) => {
        totalCostResourceEstimate =
          totalCostResourceEstimate + item.total_hours;
      });
      this.totalCostResourceEstimate = totalCostResourceEstimate;
    }
    let clientDataArray = [];
    let estimatination_hours = [];
    let x1 = [...this.clientEstimatesColumn];
    x1.splice(0, 6);
    //console.log("Month cols Date value :",this.MonthsColsDataValue);
    // console.log("Value :", this.MonthsColsDataValue);

    this.MonthsColsDataValue.map((item) => {

      let estimateObject = {};
      estimatination_hours = [];
      x1.map((ele, i) => {
        if (item[ele]) {
          estimateObject[ele] = item[ele];
          let obj = {};
          // console.log('ele',ele);
          obj['month'] = moment(ele, 'MMM').format('MMM');
          obj['year'] = moment(ele, 'yyyy').format('yyyy');
          obj['hours'] = item[ele];
          estimatination_hours.push(obj);
        }
      });
      if (estimatination_hours.length == 0) {
        let obj = {};
        obj['month'] = moment(this.monthlyValue[0], 'MMM').format('MMM');
        obj['year'] = moment(this.monthlyValue[0], 'yyyy').format('yyyy');
        obj['hours'] = 0;
        estimatination_hours.push(obj);
      }
      let client_estimate_detail = {
        rwt_level_id: parseInt(item.rwt_id),
        level_id: parseInt(item.level_id),
        billing_hours: item.total_cost,
        baseline_rate: (item.baseline_rate),
        location_id: parseInt(item.location_id),
        estimatination_hours: estimatination_hours,
      };
      if (client_estimate_detail.estimatination_hours.length > 0) {
        if (
          !isNaN(client_estimate_detail.rwt_level_id) &&
          (isNaN(client_estimate_detail.level_id) ||
            isNaN(client_estimate_detail.location_id))
        ) {
          this.mandatoryFlag = true;
        }
      }
      if (!isNaN(client_estimate_detail.rwt_level_id)) {
        clientDataArray.push(client_estimate_detail);
        clientEstimateFlag = 1;
      }
    });
    let internalDataArray = [];
    let estimatination_hoursInternal = [];
    let x1internal = [...this.internalEstimatesColumn];
    x1internal.splice(0, 6);
    this.internalMonthsColsDataValue.map((item) => {
      let estimateObject = {};
      estimatination_hoursInternal = [];
      x1.map((ele, i) => {
        if (item[ele]) {
          estimateObject[ele] = item[ele];
          let obj = {};
          obj['month'] = moment(ele, 'MMM').format('MMM');
          obj['year'] = moment(ele, 'yyyy').format('yyyy');
          obj['hours'] = item[ele];
          estimatination_hoursInternal.push(obj);
        }
      });
      if (estimatination_hoursInternal.length == 0) {
        let obj = {};
        obj['month'] = moment(this.monthlyValue[0], 'MMM').format('MMM');
        obj['year'] = moment(this.monthlyValue[0], 'yyyy').format('yyyy');
        obj['hours'] = 0;
        estimatination_hoursInternal.push(obj);
      }
      // let internal_estimate_detail = {
      //   rwt_level_id: parseInt(item.rwt_id),
      //   level_id: parseInt(item.level_id),
      //   billing_hours: item.total_cost,
      //   baseline_rate: item.baseline_rate,
      //   location_id: parseInt(item.location_id),
      //   estimatination_hours: estimatination_hoursInternal,
      // };
      // if (internal_estimate_detail.estimatination_hours.length > 0) {
      //   if (
      //     !isNaN(internal_estimate_detail.rwt_level_id) &&
      //     (isNaN(internal_estimate_detail.level_id) ||
      //       isNaN(internal_estimate_detail.location_id))
      //   ) {
      //     this.mandatoryFlag = true;
      //   }
      // }
      // if (!isNaN(internal_estimate_detail.rwt_level_id)) {
      //   internalEstimateFlag = 1;
      //   internalDataArray.push(internal_estimate_detail);
      // }
    });
    let resourceDataArray = [];
    let estimatination_hoursResource = [];
    this.resourceMonthsColsDataValue.map((item) => {
      let estimateObject = {};
      estimatination_hoursResource = [];
      this.monthlyValue.map((ele, i) => {
        let obj = {};
        if (this.probalityColumnAddedCheck == false) {
          obj['month'] = moment(ele, 'MMM').format('MMM');
          obj['year'] = moment(ele, 'yyyy').format('yyyy');
          obj['hours'] = item[ele + '_hours'];
          obj['probability'] = 100;
          estimatination_hoursResource.push(obj);
        }
        if (this.probalityColumnAddedCheck == true) {
          obj['month'] = moment(ele, 'MMM').format('MMM');
          obj['year'] = moment(ele, 'yyyy').format('yyyy');
          obj['hours'] = item[ele + '_hours'];
          obj['probability'] = parseInt(item[ele + '_probability']);
          estimatination_hoursResource.push(obj);
        }
      });
      let resource_estimate_detail = {
        resource_id: parseInt(item.resource_id),
        resource_email: item.resource_email,
        billing_hours: item.total_hours,
        estimatination_hours: estimatination_hoursResource,
      };
      if (resource_estimate_detail.resource_id) {
        resourceEstimateFlag = 1;
        resourceDataArray.push(resource_estimate_detail);
      }
    });
    let obj = {
      project_id: this.projectDetail.project_id,
      comments: this.comments,
      modifiedBy: this.resource.userID,
      subprojects: [
        {
          sub_project_id: this.selectedSubProject.sub_project_id,
          sub_project_name: this.selectedSubProject.sub_project_name === null ? '' : this.selectedSubProject.sub_project_name,
          department_id: parseInt(this.selectedSubProject.department_id),
          client_estimate: clientEstimateFlag,
          internal_estimate: internalEstimateFlag,
          resource_estimate: resourceEstimateFlag,
          total_work_value: this.totalCostClientEstimate,
          total_internal_value: this.totalCostInternalEstimate,
          total_resource_value: this.totalCostResourceEstimate,
          totalClientHistory: this.totalHistoryClientEstimate,
          totalInternalHistory: this.totalHistoryInternalEstimate,
          totalResourceHistory: this.totalHistoryResourceEstimate,
          delivery_manager_id: parseInt(
            this.selectedSubProject.delivery_manager_id
          ),
          client_estimate_detail: clientDataArray,
          internal_estimate_detail: this.allProjectDetail.internalEstimates,
          resource_estimate_detail: resourceDataArray,
        },
      ],

    };
    console.log("sub projects :", obj.subprojects);

    if (this.mandatoryFlag == true) {
      this.loading = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Please enter mandatory details!',
        life: 3000,
      });
      this.loading = false;
    }
    if (this.duplicateFlag == true) {
      this.loading = true;
      this.messageService.add({
        severity: 'error',
        summary: 'selected data are already there in system!',
        life: 3000,
      });
      this.loading = false;
    }
    if (this.mandatoryFlag == false && this.duplicateFlag == false) {
      this.loading = true;
      this.projectDetailService
        .postSubProjectData(obj)
        .subscribe((response) => {
          if (response.body == 1) {
            this.messageService.add({
              severity: 'success',
              summary: 'Successfuly saved',
              life: 3000,
            });
            this.router.navigateByUrl(
              'projects/project-detail/' +
              this.projectId +
              '/' +
              this.selectedSubProject.sub_project_id
            );
            this.loading = false;
            this.ngOnInit();
          } else if (response.errorMessage) {
            this.loading = true;
            this.messageService.add({
              severity: 'error',
              summary: 'failed to save',
              detail: 'Redirecting..',
              life: 3000,
            });
            this.loading = false;
          }
        });
    }
  }


  changeProbabilityCheck(event: any) {
    this.probalityColumnAddedCheck = event.target.checked;
    // this.probalityColumnAddedCheck = !this.probalityColumnAddedCheck;
    // console.log('afterchecked',this.probalityColumnAddedCheck)
    // this.probalityColumnAddedCheckFooter = !this.probalityColumnAddedCheckFooter;
    if (this.probalityColumnAddedCheck === true) {
      this.scrollableColsResources.map((item) => {
        item.show = true;
      });
      this.tdResourceColumns.map((item) => {
        item.show = true;
      });
      this.tdResourceReadOnlyColData.map((item) => {
        item.show = true;
      });
      this.probalityColumnAddedCheckFooter = true;

    }
    if (this.probalityColumnAddedCheck === false) {
      this.scrollableColsResources.map((item, i) => {
        this.monthsCol.map((element) => {
          const x = moment(element).format('MMM YYYY');
          if (item.label === x + '<br/> Prob(%)') {
            item.show = false;
            //item.index = i;
          }
        });
      });
      this.tdResourceColumns.map((item, i) => {
        if (item.probs !== undefined) {
          item.show = false;
          // item.index = i;
        }
      });
      this.tdResourceReadOnlyColData.map((item, i) => {
        if (item.probs !== undefined) {
          item.show = false;
          // item.index = i;
        }
      });
      this.probalityColumnAddedCheckFooter = false;
      // this.calculateTotalResourceEstimate();
    }

    this.calculateTotalResourceEstimate();
  }
  onTabPress(e, i, ri) {
    try {
      if (this.probalityColumnAddedCheck == false) {
        i = i + 2;
        ri = ri + 2;
        let target = e.path[5];
        let totalCols = this.tdResourceColumns.length;
        if (i == totalCols) {
          let resourceTable = e.path[6];
          let nextRow = ri;
          target = resourceTable.querySelector('tr:nth-child(' + nextRow + ')');
          i = 0;
        }
        if (target) {
          let indexCol = i + 2;
          let td = target.querySelector('td:nth-child(' + indexCol + ')');
          if (td) {
            //td.style.backgroundColor = 'darkorange';
            //td.style.color = 'white';
            td.click();
          }
        }
      }
      this.calculateTotalCostResourceEstimate(e);
    }
    catch {

    }
  }
  mapRwtDataToClientEstimates() {
    this.allProjectDetail['clientEstimates'].map((element, i) => {
      const rwtfiltered = this.allRwt.filter(
        (element1) =>
          element1.rwt_level_id == element.rwt_level_id &&
          element1.level_id == element.level_id &&
          element1.location_id == element.location_id
      );
      element.rwt_name = rwtfiltered[0] ? rwtfiltered[0].rwt_name : '';
      element.level_name = rwtfiltered[0] ? rwtfiltered[0].level_name : '';
      element.location_name = rwtfiltered[0] ? rwtfiltered[0].location_name : '';
      element.index = i;
      element.slectBoxDisabledFlag = false;
      this.allClientEstimates.push(element);
    });
    this.getMonthsColsDataValue();
  }
  mapRwtDataToInternalEstimates() {
    //
    console.log(this.allProjectDetail['internalEstimates']);

    // this.allProjectDetail['internalEstimates'].map((element, i) => {
    //   const rwtfiltered = this.allRwt.filter(
    //     (element1) =>
    //       element1.rwt_level_id === element.rwt_level_id &&
    //       element1.level_id === element.level_id &&
    //       element1.location_id === element.location_id
    //   );

    //   element.rwt_name = rwtfiltered[0] ? rwtfiltered[0].rwt_name : '';
    //   element.level_name = rwtfiltered[0] ? rwtfiltered[0].level_name : '';
    //   element.location_name = rwtfiltered[0] ? rwtfiltered[0].location_name : '';
    //   // console.log("rwtfiltered[0]",rwtfiltered[0])
    //   console.log("loog");

    //   if (rwtfiltered[0]['baseline_rates'].length === 1) {
    //     element.baseline_rate = rwtfiltered[0]['baseline_rate'];
    //   } else {
    //     rwtfiltered[0]['baseline_rates'].map(item => {
    //       if (!item['end_date']) {
    //         element.baseline_rate = item['baseline_rate'];
    //       }
    //     })
    //   }
    //   // element.baseline_rate =  rwtfiltered[0] ? rwtfiltered[0].baseline_rate : '';
    //   element.index = i;
    //   element.slectBoxDisabledFlag = false;
    //   this.allInternalEstimates.push(element);
    // });
    this.getMonthsColsDataValue();
  }
  mapRwtDataToResourceEstimates() {
    this.allProjectDetail['resourceEstimates'].map((element, i) => {
      const rwtfiltered = this.resourcesArray.filter(
        (element1) => element1.resource_id === element.resource_id
      );
      element.resource_id = rwtfiltered[0] ? rwtfiltered[0].resource_id : 0;
      element.resource_name = rwtfiltered[0] ? rwtfiltered[0].resource_name : '';
      element.resource_email = rwtfiltered[0] ? rwtfiltered[0].resource_email : '';
      element.billing_rate = rwtfiltered[0] ? rwtfiltered[0].billing_rate : '';
      element['show'] = false;
      element.index = i;
      element.slectBoxDisabledFlag = false;
      this.allResourcesEstimates.push(element);


    });
    // console.log('resE',this.allProjectDetail)
    this.getMonthsColsDataValueForResource();
  }
  selextboxChangeConfirm: boolean = false;
  selextboxChangeConfirmPopup(event) {
    this.subprojectEvent = event;
    this.projectActualsShown = true;
    this.isRecodExists = true;
    this.projectActualsMonthsColsDataValue = [];
    this.checkDataUpdationBeforeSaving();
    if (this.updateFlag == true) {
      this.confirmationService.confirm({
        message:
          'You may lose your unsaved data. Do you still want to proceed?',
        accept: () => {
          this.okSelectBoxSubProject();
        },
      });
    } else {
      this.subProjectSeletBox(event);
    }
    this.router.navigateByUrl(
      'projects/project-detail/' + this.projectId + '/' + event.target.value
    );
  }
  okSelectBoxSubProject() {
    this.subProjectSeletBox(this.subprojectEvent);
    this.selextboxChangeConfirm = false;
  }
  subProjectSeletBox(event) {
    this.totalCostInternalEstimate = 0;
    this.totalCostClientEstimate = 0;
    this.totalCostResourceEstimate = 0;
    this.probalityColumnAddedCheck = false;
    this.updateFlag = false;
    this.onInitFlag = true;
    let projectId;
    if (event.target) {
      projectId = event.target.value;
    } else {
      projectId = event;
    }
    if (this.allProjectDetail) {
      let filterSelectedSubProject = this.allProjectDetail.project.filter(
        (element) => element.sub_project_id == projectId
      );
      this.selectedSubProject = filterSelectedSubProject[0];
    }
    let arr = this.allClientEstimates.filter(
      (element) => element.sub_project_id == projectId
    );
    let internalArr = this.allInternalEstimates.filter(
      (element) => element.sub_project_id == projectId
    );
    let ResourceArr = this.allResourcesEstimates.filter(
      (element) => element.sub_project_id == projectId
    );
    this.MonthsColsDataValue = [];
    this.internalMonthsColsDataValue = [];
    this.resourceMonthsColsDataValue = [];
    this.scrollableColsResources.map((item) => {
      this.monthsCol.map((element) => {
        const x = moment(element, 'MMM YYYY').format('MMM YYYY');
        if (item.label == x + '<br/> Prob(%)') {
          item.show = false;
        }
      });
    });
    //console.log('hi');
    ResourceArr.map((item) => {
      this.monthlyValue.map((element1) => {
        if (
          item[element1 + '_probability'] &&
          item[element1 + '_probability'] != 100
        ) {
          this.probalityColumnAddedCheck = true;
          this.scrollableColsResources.map((item) => {
            item.show = true;
          });
        }
      });
    });
    console.log("resouce arr :", ResourceArr);

    ResourceArr.map((elements) => {
      let estimateObject = {};
      //console.log("Resource estimate column :", this.resourceEstimatesColumn);

      this.resourceEstimatesColumn.map((item, i) => {
        if (elements[item]) {
          estimateObject[item] = elements[item];
        } else {
          estimateObject[item] = 0;
        }
      });
      this.monthlyValue.map((month) => {
        if (elements[month + '_hours']) {
          estimateObject['total_hours'] = estimateObject['total_hours'] + elements[month + '_hours'];
          let billing_rate = this.resourceBillingRate(elements['resource_id'], month)
          estimateObject['total_cost'] = estimateObject['total_cost'] + (Number(estimateObject[month + '_hours']) * billing_rate)

          // estimateObject['total_cost'] = (estimateObject['total_hours'] * elements['billing_rate']);
        }

      });

      estimateObject['resource_id'] = elements.resource_id;
      estimateObject['resource_email'] = elements.resource_email;
      estimateObject['billing_rate'] = elements.billing_rate;
      estimateObject['index'] = this.resourceMonthsColsDataValue.length;
      if (this.probalityColumnAddedCheck == true) {
        estimateObject['show'] = true;
        estimateObject['probabilityFlag'] = true;
        this.tdResourceColumns.map((item) => {
          item.show = true;
        });
      } else {
        estimateObject['show'] = true;
        estimateObject['probabilityFlag'] = false;
        this.tdResourceColumns.map((item) => {
          if (item.probs != undefined) {
            item.show = false;
          }
        });
      }
      this.resourceMonthsColsDataValue.push(estimateObject);
      let totalCostResourceEstimate = 0;
      this.resourceMonthsColsDataValue.map((item) => {
        totalCostResourceEstimate =
          totalCostResourceEstimate + item.total_hours;
      });
      this.totalCostResourceEstimate = totalCostResourceEstimate;
    });


    internalArr.map((elements) => {
      const estimateObject = {};
      this.internalEstimatesColumn.map((item, i) => {
        if (elements[item]) {
          estimateObject[item] = elements[item];
          let baselinRate = this.getBaseLineRate(elements.rwt_level_id, elements.level_id, elements.location_id, item)
          estimateObject['total_hours'] = estimateObject['total_hours'] + elements[item];
          estimateObject['total_cost'] = estimateObject['total_cost'] + (elements[item] * baselinRate);
        } else {
          estimateObject[item] = 0;
        }
      });
      // this.internalEstimatesColumn.map((item, i) => {
      //   if (elements[item]) {
      //     estimateObject[item] = elements[item];
      //     estimateObject['total_hours'] =
      //       estimateObject['total_hours'] + elements[item];
      //     estimateObject['total_cost'] =
      //       estimateObject['total_hours'] * estimateObject['baseline_rate'];
      //   } else {
      //     estimateObject[item] = 0;
      //   }
      // });
      estimateObject['index'] = this.internalMonthsColsDataValue.length;
      estimateObject['rwt_id'] = elements.rwt_level_id;
      estimateObject['level_id'] = elements.level_id;
      estimateObject['location_id'] = elements.location_id;
      this.internalMonthsColsDataValue.push(estimateObject);
      let totalCostInternalEstimate = 0;
      let totalHoursInternalEstimate = 0;
      this.internalMonthsColsDataValue.map((item) => {
        totalCostInternalEstimate = totalCostInternalEstimate + item.total_cost;
        totalHoursInternalEstimate =
          totalHoursInternalEstimate + item.total_hours;
      });
      this.totalCostInternalEstimate = totalCostInternalEstimate;
      this.totalHoursInternalEstimate = totalHoursInternalEstimate;
    });
    arr.map((elements) => {
      const estimateObject = {};
      this.clientEstimatesColumn.map((item, i) => {
        if (elements[item]) {
          estimateObject[item] = elements[item];
          estimateObject['total_hours'] =
            estimateObject['total_hours'] + elements[item];
          estimateObject['total_cost'] =
            estimateObject['total_hours'] * elements['baseline_rate'];
        } else {
          estimateObject[item] = 0;
        }
      });

      // this.clientEstimatesColumn.map((item, i) => {
      //     if (elements[item]) {
      //       estimateObject[item] = elements[item];
      //       let baselinRate = this.getBaseLineRate(elements.rwt_level_id, elements.level_id, elements.location_id, item)
      //       estimateObject['total_hours'] = estimateObject['total_hours'] + elements[item];
      //       estimateObject['total_cost'] = estimateObject['total_cost']+  (elements[item] * baselinRate);
      //     } else {
      //       estimateObject[item] = 0;
      //     }
      //   });

      estimateObject['index'] = this.MonthsColsDataValue.length;
      estimateObject['rwt_id'] = elements.rwt_level_id;
      estimateObject['level_id'] = elements.level_id;
      estimateObject['location_id'] = elements.location_id;
      this.MonthsColsDataValue.push(estimateObject);
      let totalCostClientEstimate = 0;
      let totalHoursClientEstimate = 0;
      this.MonthsColsDataValue.map((item) => {
        totalCostClientEstimate = totalCostClientEstimate + item.total_cost;
        totalHoursClientEstimate = totalHoursClientEstimate + item.total_hours;
      });
      this.totalCostClientEstimate = totalCostClientEstimate;
      this.totalHoursClientEstimate = totalHoursClientEstimate;
    });
    this.loading = false;
    if (this.totalCostClientEstimate == 0
      && this.totalCostResourceEstimate == 0 && this.totalCostInternalEstimate == 0) {
      this.deleteThisSubProjectFlag = true;
      if (this.filteredSubPro.length == 1) {
        this.deleteThisSubProjectFlag = false;
      }
    } else {
      this.deleteThisSubProjectFlag = false;
    }
    this.disableCopyGrid();
    this.getDropDownDataForLevel();
    this.getDropDownDataForLocation();
    this.hideFooterFunction();
  }

  async getRwt() {
    this.projectDetailService.getRWT().then((rwtData) => {
      this.allRwt = rwtData;
      this.filteredRWT = this.findUnique(this.allRwt, (d) => d.rwt_name);
      const rwtlevelLocObj = {};
      this.allRwt.map(rwt => {
        let uniqRwtLevellLoc = rwt.rwt_name + '_' + rwt.level_name + '_' + rwt.location_name;
        let rwtItem = {}
        rwtItem['start_date'] = rwt.start_date
        rwtItem['end_date'] = rwt.end_date
        rwtItem['baseline_rate'] = rwt.baseline_rate
        if (!rwtlevelLocObj[uniqRwtLevellLoc]) {
          rwtlevelLocObj[uniqRwtLevellLoc] = {};
          rwtlevelLocObj[uniqRwtLevellLoc] = rwt
          rwtlevelLocObj[uniqRwtLevellLoc]['baseline_rates'] = []
          rwtlevelLocObj[uniqRwtLevellLoc]['baseline_rates'].push(rwtItem)
        } else {
          rwtlevelLocObj[uniqRwtLevellLoc]['baseline_rates'].push(rwtItem)
        }
      })
      // console.log("get rwt values :", this.allRwt);

      this.allRwt = Object.values(rwtlevelLocObj)
      this.mapRwtDataToClientEstimates();
      this.mapRwtDataToInternalEstimates();
      this.mapRwtDataToResourceEstimates();
      this.getDropDownDataForLevel();
      this.getDropDownDataForLocation();
      this.disableCopyGrid();
      this.onInitFlag = false;
      if (this.totalCostClientEstimate == 0 && this.totalCostResourceEstimate == 0 && this.totalCostInternalEstimate == 0) {
        this.deleteThisSubProjectFlag = true;
        if (this.filteredSubPro.length == 1) {
          this.deleteThisSubProjectFlag = false;
        }
      } else {
        this.deleteThisSubProjectFlag = false;
      }
    });
  }
  findUnique(arr, predicate) {
    var found = {};
    arr.map((d) => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map((key) => found[key]);
  }
  toggleDisplayForMileStones() {
    this.mileStoneShowm = !this.mileStoneShowm;
  }
  toggleDisplayForWorkValue() {
    this.workValueShown = !this.workValueShown;
  }
  toggleDisplayForInternalCost() {
    this.internalCostShown = !this.internalCostShown;
  }
  toggleDisplayForResourceEstimate() {
    this.resourceEstimateShown = !this.resourceEstimateShown;
  }

  additionCostShown: boolean = true;
  toggleDisplayForProjectMarginAdditionCost() {
    this.additionCostShown = !this.additionCostShown;
  }
  toggleDisplayForProjectActuals() {
    this.projectActualsShown = !this.projectActualsShown;
    // console.log(this.selectedSubProject);
    if (!this.projectActualsShown) {
      this.loading = true;
      this.isRecodExists = true;
      // get project actuals by sub project id
      this.projectDetailService
        .getProjectActualsBySubProjectID(this.selectedSubProject.sub_project_id)
        .then((projectActuals) => {
          // console.log(projectActuals);
          this.loading = false;
          this.projectActualsMonthsColsDataValue = projectActuals;
          if (projectActuals[0].Null === "Null") {
            this.isRecodExists = false;
          }
        }).catch((err) => {
          this.loading = false;
        });
    }
  }
  toggleDisplayForWorkValues() {
    this.workValuesShow = !this.workValuesShow;
  }

  getDropDownDataForLevel() {
    let arr = [];
    this.MonthsColsDataValue.map((element, i) => {
      let x = [];
      x.push(new levelDropDown(i, element.rwt_id, element.level_id));
      arr.push(x);
    });
    this.levelDropDownDataForWorkValue = [...arr];

    this.MonthsColsDataValue.map((item, i) => {
      this.onRwtNameSelection(item.rwt_id, i, item);
    });
    let arrInternal = [];
    this.internalMonthsColsDataValue.map((element, i) => {
      let x = [];
      x.push(new levelDropDown(i, element.rwt_id, element.level_id));
      arrInternal.push(x);
    });
    this.levelDropDownDataForInternalEstimate = [...arr];
    this.internalMonthsColsDataValue.map((item, i) => {
      this.onRwtNameSelectionForInternal(item.rwt_id, i, item);
    });
    return arr;
  }

  getDropDownDataForLocation() {
    let arr = [];
    this.MonthsColsDataValue.map((element, i) => {
      let x = [];
      x.push(
        new locationDropDown(
          i,
          element.rwt_id,
          element.level_id,
          element.location_id
        )
      );
      arr.push(x);
    });
    this.locationDropdownDataForWorkValue = [...arr];
    this.MonthsColsDataValue.map((item, i) => {
      this.onRwtLevelNameSelection(item.level_id, i, item);
    });
    let arrInternal = [];
    this.internalMonthsColsDataValue.map((element, i) => {
      let x = [];
      x.push(
        new locationDropDown(
          i,
          element.rwt_id,
          element.level_id,
          element.location_id
        )
      );
      arrInternal.push(x);
    });
    this.locationDropdownDataForInternalEstimate = [...arr];
    this.internalMonthsColsDataValue.map((item, i) => {
      this.onRwtLevelNameSelectionForInternal(item.level_id, i, item);
    });
    return arr;
  }

  // getDropDownDataForRwt() {
  //   let arr = [];
  //   this.allRwt.map((element, i) => {
  //     let x = new rwtDropDownData(element.rwt_name);
  //     arr.push(x);
  //   });
  //   return arr;
  // }

  rwtNameOnBlurClient(event, rowIndex, row) {
    this.onRwtNameSelection(event, rowIndex, row);
  }

  onRwtNameSelection(event, rowIndex, row) {
    var cloneArray = JSON.parse(JSON.stringify(this.allRwt));
    if (event.target) {
      this.onInitFlag == false;
      this.levelDropDownDataForWorkValue[rowIndex] = cloneArray.filter(
        (item) => item.rwt_level_id == parseInt(event.target.value)
      );
      this.levelDropDownDataForWorkValue[rowIndex] = this.findUnique(
        this.levelDropDownDataForWorkValue[rowIndex], (d) => d.level_id
      );

      // auto selection of level and location if only one level under rwt by kiran on 05/13/21
      if (this.levelDropDownDataForWorkValue[rowIndex].length == 1) {
        this.MonthsColsDataValue[rowIndex].level_id = this.levelDropDownDataForWorkValue[rowIndex][0].level_id;
        //this.onRwtLevelNameSelection(event,rowIndex,row);
        this.locationDropdownDataForWorkValue[rowIndex] = this.allRwt.filter(
          (item) =>
            item.level_id == parseInt(this.MonthsColsDataValue[rowIndex].level_id) &&
            item.rwt_level_id == row.rwt_id
        );
        // if only one location then auto select
        if (this.locationDropdownDataForWorkValue[rowIndex].length == 1) {
          this.MonthsColsDataValue[rowIndex].location_id = this.levelDropDownDataForWorkValue[rowIndex][0].location_id;
        } else {
          this.MonthsColsDataValue[rowIndex].location_id = 0;
        }
      } else {
        this.MonthsColsDataValue[rowIndex].level_id = '';
        this.locationDropdownDataForWorkValue[rowIndex] = this.allRwt.filter(
          (item) =>
            item.level_id == parseInt(this.MonthsColsDataValue[rowIndex].level_id) &&
            item.rwt_level_id == row.rwt_id
        );
      }
      this.onRwtLocationSelection(row, rowIndex, event);
    } else {
      this.levelDropDownDataForWorkValue[rowIndex] = cloneArray.filter(
        (item) => item.rwt_level_id == parseInt(event)
      );
      this.levelDropDownDataForWorkValue[rowIndex] = this.findUnique(
        this.levelDropDownDataForWorkValue[rowIndex],
        (d) => d.level_id
      );
    }
    if (this.onInitFlag == false) {
      this.checkDataUpdationBeforeSaving();
    }
  }

  rwtNameOnBlurInternal(event, rowIndex, row) {
    this.onRwtNameSelectionForInternal(event, rowIndex, row);
    this.levelDropDownDataForInternalEstimate[rowIndex] = [];
    this.locationDropdownDataForInternalEstimate[rowIndex] = [];
    this.levelDropDownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
      (item) => item.rwt_level_id == parseInt(event.target.value)
    );
    this.levelDropDownDataForInternalEstimate[rowIndex] = this.findUnique(
      this.levelDropDownDataForInternalEstimate[rowIndex],
      (d) => d.level_id
    );
    // auto drop down selection if only one level availble by kiran on 05/13/21

    if (this.levelDropDownDataForInternalEstimate[rowIndex].length == 1) {
      this.internalMonthsColsDataValue[rowIndex].level_id = this.levelDropDownDataForInternalEstimate[rowIndex][0].level_id;
      //this.onRwtLevelNameSelection(event,rowIndex,row);
      this.locationDropdownDataForInternalEstimate[rowIndex] = [];
      this.locationDropdownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
        (item) =>
          item.level_id == parseInt(this.internalMonthsColsDataValue[rowIndex].level_id) &&
          item.rwt_level_id == row.rwt_id
      );
      // if only one location then auto select
      if (this.locationDropdownDataForInternalEstimate[rowIndex].length === 1) {
        this.internalMonthsColsDataValue[rowIndex].location_id = this.locationDropdownDataForInternalEstimate[rowIndex][0].location_id;
      } else {
        this.internalMonthsColsDataValue[rowIndex].location_id = 0;
      }
    } else {
      this.internalMonthsColsDataValue[rowIndex].level_id = '';
      // this.levelDropDownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
      //   (item) =>
      //     item.level_id == parseInt(this.internalMonthsColsDataValue[rowIndex].level_id) &&
      //     item.rwt_level_id == row.rwt_id
      // );
    }

  }

  onRwtNameSelectionForInternal(event, rowIndex, row) {
    if (event.target) {
      this.onInitFlag == false;

      this.levelDropDownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
        (item) => item.rwt_level_id == parseInt(event.target.value)
      );
      this.levelDropDownDataForInternalEstimate[rowIndex] = this.findUnique(
        this.levelDropDownDataForInternalEstimate[rowIndex],
        (d) => d.level_id
      );
      if (this.levelDropDownDataForInternalEstimate[rowIndex].length == 1) {
        row.level_id = this.levelDropDownDataForInternalEstimate[rowIndex][0].level_id;
        row.location_id = this.levelDropDownDataForInternalEstimate[rowIndex][0].location_id;
      }
      this.onRwtLocationSelectionInternal(row, rowIndex, event);
    } else {
      this.levelDropDownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
        (item) => item.rwt_level_id == parseInt(event)
      );
      this.levelDropDownDataForInternalEstimate[rowIndex] = this.findUnique(
        this.levelDropDownDataForInternalEstimate[rowIndex],
        (d) => d.level_id
      );
    }
    // this.levelDropDownDataForInternalEstimate[rowIndex].forEach(element => {
    //   delete element.location_id;
    // });
    if (this.onInitFlag == false) {
      this.checkDataUpdationBeforeSaving();
    }
    this.calculateTotalCostInternalEstimate('i', rowIndex);
  }

  rwtLevelNameOnBlurClient(event, rowIndex, row) {
    this.onRwtLevelNameSelection(event, rowIndex, row);
  }

  onRwtLevelNameSelection(event, rowIndex, row) {
    if (event.target) {
      this.onInitFlag = false;
      this.locationDropdownDataForWorkValue[rowIndex] = this.allRwt.filter(
        (item) =>
          item.level_id == parseInt(event.target.value) &&
          item.rwt_level_id == row.rwt_id
      );
      this.onRwtLocationSelection(row, rowIndex, event);
      //New line added to auto select location when level changed
      this.MonthsColsDataValue[rowIndex].location_id = this.levelDropDownDataForWorkValue[rowIndex][0].location_id;
    } else {
      this.locationDropdownDataForWorkValue[rowIndex] = this.allRwt.filter(
        (item) =>
          item.level_id == parseInt(event) && item.rwt_level_id == row.rwt_id
      );
    }
    if (this.onInitFlag == false) {
      this.checkDataUpdationBeforeSaving();
    }
  }

  rwtLevelNameOnBlurInternal(event, rowIndex, row) {
    this.onRwtLevelNameSelectionForInternal(event, rowIndex, row);
    this.locationDropdownDataForInternalEstimate[rowIndex] = [];
    this.locationDropdownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
      (item) =>
        item.level_id == parseInt(event.target.value) &&
        item.rwt_level_id == row.rwt_id
    );
  }
  rwtLevelNameOnBlurInternalTest(event, rowIndex, row) {
    console.log(event);
    console.log(row);
  }
  onRwtLevelNameSelectionForInternal(event, rowIndex, row) {
    if (event.target) {
      this.onInitFlag == false;
      this.locationDropdownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
        (item) =>
          item.level_id == parseInt(event.target.value) &&
          item.rwt_level_id == row.rwt_id
      );
      this.onRwtLocationSelectionInternal(row, rowIndex, event);
      //New line added to auto select location when level changed
      this.internalMonthsColsDataValue[rowIndex].location_id = this.levelDropDownDataForInternalEstimate[rowIndex][0].location_id;
    } else {
      this.locationDropdownDataForInternalEstimate[rowIndex] = this.allRwt.filter(
        (item) =>
          item.level_id == parseInt(event) && item.rwt_level_id == row.rwt_id
      );
    }
    if (this.onInitFlag == false) {
      this.checkDataUpdationBeforeSaving();
    }
    this.calculateTotalCostInternalEstimate('i', rowIndex);
  }
  onRwtLocationSelection(row, ri, event) {
    let ele = this.allRwt.filter(
      (element) =>
        element.rwt_level_id == row['rwt_id'] &&
        element.level_id == row['level_id'] &&
        element.location_id == row['location_id']
    );
    if (
      row['rwt_id'] !== '' &&
      row['level_id'] !== '' &&
      row['location_id'] !== ''
    ) {
      // this.MonthsColsDataValue[ri]['baseline_rate'] = ele[0] ? ele[0].baseline_rate : 0;
      this.MonthsColsDataValue[ri]['baseline_rate'] = 0;
      // if (ele[0]['baseline_rates'].length === 1) {
      //   this.MonthsColsDataValue[ri]['baseline_rate'] = ele[0]['baseline_rate'];
      // } else {
      //   ele[0]['baseline_rates'].map(item => {
      //     if (!item['end_date']) {
      //       this.MonthsColsDataValue[ri]['baseline_rate'] = item['baseline_rate'];
      //     }
      //   })
      // }
    }
    this.calculateTotalCostClientEstimate('i', ri);
    this.duplicateCheckFlag();
    if (this.duplicateFlag == true) {
      let previousData = this.MonthsColsDataValue[ri];
      var x = this.MonthsColsDataValue.filter((e) => e.index != ri);
      this.MonthsColsDataValue = [...x];
      let object = {};
      for (let key in previousData) {
        object[key] = previousData[key];
      }
      object['index'] = ri;
      object['slectBoxDisabledFlag'] = true;
      object['rwt_id'] = '';
      object['level_id'] = '';
      object['location_id'] = '';
      object['rwt_name'] = '';
      object['level_name'] = '';
      object['location_name'] = '';
      object['baseline_rate'] = 0;
      object['total_cost'] = 0;

      this.MonthsColsDataValue.splice(ri, 0, object);
      this.duplicateFlag = false;
    }

    if (this.onInitFlag == false) {
      this.checkDataUpdationBeforeSaving();
    }
  }

  onRwtLocationSelectionInternal(row, ri, event) {
    let ele = this.allRwt.filter(
      (element) =>
        element.rwt_level_id == row['rwt_id'] &&
        element.level_id == row['level_id'] &&
        element.location_id == row['location_id']
    );
    if (
      row['rwt_id'] !== '' &&
      row['level_id'] !== '' &&
      row['location_id'] !== ''
    ) {
      // this.internalMonthsColsDataValue[ri]['baseline_rate'] =
      //   ele[0] ? ele[0].baseline_rate : 0;
      if (ele[0]['baseline_rates'].length === 1) {
        this.internalMonthsColsDataValue[ri]['baseline_rate'] = ele[0]['baseline_rate'];
      } else {
        ele[0]['baseline_rates'].map(item => {
          if (!item['end_date']) {
            this.internalMonthsColsDataValue[ri]['baseline_rate'] = item['baseline_rate'];
          }
        })
      }
    }
    this.calculateTotalCostInternalEstimate('i', ri);
    this.duplicateCheckFlag();
    if (this.duplicateFlag == true) {
      let previousData = this.internalMonthsColsDataValue[ri];
      var x = this.internalMonthsColsDataValue.filter((e) => e.index != ri);
      this.internalMonthsColsDataValue = [...x];

      let object = {};
      for (let key in previousData) {
        object[key] = previousData[key];
      }
      object['index'] = ri;
      object['slectBoxDisabledFlag'] = true;
      object['rwt_id'] = '';
      object['level_id'] = '';
      object['location_id'] = '';
      object['rwt_name'] = '';
      object['level_name'] = '';
      object['location_name'] = '';
      object['baseline_rate'] = 0;
      object['total_cost'] = 0;
      this.internalMonthsColsDataValue.splice(ri, 0, object);
    }
    if (this.onInitFlag == false) {
      this.checkDataUpdationBeforeSaving();
    }
  }
  onRowSelect(event) {
    this.disableCopyGrid();

    if (this.selectedRowsClient.length > 0) {
      this.deleteClientFlag = true;
      this.cloneSelectedClientFlag = true;
    }
    if (this.selectedRowsInternal.length > 0) {
      this.deleteInternalFlag = true;
      this.cloneSelectedInternalFlag = true;
    }
    if (this.selectedRowsResources.length > 0) {
      this.deleteResoureFlag = true;
      this.cloneSelectedResoureFlag = true;
    }

  }
  onRowUnselect(event) {
    this.disableCopyGrid();
    if (this.selectedRowsClient.length == 0) {
      this.deleteClientFlag = false;
      this.cloneSelectedClientFlag = false;
    }
    if (this.selectedRowsInternal.length == 0) {
      this.deleteInternalFlag = false;
      this.cloneSelectedInternalFlag = false;
    }
    if (this.selectedRowsResources.length == 0) {
      this.deleteResoureFlag = false;
      this.cloneSelectedResoureFlag = false;
    }
  }
  deleteClientEstimate() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected row(s)?',
      accept: () => {
        this.selectedRowsClient.map((item) => {
          var x = this.MonthsColsDataValue.filter((e) => e.index != item.index);
          x.forEach((element, i) => {
            element.index = i;
          });
          this.MonthsColsDataValue = [...x];
        });
        // auto selection of level and location if only one level under rwt by kiran on 05/13/21
        this.selectedRowsClient.map((item) => {
          // this.MonthsColsDataValue.splice( item.index, 1); 
          this.levelDropDownDataForWorkValue.splice(item.index, 1);
          this.locationDropdownDataForWorkValue.splice(item.index, 1);

        });
        this.onInitFlag=false;
        if (this.onInitFlag == false) {
          this.updateFlagTrue();
        }
        this.calculateTotalCostClientEstimate('input', 'ri');
        this.selectedRowsClient = [];
        if (this.selectedRowsClient.length == 0) {
          this.deleteClientFlag = false;
          this.cloneSelectedClientFlag = false;
        }
        if (this.selectedRowsInternal.length == 0) {
          this.deleteInternalFlag = false;
          this.cloneSelectedInternalFlag = false;
        }
        if (this.selectedRowsResources.length == 0) {
          this.deleteResoureFlag = false;
          this.cloneSelectedResoureFlag = false;
        }
        this.hideFooterFunction();
        // this.disableCopyGrid();
        this.checkDataUpdationBeforeSaving();
        // this.copyToNextGridClientButtonFlag = false;

        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Row(s) deleted',
          life: 3000,
        });
      },
    });
  }
  deleteInternalEstimate() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected row(s)?',
      // header: 'Confirm',
      // icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedRowsInternal.map((item) => {
          var x = this.internalMonthsColsDataValue.filter(
            (e) => e.index != item.index
          );

          x.forEach((element, i) => {
            element.index = i;
          });
          this.internalMonthsColsDataValue = [...x];

        });
        // auto selection of level and location if only one level under rwt by kiran on 05/13/21
        this.selectedRowsInternal.map((item) => {
          // this.MonthsColsDataValue.splice( item.index, 1); 
          this.levelDropDownDataForInternalEstimate.splice(item.index, 1);
          this.locationDropdownDataForInternalEstimate.splice(item.index, 1);

        });
        this.calculateTotalCostInternalEstimate('input', 'ri');
        this.selectedRowsInternal = [];

        if (this.selectedRowsClient.length == 0) {
          this.deleteClientFlag = false;
          this.cloneSelectedClientFlag = false;
        }

        if (this.selectedRowsInternal.length == 0) {
          this.deleteInternalFlag = false;
          this.cloneSelectedInternalFlag = false;
        }
        if (this.selectedRowsResources.length == 0) {
          this.deleteResoureFlag = false;
          this.cloneSelectedResoureFlag = false;
        }
        this.hideFooterFunction();
        this.checkDataUpdationBeforeSaving();
        this.copyToNextGridInternalButtonFlag = false;
        this.copyToNextGridClientButtonFlag = true;
      },
    });
  }

  projectdetaillink() {
    window.open(ConfigJson.sharepointDMSLink + this.projectDetail.project_code, '_blank');
  }

  selectedRows: any[] = [];

  // Function to remove selected rows
  removeSelectedRows() {
    if (this.onInitFlag == false) {
      this.updateFlagTrue();
    }
    this.allProjectDetail.internalEstimates = this.allProjectDetail.internalEstimates.filter(
      estimate => !this.selectedRows.includes(estimate)
    );

    // Clear selected rows array after deletion
    this.selectedRows = [];
    this.deleteAdditionalCost = false;
    this.recalculateTotalAmount();

  }

  // Function to handle checkbox selection
  handleCheckboxSelection(event: any, row: any) {
    this.deleteAdditionalCost = true
    if (event.checked) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows = this.selectedRows.filter(selectedRow => selectedRow !== row);
    }
  }

  deleteResourceEstimate() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected row(s)?',
      // header: 'Confirm',
      // icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedRowsResources.map((item) => {
          var x = this.resourceMonthsColsDataValue.filter(
            (e) => e.index != item.index
          );
          this.resourceMonthsColsDataValue = [...x];
        });
        this.calculateTotalCostResourceEstimate('input');
        this.selectedRowsResources = [];
        if (this.selectedRowsClient.length == 0) {
          this.deleteClientFlag = false;
          this.cloneSelectedClientFlag = false;
        }
        if (this.selectedRowsInternal.length == 0) {
          this.deleteInternalFlag = false;
          this.cloneSelectedInternalFlag = false;
        }
        if (this.selectedRowsResources.length == 0) {
          this.deleteResoureFlag = false;
          this.cloneSelectedResoureFlag = false;
        }
        this.hideFooterFunction();
        this.checkDataUpdationBeforeSaving();
        if (this.internalMonthsColsDataValue.length > 0 && this.resourceMonthsColsDataValue.length == 0) {
          this.copyToNextGridInternalButtonFlag = true;
        }
      },
    });
  }
  async getMonthsColsDataValue() {
    this.MonthsColsDataValue = [];
    this.internalMonthsColsDataValue = [];
    this.clientEstimatesColumn = [
      'rwt_name',
      'level_name',
      'location_name',
      'baseline_rate',
      'total_hours',
      'total_cost',
    ];
    this.clientEstimationColumnLabel = [
      'rwt name',
      'level name',
      'location name',
      'baseline rate',
      'total hours',
      'total cost',
    ];
    this.colHeaderClientFixed = [
      'rwt name',
      'level name',
      'location name',
      'baseline rate',
    ];
    this.internalEstimationColumnLabel = [
      'rwt name',
      'level name',
      'location name',
      'baseline rate',
      'total hours',
      'total cost',
    ];
    this.internalEstimatesColumn = [
      'rwt_name',
      'level_name',
      'location_name',
      'baseline_rate',
      'total_hours',
      'total_cost',
    ];
    this.projectActualsColumnLabel = [
      'resource_name'
    ]
    this.resourceEstimatesColumn = ['resource_name', 'total_hours', 'total_cost'];
    this.monthlyValue = [];
    this.projectActualsmonthlyValue = [];
    this.scrollableCols = [];
    this.scrollableColsProjectActuals = [];
    this.scrollableCols = ['Total Hours', 'Total Cost'];
    if (this.monthsCol) {
      this.monthsCol.map((element) => {
        const x = moment(element).format('MMM YYYY');
        this.clientEstimationColumnLabel.push(x);
        this.scrollableCols.push(x);

        this.internalEstimationColumnLabel.push(x);
        const colheaderMonths = moment(element).format('MMMYYYY');
        this.clientEstimatesColumn.push(colheaderMonths);
        this.internalEstimatesColumn.push(colheaderMonths);
        let x1 = [...this.clientEstimatesColumn];
        x1.splice(0, 3);
        let x1internal = [...this.internalEstimatesColumn];
        x1internal.splice(0, 3);
        this.tdcolumns = [...x1];
        this.tdInternalColumns = [...x1internal];
        this.monthlyValue.push(colheaderMonths);
      });
      if (this.projectActualsmonthsCol) {
        this.projectActualsmonthsCol.map((pelement) => {
          const x = moment(pelement).format('MMM YYYY');
          this.projectActualsColumnLabel.push(x);  // Project actuals 
          this.scrollableColsProjectActuals.push(x); // Project actuals 
          const colheaderMonths = moment(pelement).format('MMMYYYY');
          this.projectActualsmonthlyValue.push(colheaderMonths);
        });
      }
    }
    // this.allProjectDetail['clientEstimates'].map((elements) => {
    //   const estimateObject = {};
    //   // this.clientEstimatesColumn.map((item, i) => {
    //   //   if (elements[item]) {
    //   //     estimateObject[item] = elements[item];
    //   //     estimateObject['total_hours'] = estimateObject['total_hours'] + elements[item];
    //   //     estimateObject['total_cost'] =  estimateObject['total_hours'] * estimateObject['baseline_rate'];
    //   //   } else {
    //   //     estimateObject[item] = 0;
    //   //   }
    //   // });

    //   // this.clientEstimatesColumn.map((item, i) => {
    //   //   elements['total_hours'] = 0;
    //   //   elements['total_cost'] = 0;
    //   //     if (elements[item]) {
    //   //       estimateObject[item] = elements[item];
    //   //       let baselinRate = this.getBaseLineRate(elements.rwt_id, elements.level_id, elements.location_id, item)
    //   //       estimateObject['total_hours'] = estimateObject['total_hours'] + elements[item];
    //   //       // estimateObject['total_cost'] = estimateObject['total_cost']+  (elements[item] * baselinRate);
    //   //       estimateObject['total_cost']=1
    //   //       console.log("estimateObject",estimateObject)
    //   //     } else {
    //   //       estimateObject[item] = 0;
    //   //     }
    //   //   });

    //   estimateObject['rwt_id'] = elements.rwt_level_id;
    //   this.MonthsColsDataValue.push(estimateObject);
    //   let totalCostClientEstimate = 0;
    //   let totalHoursClientEstimate = 0;
    //   this.MonthsColsDataValue.map((item) => {
    //     totalCostClientEstimate = totalCostClientEstimate + item.total_cost;
    //     totalHoursClientEstimate = totalHoursClientEstimate + item.total_hours;
    //   });

    //   this.totalCostClientEstimate = totalCostClientEstimate;
    //   this.totalHoursClientEstimate = totalHoursClientEstimate;
    // });
    // this.allProjectDetail['internalEstimates'].map((elements) => {
    //   let estimateObject1 = {};
    //   this.internalEstimatesColumn.map((item, i) => {
    //     if (elements[item]) {
    //       estimateObject1[item] = elements[item];
    //       estimateObject1['total_hours'] =
    //         estimateObject1['total_hours'] + elements[item];
    //       estimateObject1['total_cost'] =
    //         estimateObject1['total_hours'] * estimateObject1['baseline_rate'];
    //     } else {
    //       estimateObject1[item] = 0;
    //     }
    //   });
    //   estimateObject1['rwt_id'] = elements.rwt_level_id;
    //   this.internalMonthsColsDataValue.push(estimateObject1);
    // });
    if (this.selectedSubProject) {
      this.subProjectSeletBox(this.selectedSubProject.sub_project_id);
    }
  }
  getMonthsColsDataValueForResource() {
    this.resourceEstimatesColumn = ['resource_name', 'total_hours', 'total_cost'];
    this.tdResourceColumns = [];
    this.tdResourceReadOnlyColData = [];
    this.tdResourceAColData = [];

    this.scrollableColsResources = [
      { label: 'Total Hours', show: true },
      { label: 'Total Cost', show: true },
    ];
    this.monthlyValue = [];
    let tempdataBefore = [];
    let tempdataAfter = [];
    if (this.monthsCol) {
      this.monthsCol.map((element) => {
        const x = moment(element).format('MMM YYYY');
        let maxHour = this.calculate_totalHours(x);
        let tempObj = { label: x + '<br/> Hours (' + maxHour + ')', show: true };
        //let tempObjP = { label: x + '<br/> Prob(%)', show: false };


        this.scrollableColsResources.push(tempObj);
        //this.scrollableColsResources.push(tempObjP);
        const colheaderMonths = moment(element).format('MMMYYYY');
        let colheaderMonthsName = colheaderMonths + '_hours';
        let colheaderMonthsProbabilty = colheaderMonths + '_probability';
        //this.probabiltyColumnsLabel.push(x);
        this.resourceEstimatesColumn.push(colheaderMonthsName);
        //this.resourceEstimatesColumn.push(colheaderMonthsProbabilty);
        let x1 = [...this.resourceEstimatesColumn];
        //console.log("ffff :", x1);

        x1.splice(0, 1);
        let tempObjProbs = {};
        let tempObjPProbs = {};



        let tempReadOnlyData = {};
        let tempReadOnlyProbs = {};
        let compareDate = moment(element).format('YYYY-MM');
        let currentDate = moment().format('YYYY-MM');
        if (moment(compareDate).isSameOrAfter(moment(currentDate))) {
          tempObjProbs = {
            label: colheaderMonths + '_hours',
            show: true,
            hours: true,
          };

          // tempObjPProbs = {
          //   label: colheaderMonths + '_probability',
          //   show: true,
          //   probs: false,
          // };

        } else {
          tempReadOnlyData = {
            label: colheaderMonths + '_hours',
            show: true,
            hours: true,
          };
          // tempReadOnlyProbs = {
          //   label: colheaderMonths + '_probability',
          //   show: true,
          //   probs: false,
          // };

        }

        tempdataAfter.push(tempObjProbs);
        //tempdataAfter.push(tempObjPProbs);
        let datafter = tempdataAfter.filter(value => Object.keys(value).length !== 0);
        this.tdResourceColumns = [...datafter];
        // console.log('tempObj',tempObj);

        // console.log('colheaderMonthsName',colheaderMonthsName)


        tempdataBefore.push(tempReadOnlyData);
        //tempdataBefore.push(tempReadOnlyProbs);
        let databefore = tempdataBefore.filter(value => Object.keys(value).length !== 0);
        this.tdResourceReadOnlyColData = [...databefore];
        // console.log('dabempt',databefore);


        // this.tdResourceReadOnlyColData.push(tempReadOnlyData);
        // this.tdResourceReadOnlyColData.push(tempReadOnlyProbs);
        // console.log('b4',this.tdResourceReadOnlyColData)
        // console.log('this.tdResourceColumns',this.tdResourceColumns)
        this.monthlyValue.push(colheaderMonths);
        // this.tdResourceColumns.push(tempObjProbs);
        // this.tdResourceColumns.push(tempObjPProbs);
        // this.tdResourceReadOnlyColData.push(tempReadOnlyData);
        // this.tdResourceReadOnlyColData.push(tempReadOnlyProbs);
        // console.log('b4',this.tdResourceReadOnlyColData)
        // console.log('this.tdResourceColumns',this.tdResourceColumns)
        // this.monthlyValue.push(colheaderMonths);
      });
    }
    var values = [];
    this.allProjectDetail['resourceEstimates'].map((elements) => {
      let estimateObject1 = {};
      this.resourceEstimatesColumn.map((item, i) => {
        if (elements[item]) {
          estimateObject1[item] = elements[item];
        } else {
          estimateObject1[item] = 0;
        }
      });
      this.monthlyValue.map((month) => {
        if (elements[month + '_hours']) {
          estimateObject1['total_hours'] =
            estimateObject1['total_hours'] + elements[month + '_hours'];

          estimateObject1['total_cost'] = (estimateObject1['total_hours'] * elements['billing_rate']);
        }
      });

      estimateObject1['show'] = false;
      this.resourceMonthsColsDataValue.push(estimateObject1);
      // console.log('date33',estimateObject1)
    });
    if (this.selectedSubProject) {
      this.subProjectSeletBox(this.selectedSubProject.sub_project_id);
      let ResourceArr = this.allResourcesEstimates.filter(
        (element) =>
          element.sub_project_id == this.selectedSubProject.sub_project_id
      );
    }
  }

  getBaseLineRate(rwtlevel, level, location, month) {
    let baseLinerate = 0
    const filteredRWT = this.allRwt.filter(rwt => {
      if (Number(rwt.level_id) == Number(level) && Number(rwt.location_id) == Number(location) && Number(rwt.rwt_level_id) == Number(rwtlevel)) {
        return true
      }
    })

    if (filteredRWT.length > 0) {
      filteredRWT[0]['baseline_rates'].map(rwtElement => {
        if (rwtElement.end_date && moment(month, 'MMMYYYY').isBetween(rwtElement.start_date, rwtElement.end_date, null, "[]")) {
          baseLinerate = rwtElement.baseline_rate
          //   return rwtElement.baseline_rate
        }
        if (!rwtElement.end_date && moment(month, 'MMMYYYY').isSameOrAfter(rwtElement.start_date)) {
          baseLinerate = rwtElement.baseline_rate
        }
      })
    }


    return baseLinerate;

  }

  calculateTotalCostClientEstimate(input, ri) {
    // input.clipboardData.getData('text/plain').slice(0, 4);
    let elements = this.MonthsColsDataValue[ri];
    // this.MonthsColsDataValue.map((elements) => {
    let estimateObject = {};
    elements['total_hours'] = 0;
    elements['total_cost'] = 0;
    this.monthlyValue.map((item, i) => {
      if (elements[item]) {
        elements['total_hours'] = elements['total_hours'] + Number(elements[item]);
        // let baselinRate = this.getBaseLineRate(elements.rwt_id, elements.level_id, elements.location_id, item)
        elements['total_cost'] = elements['total_hours'] * elements['baseline_rate'];
        // elements['total_cost'] = Number(elements['total_cost']) + Number(Number(elements[item]) * baselinRate);
      } else {
        elements[item] = 0;
      }

    });

    // });
    let totalCostClientEstimate = 0;
    let totalHoursClientEstimate = 0;
    this.MonthsColsDataValue.map((item) => {
      totalCostClientEstimate = totalCostClientEstimate + item.total_cost;
      totalHoursClientEstimate = totalHoursClientEstimate + item.total_hours;
    });

    this.totalCostClientEstimate = totalCostClientEstimate;
    this.totalHoursClientEstimate = totalHoursClientEstimate;
    this.checkDataUpdationBeforeSaving();
  }

  // calculateTotalCostClientEstimate(input, ri) {
  //   // input.clipboardData.getData('text/plain').slice(0, 4);
  //   // console.log("input, ri",input, ri)
  //   console.log("MonthsColsDataValue",this.MonthsColsDataValue)
  //   // console.log("this.allRwt",this.allRwt)
  //   const currentRow =this.MonthsColsDataValue[ri]

  //   const filteredRWT = this.allRwt.filter(rwt=>{
  //     if (Number(rwt.level_id) == Number(currentRow.level_id) && Number(rwt.location_id) == Number(currentRow.location_id) && Number(rwt.rwt_level_id) == Number(currentRow.rwt_id)){
  //       return true
  //     }
  //   })
  //   console.log("filteredRWT",filteredRWT)
  //   let latestBaseLineRate=0;
  //   let elements=this.MonthsColsDataValue[ri];
  //   // this.MonthsColsDataValue.map((elements) => {
  //     // let estimateObject = {};
  //     elements['total_hours'] = 0;

  //     this.monthlyValue.map((item) => {
  //       let isLatest=true;
  //       let monthBaselinerate=0
  //       if (elements[item]) {
  //         elements['total_hours'] = elements['total_hours'] + Number(elements[item]);

  //         filteredRWT[0]['baseline_rates'].map(month=>{
  //           if(month.end_date && moment(item).isBetween(month.start_date,month.end_date,null,"[]")){
  //             isLatest = false
  //             monthBaselinerate = month.baseline_rate;
  //             return
  //           }
  //           // else{
  //           //   isLatest = true
  //           //   monthBaselinerate = elements['baseline_rate'];
  //           // }
  //             // if(moment(item).isBetween(month.start_date,month.end_date,null,"[]")){
  //             //   elements['total_cost'] = elements['total_hours'] * month.baseline_rate;
  //           //   // }
  //           //   elements['total_cost'] = elements['total_hours'] * elements['baseline_rate'];
  //           // }else{
  //           //   elements['total_cost'] = elements['total_hours'] * monthBaselinerate;
  //           // }
  //         })
  //         // elements['total_cost'] = elements['total_hours'] * monthBaselinerate;
  //         if(isLatest){
  //           elements['total_cost'] = elements['total_hours'] * elements['baseline_rate'] ;
  //         }else{
  //           elements['total_cost'] = elements['total_hours'] * monthBaselinerate;

  //         }
  //         // elements['total_cost'] = elements['total_hours'] * elements['baseline_rate'];
  //       } else {
  //         elements[item] = 0;
  //       }
  //       let totalCostClientEstimate = 0;
  //       let totalHoursClientEstimate = 0;
  //       this.MonthsColsDataValue.map((item) => {
  //         totalCostClientEstimate = totalCostClientEstimate + item.total_cost;
  //         totalHoursClientEstimate = totalHoursClientEstimate + item.total_hours;
  //       });

  //       this.totalCostClientEstimate = totalCostClientEstimate;
  //       this.totalHoursClientEstimate = totalHoursClientEstimate;
  //     });
  //   // });
  //   this.checkDataUpdationBeforeSaving();
  // }

  calculateTotalCostInternalEstimate(input, ri) {
    let elements = this.internalMonthsColsDataValue[ri];
    // this.internalMonthsColsDataValue.map((elements) => {
    let estimateObject = {};
    elements['total_hours'] = 0;
    elements['total_cost'] = 0;
    this.monthlyValue.map((item, i) => {
      if (elements[item]) {
        elements['total_hours'] = elements['total_hours'] + Number(elements[item]);
        let baselinRate = this.getBaseLineRate(elements.rwt_id, elements.level_id, elements.location_id, item)
        elements['total_cost'] = Number(elements['total_cost']) + Number(Number(elements[item]) * baselinRate);
      } else {
        elements[item] = 0;
      }

    });
    //console.log("MOnthly value :", this.monthlyValue);

    let totalCostInternalEstimate = 0;
    let totalHoursInternalEstimate = 0;
    this.internalMonthsColsDataValue.map((item) => {
      totalCostInternalEstimate = totalCostInternalEstimate + item.total_cost;
      totalHoursInternalEstimate = totalHoursInternalEstimate + item.total_hours;
    });
    this.totalCostInternalEstimate = totalCostInternalEstimate;
    this.totalHoursInternalEstimate = totalHoursInternalEstimate;
    // });
    this.checkDataUpdationBeforeSaving();
  }

  //older code
  // resourceBillingRate(resourceID, month) {
  //   // console.log('resourceID,month',resourceID,month);
  //   let billing_rate = 0
  //   this.allResources.filter(resource => {
  //     if (resource.resource_id === resourceID && moment(month, 'MMMYYYY').isBetween(resource.rwt_start_date, resource.rwt_end_date, null, '[]') && moment(month, 'MMMYYYY').isBetween(resource.br_start_date, resource.br_end_date, null, '[]')) {
  //       billing_rate = resource.billing_rate
  //       // console.log('bnid billing_rate',billing_rate)
  //       return true
  //     }
  //   })
  //   return billing_rate
  // }

  resourceBillingRate(resourceID, month) {
    let billing_rate = 0
    //filtering the resources using the rwt start and end date
    let filteredResources = this.allResources.filter(resource =>
      resource.resource_id === resourceID &&
      moment(month, 'MMMYYYY').isBetween(resource.rwt_start_date, resource.rwt_end_date, null, '[]')
    );
    //retrieivng of rwtid
    let rwtId = filteredResources.map(resource => resource.rwt_id);
    // let filteredData1 = this.allRwt.filter(item =>
    //   rwtId.includes(item.rwtid)
    // );
    let monthVal = month; // Example month value in "Jan 2023" format
    let formattedMonthVal = `${monthVal.slice(0, 3)} ${monthVal.slice(3)}`; // Convert to "Feb 2023" format
    //code for filtering 
    let filteredData = this.allRwt.map(item => {
      // Mapping through each item in the 'allRwt' array
      const baselineRates = item.baseline_rates || []; // Assigning the 'baseline_rates' property of the current item to 'baselineRates'. If it doesn't exist, set it as an empty array.
      const matchedRate = baselineRates.find(rate => {  // Finding a rate within the 'baselineRates' array that matches certain conditions
        const startOfMonth = moment(rate.start_date).startOf('month');    // Parsing the 'start_date' of the rate and getting the start of the month using Moment.js

        const endOfMonth = rate.end_date ? moment(rate.end_date).startOf('month') : moment().startOf('month'); // Parsing the 'end_date' of the rate and getting the start of the month if it exists. If not, getting the current month's start using Moment.js
        const monthValue = moment(formattedMonthVal, 'MMM YYYY'); // Parsing the 'formattedMonthVal' string into a Moment.js object representing the month value
        return (
          monthValue.isSameOrAfter(startOfMonth, 'month') &&
          (rate.end_date === null || monthValue.isSameOrBefore(endOfMonth, 'month'))
        );  // Returning whether the 'monthValue' falls within the range of 'startOfMonth' and 'endOfMonth'
      });
      return {
        ...item,
        baseline_rates: matchedRate ? [matchedRate] : [] // Returning the item with an updated 'baseline_rates' property that contains either the matched rate or an empty array if no rate was found
      };
    }).filter(item => item.baseline_rates.length > 0 && rwtId.includes(item.rwtid));// Filtering the mapped items, keeping only those with a non-empty 'baseline_rates' array and whose 'rwtid' is included in the 'rwtId' array

    console.log("filter data :", filteredData);

    billing_rate = filteredData[0].baseline_rates[0].baseline_rate
    return billing_rate
  }



  calculateTotalCostResourceEstimate(i) {
    this.resourceMonthsColsDataValue.map((elements) => {
      let estimateObject = {};
      elements['total_hours'] = 0;
      elements['total_cost'] = 0;

      this.monthlyValue.map((item, i) => {

        if (elements[item] + '_hours') {
          elements['total_hours'] =
            elements['total_hours'] + Number(elements[item + '_hours']);
          // elements['total_cost'] =
          //   (elements['total_hours'] + Number(elements[item + '_hours'])* Number(elements['billing_rate']));
          let billing_rate = this.resourceBillingRate(elements['resource_id'], item)
          console.log("billing ");

          // elements['total_cost'] = (elements['total_hours'] * elements['billing_rate']);
          elements['total_cost'] = elements['total_cost'] + (Number(elements[item + '_hours']) * billing_rate)
        } else {
          elements[item] = 0;
        }

      });
      let totalCostResourceEstimate = 0;
      this.resourceMonthsColsDataValue.map((item) => {
        totalCostResourceEstimate = totalCostResourceEstimate + item.total_hours;
      });
      console.log("Resource month cost value :", this.resourceMonthsColsDataValue);

      this.totalCostResourceEstimate = totalCostResourceEstimate;
    });
    this.checkDataUpdationBeforeSaving();
    this.calculateTotalResourceEstimate();
    // console.log('tdresource',this.tdResourceColumns)
    // console.log('resourceMonthsColsDataValue',this.resourceMonthsColsDataValue)
  }

 
  dateRange(startDate, endDate) {
    var start = startDate.split('-');
    var end = endDate.split('-');
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? '0' + month : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }
    return dates;
  }
  tableheaderCheckboxClickClient() {
    if (this.selectedRowsClient.length == 0) {
      this.onRowUnselect('ev');
      this.copyToNextGridClientButtonFlag = false;
    } else if (this.selectedRowsClient.length > 0) {
      this.onRowSelect('ev');
    }
  }
  tableheaderCheckboxClickInternal() {
    if (this.selectedRowsInternal.length == 0) {
      this.onRowUnselect('ev');
      this.copyToNextGridInternalButtonFlag = false;
    }
    if (this.selectedRowsInternal.length > 0) {
      this.onRowSelect('ev');
    }
    if (this.selectedRowsResources.length == 0) {
      this.copyToNextGridInternalButtonFlag = false;
    }
    if (this.resourceMonthsColsDataValue.length == 0 && this.selectedRowsInternal.length > 0) {
      this.copyToNextGridInternalButtonFlag = true;
    }
  }
  tableheaderCheckboxClickResource() {
    if (this.selectedRowsResources.length == 0) {
      this.onRowUnselect('ev');
    } else if (this.selectedRowsResources.length > 0) {
      this.onRowSelect('ev');
    }
  }
  test() {
    alert('test');
  }
  getTotalResourceEstimate: number = 0;
  calculateTotalResourceEstimate() {
    console.log('hi');
    this.recalculateTotalAmount()
    this.monthlyResourceEstimate = [];
    // this.probalityColumnAddedCheck = !this.probalityColumnAddedCheck;
    this.footerResourceData = [...this.resourceMonthsColsDataValue];
    this.footerResourceHoursData = [...this.resourceMonthsColsDataValue];
    // console.log('this.footerResourceHoursData',this.footerResourceHoursData)
    let estimateObject = {};
    //Total Resource Estimate

    this.footerResourceData.forEach(x => {
      estimateObject['total_hours'] = 0;

      this.monthlyValue.map((item) => {
        if (Object.keys(x).find(y => (y === item + '_hours'))) {
          let baseline_rate = this.resourceBillingRate(x.resource_id, item)
          // estimateObject[item + '_hours'] = estimateObject[item + '_hours'] === undefined ?
          //   (Number(x[item + '_hours']) * x.billing_rate) : (estimateObject[item + '_hours'] + (Number(x[item + '_hours']) * x.billing_rate));
          estimateObject[item + '_hours'] = estimateObject[item + '_hours'] === undefined ?
            (Number(x[item + '_hours']) * baseline_rate) : (estimateObject[item + '_hours'] + (Number(x[item + '_hours']) * baseline_rate));
        }
        estimateObject['total_hours'] = estimateObject['total_hours'] === undefined ?
          Number(estimateObject['total_hours']) : Number(estimateObject['total_hours']) + Number(estimateObject[item + '_hours']);
        this.getTotalResourceEstimate = estimateObject['total_hours'];
      });
    });
    // console.log('fe',estimateObject)
    let estimateObject1 = {};
    //Grand total hours
    this.footerResourceHoursData.forEach(x => {
      estimateObject1['total_hours'] = 0;
      this.monthlyValue.map((item) => {
        if (Object.keys(x).find(y => (y === item + '_hours'))) {
          estimateObject1[item + '_hours'] = estimateObject1[item + '_hours'] === undefined ?
            (Number(x[item + '_hours'])) : (estimateObject1[item + '_hours'] + (Number(x[item + '_hours'])));
          // estimateObject1[item + '_probability'] = estimateObject1[item + '_probability'] === undefined ?
          //  Number(x[item + '_probability']) : (estimateObject1[item + '_probability'] + Number(x[item + '_probability']));
        }
        estimateObject1['total_hours'] = estimateObject1['total_hours'] === undefined ?
          Number(estimateObject1['total_hours']) : Number(estimateObject1['total_hours']) + Number(estimateObject1[item + '_hours']);
      });
    });
    // this.resourceEstimatesColumnFooter = [...this.resourceEstimatesColumn];
    // let footerdata = [];
    // footerdata =  [...this.resourceEstimatesColumn];
    // footerdata.splice(0,1);
    //  this.footerData = [...footerdata]
    // this.footerData.splice('total_cost',1);
    this.resourceEstimatesColumnFooter = [...this.resourceEstimatesColumn];

    this.resourceEstimatesColumnFooter.splice(0, 3)
    let rsEstTemp = [...this.resourceEstimatesColumnFooter];
    let resourceEstFooter = [...this.resourceEstimatesColumnFooter];
    this.estimateObjectData = { ...estimateObject };
    this.monthlyHoursFooterData = { ...estimateObject1 };

    // let resourceEstmiateObject = {};
    // footerdata.forEach(x => {

    //   if(Object.keys(estimateObject).find( y => (y === x ))){
    //     resourceEstmiateObject[x] = estimateObject[x];
    //   }

    // });
    // console.log('er',resourceEstmiateObject)
    // console.log('frozenColsResourceEstimate',this.frozenColsResourceEstimate);
    // console.log('scrollableColsResources',this.scrollableColsResources);
    let month = '';
    let key = '';
    this.scrollableColsResources.forEach(data => {
      if (data.show === true && data.label !== 'Total Hours' && data.label !== 'Total Cost' && data.label.includes('<br/> Hours')) {
        month = data.label.split('<br/>')[0];
        // console.log('k',data.label);
        key = month.split(' ')[0] + month.split(' ')[1] + '_hours';
        if (Object.keys(estimateObject).includes(key)) {
          this.monthlyResourceEstimate.push({ MonthYear: month, value: estimateObject[key] });
        }
      }
    });
    // this.monthlyResourceEstimate.forEach(x => {
    //   Object.keys(estimateObject).forEach(y =>{
    //     if(y.includes('_hours') && y !== 'total_hours') {
    //       if(x.key === y) {
    //         x.value = estimateObject[y];
    //       }
    //     }
    //   })
    // })
    // this.resourceEstimatesColumnFooter 

    let temp = [];

    if (this.probalityColumnAddedCheckFooter == true) {

      this.resourceEstimatesColumnFooter = [...rsEstTemp];
      // resourceEstFooter.forEach(y => {
      //   if(y.includes('_hours')) {
      //     // console.log('vv',y)
      //   // let re =/_probability/gi;
      //     temp.push(y);
      //   }
      // });

    }
    if (this.probalityColumnAddedCheckFooter == false) {

      resourceEstFooter.forEach(y => {
        let re = /_probability/gi;
        temp.push(y.replace(re, ''));
      });
      this.resourceEstimatesColumnFooter = [...temp];
      // this.estimateObjectData = {...estimateObject};

    }
    var calc = 0;
    //console.log("this.projectDetail.non_expense_milestone_amount  :", this.projectDetail.non_expense_milestone_amount, " ", "estimateObject['total_hours'] :", estimateObject['total_hours'], " ", "total Additional :", this.totalAmountAdditionalCost);
    console.log("Data 1 :", this.totalProjectMargin);
    console.log("Data 2 :", this.totalAmountAdditionalCost);
    if (this.projectDetail.non_expense_milestone_amount !== 0)
      //calc = (this.projectDetail.non_expense_milestone_amount - estimateObject['total_hours']) / this.projectDetail.non_expense_milestone_amount;
      calc = (this.projectDetail.non_expense_milestone_amount - estimateObject['total_hours'] - this.totalAmountAdditionalCost) / this.projectDetail.non_expense_milestone_amount;
    //totalProjectMargin
    this.resourceProfitability = calc * 100;
    // console.log('calc',calc);
    // console.log('estimateObject',estimateObject)
    // console.log('resourceEstimatesColumn',this.resourceEstimatesColumnFooter)
    // console.log('temp',temp)
    // console.log('status',this.probalityColumnAddedCheck)
    // console.log('fstatus',this.probalityColumnAddedCheckFooter)
    this.getTotalResourceEstimate = 0;
    this.getTotalResourceEstimate = estimateObject['total_hours'];
    this.recalculateTotalAmount();
  }

  calculate_totalHours(x) {
    let month_year = x.split(' ');
    const month = month_year[0];
    const year = month_year[1];
    const noOfWorkingHrsPerDay = 8;
    const currentMonthStartDate = moment(year + '-' + moment().month(month).format('M') + '-' + moment().month(month).year(year).startOf('month').format('DD'), 'YYYY-MM-DD').format('YYYY-MM-DD');
    const currentMonthEndDate = moment(year + '-' + moment().month(month).format('M') + '-' + moment().month(month).year(year).endOf('month').format('DD'), 'YYYY-MM-DD').format('YYYY-MM-DD');
    let resourceStartDay, resourceEndDay, noDaysFromStartDate, noDaysFromEndDate, resourceBillableHrs;
    const currentMonth = moment(currentMonthEndDate).format('MMM');
    resourceStartDay = parseInt(moment(currentMonthStartDate).format('D')) - 1;
    const lastDay = parseInt(moment(currentMonthEndDate).format('D'));
    const noOfSat = this.weekdaysBetween(moment(currentMonthStartDate), moment(currentMonthEndDate), 6);
    const noOfSun = this.weekdaysBetween(moment(currentMonthStartDate), moment(currentMonthEndDate), 0);
    noDaysFromStartDate = lastDay - (resourceStartDay + (noOfSat + noOfSun));
    resourceBillableHrs = noDaysFromStartDate * noOfWorkingHrsPerDay;
    // console.log('re',resourceBillableHrs)
    return resourceBillableHrs;
  }
  weekdaysBetween(d1, d2, isoWeekday) {
    // ensure we have valid moment instances
    d1 = moment(d1);
    d2 = moment(d2);
    // figure out how many days to advance to get to the next specified weekday (might be 0 if d1 is already the specified weekday).
    const daysToAdd = ((7 + isoWeekday) - d1.isoWeekday()) % 7;
    const nextTuesday = d1.clone().add(daysToAdd, 'days');
    // if we are already passed the end date, there must not  be any of that day in the given period.
    if (nextTuesday.isAfter(d2)) {
      return 0;
    }
    // otherwise, just return the whole number of weeks difference plus one for the day we already advanced to
    const weeksBetween = d2.diff(nextTuesday, 'weeks');
    return weeksBetween + 1;
  }

}
