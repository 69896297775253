import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Moment } from "moment";
import { Table } from 'primeng/table';
import { ProjectService } from 'src/app/_services/project.service';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_services/storage.service';
import { ConfirmationService, SortEvent } from 'primeng/api';
const moment = _moment;


export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export interface Evaluation {
  resource_estimate_master_id?: number;
  resource_name?: string;
  RWT?: string;
  client_name?: string;
  project_name?: string;
  sub_project_name?: string;
  sub_project_id?:string;
  project_id?:number;
  overall_score?: number;
  delivery_performance?: number;
  demonstrated_solutioning?: string;
  toggleDemonstratedSolutioning?: boolean;
  is_demonstrated_solutioning?: boolean;
  toggleProjectEscalations?: boolean;
  is_project_escalations?: boolean;
  project_escalations?: string;
  showcased_dependability?: string;
  demonstrated_initiative?: string;
  remarks?: string;
  IsEditable?: boolean;
  year:string;
  month:string;
  createdby:string;
}

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
  styles: [`
  :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
  }

  @media screen and (max-width: 64em) {
      :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
          top: 99px;
      }
  }
`],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    ConfirmationService,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class EvaluationComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  startDate = new FormControl(moment());
  editing = false;
  @ViewChild('dt', { static: false }) private dataTable: Table;
  editingRows: any = [];
  evaluationData: any = []; //: Evaluation[] ; 
  clonedEvaluation: { [s: number]: Evaluation; } = {};
  currentDate: string;  //.subtract(1,'year');
  monthYear: Date;
  evaluationObject: {};
  loading: boolean;
  resource: any;
  clients: any;
  projects: any;
  overallscores: any;
  deliveryPerformances: any;
  selectedEscalation ='0';
  selectedClient = '0';
  selectedProject = '0';
  selectedOverallscore = '0';
  selectedDeliveryPerformance = '0';
  originalData: any;
  projectControl = new FormControl();
  clientControl = new FormControl();
  overallscoreControl = new FormControl();
  deliveryPerformanceControl = new FormControl();
  escalationControl = new FormControl();
  saveFlag: boolean=false;
  saveDisplay: number =0;
  resourceCount: number;
  resourceObj: any;
  futureMonthClickDisable: boolean=false;
  previousMonthClickDisable: boolean=false;
  evaluationEditedData: any;
  evaluationCheckData: any = [];
  checkAllEvaluationData: boolean=false;
  displayPopup: boolean=false;
  evaluationCheckDataSelection: any = [];
  userRole: string;
  linkText: string;
  constructor(private apiService:ProjectService,private storage: StorageService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.resource=JSON.parse(this.storage.getItem('user'));
    this.currentDate = moment().set('date', 15).subtract(1,'month').format('MM/DD/YYYY')
    var datefilter=moment(this.currentDate).clone();
    datefilter=moment(datefilter)
    const month_number= moment(datefilter,'MM').format('MM');
    const month_name= moment(datefilter,'MMM').format('MMM')
    const currentyear = moment(datefilter,'YYYY').format('YYYY');
    this.projectControl.setValue('0');
    this.clientControl.setValue('0');
    this.escalationControl.setValue('0');
    this.checkCurrentDate()
    this.getEvaluationReport(currentyear,month_number,month_name)

  }
  checkCurrentDate(){
    const prevMonth=moment().set('date', 15).subtract(1,'month').format('MM/DD/YYYY')
    if(moment(this.currentDate).isSameOrAfter(prevMonth)){
      this.futureMonthClickDisable=true
    }else{
      this.futureMonthClickDisable=false
    }
    // const oneYearOldDate=moment().set('date', 15).subtract(1,'month').format('MM/DD/YYYY')
    const afterJulyDate='07/15/2021'
    if((moment(this.currentDate).isSameOrBefore(afterJulyDate))){
      this.previousMonthClickDisable=true
    }else{
      this.previousMonthClickDisable=false
    }
  }

  onPrevClick() {
    
    var datefilter=moment(this.currentDate).clone();
    datefilter=moment(datefilter).subtract(1,'month')
    const month_number= moment(datefilter,'MM').format('MM');
    const month_name= moment(datefilter,'MMM').format('MMM')
    const currentyear = moment(datefilter,'YYYY').format('YYYY');
    this.currentDate =moment(month_number+'-15-'+currentyear,'MM/DD/YYYY').format('MM/DD/YYYY')
    // console.log('date',this.currentDate)
    this.checkCurrentDate()
    this.getEvaluationReport(currentyear,month_number,month_name)

  }
  onNextClick() {
    var datefilter=moment(this.currentDate).clone();
    datefilter=moment(datefilter).add(1,'month')
    const month_number= moment(datefilter,'MM').format('MM');
    const month_name= moment(datefilter,'MMM').format('MMM')
    const currentyear = moment(datefilter,'YYYY').format('YYYY');
    this.currentDate =moment(month_number+'-15-'+currentyear,'MM/DD/YYYY').format('MM/DD/YYYY')
    this.checkCurrentDate()
    this.getEvaluationReport(currentyear,month_number,month_name)

  }



  onRowEditInit(evaluation: Evaluation) {
    
    this.clonedEvaluation[evaluation.resource_estimate_master_id] = { ...evaluation };
    this.saveDisplay = this.saveDisplay+1;
    this.saveFlag=true;
  }

  onRowEditSave(evaluation: Evaluation) {
    delete this.clonedEvaluation[evaluation.resource_estimate_master_id];
  }

  onRowEditCancel(evaluation: Evaluation, index: number) {
    this.saveDisplay = this.saveDisplay-1;
    if( this.saveDisplay==0){
      this.saveFlag=false;
    }
    this.evaluationData[index] = this.clonedEvaluation[evaluation.resource_estimate_master_id];
    delete this.clonedEvaluation[evaluation.resource_estimate_master_id];

  }

  chosenstartYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.startDate.value;
    ctrlValue.year(normalizedYear.year());
    this.startDate.setValue(ctrlValue);
  }

  chosenstartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.startDate.value;
    ctrlValue.month(normalizedMonth.month());
    this.startDate.setValue(ctrlValue);
    datepicker.close();
    // this.filterChange()
  }

  


  getClients() {
    this.clients = this.evaluationData.map((item: any) => item.client_name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
  }
  getProjects() {
    this.projects = this.evaluationData.map((item: any) => item.project_name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
  }
  getOverallscore() {
    this.overallscores = this.evaluationData.map((item: any) => item.overall_score).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
  }
  getDeliveryPerformance() {
    this.deliveryPerformances = this.evaluationData.map((item: any) => item.delivery_performance).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
  }
  
  myStateSave(event) {
  }
  changeFn(project) {
   
  // console.log(project.isChanged);
   project.isChanged = 1;
   //console.log(project);
  }
  applyFilters(){
    this.evaluationData = [...this.originalData];
    this.resourceCount = 0;
    this.resourceObj={};
     if (this.projectControl.value !== '0') {

      this.evaluationData = this.evaluationData.filter((item: any) => {
        if (item.project_name === this.projectControl.value) {
          return item;
        }
      });
      
    }

    if (this.escalationControl.value !== '0') {
      this.evaluationData = this.evaluationData.filter((item: any) => {
        if (this.escalationControl.value === 'Yes') {
          if (item.project_escalations === '1') {
            return item;
          }
        }
        if (this.escalationControl.value === 'No') {
          if (item.project_escalations === '0') {
            return item;
          }
        }
      });
    }
    if (this.clientControl.value !== '0') {
        this.evaluationData = this.evaluationData.filter((item: any) => {
          if (item.client_name === this.clientControl.value) {
            return item;
          }
        });
      }
    if (this.overallscoreControl.value !== '0') {
        this.evaluationData = this.evaluationData.filter((item: any) => {
          if (item.overall_score === Number(this.overallscoreControl.value)) {
            return item;
          }
        });
      }
    if (this.deliveryPerformanceControl.value !== '0') {
        this.evaluationData = this.evaluationData.filter((item: any) => {
          if (item.delivery_performance === Number(this.deliveryPerformanceControl.value)) {
            return item;
          }
        });
      }
      this.evaluationData.forEach((item: Evaluation, index: number) => {
        if(!this.resourceObj[item.resource_name]){
          this.resourceObj[item.resource_name]=item.resource_name;
          this.resourceCount+=1;
        }
      });
  }

  // changeStatus(projectescalation,ev){
  //   projectescalation
  // }

  getEvaluationReport(currentyear,month_number,month_name){
    this.saveFlag=false;
    this.loading=true;
    this.resourceCount=0;
    this.resourceObj={};
    this.evaluationData =[];
    this.originalData=this.evaluationData;
    this.userRole = this.storage.getItem("selectedRole");
    this.linkText = 'Resource Evaluation On Projects';
    if(this.userRole === 'Delivery Partner') {
      this.linkText = 'SPDM Evaluation On Projects';
    }
    this.apiService.getProjectEvaluationReport(currentyear,month_number,month_name,this.userRole).then((evaluationReports) => {
      this.evaluationData =evaluationReports;
      this.evaluationData.forEach((item: Evaluation, index: number) => {
        item.month =moment(this.currentDate).format('MMM'); 
        item.year =moment(this.currentDate).format('YYYY');
        item.createdby=this.resource.userID;
        //demonsatrated Solutioning
        if(item.demonstrated_solutioning==='-1'){
          item.is_demonstrated_solutioning=true;
          item.toggleDemonstratedSolutioning=true;
        }else{
          item.is_demonstrated_solutioning=false;
        }

        if(item.demonstrated_solutioning==='1'){
          item.toggleDemonstratedSolutioning=true;
        }
        if(item.demonstrated_solutioning==='0'){
          item.toggleDemonstratedSolutioning=false;
        }
        //projectEscalation
        if(item.project_escalations==='-1'){
          item.is_project_escalations=true;
          item.toggleProjectEscalations=false;
        }else{
          item.is_project_escalations=false;
        }

        if(item.project_escalations==='1'){
          item.toggleProjectEscalations=true;
        }
        if(item.project_escalations==='0'){
          item.toggleProjectEscalations=false;
        }
        
        if(!this.resourceObj[item.resource_name]){
          this.resourceObj[item.resource_name]=item.resource_name;
          this.resourceCount+=1;
        }

        if (item.IsEditable) {
          this.onRowEditInit(item);
          this.dataTable.initRowEdit(item);
        }else{
          
          // this.onRowEditCancel(item,index);
          this.dataTable.cancelRowEdit(item);
        }
      });
      this.originalData=this.evaluationData;
      this.getClients();
      this.getProjects();
      this.getOverallscore();
      this.getDeliveryPerformance();
      this.loading=false;
    }).catch(data=>{
      this.evaluationData =[];
      this.originalData=this.evaluationData;
      this.loading=false;
    })
  }

  changeDemonstratedSolutioningToggle(rowIndex,item,event){
    if(item.toggleDemonstratedSolutioning===true){
      item.demonstrated_solutioning='1';
    }else{
      item.demonstrated_solutioning='0';
    }
    this.changeFn(item);
  }

  changeDemonstratedSolutioning(rowIndex,item,event){
    if(event.target.checked){
      item.is_demonstrated_solutioning=true
      // item.toggleDemonstratedSolutioning=true;
      item.demonstrated_solutioning='-1';
    }else{
      item.is_demonstrated_solutioning=false;
      if(item.toggleDemonstratedSolutioning===true){
        item.demonstrated_solutioning='1';
      }else{
        item.demonstrated_solutioning='0';
      }
      
    }
    this.changeFn(item);
  }

  changeProjectEscalationToggle(rowIndex,item,event){
    if(item.toggleProjectEscalations===true){
      item.project_escalations='1';
    }else{
      item.project_escalations='0';
    }
    this.changeFn(item);
  }

  changeProjectEscalation(rowIndex,item,event){
    if(event.target.checked){
      item.is_project_escalations=true
      // item.toggleDemonstratedSolutioning=true;
      item.project_escalations='-1';
    }else{
      item.is_project_escalations=false;
      if(item.toggleProjectEscalations===true){
        item.project_escalations='1';
      }else{
        item.project_escalations='0';
      }
      
    }
    this.changeFn(item);
  }
  

  onSave() {
    this.evaluationCheckDialog();
  }
  evaluationCheckDialog () {

    this.evaluationCheckData = this.evaluationData.filter((item: any) => {
      if (item.isChanged === 0  && item.IsEditable === 1) {
        item.isChecked =  false;
        return item;
      }
    });
    this.evaluationCheckDataSelection=this.evaluationCheckData;
    //console.log(this.evaluationCheckData);

    if(this.evaluationCheckData.length>0){
      this.displayPopup = true;
      this.onChkChangeHeaderOpenPopup();
    }
    else{
      this.saveFunction();
    }
    //   this.confirmationService.confirm({
    //     message:
    //       'Default evaluation will get submitted for all the resources in the list below. If you donot wish to submit the evaluation now for any of the resources , you may uncheck their names and click OK to proceed',
    //     header: 'Confirm',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: () => {
    //      console.log(this.evaluationData);
    //      this.saveFunction();
    //     },
    //   });
  }
  onChkChangeHeader(){
   // this.onChkChange (this.evaluationCheckData[0]);
   
    this.evaluationCheckData.filter((item: any) => {
      if(this.checkAllEvaluationData){
        item.isChanged = 0;
      }
      else{
        item.isChanged = 1;
      }
      this.onChkChange (item);
    });
    this.checkAllEvaluationData = this.checkAllEvaluationData?false:true;
  }
  onChkChange(e) {
    console.log(e);
   // console.log(this.evaluationData[ri].isChanged=1)
   this.evaluationData = this.evaluationData.filter((item: any) => {
    if (item.project_id ===e.project_id  &&  item.resource_estimate_master_id ===e.resource_estimate_master_id && item.resource_name === e.resource_name) {
          if(item.isChanged === 1){
            item.isChanged =0;
          }
          else {
            item.isChanged =1;
          }
    }
    return item;
  });
  }
  acceptSave(){
    this.saveFunction();
  }
  saveFunction(){
    this.loading=true;
    let that=this;

    this.evaluationEditedData = this.evaluationData.filter((item: any) => {
      if (item.isChanged === 1) {
        if(this.userRole === 'Sub Project Delivery Manager') {
        item.resource_role='Resource';
        }
        else if(this.userRole === 'Engagement Partner') {
          item.resource_role=item.role;
        }
        else {
          item.resource_role= 'SPDM';
        }
        return item;
      }
    });
    
    this.apiService.insertProjectEvaluationDetails(this.evaluationEditedData).then((submitdata)=>{
      if(submitdata[0]){
        that.loading=false;
        Swal.fire("Saved Successfully",'','success')
        var datefilter=moment(that.currentDate).clone();
        datefilter=moment(datefilter)
        const month_number= moment(datefilter).format('MM');
        const month_name= moment(datefilter).format('MMM')
        const currentyear = moment(datefilter).format('YYYY');
        this.selectedClient = '0';
        this.selectedProject = '0';
        this.selectedEscalation = '0';
        this.projectControl.setValue('0');
        this.clientControl.setValue('0');
        this.escalationControl.setValue('0');
    
        that.getEvaluationReport(currentyear,month_number,month_name)
        this.displayPopup = false;
      }
      else{
        Swal.fire("No Changes Detected",'','success')
        this.loading=false;
        this.displayPopup = false;
      }
    }).catch(data=>{
      this.loading=false;
      this.displayPopup = false;
    })
  }
  rejectSave(){
    this.displayPopup = false;
    this.onChkChangeHeaderClosePopup();
  }
  onChkChangeHeaderOpenPopup(){
    // this.onChkChange (this.evaluationCheckData[0]);
    
     this.evaluationCheckData.filter((item: any) => {
         item.isChanged = 0;
      
       this.onChkChange (item);
     });
   }
  onChkChangeHeaderClosePopup(){
    // this.onChkChange (this.evaluationCheckData[0]);
    
     this.evaluationCheckData.filter((item: any) => {
         item.isChanged = 1;
      
       this.onChkChange (item);
     });
   }
   onClientChange(e) {
    //console.log(e);
   if(e==='0'){
    this.projects = this.evaluationData.map((item: any) => item.project_name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
   } 
   else {
   this.projects = this.evaluationData.filter((cl:any)=> cl.client_name === e).map((item: any) => item.project_name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
   }
  }
  onProjectChange(e) {
    //console.log(e);
   if(e==='0'){
    this.clients = this.evaluationData.map((cl: any) => cl.client_name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
   } 
   else {
   this.clients = this.evaluationData.filter((item:any)=> item.project_name === e).map((cl: any) => cl.client_name).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
   }
  }
  clearFilters(){
    this.selectedClient = '0';
    this.selectedProject = '0';
    this.selectedEscalation = '0';
    this.selectedOverallscore = '0';
    this.selectedDeliveryPerformance = '0';
    this.projectControl.setValue('0');
    this.clientControl.setValue('0');
    this.escalationControl.setValue('0');
    this.evaluationData = [...this.originalData];
    this.getProjects();
  }
  onFreeTextChange(freeText){
    // console.log(freeText);
    this.evaluationData = this.evaluationData.filter(o =>
      Object.keys(o).some(k => 
        o.client_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.resource_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.rwt_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.project_name.toLowerCase().includes(freeText.toLowerCase()) 
        || o.remarks?.toLowerCase().includes(freeText.toLowerCase()) 
        ));
        if(freeText === ''){
          this.evaluationData= this.originalData;
        }
  }
 
}
