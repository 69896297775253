import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path:'',
    loadChildren: () => import('src/app/login/login.module').then(m => m.LoginModule),

  },
  {
    path:'home',
    loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule),

  },
  {
    path:'projects',
    loadChildren: () => import('src/app/projects/projects.module').then(m => m.ProjectsModule)

  },
  {
    path:'resource',
    loadChildren: () => import('src/app/resource/resources.module').then(m => m.ResourcesModule)
  },
  {
    path:'resource_manager',
    loadChildren: () => import('src/app/resource_manager/resources-manager.module').then(m => m.ResourcesManagerModule)
  },
  {
    path:'admin',
    loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
