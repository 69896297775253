<ng-container *ngIf="userRole">
   <nav class="navbar  custmnavbar navbar-expand-lg navbar-dark ">
      <div class="container-fluid">
         <a class="navbar-brand" href="#"><img src="assets/images/logo.png"></a>
         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
         </button>
         <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav ml-auto ">
               <!-- <li class="nav-item dropdown my-2 my-lg-0">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 App Switcher
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <a class="dropdown-item" routerLink="/resource_manager">Resource</a>
                     <a class="dropdown-item" routerLink="/">Project</a>
                  </div>
               </li> -->

               <li class="mr-3"><span  style="font-size: 20px;cursor: pointer; outline: none !important;"
                     routerLink="/home"><i  class="fa fa-home fa-homectm" aria-hidden="true"></i></span></li>
               <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                     aria-expanded="false">Quick Menu</a>

                  <div class="dropdown-menu mega-menu p-3">
                     <div class="row">
                        <div class="col hrline brdr">
                           <h5>My...</h5>
                           <a class="dropdown-item" routerLink="/resource/project">Projects</a>
                           <a class="dropdown-item" routerLink="/resource/actuals">Timesheet/Project Actuals History</a>
                           <a class="dropdown-item" routerLink="/resource/profile">Profile</a>
                           
                           <!-- <h5 *ngIf="userRole==='Admin'">Admin Pages</h5>
                           <a class="dropdown-item" *ngIf="userRole==='Admin'" routerLink="/admin/manage-resource">Manage Resource</a>
                           <a  class="dropdown-item" *ngIf="userRole==='Admin'" routerLink="/admin/manage-rwt">Manage Rwt</a> -->
                           <!-- <ng-container *ngIf="userRole==='Admin'">
                              <h6><span>Reports</span></h6>
                              <a class="dropdown-item" *ngIf="userRole==='Admin'"
                                    routerLink="/resource/projectactuals">Actual Report</a>
                              <a class="dropdown-item" *ngIf="userRole==='Admin'"
                                    routerLink="/resource/submissionreport">Submission Status</a>
                           </ng-container> -->

                        </div>
                        <ng-container *ngIf="userRole!='Sub Project Delivery Manager' && userRole!='Billing Manager' && userRole!='Delivery Manager'  && userRole!='Delivery Partner'
                        && userRole!='Engagement Partner' && userRole!='Engagement Manager'  && userRole!='Invoice Manager' && userRole!='Project Team Member'">
                           <div class="col hrline ">
                              <h5>Resource App</h5>
                              <a class="dropdown-item" *ngIf="userRole==='Resource Manager'"
                                 routerLink="/resource_manager/">Dashboard</a>
                              <a class="dropdown-item" *ngIf="userRole==='Resource Manager' || userRole==='PMO Admin' || userRole==='HR Admin'"
                                 routerLink="/resource_manager/timesheet">Project Actuals (Submit Timesheet)</a>
                                 
                              <h6><span>Reports</span></h6>
                              <a class="dropdown-item" *ngIf="userRole==='Resource Manager'"
                                 routerLink="/resource_manager/allocation-report">
                                 Resource Allocations on Projects
                              </a>
                              <a class="dropdown-item" *ngIf="userRole==='Resource Manager'"
                                 routerLink="/resource_manager/submissionreport">Actuals Submission Status
                              </a>
                              <a class="dropdown-item" *ngIf="userRole==='Resource Manager'"
                                 routerLink="/resource_manager/actuals-report">Project Actuals History</a>
                              
                              <a class="dropdown-item" *ngIf="userRole==='PMO Admin' || userRole==='HR Admin'"
                                    routerLink="/resource/submissionreport">Actuals Submission Status</a>
                              <a class="dropdown-item" *ngIf="userRole==='PMO Admin' || userRole==='HR Admin'"
                                    routerLink="/resource/projectactuals">Project Actuals </a>
                              
                           </div>
                        </ng-container>
                        <ng-container *ngIf="userRole==='Sub Project Delivery Manager' || userRole==='Billing Manager' || userRole==='Delivery Manager' || userRole==='Delivery Partner'
                        || userRole==='Engagement Partner' || userRole==='Engagement Manager'  || userRole==='Invoice Manager'">
                           <div class="col hrline">
                              <h5>Project App</h5>
                              <a class="dropdown-item" routerLink="/projects">Projects Lookup</a>
                              <!-- <a   *ngIf="userRole==='Sub Project Delivery Manager' || userRole==='Delivery Partner' || userRole==='Engagement Partner' || userRole==='Engagement Manager'" class="dropdown-item" routerLink="/projects/evaluation">{{linkText}}</a> -->
                              
                              <h6><span>Reports</span></h6>
                              <a class="dropdown-item"  routerLink="/projects/status-report">Project Status History</a>
                              <a class="dropdown-item" routerLink="/projects/allocation-report">Resource Allocations on Projects</a>

                           </div>
                        </ng-container>


                     </div>
                  </div>
               </li>
               <li class="nav-item dropdown  ml-md-2 my-2 my-lg-0">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false">
                     {{userRole}}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <ng-container *ngFor="let user of userRoles">
                        <a class="dropdown-item" (click)="roleSwitcher(user)">{{user}}</a>
                     </ng-container>
                  </div>
               </li>
               <li class="nav-item dropdown usrrole ml-md-2 my-2 my-lg-0">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                     {{userName}} &nbsp;<img src="assets/images/user.svg">
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                     <a class="dropdown-item" href="#" (click)="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </nav>
</ng-container>