
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import * as ConfigJson from '../../../assets/config.json';
import * as ConfigJson from '../../assets/config.json';
export interface Projects 
{
  client_estimate;  client_name;
    end_date; internal_estimate; 
  non_expense_milestone_amount;
   po_number;project_amount;
  project_id; project_name; 
  project_type;resource_estimate;start_date;sub_project_id;sub_project_name;total_invoicce;total_milestone_amount
  
  }
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  base_url = ConfigJson.API_URL
  constructor(private http: HttpClient) {}
  
  getProjects(userRole,userName) {  
      return this.http.get<any>(this.base_url+ "getdashboard?userRole=\'"+userRole+"\'") 
          .toPromise().then(res => <Projects[]>res).then(data => {  return data; });    }
  
}
